// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import AssociationDuesVSOPEXFilter from './Filter';
import AssociationDuesVSOPEXTable from './Table';
import AssociationDuesVSOPEXPagination from './Pagination';
import AssociationDuesVSOPEXGraph from './Graph';

// main component
const AssociationDuesVSOPEX = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <AssociationDuesVSOPEXFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && (
        <>
          <AssociationDuesVSOPEXTable />
          <AssociationDuesVSOPEXPagination />
        </>
      )}
      {toggle && <AssociationDuesVSOPEXGraph selected={selectedFilter} />}
    </Fragment>
  );
};

export default AssociationDuesVSOPEX;
