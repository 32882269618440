// import core & vendor packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// import components below
import Main from '../../../components/Main';
import AdministrativeReportTabs from './Tabs';

// main component
const AdministrativeReport = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Administrative Reports</title>
      </Helmet>
      <Main title='Administrative Reports'>
        <Container fluid>
          <Row>
            <Col>
              <AdministrativeReportTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default AdministrativeReport;
