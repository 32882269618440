/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { submitAmenitiesForm } from '../../../../api/request';
// CSS
import './AmenitiesForm.scss';

// Components

// API

// CSS

// Main Component
const AmenitiesForm = () => {
  const [Amenity, setAmenity] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [rules, setRules] = useState('');
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataSubmit = {
      name: Amenity,
      price: price,
      description: description,
      rules: rules,
    };
    console.log(dataSubmit);
    submitAmenitiesForm(dataSubmit, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setAmenity('');
        setPrice('');
        setDescription('');
        setRules('');
        setMsg('');
        console.log(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };

  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );

  const clearForm = () => {
    setAmenity('');
    setPrice('');
    setPrice('');
    setDescription('');
    setRules('');
  };
  
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className='mb-4'
          controlId='formAmenity'
        >
          <Form.Label>Amenity</Form.Label>
          <Form.Control
            type='text'
            value={Amenity}
            onChange={(e) => setAmenity(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-4'
          controlId='formPrice'
        >
          <Form.Label>Price</Form.Label>
          <Form.Control
            type='number'
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-4'
          controlId='formDescription'
        >
          <Form.Label>Description</Form.Label>
          <Form.Control
            name='description'
            as='textarea'
            rows={7}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-5'
          controlId='formRules'
        >
          <Form.Label>Rules and Regulations</Form.Label>
          <Form.Control
            name='rules'
            as='textarea'
            rows={7}
            value={rules}
            onChange={(e) => setRules(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
            onClick={clearForm}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      {showResponseAPI}
    </Card>
  );
};

export default AmenitiesForm;
