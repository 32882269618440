const UserFriends = () => {
  return (
    <svg viewBox='0 0 27.143 19'>
      <path
        id='user-friends'
        d='M8.143,41.5a4.75,4.75,0,1,0-4.75-4.75A4.748,4.748,0,0,0,8.143,41.5ZM11.4,42.857h-.352a6.558,6.558,0,0,1-5.81,0H4.886A4.887,4.887,0,0,0,0,47.743v1.221A2.036,2.036,0,0,0,2.036,51H14.25a2.036,2.036,0,0,0,2.036-2.036V47.743A4.887,4.887,0,0,0,11.4,42.857ZM20.357,41.5a4.071,4.071,0,1,0-4.071-4.071A4.072,4.072,0,0,0,20.357,41.5Zm2.036,1.357h-.161a5.348,5.348,0,0,1-3.749,0h-.161a4.711,4.711,0,0,0-2.362.653,6.207,6.207,0,0,1,1.684,4.233v1.629c0,.093-.021.182-.025.271h7.49a2.036,2.036,0,0,0,2.036-2.036A4.748,4.748,0,0,0,22.393,42.857Z'
        transform='translate(0 -32)'
      />
    </svg>
  );
};

export default UserFriends;
