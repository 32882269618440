// import core and vendor packages below
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../../../components/UI/TableUI';

// import assets below
import '../TableReports/style.scss';

// main component
const OccupancyReportsTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Details',
        accessor: 'col1',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: 'Total No. of Units',
      },
      {
        col1: 'Turned Over Units from Developer to Owner',
      },
      {
        col1: 'Units Under Owner',
      },
      {
        col1: 'Units Occupied by Tenants',
      },
      {
        col1: 'Units Occupied by Owners',
      },
      {
        col1: 'Vacant Units',
      },
      {
        col1: 'Occupancy Rate',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='dashboardOccupancyReports detailsTable'>
      <TableUI>
        <Table
          responsive
          {...getTableProps()}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(() => (
                      // Apply the header cell props
                      <th key={index}></th>
                    ))
                  }
                </tr>
              ))
            }
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        rowSpan='2'
                        {...column.getHeaderProps()}
                        className={
                          column.Header === 'Parking' ? 'divider' : null
                        }
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default OccupancyReportsTable;
