// Packages
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ViolationsForm from './ViolationsForm/ViolationsForm';
import ViolationsContent from './ViolationsList/ViolationsContent';

// API

// CSS
import './Violations.scss';

// Main Component
const Violations = (props) => {
  console.log(props);

  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const handleSelect = (e) => {
    if (e === 'Violations List') {
      setRefreshList(true);
    }

    setActiveKey(e);
  };

  useEffect(() => {
    setActiveKey('Violations Form');
  }, []);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Violations</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Violations Form'
              id='violations-form'
              className='mb-3 house-rules'
              onSelect={(e) => handleSelect(e)}
              activeKey={activeKey}
            >
              <Tab
                eventKey='Violations Form'
                title='Violations Form'
              >
                <ViolationsForm />
              </Tab>
              <Tab
                eventKey='Violations List'
                title='Violations List'
              >
                <ViolationsContent
                  refreshList={refreshList}
                  setRefreshList={setRefreshList}
                  setActiveKey={setActiveKey}
                />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Violations;
