/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import FormUI from 'components/UI/FormUI';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import './ApplicationForm.scss';
import {
  carStickerSubmitForm,
  getGlobalNameList,
  getparkingSlotNo,
} from 'api/request';

function ApplicationForm({
  buildings,
  setSelectedUnit,
  setSelectedName,
  names,
  setSubmit,
  msg,
  user,
  setMsg,
  userUnit,
  parkingSlots,
}) {
  const [value, onChange] = useState(new Date());
  const [unitId, setUnitId] = useState();
  const [success, setSuccess] = useState('');
  const [submitData, setSubmitData] = useState({
    building: '',
    unit: '',
    user: '',
    date: '',
    modelYear: '',
    carModel: '',
    color: '',
    plateNo: '',
    parkingSlot: '',
    car_sticker_image: '',
    car_sticker_image2: '',
  });
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
    parkingSlots: [],
  });

  const role = user.attributes.role;

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const getNames = (e) => {
    setSelectedUnit(e);
  };

  const onSubmit = (e) => {
    const newFormData = new FormData();
    newFormData.append(
      'unit_id',
      role === 'admin' ? submitData.unit.id : userUnit?.id,
    );
    newFormData.append(
      'user_id',
      role === 'admin' ? submitData.user.id : user?.id,
    );
    newFormData.append(
      'parking_slot_id',
      role === 'admin'
        ? submitData.parkingSlot.id
        : user?.relationships?.owner?.relationships?.units[0].relationships
            ?.parking_slots[0]?.id,
    );
    newFormData.append('date', submitData.date);
    newFormData.append('model_year', submitData.modelYear);
    newFormData.append('car_model', submitData.carModel);
    newFormData.append('color', submitData.color);
    newFormData.append('plate_no', submitData.plateNo);
    newFormData.append('car_model', submitData.plateNo);
    newFormData.append('or_cr_img', submitData.car_sticker_image);
    newFormData.append(
      'parking_lease_agreement_img',
      submitData.car_sticker_image2,
    );
    setSubmit(newFormData);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };

  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);

  useEffect(() => {
    if (parkingSlots && parkingSlots.length > 0) {
      const newSlots = parkingSlots.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.slot_number || `Unnamed Parking Slot ${idx}`,
          label: list?.attributes?.slot_number || `Unnamed Parking Slot ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, parkingSlots: newSlots };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, parkingSlots: [] };
      });
    }
  }, [parkingSlots]);

  useEffect(() => {
    if (role === 'owner') {
      getNames(userUnit.id);
    }
  }, [role]);

  useEffect(() => {
    if (msg.message === 'Car sticker created successfully.') {
      setValue('unit', '');
      setValue('building', '');
      setValue('user', '');
      setValue('date', '');
      setValue('modelYear', '');
      setValue('carModel', '');
      setValue('color', '');
      setValue('plateNo', '');
      setValue('parkingSlot', '');
      setValue('car_sticker_image', '');
      setValue('car_sticker_image2', '');
      setSubmitData({
        building: '',
        unit: '',
        user: '',
        date: '',
        modelYear: '',
        carModel: '',
        color: '',
        plateNo: '',
        parkingSlot: '',
        car_sticker_image: '',
        car_sticker_image2: '',
      });
    }
  }, [msg]);

  return (
    <div className='applicationForm car-sticker'>
      <FormUI>
        <Form
          className=''
          onSubmit={handleSubmit(onSubmit)}
        >
          {role === 'admin' && (
            <Row>
              <div className='col-lg-4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Building Name
                    {errors.building ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.building.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.buildings}
                        placeholder='Select Building'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('building', {
                      required: '(Required)',
                      value: submitData.building,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            building: event.target.value,
                            unit: '',
                            user: '',
                          };
                        });
                        setValue('unit', '');
                        setValue('user', '');
                        setUnitId(event.target.value.id);
                      },
                    })}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Unit No.
                    {errors.unit ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.unit.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.units}
                        placeholder='Select Unit'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('unit', {
                      required: '(Required)',
                      value: submitData.unit,
                      onChange: (event) => {
                        getNames(event.target.value.id);
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            unit: event.target.value,
                            user: '',
                            parkingSlot: '',
                          };
                        });
                        setValue('user', '');
                        setValue('parkingSlot', '');
                      },
                    })}
                  />
                </Form.Group>
              </div>

              <div className='col-lg-4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Name{' '}
                    {errors.user ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.user.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.names}
                        placeholder='Select User'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('user', {
                      required: '(Required)',
                      value: submitData.user,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          setSelectedName(event.target.value.label);
                          setValue('parkingSlot', '');
                          return {
                            ...prevState,
                            user: event.target.value,
                            parkingSlot: '',
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
            </Row>
          )}
          <Row>
            <div className='col-lg-4'>
              <Form.Group className='mb-5'>
                <Form.Label>
                  Date
                  {errors.date ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.date.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  type='date'
                  {...register('date', {
                    required: '(Required)',
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          date: event.target.value,
                        };
                      });
                    },
                  })}
                  value={submitData.date}
                />
              </Form.Group>
            </div>

            <div className='col-lg-4'>
              <Form.Label>
                Model Year{' '}
                {errors.modelYear ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.modelYear.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Model Year Here'
                  {...register('modelYear', {
                    required: '(Required)',
                    value: submitData.modelYear,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          modelYear: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Car Model{' '}
                {errors.carModel ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.carModel.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Car Model Here'
                  {...register('carModel', {
                    required: '(Required)',
                    value: submitData.carModel,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          carModel: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Color{' '}
                {errors.color ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.color.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Color Here'
                  {...register('color', {
                    required: '(Required)',
                    value: submitData.color,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          color: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Plate No.{' '}
                {errors.plateNo ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.plateNo.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Plate No. Here'
                  {...register('plateNo', {
                    required: '(Required)',
                    value: submitData.plateNo,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          plateNo: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            {role === 'admin' && (
              <div className='col-lg-4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Parking Slot No.
                    {errors.parkingSlot ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.parkingSlot.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.parkingSlots}
                        placeholder='Select User'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('parkingSlot', {
                      required: '(Required)',
                      value: FormData.parkingSlot,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            parkingSlot: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
            )}
          </Row>

          <h3>Upload Documents</h3>

          <Row>
            <div className='col-lg-3'>
              <div className='text-start'>
                <Form.Group className='mb-3'>
                  <h5>
                    OR/CR
                    {errors.car_sticker_image ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.car_sticker_image.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </h5>
                  <div className='input_file mb-3'>
                    <Form.Label>
                      <div className='input_file__body'>
                        <div className='svg__wrapper'>
                          <svg
                            width='46.549'
                            height='46.542'
                            viewBox='0 0 46.549 46.542'
                          >
                            <path
                              id='upload'
                              d='M26.911,34.98H19.638A2.177,2.177,0,0,1,17.456,32.8V17.524H9.483a1.815,1.815,0,0,1-1.282-3.1L22.029.586a1.761,1.761,0,0,1,2.482,0L38.348,14.424a1.815,1.815,0,0,1-1.282,3.1H29.093V32.8A2.177,2.177,0,0,1,26.911,34.98Zm19.638-.727V44.435a2.177,2.177,0,0,1-2.182,2.182H2.182A2.177,2.177,0,0,1,0,44.435V34.253a2.177,2.177,0,0,1,2.182-2.182H14.547V32.8a5.1,5.1,0,0,0,5.091,5.091h7.273A5.1,5.1,0,0,0,32,32.8v-.727H44.367A2.177,2.177,0,0,1,46.549,34.253Zm-11.274,8a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,35.275,42.253Zm5.819,0a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,41.094,42.253Z'
                              transform='translate(0 -0.075)'
                              fill='#afafaf'
                            />
                          </svg>
                        </div>
                        <span>
                          {submitData.car_sticker_image && (
                            <>
                              {submitData.car_sticker_image2 && (
                                <>1 File Selected</>
                              )}
                              {!submitData.car_sticker_image2 && (
                                <>No Images Uploaded</>
                              )}
                            </>
                          )}
                          {!submitData.car_sticker_image && (
                            <>No Images Uploaded</>
                          )}
                        </span>
                      </div>
                      <Form.Control
                        accept='image/*'
                        id='file'
                        type='file'
                        {...register('car_sticker_image', {
                          value: submitData.car_sticker_image,
                          onChange: (e) => {
                            // eslint-disable-next-line prefer-const
                            let docuArr = [];

                            Array.from(e.target.files).forEach((file) => {
                              docuArr.push(file);
                            });

                            setSubmitData((prevState) => {
                              return {
                                ...prevState,
                                car_sticker_image: e.target.files[0],
                              };
                            });
                          },
                        })}
                        multiple
                      />
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='text-start'>
                <Form.Group className='mb-3'>
                  <h5>
                    Parking Lease Agreement
                    {errors.car_sticker_image ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.car_sticker_image.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </h5>
                  <div className='input_file mb-3'>
                    <Form.Label>
                      <div className='input_file__body'>
                        <div className='svg__wrapper'>
                          <svg
                            width='46.549'
                            height='46.542'
                            viewBox='0 0 46.549 46.542'
                          >
                            <path
                              id='upload'
                              d='M26.911,34.98H19.638A2.177,2.177,0,0,1,17.456,32.8V17.524H9.483a1.815,1.815,0,0,1-1.282-3.1L22.029.586a1.761,1.761,0,0,1,2.482,0L38.348,14.424a1.815,1.815,0,0,1-1.282,3.1H29.093V32.8A2.177,2.177,0,0,1,26.911,34.98Zm19.638-.727V44.435a2.177,2.177,0,0,1-2.182,2.182H2.182A2.177,2.177,0,0,1,0,44.435V34.253a2.177,2.177,0,0,1,2.182-2.182H14.547V32.8a5.1,5.1,0,0,0,5.091,5.091h7.273A5.1,5.1,0,0,0,32,32.8v-.727H44.367A2.177,2.177,0,0,1,46.549,34.253Zm-11.274,8a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,35.275,42.253Zm5.819,0a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,41.094,42.253Z'
                              transform='translate(0 -0.075)'
                              fill='#afafaf'
                            />
                          </svg>
                        </div>
                        <span>
                          {submitData.car_sticker_image2 && (
                            <>1 File Selected</>
                          )}
                          {!submitData.car_sticker_image2 && (
                            <>No Images Uploaded</>
                          )}
                        </span>
                      </div>
                      <Form.Control
                        accept='image/*'
                        id='file'
                        type='file'
                        {...register('car_sticker_image2', {
                          value: submitData.car_sticker_image2,
                          onChange: (e) => {
                            // eslint-disable-next-line prefer-const
                            let docuArr = [];

                            Array.from(e.target.files).forEach((file) => {
                              docuArr.push(file);
                            });

                            setSubmitData((prevState) => {
                              return {
                                ...prevState,
                                car_sticker_image2: e.target.files[0],
                              };
                            });
                          },
                        })}
                        multiple
                      />
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Row>
          <div className='d-flex justify-content-between'>
            <div>
              <span
                style={{
                  color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                  display: 'block',
                }}
              >{`${msg.message}`}</span>
            </div>
            <Form.Group
              className='buttons text-end'
              style={{ justifyContent: 'end' }}
            >
              <Button
                className='submit mx-1'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel shadow-sm mx-1 '
                style={{ color: '#00B6E9' }}
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </div>
        </Form>
      </FormUI>
    </div>
  );
}

export default ApplicationForm;
