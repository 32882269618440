/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import React, { Fragment, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate, Link } from 'react-router-dom';

function CollectionTable() {
  const navigate = useNavigate();

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Payor',
        accessor: 'payor',
      },

      {
        Header: 'Account Type',
        accessor: 'accountype',
      },
      {
        Header: 'Unit No.',
        accessor: 'unitno',
      },
      {
        Header: 'OR No.',
        accessor: 'orno',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
    ],
    [],
  );
  const staticData = [
    {
      date: '01/31/2021',
      payor: 'Carl Dela Cruz',
      account_type: 'T',
      unit_no: 'A 101',
      or_no: 'OR-0001',
      amount: '100,000,000',
    },
    {
      date: '01/31/2021',
      payor: 'Carl Dela Cruz',
      account_type: 'T',
      unit_no: 'A 101',
      or_no: 'OR-0001',
      amount: '100,000,000',
    },
    {
      date: '01/31/2021',
      payor: 'Carl Dela Cruz',
      account_type: 'T',
      unit_no: 'A 101',
      or_no: 'OR-0001',
      amount: '100,000,000',
    },
  ];
  // status handler
  const statusHandler = (status) => {
    return (
      <Fragment>
        {status === 'pending' && <p style={{ color: '#D9A121' }}>Pending</p>}
        {status === 'denied' && <p className='text-danger'>Denied</p>}
        {status === 'approved' && (
          <p style={{ color: 'rgb(72, 217, 111)' }}>Approved</p>
        )}
      </Fragment>
    );
  };
  const view = (reference_id) => {
    return (
      <u
        onClick={() =>
          navigate(`/finance/summaryReports/summary-collections/view-graph`)
        }
        style={{ cursor: 'pointer' }}
      >
        {reference_id}
      </u>
    );
  };

  // Data of the table
  const data = React.useMemo(() => {
    return staticData.map((data, idx) => {
      return {
        date: data?.date,
        payor: data?.payor,
        accountype: data?.account_type,
        unitno: data?.unit_no,
        orno: view(data?.or_no),
        amount: data?.amount,
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='pet-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => {
                      // Apply the header cell props

                      return (
                        <th
                          key={idx}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CollectionTable;
