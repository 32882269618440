import React, { useState } from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Main from '../../../../components/Main';
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Card,
  Button,
} from 'react-bootstrap';
function UploadProofPayment() {
  const [submitData, setSubmitData] = useState();

  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Budget File'
                id='Upload Budget File'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Upload Budget File'
                  title='Upload Budget File'
                  aria-selected='true'
                >
                  <Card className='admin-form shadow-sm'>
                    <Form>
                      <Row className='container'>
                        <Col>
                          <Form.Label className='mb-4 h5'>
                            Upload Image
                          </Form.Label>
                          <h5>
                            Bank Name - BDO Unibank <br />
                            Bank Account Details - XXXX-XXXX-XXXX
                          </h5>
                        </Col>
                        <Col>
                          <Row>
                            <Form.Group
                              className='mb-4'
                              controlId='formUpload'
                            >
                              <Form.Label className='mb-4 h5'>
                                Upload Image
                              </Form.Label>

                              <div className='upload-cont d-flex justify-content-start'>
                                <div className='upload-input'>
                                  <label htmlFor='formUpload'>
                                    Select file
                                  </label>
                                  <Form.Control
                                    type='file'
                                    className='custom-file-upload'
                                    aria-label='Click to Select File to Upload'
                                    onChange={(e) => {
                                      // eslint-disable-next-line prefer-const
                                      let docuArr = [];

                                      Array.from(e.target.files).forEach(
                                        (file) => {
                                          docuArr.push(file);
                                        },
                                      );

                                      setSubmitData(e.target.files[0]);
                                    }}
                                  ></Form.Control>
                                </div>

                                <span>{submitData && '1 File Selected'}</span>
                                <span style={{ color: '#AFAFAF' }}>
                                  {!submitData && 'No Image Uploaded'}
                                </span>
                              </div>
                            </Form.Group>
                          </Row>
                        </Col>
                      </Row>

                      <Form.Group
                        className='buttons'
                        style={{ justifyContent: 'end' }}
                      >
                        <Button
                          className='submit'
                          variant='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                        <Button
                          className='cancel'
                          variant='default'
                          style={{ color: '#00B6E9' }}
                        >
                          Cancel
                        </Button>
                      </Form.Group>
                    </Form>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default UploadProofPayment;
