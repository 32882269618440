/* eslint-disable no-unused-vars */
// import core & vendor packages
import { Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import components below
import FormUI from '../../../../components/UI/FormUI';

// import assets below
import './Form.scss';
import { moveDenySubmitForm } from 'api/request';

// main component
const ConcernForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [remarks, setRemarks] = useState('');
  // response api here
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  const onsubmitHandler = (e) => {
    e.preventDefault();
    const params = {
      remarks: remarks,
    };
    moveDenySubmitForm(id, params, callback);
  };
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        console.log(data.message);

        setMsg({
          message: 'Request Move denied successfully.',
          status: 'success',
        });

        setTimeout(() => {
          navigate(-2);
        }, 3000);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        console.log(data.message);
        setSuccess('');
      }
    }
  };

  return (
    <Form
      onSubmit={onsubmitHandler}
      className='concernForm'
    >
      <FormUI>
        <Form.Group>
          <Form.Label>Remarks</Form.Label>
          <Form.Control
            as='textarea'
            rows='12'
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Form.Group>
        <div className='d-flex justify-content-between'>
          <div style={{ color: '#3bd14d' }}>{msg.message}</div>
          <div className='formUI__footer'>
            <Button
              type='submit'
              variant='submit'
            >
              Submit
            </Button>
            <Button variant='cancel'>Cancel</Button>
          </div>
        </div>
      </FormUI>
    </Form>
  );
};

export default ConcernForm;
