/* eslint-disable camelcase */
// packages
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';

// CSS
import './UnitNumberTable.scss';

// Components

// API
import { updateBuildingUnitDetails } from 'api/request';

// CSS

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  column: { Header }, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (id === 'classifications') {
    return (
      <Form.Select
        aria-label='Classifications'
        id='classifications'
        className='editable-cell'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{ backgroundColor: '#F9F9F9' }}
      >
        <option
          value=''
          hidden
        >
          Select Classification
        </option>
        <option value='commercial'>Commercial</option>
        <option value='residential'>Residential</option>
      </Form.Select>
    );
  }

  return (
    <input
      value={value}
      placeholder={`Enter ${Header} Here`}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={id === 'number'}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop

  // the current page from resetting when our data changes

  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but

        // anything we put into these options will

        // automatically be available on the instance.

        // That way we can call this function from our

        // cell renderer!
        updateMyData,
      },
      usePagination,
    );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, idx) => (
                <th
                  key={idx}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, ri) => {
            prepareRow(row);
            return (
              <tr
                key={ri}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, ci) => {
                  return (
                    <td
                      key={ci}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className='pagination'>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
    </>
  );
}

// Main Component
function UnitNumberTable({ unitList, setUpdateMsg }) {
  // Static columns used in table
  const columns = React.useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'number',
      },
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Unit Area',
        accessor: 'unitArea',
      },
      {
        Header: 'Classifications',
        accessor: 'classifications',
      },
      {
        Header: 'Building Floor',
        accessor: 'buildingFloor',
      },
    ],
    [],
  );

  // Data used in table
  const [data, setData] = React.useState([]);

  useEffect(() => {    
    setData(() => {
      return unitList.map((list, idx) => {
        return {
          id: list.id,
          number: parseInt(idx + 1),
          unitNo: list.attributes.unit_number ? list.attributes.unit_number : '',
          unitArea: list.attributes.unit_area ? list.attributes.unit_area : '',
          classifications: list.relationships.classification ? list.relationships.classification.attributes.name : '',
          buildingFloor: list.attributes.building_floor ? list.attributes.building_floor : '',
        };
      });
    });
  }, [unitList]);

  // useEffect(() => { console.log(data); }, [data]);

  // const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we

  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use

  // the rowIndex, columnId and new value to update the

  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          submitUpdate({
            ...old[rowIndex],
            [columnId]: value,
          });

          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const submitUpdate = (list) => {
    setUpdateMsg({ message: '', status: '' });

    const params = {
      unit_number: list.unitNo,
      unit_area: list.unitArea,
      building_floor: list.buildingFloor,
      classification: list.classifications,
    };

    updateBuildingUnitDetails(list.id, params, callback);
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
          setUpdateMsg({ message: 'Successfully updated', status: 'success' });
        setTimeout(() => {
        }, 2000);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setUpdateMsg({ message: 'Something went wrong.', status: 'failed' });
      }
    }
  };

  // After data chagnes, we turn the flag back off

  // so that if data actually changes when we're not

  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help

  // illustrate that flow...

  return (
    <>
      {/* <button onClick={resetData}>Reset Data</button> */}
      <div className='unit-table'>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
        />
      </div>
    </>
  );
}

export default UnitNumberTable;
