const Dog = () => {
  return (
    <svg viewBox='0 0 24.534 23.002'>
      <path
        id='dog'
        d='M44.749,41.173l7.185,2.566V54.206a.767.767,0,0,1-.767.767H48.1a.767.767,0,0,1-.767-.767V48.84H39.667v5.367a.767.767,0,0,1-.767.767H35.833a.767.767,0,0,1-.767-.767V43.956A4.592,4.592,0,0,1,32,39.64a1.533,1.533,0,1,1,3.067,0A1.536,1.536,0,0,0,36.6,41.173Zm11.785-5.367V37.34a3.067,3.067,0,0,1-3.067,3.067H51.934v1.7L45.8,39.921V32.739a.766.766,0,0,1,1.309-.542l1.307,1.309h2.57a1.677,1.677,0,0,1,1.371.848l.343.686h3.067A.767.767,0,0,1,56.534,35.806Zm-5.367,0a.767.767,0,1,0-.767.767A.767.767,0,0,0,51.167,35.806Z'
        transform='translate(-32 -31.971)'
      />
    </svg>
  );
};

export default Dog;
