/* eslint-disable no-unused-vars */
// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';
import React, { useState, useEffect, useContext } from 'react';
// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import View from './View';

// main component
const ViewRequestTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='viewDetails'
        className='mb-3'
      >
        <Tab
          eventKey='viewDetails'
          title='View Details'
        >
          <View />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default ViewRequestTabs;
