/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

import Select from 'react-select';

import { useForm, Controller } from 'react-hook-form';
// CSS
import './ViolationForm.scss';
import ViolationReports from 'pages/Dashboard/AdministrativeReport/ViolationReports';

const noOfOffense = [
  {
    id: 1,
    value: 1,
    title: '1st Offense',
  },
  {
    id: 2,
    value: 2,
    title: '2nd Offense',
  },
  {
    id: 3,
    value: 3,
    title: '3rd Offense',
  },
  {
    id: 4,
    value: 4,
    title: '4th Offense',
  },
  {
    id: 5,
    value: 5,
    title: '5th Offense',
  },
];
// Main Component
const ViolationForm = ({
  buildings,
  setSelectedUnit,
  names,
  setSubmit,
  msg,
  setMsg,
  violations,
  violationForm,
  incidentreportData,
}) => {
  // States
  const [name, setName] = useState('');
  const [unitId, setUnitId] = useState();
  const [submitData, setSubmitData] = useState({
    violationType: '',
    unitNoL: '',
    tenantName: '',
    date: '',
    time: '',
    noOfOffense: '',
    reportDetails: '',
    penalty: '',
    images: [],
  });
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
    parkingSlots: [],
  });

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // Will fire after submitting form
  const onSubmit = (e) => {
    const newForm = new FormData();
    newForm.append('violation_id', submitData.violationType);
    newForm.append('date', submitData.date);
    newForm.append('time', submitData.time);
    newForm.append('building_id', submitData.building.id);
    newForm.append('unit_id', submitData.unit.id);
    newForm.append('user_id', submitData.user.id);
    newForm.append('report_details', submitData.reportDetails);
    newForm.append('penalty', submitData.penalty);
    for (let i = 0; i < submitData.images.length; i++) {
      newForm.append(`violation_report_images[${i}]`, submitData.images[i]);
    }
    setSubmit(newForm);
  };
  useEffect(() => {
    setSubmit(null);
    setMsg({ message: '', status: '' });
  }, []);
  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);
  const getNames = (e) => {
    setSelectedUnit(e);
  };
  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  // To render on screen
  return (
    <Card className='admin-form violation-form shadow-sm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {violationForm && (
          <div>
            <p className='h6 fw-bold mb-4'>
              Convert Incident Report to Violation Report
            </p>
            <Row>
              <Col>
                <p className='fw-bold'>Incident No.</p>
                <p>{incidentreportData.reference_id}</p>
              </Col>
              <Col>
                <p className='fw-bold'>Subject</p>
                <p>Incident report</p>
              </Col>
            </Row>
          </div>
        )}
        <Row>
          <Col>
            <Form.Group className='mb-5'>
              <Form.Label>
                Building Name
                {errors.building ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.building.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    options={data.buildings}
                    placeholder='Select Building'
                    styles={customStyles}
                    {...field}
                  ></Select>
                )}
                {...register('building', {
                  required: '(Required)',
                  value: submitData.building,
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return {
                        ...prevState,
                        building: event.target.value,
                        unit: '',
                        user: '',
                      };
                    });
                    setValue('unit', '');
                    setValue('user', '');
                    setUnitId(event.target.value.id);
                  },
                })}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className='mb-5'>
              <Form.Label>
                Unit No.
                {errors.unit ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.unit.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    options={data.units}
                    placeholder='Select Unit'
                    styles={customStyles}
                    {...field}
                  ></Select>
                )}
                {...register('unit', {
                  required: '(Required)',
                  value: submitData.unit,
                  onChange: (event) => {
                    getNames(event.target.value.id);
                    setSubmitData((prevState) => {
                      return {
                        ...prevState,
                        unit: event.target.value,
                        user: '',
                        parkingSlot: '',
                      };
                    });
                    setValue('user', '');
                    setValue('parkingSlot', '');
                  },
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-5'>
              <Form.Label>
                Name{' '}
                {errors.user ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.user.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    options={data.names}
                    placeholder='Select User'
                    styles={customStyles}
                    {...field}
                  ></Select>
                )}
                {...register('user', {
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return {
                        ...prevState,
                        user: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {' '}
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formViolationType'
            >
              <Form.Label>
                Violation Type{' '}
                {errors.violationType ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.violationType.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Select
                aria-label='Select Title'
                {...register('violationType', {
                  required: 'Select Type',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return {
                        ...prevState,
                        violationType: event.target.value,
                      };
                    });
                  },
                })}
                value={data.violationType}
              >
                <option
                  value=''
                  hidden
                >
                  Select Violation Here
                </option>
                {violations.map((val) => {
                  return (
                    <option
                      key={val.id}
                      value={val.id}
                    >
                      {val.attributes.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formDate'
            >
              <Form.Label>
                Date{' '}
                {errors.date ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.date.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='date'
                value={incidentreportData.date}
                aria-label='Select Date Here'
                placeholder='Enter Date Here'
                {...register('date', {
                  required: 'Select Date',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return { ...prevState, date: event.target.value };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formTime'
            >
              <Form.Label>
                Time{' '}
                {errors.time ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.time.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='time'
                aria-label='Select Time Here'
                placeholder='Enter Subject Here'
                {...register('time', {
                  required: 'Select Time',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return { ...prevState, time: event.target.value };
                    });
                  },
                })}
                value={incidentreportData.time}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formReportDetails'
            >
              <Form.Label>
                Report Details{' '}
                {errors.reportDetails ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.reportDetails.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder='Enter Report Details Here'
                {...register('reportDetails', {
                  required: 'Enter Report Details',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return {
                        ...prevState,
                        reportDetails: event.target.value,
                      };
                    });
                  },
                })}
                value={incidentreportData.reportDetails}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formPenalty'
            >
              <Form.Label>
                Penalty{' '}
                {errors.penalty ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.penalty.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder='Enter Penalty Here'
                {...register('penalty', {
                  required: 'Enter Penalty',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return { ...prevState, penalty: event.target.value };
                    });
                  },
                })}
                value={incidentreportData.penalty}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='violation-upload-images'>
              <Form.Group
                className='mb-2'
                controlId='formUpload'
              >
                <Form.Label>
                  Upload Images{' '}
                  {errors.images ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.images.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>

                <div className='upload-cont image-upload'>
                  <div className='upload-input'>
                    <label htmlFor='formUpload'>Select files</label>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      accept='.png, .jpg, .jpeg'
                      multiple
                      {...register('images', {
                        required: 'Images Required',
                        onChange: (e) => {
                          const docuArr = [];

                          Array.from(e.target.files).forEach((file) => {
                            docuArr.push(file);
                          });

                          setSubmitData((prevState) => {
                            return { ...prevState, images: docuArr };
                          });
                        },
                      })}
                    ></Form.Control>
                  </div>
                  {submitData.images.length > 0 && (
                    <span>{submitData.images.length} File Selected</span>
                  )}
                  {!submitData.images.length === 0 && (
                    <span>No Images Uploaded</span>
                  )}
                </div>
              </Form.Group>
            </div>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formPreparedBy'
            >
              <Form.Label>
                Prepared By{' '}
                {errors.preparedBy ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.preparedBy.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Enter Name Here'
                placeholder='Enter Name Here'
                {...register('preparedBy', {
                  required: 'Enter Name',
                  onChange: (event) => {
                    setSubmitData((prevState) => {
                      return { ...prevState, preparedBy: event.target.value };
                    });
                  },
                })}
                value={data.preparedBy}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel'
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ViolationForm;
