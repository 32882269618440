/* eslint-disable no-unused-vars */
// import core & vendor packages
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../components/UI/TabsUI';
import WorkPermitForm from './Form';
import WorkPermitFilter from './Filter';
import WorkPermitTable from './Table';
import userContext from 'components/userContext/userContext';
import TablePagination from 'components/Pagination';

// API
import { getBuildingLists, userAccounts } from 'api/request';
import {
  submitWorkPermit,
  getWorkPermits,
  cancelWorkPermit,
} from 'api/WorkPermit';

// main component
const WorkPermitTabs = () => {
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?include=unit,building,user,personnels,requestedBy&building_id=${userUnit?.buildingId}${page}&filter[search]=${search}&unit_id=${userUnit?.id}`;
      getWorkPermits(params, requestAmenitiesCallback);
    } else {
      const params = `?include=unit,building,user,personnels&building_id=${selectedBuilding}${page}&filter[search]=${search}`;
      getWorkPermits(params, requestAmenitiesCallback);
    }
  };

  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      setLoading(true);
      const params = `?include=unit,building,user,personnels&building_id=${userUnit?.buildingId}&unit_id=${userUnit?.id}`;
      getWorkPermits(params, requestAmenitiesCallback);
    } else {
      setLoading(true);
      const params = `?include=unit,building,user,personnels&building_id=${selectedBuilding}`;
      getWorkPermits(params, requestAmenitiesCallback);
    }
  };

  const deleteSingleListData = (storeId) => {
    cancelWorkPermit(storeId, cancelCallback);
  };

  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=unit,building,user,personnels&building_id=${userUnit?.buildingId}&unit_id=${userUnit?.id}`;
        getWorkPermits(params, requestAmenitiesCallback);
      } else {
        const params = `?include=unit,building,user,personnels&building_id=${selectedBuilding}`;
        getWorkPermits(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=unit,building,user,personnels&building_id=${userUnit?.buildingId}&filter[search]=${search}&unit_id=${userUnit?.id}`;
        getWorkPermits(params, requestAmenitiesCallback);
      } else {
        const params = `?include=unit,building,user,personnels&building_id=${selectedBuilding}&filter[search]=${search}`;
        getWorkPermits(params, requestAmenitiesCallback);
      }
    }
  }, [search]);

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
  }, []);

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      submitWorkPermit(submit, submitCallback);
    }
  }, [submit]);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      const params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const cancelCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        refreshTable();
        setMsg({ message: data.message, status: 'success' });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='workPermitForm'
        className='mb-3'
      >
        <Tab
          eventKey='workPermitForm'
          title='Work Permit Form'
        >
          {loading && <p>Loading...</p>}
          {!loading && (
            <WorkPermitForm
              buildings={buildings}
              setSelectedUnit={setSelectedUnit}
              names={names}
              setSubmit={setSubmit}
              msg={msg}
              user={user}
              userUnit={userUnit}
              setMsg={setMsg}
            />
          )}
        </Tab>
        <Tab
          eventKey='workPermitList'
          title='Work Permit List'
        >
          <WorkPermitFilter
            setSearch={setSearch}
            buildings={buildings}
            setSelectedBuilding={setSelectedBuilding}
            selectedBuilding={selectedBuilding}
            user={user}
            userUnit={userUnit}
          />
          <WorkPermitTable
            tableData={tableData}
            user={user}
            refreshTable={refreshTable}
            deleteSingleListData={deleteSingleListData}
            msg={msg}
          />
          <TablePagination
            links={links}
            meta={meta}
            selectPage={selectPage}
          />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default WorkPermitTabs;
