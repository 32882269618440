// import core & vendor packages below
import { Col } from 'react-bootstrap';

// import components below
import CollectionEfficiencyTableLeft from './TableLeft';
import CollectionEfficiencyTableRight from './TableRight';

// main component
const CollectionEfficiencyTable = () => {
  return (
    <div className='collectionEfficiency'>
      <Col lg='4'>
        <CollectionEfficiencyTableLeft />
      </Col>
      <Col lg='8'>
        <CollectionEfficiencyTableRight />
      </Col>
    </div>
  );
};

export default CollectionEfficiencyTable;
