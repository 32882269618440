import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import './ViewCollectionDetails.scss';

function ViewCollectionDetails() {
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Collection Details '
                id='View Collection Details '
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View Collection Details '
                  title='View Collection Details '
                >
                  <div className='viewRequest shadow-md'>
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Unit No.</p>
                        <p>A 101</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Type</p>
                        <p>C</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Payor</p>
                        <p>Juan Dela Cruz</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>SOA #</p>
                        <p>1002401</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Month</p>
                        <p>Jan</p>
                      </div>
                    </Row>

                    <Row className='mt-5'>
                      <div className='col-lg'>
                        <p className='h5 fw-bold'>OR #</p>
                        <p>0110</p>
                      </div>
                      <div className='col-lg'>
                        <p className='h5 fw-bold'>OR Date</p>
                        <p>01/21/2021</p>
                      </div>
                      <div className='col-lg'>
                        <p className='h5 fw-bold'>Amount</p>
                        <p>231,010,000.00</p>
                      </div>
                      <div className='col-lg'></div>
                      <div className='col-lg'></div>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default ViewCollectionDetails;
