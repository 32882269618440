// import core & vendor packages below
import { Chart } from 'react-google-charts';

// import assets below
import './style.scss';

// main component
const CollectionsGraph = (props) => {
  const { selected } = props;

  const optionsCollectionsMonthly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'none' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    bar: { groupWidth: '90%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [
        0, 200000, 400000, 600000, 800000, 1000000, 1200000, 1400000, 1600000,
        1800000, 2000000,
      ],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsMonthly = [
    [
      'Year',
      'Value',
      { type: 'number', role: 'tooltip', p: { html: true } },
      { role: 'annotation' },
    ],
    ['JAN', 200000, 200000, 200000],
    ['FEB', 400000, 400000, 400000],
    ['MAR', 800000, 800000, 800000],
    ['APR', 900000, 900000, 900000],
    ['MAY', 500000, 500000, 500000],
    ['JUN', 1900000, 1900000, 1900000],
    ['JUL', 450000, 450000, 450000],
    ['AUG', 200000, 200000, 200000],
    ['SEP', 1200000, 1200000, 1200000],
    ['OCT', 230000, 230000, 230000],
    ['NOV', 600000, 600000, 600000],
    ['DEC', 1200000, 1200000, 1200000],
  ];

  const optionsCollectionsQuarterly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'none' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 1000000, 2000000, 3000000, 4000000, 5000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsQuarterly = [
    [
      'Quarter',
      'Value',
      { type: 'number', role: 'tooltip', p: { html: true } },
      { role: 'annotation' },
    ],
    ['Q1', 4200000, 4200000, 4200000],
    ['Q2', 3400000, 3400000, 3400000],
    ['Q3', 4800000, 4800000, 4800000],
    ['Q4', 1900000, 1900000, 1900000],
  ];

  const optionsCollectionsYearly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'none' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 1000000, 2000000, 3000000, 4000000, 5000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsYearly = [
    [
      'Year',
      'Value',
      { type: 'number', role: 'tooltip', p: { html: true } },
      { role: 'annotation' },
    ],
    ['2021', 4600000, 4600000, 4600000],
    ['2022', 1400000, 1400000, 1400000],
  ];

  return (
    <div className='collectionsGraph'>
      {selected === 'Total' && <span>No total graph in XD</span>}
      {selected === 'Monthly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsMonthly}
          data={dataCollectionsMonthly}
        />
      )}
      {selected === 'Quarterly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsQuarterly}
          data={dataCollectionsQuarterly}
        />
      )}
      {selected === 'Yearly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsYearly}
          data={dataCollectionsYearly}
        />
      )}
    </div>
  );
};

export default CollectionsGraph;
