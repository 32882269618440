// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Form.scss';

// API
import { getGatePassByReferenceId, denyGatePass } from 'api/request';

// main component
const DenyForm = () => {
  // Useform
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const [msg, setMsg] = useState({
    message: '',
    status: '',
  });

  const [userData, setUserData] = useState({
    bearer: '',
    unitNo: '',
    dateTime: '',
    controlNo: '',
    preparedBy: '',
    ownersApproval: '',
    items: '',
    status: '',
    remarks: '',
  });

  const onSubmit = () => {
    setMsg({ message: '', status: '' });

    const params = {
      remarks: userData.remarks,
    };

    denyGatePass(id, params, denyCallback);
  };

  useEffect(() => {
    getGatePassByReferenceId(id, '', callback);
  }, []);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const items = data.data.relationships.items.map((item, idx) => {
          return {
            id: item?.id,
            itemNo: parseInt(idx + 1),
            quantity: item?.attributes?.quantity,
            unit: item?.attributes?.unit,
            description: item?.attributes?.description,
            inventoryTag: item?.attributes?.inventory_tag,
          };
        });

        setUserData((prevState) => {
          return {
            ...prevState,
            bearer: data?.data?.attributes?.bearer,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            dateTime: data?.data?.attributes?.scheduled_at,
            controlNo: data?.data?.attributes?.control_number,
            preparedBy: data?.data?.attributes?.prepared_by,
            status: data?.data?.attributes?.status,
            ownersApproval: '',
            items: items,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const denyCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Denied Successfully', status: 'success' });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  return (
    <div className='gatePass_denyForm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormUI>
          <h6>SUMMMARY OF REQUEST</h6>
          <Row>
            <Col>
              <div>
                <h6 className='mb-3'>Bearer</h6>
                <span>{userData.bearer}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className='mb-3'>Unit No.</h6>
                <span>{userData.unitNo}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className='mb-3'>Date and Time</h6>
                <span>{userData.dateTime}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg='4'>
              <Form.Group>
                <h6 className='mb-3'>Control No.</h6>
                <span>{userData.controlNo}</span>
              </Form.Group>
            </Col>
          </Row>
          <h6>DESCRIPTION</h6>
          <Row>
            <Col>
              <div>
                <h6>Item No.</h6>
              </div>
            </Col>
            <Col>
              <div>
                <h6>Quantity</h6>
              </div>
            </Col>
            <Col>
              <div>
                <h6>Unit</h6>
              </div>
            </Col>
            <Col>
              <div>
                <h6>Description</h6>
              </div>
            </Col>
            <Col>
              <div>
                <h6>Inventory Tag</h6>
              </div>
            </Col>
          </Row>
          {userData.items &&
            userData.items.map((list) => {
              return (
                <Row key={list.id}>
                  <Col>
                    <div>
                      <span>{list.itemNo}</span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <span>{list.quantity}</span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <span>{list.unit}</span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <span>{list.description}</span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <span>{list.inventoryTag}</span>
                    </div>
                  </Col>
                </Row>
              );
            })}
          <Row>
            <Col>
              <div>
                <h6 className='mt-4 mb-3'>Prepared by</h6>
                <span>{userData.preparedBy}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Remarks{' '}
                  {errors.remarks ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.remarks.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows='10'
                  {...register('remarks', {
                    required: '(Required)',
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          remarks: event.target.value,
                        };
                      });
                    },
                  })}
                  value={userData.remarks}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='send-button'>
            <Col
              className='message-text-result'
            >
              <span
                style={{
                  color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                  display: 'block',
                }}
              >{`${msg.message}`}</span>
            </Col>
            <Col className='send-button-cont'>
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button
                variant='cancel'
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </FormUI>
      </Form>
    </div>
  );
};

export default DenyForm;
