const FileSignature = () => {
  return (
    <svg viewBox='0 0 26.859 23.875'>
      <path
        id='file-signature'
        d='M10.173,19.778a.517.517,0,0,0-.474-.3.509.509,0,0,0-.467.289l-.358.715a.746.746,0,0,1-1.375-.1l-.785-2.353L6.22,19.514a2.013,2.013,0,0,1-1.912,1.377H3.73a.746.746,0,0,1,0-1.492h.578a.523.523,0,0,0,.5-.357l.848-2.548a1.12,1.12,0,0,1,2.124,0l.647,1.942a2.093,2.093,0,0,1,3.078.66.529.529,0,0,0,.436.292V15.558l5.969-5.926V7.461H11.564a1.122,1.122,0,0,1-1.119-1.119V0H1.119A1.116,1.116,0,0,0,0,1.119V22.756a1.116,1.116,0,0,0,1.119,1.119H16.787a1.116,1.116,0,0,0,1.119-1.119V20.891l-5.969-.005A2,2,0,0,1,10.173,19.778ZM17.906,5.684A1.116,1.116,0,0,0,17.58,4.9L13.015.326A1.118,1.118,0,0,0,12.222,0h-.284V5.969h5.969ZM13.43,16.179V19.4h3.217l7.539-7.591L21.021,8.643ZM26.512,7.8,25.025,6.317a1.188,1.188,0,0,0-1.679,0L22.076,7.587l3.165,3.165,1.271-1.271A1.187,1.187,0,0,0,26.512,7.8Z'
      />
    </svg>
  );
};

export default FileSignature;
