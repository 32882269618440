/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS

// Components

// API

// CSS

// Main Component
const ViewForm = () => {
  // States
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };

  // To render on screen
  return (
    <Card className='admin-form view-form shadow-sm'>
      <Form>
        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='name'
            >
              <Form.Label>Concern</Form.Label>

              <Form.Control
                aria-label='Concern'
                type='text'
                placeholder='Concern here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='name'
            >
              <Form.Label>Subject</Form.Label>

              <Form.Control
                aria-label='Subject'
                type='text'
                placeholder='Subject here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='name'
            >
              <Form.Label>Unit No.</Form.Label>

              <Form.Control
                aria-label='Unit No.'
                type='text'
                placeholder='Unit No. here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='name'
            >
              <Form.Label>Name</Form.Label>

              <Form.Control
                aria-label='Name'
                type='text'
                placeholder='Name here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='description'
            >
              <Form.Label>Description</Form.Label>

              <Form.Control
                aria-label='Description'
                type='text'
                placeholder='Description here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='images'
            >
              <Form.Label>Images</Form.Label>

              {/* <Form.Control
                aria-label='Images'
                type='text'
                placeholder='Images here'
                readOnly
              /> */}
              <div style={{ display: 'block', backgroundColor: 'grey', width: '400px', height: '250px' }}></div>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            as={Link}
            to={'/my-tenant/request?activeKey=my-tenant'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Back
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViewForm;
