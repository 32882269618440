// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import LoginPage from '../pages/Login';
import ForgotPasswordPage from '../pages/ForgotPassword';
import ResetpasswordPage from '../pages/ResetPassword';

const LoginRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<LoginPage />}
      />
      <Route
        path='/forgot-password'
        element={<ForgotPasswordPage />}
      />
      <Route
        path='/reset-password'
        element={<ResetpasswordPage />}
      />
    </Routes>
  );
};

export default LoginRouter;
