/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab, Button, Form } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
// Components
import Main from '../../../../components/Main';
import './staffUpload.scss';
import { useEffect } from 'react';
import {
  carStickerApprove,
  staffIDapprove,
  viewStaffIDRequestForm,
} from 'api/request';
function StaffViewRequest() {
  const { id, selectedbuilding } = useParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    home_address: '',
    mobile_no: '---',
    date_of_birth: '',
    gender: '',
    citizenship: '',
    occupation: '',
    contact_person: '',
    contact_no: '',
    id_picture_img: '',
    nbi_clearance_img: '',
    valid_id_img: '',
  });
  useEffect(() => {
    console.log(id, selectedbuilding);
    viewStaffIDRequestForm(selectedbuilding, id, callback);
  }, []);
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data?.data[0]);

        setUserData((prev) => {
          return {
            ...prev,
            name: data?.data[0]?.attributes?.name,
            home_address: data?.data[0]?.attributes?.home_address,
            mobile_no: data?.data[0]?.attributes?.mobile_number,
            date_of_birth: data?.data[0]?.attributes?.date_of_birth,
            gender: data?.data[0]?.attributes?.gender,
            citizenship: data?.data[0]?.attributes?.citizenship,
            occupation: data?.data[0]?.attributes?.occupation,
            contact_person: data?.data[0]?.attributes?.emergency_contact_person,
            contact_no: data?.data[0]?.attributes?.emergency_contact_number,
            id_picture_img: data?.data[0]?.attributes?.id_picture_img.name,
            nbi_clearance_img:
              data?.data[0]?.attributes?.nbi_clearance_img.name,
            valid_id_img: data?.data[0]?.attributes?.valid_id_img.name,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  const approveHandler = (e) => {
    const params = '';
    staffIDapprove(id, params, approvecallback);
  };
  const approvecallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        console.log(data.message);

        setMsg({
          message: 'Request staff approved successfully.',
          status: 'success',
        });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Request'
                id='View Request'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View Request'
                  title='View Request'
                >
                  <div className='staffUpload shadow-md'>
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Staff Name*</p>
                        <p>{userData.name}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Home Address*</p>
                        <p>{userData.home_address}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Mobile No.*</p>
                        <p>{userData.mobile_no}</p>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Date of Birth*</p>
                        <p>{userData.date_of_birth}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Gender*</p>
                        <p>{userData.gender}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Citizenship*</p>
                        <p>{userData.citizenship}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Occupation</p>
                        <p>{userData.occupation}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Emergency Contact Person*</p>
                        <p>{userData.contact_person}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Contact No.*</p>
                        <p>{userData.contact_no}</p>
                      </div>
                    </Row>

                    <p className='headerInformation'>UPLOAD DOCUMENTS</p>
                    <Row>
                      <div className='col'>
                        <div className='text-start'>
                          <Form.Group
                            className='mb-4'
                            controlId='formUpload'
                          >
                            <Form.Label className='my-4 fw-bold h5'>
                              ID Picture*
                            </Form.Label>

                            <div className='upload-cont d-flex justify-content-start'>
                              <div className='upload-input'>
                                <label htmlFor='formUpload'></label>
                                <Form.Control
                                  type='file'
                                  className='custom-file-upload'
                                  aria-label='Click to Select File to Upload'
                                  accept='.xlsx, .xls, .csv'
                                ></Form.Control>
                              </div>

                              <span style={{ color: 'black' }}>
                                {!userData.id_picture_img && '---'}
                              </span>
                              <span>
                                {userData.id_picture_img &&
                                  userData.id_picture_img}
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col'>
                        <Form.Group
                          className='mb-4'
                          controlId='formUpload'
                        >
                          <Form.Label className='my-4 fw-bold h5'>
                            NBI Clearance*
                          </Form.Label>

                          <div className='upload-cont d-flex justify-content-start'>
                            <div className='upload-input'>
                              <label htmlFor='formUpload'></label>
                              <Form.Control
                                type='file'
                                className='custom-file-upload'
                                aria-label='Click to Select File to Upload'
                                accept='.xlsx, .xls, .csv'
                              ></Form.Control>
                            </div>

                            <span style={{ color: 'black' }}>
                              {!userData.nbi_clearance_img && '---'}
                            </span>
                            <span>
                              {userData.nbi_clearance_img &&
                                userData.nbi_clearance_img}
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group
                          className='mb-4'
                          controlId='formUpload'
                        >
                          <Form.Label className='my-4 fw-bold h5'>
                            Valid ID
                          </Form.Label>

                          <div className='upload-cont d-flex justify-content-start'>
                            <div className='upload-input'>
                              <label htmlFor='formUpload'></label>
                              <Form.Control
                                type='file'
                                className='custom-file-upload'
                                aria-label='Click to Select File to Upload'
                                accept='.xlsx, .xls, .csv'
                              ></Form.Control>
                            </div>

                            <span style={{ color: 'black' }}>
                              {!userData.valid_id_img && '---'}
                            </span>
                            <span>
                              {userData.valid_id_img && userData.valid_id_img}
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                    </Row>

                    <div className='d-flex justify-content-between'>
                      <div style={{ color: '#3bd14d' }}>{msg.message}</div>
                      <div>
                        <Button
                          className='btn mx-1 '
                          onClick={approveHandler}
                        >
                          Approve
                        </Button>
                        <Link
                          className='btn btn-danger py-3 px-5 mx-1 '
                          to={`/administration/requests/staff-id/staff-deny-request/${selectedbuilding}/${id}`}
                        >
                          Deny
                        </Link>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default StaffViewRequest;
