// Packages
/* eslint-disable multiline-comment-style */
import { Link } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';

// import assets below
import Users from '../../SVG/Users';
import Billing from '../../SVG/Billing';
import Request from '../../SVG/Request';
import Violation from '../../SVG/Violation';

// main component
const Administration = (props) => {
  const { activeKey, pathname } = props;

  return (
    <Accordion defaultActiveKey={activeKey}>
      <Accordion.Item eventKey='my-tenant'>
        <Accordion.Header>
          <div className='svg__wrapper'>{Users()}</div>
          <Nav.Link
            className='nav-link'
            href='#'
          >
            My Tenant
          </Nav.Link>
        </Accordion.Header>
        <Accordion.Body>
          <Nav.Item
            className={
              pathname === '/my-tenant/billing' && 'active'
            }
          >
            <div className='svg__wrapper'>{Billing()}</div>
            <Link
              className='nav-link'
              to='/my-tenant/billing?activeKey=my-tenant'
            >
              Biling
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/my-tenant/request' && 'active'
            }
          >
            <div className='svg__wrapper'>{Request()}</div>
            <Link
              className='nav-link'
              to='/my-tenant/request?activeKey=my-tenant'
            >
              Request
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/administration/admin-cms/payment-instructions' &&
              'active'
            }
          >
            <div className='svg__wrapper'>{Violation()}</div>
            <Link
              className='nav-link'
              to='/administration/admin-cms/payment-instructions?activeKey=my-tenant'
            >
              Violations
            </Link>
          </Nav.Item>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Administration;
