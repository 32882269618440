const Phone = () => {
  return (
    <svg viewBox='0 0 60.439 60.439'>
      <path
        id='phone-alt'
        d='M58.715,42.715,45.494,37.049a2.833,2.833,0,0,0-3.305.815l-5.855,7.154A43.755,43.755,0,0,1,15.417,24.1l7.154-5.855a2.826,2.826,0,0,0,.815-3.305L17.719,1.718A2.852,2.852,0,0,0,14.473.078L2.2,2.911A2.833,2.833,0,0,0,0,5.672,54.768,54.768,0,0,0,54.774,60.445a2.833,2.833,0,0,0,2.762-2.2l2.833-12.277A2.867,2.867,0,0,0,58.715,42.715Z'
        transform='translate(0 -0.006)'
      />
    </svg>
  );
};

export default Phone;
