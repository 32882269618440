// import core & vendor packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';

// import components below
import FormUI from '../../../../components/UI/FormUI';

// Import API
import {
  getRequestAmenitiesByReferenceId,
  getPaymentInstruction,
  submitDenyRequest,
} from 'api/request';

// import assets below
import './Form.scss';

// main component
const AmenitiesForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    amenity: '',
    buildingName: '',
    unitNo: '',
    name: '',
    startDate: '',
    endDate: '',
    paymentInstructions: '',
    bookingNo: '',
  });
  const [remarks, setRemarks] = useState('');

  const submitData = () => {
    const params = {
      remarks: remarks,
    };

    submitDenyRequest(id, params, submitCallback);
  };

  useEffect(() => {
    getRequestAmenitiesByReferenceId(
      id,
      '?include=payment_instructions',
      callback,
    );
    getPaymentInstruction(paymentCallback);
  }, []);

  useEffect(() => {
    console.log('USER DATA', userData);
  }, [userData]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            amenity: data?.data?.relationships?.amenity?.attributes?.name,
            buildingName: data?.data?.relationships?.building?.attributes?.name,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            startDate: data?.data?.attributes?.start_datetime,
            endDate: data?.data?.attributes?.end_datetime,
            bookingNo: data?.data?.attributes?.reference_id,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const paymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            paymentInstructions: data?.data?.attributes?.description,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        navigate('/administration/requests/amenities?activeKey=requests');
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <Form className='amenitiesForm'>
      <FormUI>
        <h6>SUMMMARY OF REQUEST</h6>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Amenity</h6>
              <span>{userData?.amenity}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Building Name</h6>
              <span>{userData?.buildingName}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Unit No.</h6>
              <span>{userData?.unitNo}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Name</h6>
              <span>{userData?.name}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Start/End Date and Time</h6>
              <span>
                {userData.startDate} <br />
                {userData.endDate}
              </span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Payment Instructions</h6>
              <textarea
                rows='2'
                readOnly
                style={{ border: 'none' }}
                value={userData.paymentInstructions}
              >
              </textarea>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Booking No.</h6>
              <span>{userData.bookingNo}</span>
            </div>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>PMO Remarks</Form.Label>
          <Form.Control
            as='textarea'
            rows='12'
            value={remarks}
            onChange={(e) => {
              setRemarks(e.target.value);
            }}
          />
        </Form.Group>
        <div className='formUI__footer'>
          <Button variant='submit' onClick={submitData}>Submit</Button>
          <Button
            style={{
              color: '#00B6E9',
              padding: '1em 2em',
              borderRadius: '5px',
            }}
            variant='cancel'
            as={Link}
            to={`/administration/requests/amenities/view-request/${id}?activeKey=requests`}
          >
            Cancel
          </Button>
        </div>
      </FormUI>
    </Form>
  );
};

export default AmenitiesForm;
