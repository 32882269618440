const AssistiveListening = () => {
  return (
    <svg viewBox='0 0 22 22'>
      <path
        id='assistive-listening-systems'
        d='M9.281,11.172a1.2,1.2,0,0,1-2.406,0,3.438,3.438,0,0,1,6.875,0,1.2,1.2,0,0,1-2.406,0,1.031,1.031,0,0,0-2.063,0Zm1.031-7.563A7.571,7.571,0,0,0,2.75,11.172a1.2,1.2,0,0,0,2.406,0,5.156,5.156,0,1,1,10.313,0c0,3.23-3.051,3.021-3.094,6.171v.016a2.237,2.237,0,0,1-2.234,2.234,1.2,1.2,0,0,0,0,2.406,4.646,4.646,0,0,0,4.641-4.631c.026-1.479,3.094-2.073,3.094-6.2A7.571,7.571,0,0,0,10.313,3.609ZM6.875,13.75A1.375,1.375,0,1,0,8.25,15.125,1.375,1.375,0,0,0,6.875,13.75Zm-5.5,5.5A1.375,1.375,0,1,0,2.75,20.625,1.375,1.375,0,0,0,1.375,19.25ZM22,11.172c0-.065,0-.13,0-.195A11.747,11.747,0,0,0,14.094.065a1.2,1.2,0,0,0-.778,2.277,9.334,9.334,0,0,1,6.277,8.678v.008c0,.048,0,.1,0,.145a1.2,1.2,0,0,0,2.406,0ZM6.573,18.865,3.135,15.427,1.677,16.886l3.438,3.438,1.458-1.458Z'
        transform='translate(0 0)'
      />
    </svg>
  );
};

export default AssistiveListening;
