import { Fragment, useState } from 'react';

// import components below
import CollecetionvsExpendituresFilter from './Filter';
import CollecetionvsExpendituresTable from './Table';
import CollecetionvsExpendituresGraph from './Graph';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';

function CollectionsvsExpenditure() {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };
  return (
    <Fragment>
      <Helmet>
        <title>Collection vs Expenditures</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Collection vs Expenditures'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Collection vs Expenditures'
                title='Collection vs Expenditures'
              >
                {' '}
                <CollecetionvsExpendituresFilter
                  toggle={toggle}
                  onToggle={setToggle}
                  selected={selectedFilter}
                  onSelect={onSelectFilter}
                />
                {!toggle && <CollecetionvsExpendituresTable />}
                {toggle && (
                  <CollecetionvsExpendituresGraph selected={selectedFilter} />
                )}
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default CollectionsvsExpenditure;
