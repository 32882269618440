// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main/';
import OccupancyForm from './UploadOccupancy/OccupancyForm';
import OccupanyContent from './OccupancyList/OccupanyContent';

// API

// CSS
import './Occupancy.scss';

// Main Component
const Occupancy = () => {
  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Emergency Directory</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Occupancy'
                id='house-rules-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='Upload Occupancy'
                  title='Upload Occupancy'
                >
                  <OccupancyForm />
                </Tab>
                <Tab
                  eventKey='Occupancy List'
                  title='Occupancy List'
                >
                  <OccupanyContent />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Occupancy;
