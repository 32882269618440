/* eslint-disable newline-per-chained-call */
import React from 'react';
import { Col, Form } from 'react-bootstrap';

const DateTimeRange = ({ errors, register, setSubmitData, submitData }) => {
  return (
    <>
      <Col>
        <Form.Group className='mb-3'>
          <Form.Label>
            Start Date and Time{' '}
            {errors.startDateTime ? (
              <span style={{ color: '#ff5c5c' }}>
                {errors.startDateTime.message}
              </span>
            ) : (
              ''
            )}
          </Form.Label>
          <Form.Control
            type='datetime-local'
            min={new Date().toISOString().slice(0, -8)}
            {...register('startDateTime', {
              required: '(Required)',
              onChange: (event) => {
                setSubmitData((prevState) => {
                  return {
                    ...prevState,
                    startDateTime: event.target.value,
                  };
                });
              },
            })}
            value={submitData.startDateTime}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className='mb-3'>
          <Form.Label>
            End Date and Time{' '}
            {errors.endDateTime ? (
              <span style={{ color: '#ff5c5c' }}>
                {errors.endDateTime.message}
              </span>
            ) : (
              ''
            )}
          </Form.Label>
          <Form.Control
            type='datetime-local'
            min={new Date().toISOString().slice(0, -8)}
            {...register('endDateTime', {
              required: '(Required)',
              onChange: (event) => {
                setSubmitData((prevState) => {
                  return {
                    ...prevState,
                    endDateTime: event.target.value,
                  };
                });
              },
            })}
            value={submitData.endDateTime}
          />
        </Form.Group>
      </Col>
    </>
  );
};

export default DateTimeRange;
