// Packages
import { Form, FloatingLabel } from 'react-bootstrap';

// CSS
import './style.scss';

const FormResetPassword = ({ params, setParams, register, errors }) => {
  return (
    <div className='form form--login'>
      <div className='form__body'>
        <FloatingLabel
          controlId='floatingPassword'
          className={`mb-3 ${errors.password ? 'error-label' : ''}`}
          label={`Password ${
            errors.password ? `(${errors.password.message})` : ''
          }`}
        >
          <Form.Control
            {...register('password', {
              required: 'Password Required',
              value: params.password,
              onChange: (e) => {
                setParams((prevState) => {
                  return { ...prevState, password: e.target.value };
                });
              },
            })}
            className='mb-3'
            type='password'
            placeholder='Enter password'
          />
        </FloatingLabel>
        <FloatingLabel
          controlId='floatingConfirmPassword'
          className={`mb-3 ${
            errors.password_confirmation ? 'error-label' : ''
          }`}
          label={`Confirm Password ${
            errors.password_confirmation
              ? `(${errors.password_confirmation.message})`
              : ''
          }`}
        >
          <Form.Control
            {...register('password_confirmation', {
              required: 'Password Required',
              value: params.passwordConfirmation,
              onChange: (e) => {
                setParams((prevState) => {
                  return { ...prevState, passwordConfirmation: e.target.value };
                });
              },
            })}
            type='password'
            placeholder='Password'
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

export default FormResetPassword;
