const CaretRight = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8.444'
      height='14.889'
      viewBox='0 0 8.444 14.889'
    >
      <path
        id='caret-right'
        d='M0,121.184V108.3a1,1,0,0,1,1.709-.708l6.441,6.441a1,1,0,0,1,0,1.416l-6.441,6.441A1,1,0,0,1,0,121.184Z'
        transform='translate(0 -107.298)'
        fill='#fff'
      />
    </svg>
  );
};

export default CaretRight;
