// Packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Modal } from 'react-bootstrap';

// Components
import ViolationsTable from './ViolationsTable';
import TablePagination from 'components/Pagination';

// CSS/SCSS
import './ViolationsContent.scss';

// API
import { deleteViolationClassification } from 'api/request';
import { getViolationClassificationsNoPage } from 'api/Violations';

// Main Component
const ViolationsContent = ({ refreshList, setRefreshList, setActiveKey }) => {
  // States
  const [msg, setMsg] = useState('');
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableList, setTableList] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [violationId, setViolationId] = useState('');

  const selectPage = (page) => {
    const params = `?${page}`;
    setLoading(true);
    setTableList([]);
    getViolationClassificationsNoPage(params, callback);
  };

  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getViolationClassificationsNoPage('', callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList, tableList]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableList(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setMsg('Something went wrong.');
      }
    }
  };

  const confirmDelete = (id) => {
    setViolationId(id);
    setDeleteModal(true);
  };

  const handleClose = () => {
    setDeleteModal(false);
  };

  const deleteViolation = () => {
    deleteViolationClassification(violationId, deleteCallback);
  };

  const deleteCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 204) {
        setLoading(true);
        getViolationClassificationsNoPage('', callback);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setMsg('Something went wrong.');
      }
    }

    setViolationId('');
    setDeleteModal(false);
  };

  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={5}>
            <div className='edit-container mb-3'>
              <Button
                className='edit'
                variant='primary'
                onClick={() => setActiveKey('Violations Form')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26.879'
                  height='26.879'
                  viewBox='0 0 26.879 26.879'
                >
                  <path
                    id='plus'
                    d='M24.959,42.56h-8.64V33.92A1.92,1.92,0,0,0,14.4,32h-1.92a1.92,1.92,0,0,0-1.92,1.92v8.64H1.92A1.92,1.92,0,0,0,0,44.479V46.4a1.92,1.92,0,0,0,1.92,1.92h8.64v8.64a1.92,1.92,0,0,0,1.92,1.92H14.4a1.92,1.92,0,0,0,1.92-1.92v-8.64h8.64a1.92,1.92,0,0,0,1.92-1.92v-1.92A1.92,1.92,0,0,0,24.959,42.56Z'
                    transform='translate(0 -32)'
                    fill='#fff'
                  />
                </svg>
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        {loading && <p>Loading...</p>}
        {!loading && msg && <p>{msg}</p>}
        {!loading && (
          <>
            {tableList && tableList.length === 0 && <p>Table is empty</p>}
            {tableList && tableList.length > 0 && (
              <>
                <ViolationsTable
                  confirmDelete={confirmDelete}
                  tableList={tableList}
                />

                <TablePagination
                  links={links}
                  meta={meta}
                  selectPage={selectPage}
                ></TablePagination>
              </>
            )}
          </>
        )}
      </Card>

      <Modal
        show={deleteModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Violation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Confirm delete?</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant='danger'
            onClick={deleteViolation}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViolationsContent;
