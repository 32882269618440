// import core & vendor packages
import { Form, FloatingLabel } from 'react-bootstrap';

// import assets below
import './style.scss';

// main component
const FormLogin = ({ params, setParams, register, errors }) => {
  return (
    <div className='form form--login'>
      <div className='form__body'>
        <FloatingLabel
          className={`mb-3 ${errors.email ? 'error-label' : ''}`}
          controlId='floatingEmail'
          label={`Email Address ${
            errors.email ? `(${errors.email.message})` : ''
          }`}
        >
          <Form.Control
            {...register('email', {
              required: 'Email Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              value: params.email,
              onChange: (e) => {
                setParams((prevState) => {
                  return { ...prevState, email: e.target.value };
                });
              },
            })}
            type='email'
            placeholder='name@example.com'
          />
        </FloatingLabel>
        <FloatingLabel
          controlId='floatingPassword'
          className={`mb-3 ${errors.password ? 'error-label' : ''}`}
          label={`Password ${
            errors.password ? `(${errors.password.message})` : ''
          }`}
        >
          <Form.Control
            {...register('password', {
              required: 'Password Required',
              value: params.password,
              onChange: (e) => {
                setParams((prevState) => {
                  return { ...prevState, password: e.target.value };
                });
              },
            })}
            type='password'
            placeholder='Password'
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

export default FormLogin;
