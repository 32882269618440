const Search = () => {
  return (
    <svg viewBox='0 0 69.69 70.299'>
      <path
        id='search'
        d='M336.364,116.655a29.269,29.269,0,1,1,58.538,0,28.894,28.894,0,0,1-4.288,15.155l13.368,13.364a7.383,7.383,0,0,1-5.221,12.51,7.4,7.4,0,0,1-5.254-2.075L380.035,142.1a29.075,29.075,0,0,1-14.4,3.823A29.372,29.372,0,0,1,336.364,116.655Zm48.247,0a18.978,18.978,0,1,0-18.978,18.978A19.014,19.014,0,0,0,384.611,116.655Z'
        transform='translate(-336.364 -87.386)'
        fill='#fff'
      />
    </svg>
  );
};

export default Search;
