// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import CalendarContainer from './CalendarContainer';

// API

// CSS
import './Calendar.scss';

// Main Component
const Calendar = () => {
  // To render on screen
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Fragment>
      <Helmet>
        <title>Renewal Calendar</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Renewal Calendar'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Renewal Calendar'
                title='Renewal Calendar'
              >
                <CalendarContainer />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Calendar;
