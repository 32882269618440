/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';
// Components

// CSS/SCSS
import './ContractListTable.scss';

// Main Component
const ContractListTable = ({ tableList, deleteSingleListData }) => {
  
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  // modal show confirmation delete
  const handleShow = (id) => {
    console.log(id);
    setStoreId(id);
    return setConfirmationDelete(true);
  };
  // To return view/delete action
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            as={Link}
            to={`/administration/contracts/service-providers/upload-document/${id}`}
          >
            Upload Document
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to={`/administration/contracts/service-providers/${id}`}
          >
            View
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name of Document',
        accessor: 'nameOfDocument',
      },
      {
        Header: 'Service Provider',
        accessor: 'serviceProvider',
      },
      {
        Header: 'Period Cover',
        accessor: 'periodCover',
      },
      {
        Header: 'Renewal Date',
        accessor: 'renewalDate',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table

  // eslint-disable-next-line multiline-comment-style
  // const data = React.useMemo(() => {
  //   const reconstructedList = tableList.map((data) => {
  //     return {
  //       amenities: data.attributes.name,
  //       description: data.attributes.description,
  //       action: renderAction(data.id),
  //     };
  //   });
  //   return reconstructedList;
  // }, []);

    const data = React.useMemo(() => [
      {
        nameOfDocument: 'Cable',
        serviceProvider: 'Cignal',
        periodCover: '01/01/2021 - 01/01/2022',
        renewalDate: '01/01/2022',
        action: renderAction('1'),
      },
      {
        nameOfDocument: 'Internet',
        serviceProvider: 'Converge',
        periodCover: '01/01/2021 - 01/01/2022',
        renewalDate: '01/01/2022',
        action: renderAction('2'),
      },
    ], []);

  // Initialize useTable and get its properties and functions
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    <>
      <Modal
        show={confirmationDelete}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => deleteSingleListData(storeId)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <table
        {...getTableProps()}
        className='admin-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.amenities}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      key={column.amenities}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  key={row.amenities}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={cell.amenities}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  );
};

export default ContractListTable;
