// import core & vendor packages
import { Col } from 'react-bootstrap';

// import components below
import TableLeft from './TableLeft';
import TableRight from './TableRight';

// import assets below
import './style.scss';

// main component
const BudgetTable = () => {
  return (
    <div className='dashboardBudgetTable'>
      <h5>Budget for the Calendar Year Ending December 2022</h5>
      <div className='dashboardBudgetTable__content'>
        <Col>
          <TableRight />
        </Col>
        <Col>
          <TableLeft />
        </Col>
      </div>
    </div>
  );
};

export default BudgetTable;
