// Packages
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import SuppliersDirectoryForm from './SuppliersDirectoryForm/SuppliersDirectoryForm.jsx';
import SuppliersDirectoryContent from './SuppliersDirectoryList/SuppliersDirectoryContent';

// API

// CSS
import './SuppliersDirectory.scss';

// Main Component
const SuppliersDirectory = () => {
  // handle refresh loading
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const handleSelect = (e) => {
    if (e === 'Suppliers Directory List') {
      setRefreshList(true);
    }
    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('Suppliers Directory Form');
  }, []);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Emergency Directory</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Suppliers Directory Form'
                id='house-rules-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='Suppliers Directory Form'
                  title='Suppliers Directory Form'
                >
                  <SuppliersDirectoryForm />
                </Tab>
                <Tab
                  eventKey='Suppliers Directory List'
                  title='Suppliers Directory List'
                >
                  <SuppliersDirectoryContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default SuppliersDirectory;
