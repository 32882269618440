const ChartPie = () => {
  return (
    <svg viewBox='0 0 25.245 23.76'>
      <path
        id='chart-pie'
        d='M24.491,13.369H13.479L20.813,20.7a.745.745,0,0,0,1.03.032A11.114,11.114,0,0,0,25.236,14.2a.744.744,0,0,0-.745-.828Zm-.735-3.007A11.164,11.164,0,0,0,13.4.006a.747.747,0,0,0-.78.752V11.141H23A.746.746,0,0,0,23.756,10.362ZM10.393,13.369V2.357a.744.744,0,0,0-.828-.745A11.129,11.129,0,0,0,0,13.015,11.275,11.275,0,0,0,11.276,23.763a11.051,11.051,0,0,0,6.277-2.043.737.737,0,0,0,.073-1.117Z'
        transform='translate(0.002 -0.004)'
      />
    </svg>
  );
};

export default ChartPie;
