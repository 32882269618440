// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import TenantBillingList from './TenantBillingList';

// API

// CSS
import './Billing.scss';

const Billing = () => {
  return (
    <Fragment>
      <Helmet>
        <title>My Tenant Billing</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Col>
            <Tabs
              defaultActiveKey='Tenant Billing List'
              id='tenant-billing-list'
              className='mb-3 finance-nav'
            >
              <Tab
                eventKey='Tenant Billing List'
                title='Tenant Billing List'
              >
                <TenantBillingList />
              </Tab>
            </Tabs>
          </Col>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Billing;
