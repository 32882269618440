/* eslint-disable multiline-comment-style */
// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
// Components

// CSS/SCSS
import './StaffIDRequestlistTable.scss';

// Main Component
const StaffIDRequestlistTable = ({ tableData, selectedBuilding }) => {
  // use navigate
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Staff ID No.',
        accessor: 'staffIDNo',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },

      {
        Header: ' Mobile No.',
        accessor: 'mobileNo',
      },
      {
        Header: 'Request Type',
        accessor: 'requestType',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [],
  );
  // check status
  const statusHandler = (status) => {
    return (
      <div>
        {status === 'pending' && <p className='pending'>Pending</p>}
        {status === 'approved' && <p style={{ color: '#45c33b' }}>Approved</p>}
        {status === 'deactivated' && <p className='text-danger'>Deactivated</p>}
      </div>
    );
  };
  // check request type
  const requestTypeHandler = (requestType) => {
    return (
      <div>
        {requestType === 'application' && (
          <p className='application'>Application</p>
        )}
        {requestType === 'deactivation' && (
          <p className='text-danger'>Deactivation</p>
        )}
      </div>
    );
  };
  // navigate Request
  const viewRequest = (stadffIDNo) => {
    return (
      <div>
        <u
          onClick={() =>
            navigate(
              `/administration/requests/staff-id/staff-view-request/${selectedBuilding}/${stadffIDNo}`,
            )
          }
          style={{ cursor: 'pointer' }}
        >
          {stadffIDNo}
        </u>
      </div>
    );
  };
  // Data of the table
  const data = React.useMemo(() => {
    if (tableData && tableData.length > 0) {
      console.log(tableData);
      const newData = tableData.map((list) => {
        return {
          staffIDNo: viewRequest(list.attributes?.reference_id),
          name: list?.attributes?.name,
          mobileNo: list?.attributes?.mobile_number,
          requestType: requestTypeHandler(list?.attributes?.request_type),
          status: statusHandler(list?.attributes?.status),
        };
      });

      return newData;
    } else {
      return [];
    }
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='staffID-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaffIDRequestlistTable;
