// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Import API
import {
  getRequestAmenitiesByReferenceId,
  getPaymentInstruction,
} from 'api/request';

// import assets below
import './View.scss';

// main component
const View = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({
    amenity: '',
    buildingName: '',
    unitNo: '',
    name: '',
    startDate: '',
    endDate: '',
    paymentInstructions: '',
    bookingNo: '',
    status: '',
    proofOfPayment: '',
  });

  useEffect(() => {
    getRequestAmenitiesByReferenceId(
      id,
      '?include=payment_instructions',
      callback,
    );
    getPaymentInstruction(paymentCallback);
  }, []);

  useEffect(() => {
    console.log('USER DATA', userData);
  }, [userData]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            amenity: data?.data?.relationships?.amenity?.attributes?.name,
            buildingName: data?.data?.relationships?.building?.attributes?.name,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            startDate: data?.data?.attributes?.start_datetime,
            endDate: data?.data?.attributes?.end_datetime,
            bookingNo: data?.data?.attributes?.reference_id,
            status: data?.data?.attributes?.status,
            proofOfPayment: data?.data?.attributes?.proof_of_payment,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const paymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            paymentInstructions: data?.data?.attributes?.description,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <div className='view-concern-amenities'>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Amenity</h6>
            <span>{userData?.amenity}</span>
          </div>
        </Col>
        <Col>
          {' '}
          <div>
            <h6 className='mb-3'>Payment Instructions</h6>

            <textarea
              rows='2'
              readOnly
              style={{ border: 'none' }}
              value={userData.paymentInstructions}
            ></textarea>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Start/End Date and Time</h6>
            <span>
              {userData.startDate} <br />
              {userData.endDate}
            </span>
          </div>
        </Col>
        <Col>
          <Form>
            <Form.Group
              className='mb-4'
              controlId='proof-of-payment'
            >
              <h6 className='mb-3'>Proof of Payment</h6>

              <div className='upload-cont'>
                <div className='upload-input proof-of-payment'>
                  <label
                    htmlFor='proof-of-payment'
                  >
                    Select file
                  </label>
                </div>

                <span>
                  {userData.proofOfPayment && userData?.proofOfPayment?.name ? userData?.proofOfPayment?.name : 'No file uploaded'}
                </span>
              </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col
          className='message-text-result'
          style={{ justifyContent: 'flex-start' }}
        ></Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <div style={{ margin: 0, padding: 0 }}>
            <div className='buttons-amenity'>
              <Button
                as={Link}
                to={'/administration/requests/amenities?activeKey=requests'}
                className='cancel shadow-sm'
                variant='default'
                style={{ padding: '0.8em 2em', color: '#00B6E9' }}
              >
                Back
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
