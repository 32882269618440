// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Components
import Main from '../../../components/Main';
import EditUnitForm from './EditUnitForm';

// API

// CSS
import './EditUnit.scss';

// Main Component
const EditUnit = () => {
  const { id } = useParams();

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Edit Unit</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Edit Unit'
              id='violations-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Edit Unit'
                title='Edit Unit'
              >
                <EditUnitForm id={id} />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EditUnit;
