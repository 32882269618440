// Packages
import React, { useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

// CSS
import './UnitList.scss';

const UnitList = ({ units, setUnits }) => {
  // States

  // Will render one time
  useEffect(() => {
    if (units) {
      console.log('Rendering...');
    }
  }, []);

  // To Change Value on any Textbox
  const changeUnitValue = (e, unit, type) => {
    const newValue = units.map((arrUnit) => {
      if (unit.id === arrUnit.id) {
        if (type === 'name') {
          return { ...unit, name: e };
        }

        if (type === 'age') {
          return { ...unit, age: e };
        }

        if (type === 'gender') {
          return { ...unit, gender: e };
        }

        if (type === 'remarks') {
          return { ...unit, remarks: e };
        }
      }

      return arrUnit;
    });

    setUnits(newValue);
  };

  // To add Unit textbox
  const addUnit = () => {
    const newID = units.length + 1;

    const newViolation = {
      id: newID,
      name: '',
      age: '',
      gender: '',
      remarks: '',
    };

    setUnits((prevState) => {
      return [...prevState, newViolation];
    });
  };

  return (
    <>
      {units &&
        units.length > 0 &&
        units.map((unit) => {
          return (
            <Row key={unit.id}>
              <Col md={3}>
                <Form.Group
                  className='mb-3'
                  controlId={unit.id}
                >
                  <Form.Control
                    aria-label='unit'
                    placeholder='Name'
                    type='text'
                    value={unit.name}
                    onChange={(e) =>
                      changeUnitValue(e.target.value, unit, 'name')
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={2}>
                <Form.Group
                  className='mb-3'
                  controlId={unit.id}
                >
                  <Form.Control
                    aria-label='unit'
                    placeholder='Age'
                    type='number'
                    value={unit.age}
                    onChange={(e) =>
                      changeUnitValue(e.target.value, unit, 'age')
                    }
                  />
                </Form.Group>
              </Col>

              <Col md={2}>
                <Form.Group
                  className='mb-3'
                  controlId={unit.id}
                >
                  <Form.Select
                    aria-label='Select Gender'
                    value={unit.gender}
                    onChange={(e) =>
                      changeUnitValue(e.target.value, unit, 'gender')
                    }
                  >
                    <option
                      value=''
                      hidden
                    >
                      Gender
                    </option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group
                  className='mb-3'
                  controlId={unit.id}
                >
                  <Form.Control
                    aria-label='unit'
                    placeholder='Remarks'
                    type='text'
                    value={unit.remarks}
                    onChange={(e) =>
                      changeUnitValue(e.target.value, unit, 'remarks')
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          );
        })}
      <Form.Group
        className='buttons add mt-3 mb-5'
        style={{ justifyContent: 'flex-start' }}
      >
        <Button
          className='submit add-button'
          variant='primary'
          onClick={addUnit}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            viewBox='0 0 30 30'
          >
            <path
              id='plus'
              d='M27.857,43.786H18.214V34.143A2.143,2.143,0,0,0,16.071,32H13.929a2.143,2.143,0,0,0-2.143,2.143v9.643H2.143A2.143,2.143,0,0,0,0,45.929v2.143a2.143,2.143,0,0,0,2.143,2.143h9.643v9.643A2.143,2.143,0,0,0,13.929,62h2.143a2.143,2.143,0,0,0,2.143-2.143V50.214h9.643A2.143,2.143,0,0,0,30,48.071V45.929A2.143,2.143,0,0,0,27.857,43.786Z'
              transform='translate(0 -32)'
              fill='#fff'
            />
          </svg>
          Add
        </Button>
      </Form.Group>
    </>
  );
};

export default UnitList;
