const ClipboardList = () => {
  return (
    <svg viewBox='0 0 18.955 25.274'>
      <path
        id='clipboard-list'
        d='M16.586,3.159H12.637a3.159,3.159,0,1,0-6.318,0H2.369A2.37,2.37,0,0,0,0,5.529V22.9a2.37,2.37,0,0,0,2.369,2.369H16.586A2.37,2.37,0,0,0,18.955,22.9V5.529A2.37,2.37,0,0,0,16.586,3.159ZM4.739,20.93a1.185,1.185,0,1,1,1.185-1.185A1.182,1.182,0,0,1,4.739,20.93Zm0-4.739a1.185,1.185,0,1,1,1.185-1.185A1.182,1.182,0,0,1,4.739,16.191Zm0-4.739a1.185,1.185,0,1,1,1.185-1.185A1.182,1.182,0,0,1,4.739,11.452ZM9.478,1.975A1.185,1.185,0,1,1,8.293,3.159,1.182,1.182,0,0,1,9.478,1.975ZM15.8,20.14a.4.4,0,0,1-.395.395H8.293A.4.4,0,0,1,7.9,20.14v-.79a.4.4,0,0,1,.395-.395H15.4a.4.4,0,0,1,.395.395Zm0-4.739a.4.4,0,0,1-.395.395H8.293A.4.4,0,0,1,7.9,15.4v-.79a.4.4,0,0,1,.395-.395H15.4a.4.4,0,0,1,.395.395Zm0-4.739a.4.4,0,0,1-.395.395H8.293a.4.4,0,0,1-.395-.395v-.79a.4.4,0,0,1,.395-.395H15.4a.4.4,0,0,1,.395.395Z'
      />
    </svg>
  );
};

export default ClipboardList;
