/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

// Components
import AccountsListTable from './AccountsListTable';
import { getAccountList, getGlobalNameList } from '../../../api/request';
// CSS/SCSS
import './AccountsList.scss';
import TablePagination from 'components/Pagination';

// Main Component
const AccountsList = ({
  tableData,
  setSearch,
  setFilter,
  loading,
  links,
  meta,
  selectPage,
}) => {
  const [stateAccount, setStateAccount] = useState('all');

  // Static Buildings
  const roles = [
    {
      id: 1,
      filter: 'All',
    },
    {
      id: 2,
      filter: 'Owner',
    },
    {
      id: 3,
      filter: 'Tenant',
    },
    {
      id: 4,
      filter: 'Finance',
    },
    {
      id: 5,
      filter: 'Engineering',
    },
    {
      id: 6,
      filter: 'Security',
    },
  ];

  // To render on screen
  return (
    <Form className='formBuiling'>
      <Row>
        <Col md={3}>
          <Form.Group
            controlId='formSearch'
            className='mb-5 formSearch'
          >
            <Form.Control
              type='text'
              placeholder='Search'
              className='search'
              onChange={(e) => {
                return setSearch(e.target.value);
              }}
            />

            <Button className='searchbtn'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25.362'
                height='25.584'
                viewBox='0 0 25.362 25.584'
              >
                <path
                  id='search'
                  d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                  transform='translate(-336.364 -87.386)'
                  fill='#c7c7c7'
                />
              </svg>
            </Button>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group
            className='mb-4'
            controlId='formRoles'
          >
            <Form.Select
              aria-label='Select Role'
              onChange={(e) => {
                return setFilter(e.target.value.toLowerCase());
              }}
            >
              {roles.map((val) => {
                return (
                  <option
                    value={val.filter}
                    key={val.id}
                  >
                    {val.filter}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Card className='card admin-form shadow-sm'>
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            {tableData && tableData.length === 0 && <p>Table is empty</p>}
            {tableData && tableData.length > 0 && (
              <Fragment>
                <AccountsListTable
                  tableData={tableData}
                  stateAccount={stateAccount}
                />
                <TablePagination
                  links={links}
                  meta={meta}
                  selectPage={selectPage}
                />
              </Fragment>
            )}
          </>
        )}
      </Card>
    </Form>
  );
};

export default AccountsList;
