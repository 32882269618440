/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import ViolationForm from './ViolationForm/ViolationForm';
import ViolationReport from './ViolationReport/ViolationReport';
import userContext from 'components/userContext/userContext';
// API

// CSS
import './ViolationsPage.scss';
import {
  getBuildingLists,
  getViolationClassifications,
  getViolationList,
  userAccounts,
  violationSubmitFOrm,
} from 'api/request';

const ViolationsPage = () => {
  const [buildings, setBuildings] = useState([]);
  const [violations, setViolations] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();
  // check violation form if come from incident or direct from violation
  const [violationForm, setViolationForm] = useState(false);
  // violation data from incident report
  const [incidentreportData, setIncidentreportData] = useState([]);
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const handleSelect = (e) => {
    if (e === 'Violation Report') {
      setViolationForm(false);
      console.log(e);
    }

    setActiveKey(e);
  };
  const violationFormHandler = (id) => {
    console.log(id);
    setViolationForm(true);
    setActiveKey('Violation Form');
    const params = `/api/v1/violation-reports/${id}`;
    getViolationList(params, violationFormcallback);
  };

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
      getViolationClassifications(violationCallback);
    }
  }, []);
  // selected building
  useEffect(() => {
    if (selectedBuilding !== '') {
      const params = `/api/v1/violation-reports?building_id=${selectedBuilding}&per_page=5`;

      getViolationList(params, requestAmenitiesCallback);
    }
  }, [selectedBuilding]);
  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      const params = `?filter[search]=&filter[unit]=${selectedUnit}`;

      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);
  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      violationSubmitFOrm(submit, submitCallback);
    }
  }, [submit]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      const params = `/api/v1/violation-reports?building_id=${selectedBuilding}&per_page=5&filter[search]=${search}`;

      const identifier = setTimeout(() => {
        getViolationList(params, requestAmenitiesCallback);
      }, 500);

      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  const refreshTable = () => {
    setLoading(true);

    if (selectedBuilding !== '') {
      const params = `/api/v1/violation-reports?building_id=${selectedBuilding}&per_page=5`;

      getViolationList(params, requestAmenitiesCallback);
    }
  };
  const selectPage = (page) => {
    const params = `/api/v1/violation-reports?building_id=${selectedBuilding}&per_page=5${page}`;

    getViolationList(params, requestAmenitiesCallback);
  };
  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        console.log(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        console.log(data?.data[0]?.id);
        setSelectedBuilding(data?.data[0]?.id);
        refreshTable();
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const violationCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setViolations(data.data);
        console.log(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const violationFormcallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setIncidentreportData((prev) => {
          return {
            ...prev,
            reference_id: data?.data?.attributes?.reference_id,
            violation_type:
              data?.data?.relationships?.violation?.attributes?.name,
            name: data?.data?.relationships?.user?.attributes?.name,
            unit_no: data?.data?.relationships?.unit?.attributes?.unit_number,
            time: data?.data?.attributes?.time,
            date: data?.data?.attributes?.date,
            noOfOffense: data?.data?.attributes?.offense_no,
            reportDetails: data?.data?.attributes?.report_details,
            penalty: data?.data?.attributes?.penalty,
          };
        });
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Violations</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Violation Report'
              id='house-rules-form'
              className='mb-3 house-rules'
              onSelect={(e) => handleSelect(e)}
              activeKey={activeKey}
            >
              {!role === 'bod' && (
                <Tab
                  eventKey='Violation Form'
                  title='Violation Form'
                >
                  <ViolationForm
                    violations={violations}
                    setSelectedUnit={setSelectedUnit}
                    buildings={buildings}
                    names={names}
                    setNames={setNames}
                    setSubmit={setSubmit}
                    msg={msg}
                    setMsg={setMsg}
                    violationForm={violationForm}
                    setViolationForm={setViolationForm}
                    incidentreportData={incidentreportData}
                  />
                </Tab>
              )}
              <Tab
                eventKey='Violation Report'
                title='Violation Report'
              >
                {loading && <p>Loading</p>}
                {!loading && (
                  <ViolationReport
                    selectedBuilding={selectedBuilding}
                    setSelectedBuilding={setSelectedBuilding}
                    buildings={buildings}
                    tableData={tableData}
                    setSearch={setSearch}
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    links={links}
                    meta={meta}
                    selectPage={selectPage}
                    setViolationForm={setViolationForm}
                    setActiveKey={setActiveKey}
                    violationFormHandler={violationFormHandler}
                  />
                )}
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ViolationsPage;
