// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import ApproveRequestForm from './Form';

// main component
const ApproveRequestTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='approveRequest'
        className='mb-3'
      >
        <Tab
          eventKey='approveRequest'
          title='Approve Request'
        >
          <ApproveRequestForm />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default ApproveRequestTabs;
