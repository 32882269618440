/* eslint-disable no-unused-vars */
// Packages
import React, { Fragment } from 'react';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './AccountsListTable.scss';

// Main Component
const AccountsListTable = ({ tableData, stateAccount }) => {
  // To return view/delete action
  const renderAction = (id) => {
    return (
      <Link
        to={`/administration/account-management/${id}`}
        style={{ color: '#000000' }}
      >
        Update
      </Link>
    );
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit No.',
        accessor: 'unit',
      },
      {
        Header: 'Account Type',
        accessor: 'accounttype',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Mobile No.',
        accessor: 'contact',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );
  const unitList = (id) => {
    return (
      <Fragment>
        {typeof id === 'object' && (
          <p>
            {id.map((data) => {
              return <p key={data.id}>{data.attributes?.unit_number}</p>;
            })}
          </p>
        )}
        {typeof id !== 'object' && <p>{id}</p>}
      </Fragment>
    );
  };

  const data = React.useMemo(() => {
    const reconstructedList = tableData.map((role) => {
      return {
        unit: unitList(
          role?.relationships?.tenant?.relationships?.unit?.attributes
            ?.unit_number || role?.relationships?.units,
        ),
        accounttype: role?.attributes?.role,
        name: role?.attributes?.name,
        email: role?.attributes?.email,
        contact: role?.attributes?.mobile_number,
        action: renderAction(role?.id),
      };
    });
    return reconstructedList;
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <table
      {...getTableProps()}
      className='admin-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.name}
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th
                    key={column.name}
                    {...column.getHeaderProps()}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                {...row.getRowProps()}
                key={row.name}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.name}
                        style={{ textAlign: 'left' }}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default AccountsListTable;
