/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import {
  accountsManagementForm,
  getGlobalNameList,
} from '../../../api/request';
// CSS
import './ManagementForm.scss';
import UnitList from './UnitList';
import Moment from 'moment';
// Main Component
const ManagementForm = ({ stateAccount, refreshTable }) => {
  // States

  // user roles
  const [finance, setFinance] = useState('');
  const [engineering, setEngineering] = useState('');
  const [security, setSecurity] = useState('');
  const [BOD, setBOD] = useState('');
  const [tenant, setTenant] = useState('');
  const [owner, setOwner] = useState('');

  // building info
  const [buildingInfo, setBuildingInfo] = useState({
    reference_id: '',
    buildingName: '---',
    unitNo: '---',
    unitArea: '---',
    parkingSlots: '---',
  });
  // personal information
  const [userData, setUserData] = useState({
    emailAddress: '',
    homeAddress: '',
    mobileNumber: '',
    dob: '',
    gender: '',
    citizenship: '',
    occupation: '',
    companyName: '',
    companyAddress: '',
    emergencyName: '',
    emergencycontactNumber: '',
    emergencyEmailAddress: '',
    brokerName: '',
    brokerNumber: '',
    password: '',
    confirmPassword: '',
    start_period_of_lease: '',
    end_period_of_lease: '',
  });
  const [checkboxValid, setCheckboxValid] = useState({
    finance: true,
    engineering: true,
    security: true,
    BOD: true,
    tenant: false,
    owner: false,
  });
  // role side (Tenant)
  const [units, setUnits] = useState([]);
  const [periodofLeastFrom, setPeriodofLeastFrom] = useState('');
  const [periodofLeastTo, setPeriodofLeastTo] = useState('');
  // response api here
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const [data, setData] = useState({
    names: [],
  });

  const submithandler = (e) => {
    e.preventDefault();
    // user roles checkbox selected
    const userRoles = [finance, engineering, security, BOD, tenant, owner];
    // user roles checkbox reconstructed
    const constructedRoles = [];
    Object.keys(userRoles).forEach((key) => {
      if (userRoles[key]) {
        constructedRoles.push(userRoles[key]);
      }
    });

    const dataSubmit = {
      role: stateAccount.toLowerCase(),
      user_roles: constructedRoles,
      reference_id: buildingInfo.reference_id,
      email: userData.emailAddress,
      home_address: userData.homeAddress,
      mobile_number: userData.mobileNumber,
      birth_date: userData.dob,
      gender: userData.gender,
      citizenship: userData.citizenship,
      occupation: userData.occupation,
      company_name: userData.companyName,
      company_address: userData.companyAddress,
      emergency_contact_name: userData.emergencyName,
      emergency_contact_number: userData.emergencycontactNumber,
      emergency_contact_email: userData.emergencyEmailAddress,
      broker_name: userData.brokerName,
      broker_contact_no: userData.brokerNumber,
      password: userData.password,
      password_confirmation: userData.confirmPassword,
      start_period_of_lease: periodofLeastFrom,
      end_period_of_lease: periodofLeastTo,
    };

    accountsManagementForm(dataSubmit, callback);
  };

  // dropdown name list
  useEffect(() => {
    // clear fields when changing account dropdown

    setUserData((prev) => {
      return {
        ...prev,
        emailAddress: '',
        homeAddress: '',
        mobileNumber: '',
        dob: '',
        gender: '',
        citizenship: '',
        occupation: '',
        companyName: '',
        companyAddress: '',
        emergencyName: '',
        emergencycontactNumber: '',
        emergencyEmailAddress: '',
        brokerName: '',
        brokerNumber: '',
        password: '',
        confirmPassword: '',
        start_period_of_lease: '',
        end_period_of_lease: '',
      };
    });
    setBuildingInfo((prev) => {
      return {
        ...prev,
        reference_id: '',
        buildingName: '---',
        unitNo: '---',
        unitArea: '---',
        parkingSlots: '---',
      };
    });
    setValue('names', '');
    setCheckboxValid((prev) => {
      return {
        ...prev,
        finance: false,
        engineering: false,
        security: false,
        BOD: false,
        tenant: false,
      };
    });
    setUnits([]);
    setMsg('');
    getGlobalNameList(stateAccount.toLowerCase(), callbackNameSelect);
  }, [stateAccount]);

  const callbackNameSelect = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const reconstructedList = data.data.map((data) => {
          return {
            id: data.id,
            label: data?.attributes?.name,
            value: data?.attributes?.name,
            reference_id: data?.attributes?.reference_id,
            name: data?.attributes?.name,
            unit_area: data?.relationships?.unit?.attributes?.unit_area,
            unit_number: data?.relationships?.unit?.attributes?.unit_number,
            no_of_parking_slots:
              data.relationships?.unit?.relationships?.building?.attributes
                .no_of_parking_slots,
            building_name:
              data?.relationships?.unit?.relationships?.building?.attributes
                ?.name ||
              data?.relationships?.units[0]?.relationships?.building?.attributes
                ?.name,
          };
        });
        setData((prevState) => {
          return { ...prevState, names: reconstructedList };
        });
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        if (data.message === 'User created successfully.') {
          refreshTable();
          setUserData((prev) => {
            return {
              ...prev,
              emailAddress: '',
              homeAddress: '',
              mobileNumber: '',
              dob: '',
              gender: '',
              citizenship: '',
              occupation: '',
              companyName: '',
              companyAddress: '',
              emergencyName: '',
              emergencycontactNumber: '',
              emergencyEmailAddress: '',
              brokerName: '',
              brokerNumber: '',
              password: '',
              confirmPassword: '',
              start_period_of_lease: '',
              end_period_of_lease: '',
            };
          });
        }
        setBuildingInfo((prev) => {
          return {
            ...prev,
            reference_id: '',
            buildingName: '---',
            unitNo: '---',
            unitArea: '---',
            parkingSlots: '---',
          };
        });
        setValue('names', '');
        setCheckboxValid((prev) => {
          return {
            ...prev,
            finance: false,
            engineering: false,
            security: false,
            BOD: false,
            tenant: false,
          };
        });
        setUnits([]);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  return (
    <Form
      className='management-form'
      onSubmit={submithandler}
    >
      <Row>
        <Col>
          <Form.Group
            className='mb-5 item-no'
            controlId='selectRole'
          >
            <Form.Label className='mb-3 header'>
              USER ROLES (Please select if the user has other roles)
            </Form.Label>

            {((stateAccount && stateAccount === 'Owner') ||
              (stateAccount && stateAccount === 'owner')) && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='finance'
                      label='Finance'
                      value='finance'
                      checked={checkboxValid.finance}
                      onChange={() => {
                        !checkboxValid.engineering
                          ? (setFinance('Finance'),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: true };
                            }))
                          : (setFinance(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: false };
                            }));
                      }}
                    />

                    <Form.Check
                      type='checkbox'
                      id='engineering'
                      label='Engineering'
                      value='Engineering'
                      checked={checkboxValid.engineering}
                      onChange={() =>
                        !checkboxValid.engineering
                          ? (setEngineering('Engineering'),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='security'
                      label='Security'
                      value='Security'
                      checked={checkboxValid.security}
                      onChange={() =>
                        !checkboxValid.security
                          ? (setSecurity('Security'),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: true };
                            }))
                          : (setSecurity(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='bod'
                      label='BOD'
                      value='BOD'
                      checked={checkboxValid.BOD}
                      onChange={() =>
                        !checkboxValid.BOD
                          ? (setBOD('BOD'),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: true };
                            }))
                          : (setBOD(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='tenant'
                      label='Tenant'
                      value='Tenant'
                      checked={checkboxValid.tenant}
                      onChange={() =>
                        !checkboxValid.tenant
                          ? (setTenant('Tenant'),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: true };
                            }))
                          : (setTenant(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {((stateAccount && stateAccount === 'tenant') ||
              (stateAccount && stateAccount === 'Tenant')) && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='finance'
                      label='Finance'
                      value='Finance'
                      checked={checkboxValid.finance}
                      onChange={() =>
                        !checkboxValid.finance
                          ? (setFinance('Finance'),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: true };
                            }))
                          : (setFinance(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='engineering'
                      label='Engineering'
                      value='Engineering'
                      checked={checkboxValid.engineering}
                      onChange={() =>
                        !checkboxValid.engineering
                          ? (setEngineering('Engineering'),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='security'
                      label='Security'
                      value='Security'
                      checked={checkboxValid.security}
                      onChange={() =>
                        !checkboxValid.security
                          ? (setSecurity('Security'),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: true };
                            }))
                          : (setSecurity(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='bod'
                      label='BOD'
                      value='BOD'
                      checked={checkboxValid.BOD}
                      onChange={() =>
                        !checkboxValid.BOD
                          ? (setBOD('BOD'),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: true };
                            }))
                          : (setBOD(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {stateAccount && stateAccount === 'Finance' && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='engineering'
                      label='Engineering'
                      value='Engineering'
                      checked={checkboxValid.engineering}
                      onChange={() =>
                        !checkboxValid.engineering
                          ? (setEngineering('Engineering'),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='security'
                      label='Security'
                      value='Security'
                      checked={checkboxValid.security}
                      onChange={() =>
                        !checkboxValid.security
                          ? (setSecurity('Security'),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: true };
                            }))
                          : (setSecurity(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='bod'
                      label='BOD'
                      value='BOD'
                      checked={checkboxValid.BOD}
                      onChange={() =>
                        !checkboxValid.BOD
                          ? (setBOD('BOD'),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: true };
                            }))
                          : (setBOD(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='owner'
                      label='Owner'
                      checked={checkboxValid.owner}
                      onChange={() =>
                        !checkboxValid.owner
                          ? (setOwner('Owner'),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: true };
                            }))
                          : (setOwner(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='tenant'
                      label='Tenant'
                      value='Tenant'
                      checked={checkboxValid.tenant}
                      onChange={() =>
                        !checkboxValid.tenant
                          ? (setTenant('Tenant'),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: true };
                            }))
                          : (setTenant(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {stateAccount && stateAccount === 'Engineering' && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='finance'
                      label='Finance'
                      value='finance'
                      checked={checkboxValid.finance}
                      onChange={() => {
                        !checkboxValid.finance
                          ? (setEngineering('finance'),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: false };
                            }));
                      }}
                    />

                    <Form.Check
                      type='checkbox'
                      id='security'
                      label='Security'
                      value='Security'
                      checked={checkboxValid.security}
                      onChange={() =>
                        !checkboxValid.security
                          ? (setSecurity('Security'),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: true };
                            }))
                          : (setSecurity(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='bod'
                      label='BOD'
                      value='BOD'
                      checked={checkboxValid.BOD}
                      onChange={() =>
                        !checkboxValid.BOD
                          ? (setBOD('BOD'),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: true };
                            }))
                          : (setBOD(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='owner'
                      label='Owner'
                      checked={checkboxValid.owner}
                      onChange={() =>
                        !checkboxValid.owner
                          ? (setOwner('Owner'),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: true };
                            }))
                          : (setOwner(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='tenant'
                      label='Tenant'
                      value='Tenant'
                      checked={checkboxValid.tenant}
                      onChange={() =>
                        !checkboxValid.tenant
                          ? (setTenant('Tenant'),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: true };
                            }))
                          : (setTenant(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {stateAccount && stateAccount === 'Security' && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='finance'
                      label='Finance'
                      value='finance'
                      checked={checkboxValid.finance}
                      onChange={() => {
                        !checkboxValid.engineering
                          ? (setFinance('Finance'),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: true };
                            }))
                          : (setFinance(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: false };
                            }));
                      }}
                    />

                    <Form.Check
                      type='checkbox'
                      id='engineering'
                      label='Engineering'
                      value='Engineering'
                      checked={checkboxValid.engineering}
                      onChange={() =>
                        !checkboxValid.engineering
                          ? (setEngineering('Engineering'),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='bod'
                      label='BOD'
                      value='BOD'
                      checked={checkboxValid.BOD}
                      onChange={() =>
                        !checkboxValid.BOD
                          ? (setBOD('BOD'),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: true };
                            }))
                          : (setBOD(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, BOD: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='owner'
                      label='Owner'
                      checked={checkboxValid.owner}
                      onChange={() =>
                        !checkboxValid.owner
                          ? (setOwner('Owner'),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: true };
                            }))
                          : (setOwner(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: false };
                            }))
                      }
                    />
                    <Form.Check
                      type='checkbox'
                      id='tenant'
                      label='Tenant'
                      value='Tenant'
                      checked={checkboxValid.tenant}
                      onChange={() =>
                        !checkboxValid.tenant
                          ? (setTenant('Tenant'),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: true };
                            }))
                          : (setTenant(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}

            {stateAccount && stateAccount === 'BOD' && (
              <Row>
                <Col>
                  <div className='checkboxes'>
                    <Form.Check
                      type='checkbox'
                      id='finance'
                      label='Finance'
                      value='finance'
                      checked={checkboxValid.finance}
                      onChange={() => {
                        !checkboxValid.engineering
                          ? (setFinance('Finance'),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: true };
                            }))
                          : (setFinance(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, finance: false };
                            }));
                      }}
                    />

                    <Form.Check
                      type='checkbox'
                      id='engineering'
                      label='Engineering'
                      value='Engineering'
                      checked={checkboxValid.engineering}
                      onChange={() =>
                        !checkboxValid.engineering
                          ? (setEngineering('Engineering'),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: true };
                            }))
                          : (setEngineering(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, engineering: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='security'
                      label='Security'
                      value='Security'
                      checked={checkboxValid.security}
                      onChange={() =>
                        !checkboxValid.security
                          ? (setSecurity('Security'),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: true };
                            }))
                          : (setSecurity(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, security: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='owner'
                      label='Owner'
                      checked={checkboxValid.owner}
                      onChange={() =>
                        !checkboxValid.owner
                          ? (setOwner('Owner'),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: true };
                            }))
                          : (setOwner(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, owner: false };
                            }))
                      }
                    />

                    <Form.Check
                      type='checkbox'
                      id='tenant'
                      label='Tenant'
                      value='Tenant'
                      checked={checkboxValid.tenant}
                      onChange={() =>
                        !checkboxValid.tenant
                          ? (setTenant('Tenant'),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: true };
                            }))
                          : (setTenant(null),
                            setCheckboxValid((prev) => {
                              return { ...prev, tenant: false };
                            }))
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Form.Group>
        </Col>
      </Row>

      {/* Name / Building Name / Unit No. */}
      {stateAccount &&
        (stateAccount === 'Owner' ||
          stateAccount === 'Tenant' ||
          stateAccount === 'owner' ||
          stateAccount === 'tenant') && (
          <Row>
            <Col md={3}>
              <Form.Group className='mb-5'>
                <Form.Label> Name*</Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={data.names}
                      placeholder='Select Name'
                      styles={customStyles}
                      {...field}
                    ></Select>
                  )}
                  {...register('names', {
                    required: '(Required)',
                    onChange: (e) => {
                      const nameselect = e.target.value.name;

                      setBuildingInfo((prev) => {
                        return {
                          ...prev,
                          buildingName: e.target.value.building_name,
                          unitNo: e.target.value.unit_number,
                          unitArea: e.target.value.unit_area || 'N/A',
                          parkingSlots: e.target.value.no_of_parking_slots,
                          reference_id: e.target.value.reference_id,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formBuildingName'
              >
                <Form.Label className='mb-3'>Building Name</Form.Label>
                <Form.Control
                  className='read-only'
                  aria-label='Enter Building Name Here'
                  placeholder={buildingInfo.buildingName}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formUnitNo'
              >
                <Form.Label className='mb-3'>Unit No</Form.Label>
                <Form.Control
                  className='read-only'
                  aria-label='Enter Unit No Here'
                  placeholder={buildingInfo.unitNo}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        )}

      {/* Name / Email Address */}
      {stateAccount &&
        (stateAccount === 'Finance' ||
          stateAccount === 'Engineering' ||
          stateAccount === 'Security' ||
          stateAccount === 'BOD') && (
          <Row>
            <p
              className='h5 fw-bold'
              style={{ color: '#00B6E9' }}
            >
              Personal Information
            </p>
            <Col md={3}>
              <Form.Group className='mb-5'>
                <Form.Label>Name*</Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={data.names}
                      placeholder='Select Name'
                      styles={customStyles}
                      {...field}
                    ></Select>
                  )}
                  {...register('names', {
                    required: '(Required)',
                    value: data.names,
                    onChange: (e) => {
                      setBuildingInfo((prev) => {
                        return {
                          ...prev,

                          reference_id: e.target.value.reference_id,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className='mb-5'>
                <Form.Label className='mb-3'>
                  Email Address <span style={{ color: '#CB1212' }}>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Email Here'
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, emailAddress: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}

      {/* Unit Area / Period Lease Parking Slots */}
      {stateAccount &&
        (stateAccount === 'Owner' ||
          stateAccount === 'Tenant' ||
          stateAccount === 'owner' ||
          stateAccount === 'tenant') && (
          <Row>
            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formUnitArea'
              >
                <Form.Label className='mb-3'>Unit Area</Form.Label>
                <Form.Control
                  className='read-only'
                  aria-label='Enter Unit Area Here'
                  placeholder={buildingInfo.unitArea}
                  readOnly
                  onChange={(e) => setPeriodofLeastFrom(e.target.value)}
                />
              </Form.Group>
            </Col>

            {stateAccount &&
              (stateAccount === 'Owner' || stateAccount === 'owner') && (
                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formParkingSlots'
                  >
                    <Form.Label className='mb-3'>Parking Slot(s)</Form.Label>
                    <Form.Control
                      className='read-only'
                      aria-label='Enter Parking Slot(s)s Here'
                      placeholder={buildingInfo.parkingSlots}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              )}

            {stateAccount &&
              (stateAccount === 'Tenant' || stateAccount === 'tenant') && (
                <>
                  <Col md={3}>
                    <Form.Group
                      className='mb-5'
                      controlId='formPeriodLeaseFrom'
                    >
                      <Form.Label className='mb-3'>
                        Period of Lease (From){' '}
                        <span style={{ color: '#CB1212' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type='date'
                        aria-label='Enter Parking Slot(s)s Here'
                        placeholder='Select Period Lease'
                        onChange={(e) => setPeriodofLeastFrom(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group
                      className='mb-5'
                      controlId='formPeriodLeaseTo'
                    >
                      <Form.Label className='mb-3'>
                        To <span style={{ color: '#CB1212' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type='date'
                        aria-label='Enter Parking Slot(s)s Here'
                        placeholder='Select Period Lease'
                        onChange={(e) => setPeriodofLeastTo(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
          </Row>
        )}

      {/* User Roles */}
      {((stateAccount &&
        (stateAccount === 'Owner' ||
          (stateAccount && stateAccount === 'owner'))) ||
        stateAccount === 'Tenant' ||
        stateAccount === 'tenant') && (
        <>
          <Row>
            <label className='mb-3 header'>PERSONAL INFORMATION</label>

            <Col md={3}>
              <Form.Group className='mb-5'>
                <Form.Label className='mb-3'>
                  Email Address <span style={{ color: '#CB1212' }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder='Enter Email Here'
                  value={userData.emailAddress}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, emailAddress: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formHomeAddress'
              >
                <Form.Label className='mb-3'>Home Address</Form.Label>
                <Form.Control
                  aria-label='Enter Address Here'
                  placeholder='Enter Address Here'
                  value={userData.homeAddress}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, homeAddress: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formMobileNumber'
              >
                <Form.Label className='mb-3'>Mobile No.</Form.Label>
                <Form.Control
                  type='text'
                  aria-label='Enter Number Here'
                  placeholder='Enter No. Here'
                  maxLength={11}
                  value={userData.mobileNumber}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setUserData((prev) => {
                        return { ...prev, mobileNumber: e.target.value };
                      });
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formbirthdate'
              >
                <Form.Label className='mb-3'>Date of Birth</Form.Label>
                <Form.Control
                  type='date'
                  aria-label='Select Birthdate'
                  placeholder='Select Birth Date'
                  value={userData.dob}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        dob: e.target.value,
                      };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formGender'
              >
                <Form.Label className='mb-3'>Gender</Form.Label>
                <Form.Select
                  aria-label='Select Gender'
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, gender: e.target.value };
                    })
                  }
                >
                  <option
                    hidden
                    value=''
                  >
                    Select Gender
                  </option>
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formCitizenship'
              >
                <Form.Label className='mb-3'>Citizenship</Form.Label>
                <Form.Control
                  aria-label='Enter Citizenship Here'
                  placeholder='Enter Citizenship Here'
                  value={userData.citizenship}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, citizenship: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formOccupation'
              >
                <Form.Label className='mb-3'>Occupation</Form.Label>
                <Form.Control
                  aria-label='Enter Occupation Here'
                  placeholder='Enter Occupation Here'
                  value={userData.occupation}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, occupation: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formCompanyName'
              >
                <Form.Label className='mb-3'>Company Name</Form.Label>
                <Form.Control
                  aria-label='Enter Company Name Here'
                  placeholder='Enter Company Name Here'
                  value={userData.companyName}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, companyName: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formCompanyAddress'
              >
                <Form.Label className='mb-3'>Company Address</Form.Label>
                <Form.Control
                  aria-label='Enter Company Address Here'
                  placeholder='Enter Company Address Here'
                  value={userData.companyAddress}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, companyAddress: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <label className='mb-3 header'>EMERGENCY CONTACT PERSON</label>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formEmergencyName'
              >
                <Form.Label className='mb-3'>Name*</Form.Label>
                <Form.Control
                  aria-label='Enter Name Here'
                  placeholder='Enter Name Here'
                  value={userData.emergencyName}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return { ...prev, emergencyName: e.target.value };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formEmergencyContact'
              >
                <Form.Label className='mb-3'>Contact Number</Form.Label>
                <Form.Control
                  type='text'
                  maxLength={11}
                  aria-label='Enter Contact Number Here'
                  placeholder='Enter No. Here'
                  value={userData.emergencycontactNumber}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setUserData((prev) => {
                        return {
                          ...prev,
                          emergencycontactNumber: e.target.value,
                        };
                      });
                    }
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className='mb-5'>
                <Form.Label className='mb-3'>Email Address*</Form.Label>
                <Form.Control
                  type='email'
                  aria-label='Enter Email Address Here'
                  placeholder='Enter Email Here'
                  value={userData.emergencyEmailAddress}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        emergencyEmailAddress: e.target.value,
                      };
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}

      {((stateAccount && stateAccount === 'Owner') ||
        (stateAccount && stateAccount === 'owner')) && (
        <Row>
          <label className='mb-3 header'>BROKER</label>

          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBrokerName'
            >
              <Form.Label className='mb-3'>
                Broker/Property Specialist
              </Form.Label>
              <Form.Control
                aria-label='Enter Name Here'
                placeholder='Enter Name Here (Optional)'
                value={userData.brokerName}
                maxLength={11}
                onChange={(e) =>
                  setUserData((prev) => {
                    return {
                      ...prev,
                      brokerName: e.target.value,
                    };
                  })
                }
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBrokerContact'
            >
              <Form.Label className='mb-3'>
                Broker&apos;s Contact No.
              </Form.Label>
              <Form.Control
                type='text'
                maxLength={11}
                aria-label='Enter Contact Number Here'
                placeholder='Enter No. Here (Optional)'
                value={userData.brokerNumber}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        brokerNumber: e.target.value,
                      };
                    });
                  }
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}

      {((stateAccount && stateAccount === 'Tenant') ||
        (stateAccount && stateAccount === 'tenant')) && (
        <Row>
          <label className='mb-3 header'>PERSONS STAYING IN THE UNIT</label>

          <UnitList
            units={units}
            setUnits={setUnits}
          />
        </Row>
      )}

      <Row>
        <label className='mb-3 header'>PASSWORD</label>

        <Col md={3}>
          <Form.Group
            className='mb-5'
            controlId='formPassword'
          >
            <Form.Label className='mb-3'>
              Enter Password <span style={{ color: '#CB1212' }}>*</span>
            </Form.Label>
            <Form.Control
              type='password'
              aria-label='Enter Password'
              placeholder='Enter Password Here'
              value={userData.password}
              onChange={(e) =>
                setUserData((prev) => {
                  return {
                    ...prev,
                    password: e.target.value,
                  };
                })
              }
            />
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group
            className='mb-5'
            controlId='formConfirmPassword'
          >
            <Form.Label className='mb-3'>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              aria-label='Enter Confirm Password'
              placeholder='Enter Confirm Password here'
              value={userData.confirmPassword}
              onChange={(e) =>
                setUserData((prev) => {
                  return {
                    ...prev,
                    confirmPassword: e.target.value,
                  };
                })
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>{showResponseAPI}</Col>
        <Col>
          <Form.Group className='buttons'>
            <Button
              className='submit'
              variant='primary'
              type='submit'
            >
              Submit
            </Button>
            <Button
              className='cancel'
              variant='default'
            >
              Cancel
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default ManagementForm;
