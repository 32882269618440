/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';

// import components below
import CalendarUI from '../../../../components/UI/CalendarUI';
import convertDate from '../../../../helpers/convertDate';

// import assets below
import './View.scss';
import Search from '../../../../components/SVG/Search';
import CaretLeft from '../../../../components/SVG/CaretLeft';
import CaretRight from '../../../../components/SVG/CaretRight';

// API
import { getConcernByReferenceId } from 'api/request';

// Context
import userContext from 'components/userContext/userContext';

// main component
const View = () => {
  const { id } = useParams();
  const { data, setData } = useContext(userContext);
  const { role } = data;
  console.log(role);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(convertDate(new Date()));
  const [userData, setUserData] = useState({
    concern: '',
    subject: '',
    unitNo: '',
    name: '',
    accountType: '',
    description: '',
    status: '',
    images: [],
  });
  const navigate = useNavigate();

  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    getConcernByReferenceId(id, '', callback);
  }, []);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);

        setUserData((prevState) => {
          return {
            ...prevState,
            concern: data?.data?.attributes?.type,
            subject: data?.data?.attributes?.subject,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            accountType: data?.data?.relationships?.user?.attributes?.role,
            description: data?.data?.attributes?.description,
            status: data?.data?.attributes?.status,
            images: data?.data?.attributes?.concern_images,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <div className='concerns_viewRequest'>
      <Row>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Concern</h6>
            <span>{userData.concern}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Subject</h6>
            <span>{userData.subject}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Unit No.</h6>
            <span>{userData.unitNo}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Name</h6>
            <span>{userData.name}</span>
          </div>
        </Col>
        {role === 'admin' && (
          <Col>
            <div className='d-flex flex-column'>
              <h6 className='mb-3'>Account Type</h6>
              <span>{userData.accountType}</span>
            </div>
          </Col>
        )}
        <Col lg='12'>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Description</h6>
            <span>{userData.description}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Images</h6>
            <Row>
              {userData && userData.images.length > 0 && (
                <>
                  {userData.images.map((list, idx) => {
                    return (
                      <Col
                        md={4}
                        key={idx}
                      >
                        <div
                          onClick={() => {
                            enlargePhoto(list.url);
                          }}
                          style={{ backgroundImage: `url('${list.url}')` }}
                          className='image-cont'
                        >
                          <div>
                            {Search()}
                            <span>Click to Enlarge Photo</span>
                          </div>
                          <div className='image'></div>
                        </div>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className='mb-3 text-dark'>PMO Remarks</h6>
          <h6 className='mb-3 text-dark'> 01/21/2021 | 8:00 am</h6>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Status</h6>
          <span
            className='fw-bold'
            style={{ color: '#EAB82B' }}
          >
            Pending
          </span>
        </Col>
      </Row>
      {((role !== 'admin' && userData.status === 'pending') ||
        role === 'admin') && (
        <div>
          <h6>
            {!role === 'bod'
              ? 'CLIENT&#39;S AVAILABLE SCHEDULE FOR REPAIR'
              : 'CONFIRMED SCHEDULE FOR VERIFICATION/INSPECTION'}
          </h6>

          <CalendarUI>
            <Calendar
              calendarType='US'
              onChange={onChange}
              value={value}
              prevLabel={CaretLeft()}
              nextLabel={CaretRight()}
              minDate={new Date()}
              onClickDay={(value) => {
                setSelectedDate(convertDate(value));
              }}
              tileContent={(value) => {
                return (
                  <div
                    className='available-time'
                    style={{
                      display:
                        convertDate(value.date) === selectedDate
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <h6>Available Time:</h6>
                    <span>9:00AM - 4:00PM</span>
                  </div>
                );
              }}
            />
          </CalendarUI>
        </div>
      )}
      {role && role !== 'admin' && userData.status !== 'pending' && (
        <Row>
          <Col className='owner-tenant-status'>
            <div className='mb-4 pmo-remarks'>
              <h6>PMO Remarks</h6>

              <p>
                <b>01/21/2021 | 8:00 am</b>
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>

            <div className='mb-0 status'>
              <h6>Status</h6>

              <p>
                <b
                  style={{
                    textTransform: 'capitalize',
                    color: `${
                      userData.status === 'approved' ? '#45C33B' : '#CB1212'
                    }`,
                  }}
                >
                  {userData?.status} - 01/22/2021 | 8:00 am
                </b>
              </p>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          className='message-text-result'
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
              display: 'block',
            }}
          >{`${msg.message}`}</span>
        </Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <div
            style={{ margin: 0, padding: 0 }}
            className='buttons-cont'
          >
            {role &&
              role === 'admin' &&
              userData.status &&
              userData.status === 'pending' && <h6>PMO Action</h6>}

            <div className='buttons'>
              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='approve'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(
                        `/administration/requests/concerns/approve-request/${id}?activeKey=requests`,
                      );
                    }}
                  >
                    Approve
                  </Button>
                )}

              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='deny'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(
                        `/administration/requests/concerns/add-remarks/${id}?activeKey=requests`,
                      );
                    }}
                  >
                    Deny
                  </Button>
                )}

              {role && (
                <Button
                  variant='light'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
