// impor core & vendor packages
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../../components/UI/TableUI';

// import assets below
import './style.scss';

// main component
const BuildingDirectoryTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Account Type',
        accessor: 'col2',
      },
      {
        Header: 'Unit No.',
        accessor: 'col3',
      },
      {
        Header: 'Classification',
        accessor: 'col4',
      },
      {
        Header: 'Parking Slot/s',
        accessor: 'col5',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: 'Mika Reyes',
        col2: 'O',
        col3: 'A 254',
        col4: 'Residential',
        col5: '001',
      },
      {
        col1: 'Mika Reyes',
        col2: 'O',
        col3: 'A 254',
        col4: 'Residential',
        col5: '001',
      },
      {
        col1: 'Mika Reyes',
        col2: 'O',
        col3: 'A 254',
        col4: 'Residential',
        col5: '001',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='dashboardBuildingDirectoryTable'>
      <TableUI>
        <Table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                          >
                            <div
                              className={
                                (cell.value === 'Pending'
                                  ? 'tableUI__DRStatus tableUI__DRStatus--pending'
                                  : null) ||
                                (cell.value === 'Approved'
                                  ? 'tableUI__DRStatus tableUI__DRStatus--approved'
                                  : null) ||
                                (cell.value === 'Denied'
                                  ? 'tableUI__DRStatus tableUI__DRStatus--denied'
                                  : null)
                              }
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default BuildingDirectoryTable;
