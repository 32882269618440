// Packages
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

// Components
import LoginUI from '../../components/UI/LoginUI';
import CompanyLogo from '../../components/CompanyLogo';
import FormResetPassword from './FormResetPassword';

// API
import { resetPass } from '../../api/request';

// CSS
import './style.scss';

const ResetPasswordPage = () => {
  // Initialize Navigate
  const navigate = useNavigate();

  // States
  const [params, setParams] = useState({
    password: '',
    passwordConfirmation: '',
    token: '',
    email: '',
  });
  const [msg, setMsg] = useState('');

  // useForm
  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm();

  // Handle on Reset Pass Button click
  const handleResetPass = (data) => {
    const forParams = {
      password: data.password,
      // eslint-disable-next-line camelcase
      password_confirmation: data.password_confirmation,
    };

    const token = params.token;
    const email = params.email;

    setMsg('');
    resetPass(forParams, token, email, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (data && status) {
      if (status === 200 || status === 202) {
        setParams({
          password: '',
          passwordConfirmation: '',
          token: '',
          email: '',
        });

        reset();

        setTimeout(() => {
          navigate('/');
        }, 2000);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }

      if (status === 403) {
        setMsg('Token Expired. Please send link to email again.');
      }
    }
  };

  // useEffect for token and email
  useEffect(() => {
    // console.log(params);
  }, [params]);

  // useEffect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    setParams((prevState) => {
      return { ...prevState, token: token, email: email };
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>DGCondo</title>
      </Helmet>
      <div className='login'>
        <Form onSubmit={handleSubmit(handleResetPass)}>
          <LoginUI>
            <div className='loginUI__header'>
              <CompanyLogo fontSize='3rem' />
              <h3 className='loginUI__title'>Reset Password</h3>
            </div>
            <div className='loginUI__body'>
              <FormResetPassword
                params={params}
                register={register}
                setParams={setParams}
                errors={errors}
              />
            </div>
            <div className='login_error_label mb-2'>
              <label>{msg}</label>
            </div>
            <div className='loginUI__footer'>
              <Button className='loginUI__button'>Update Password</Button>
            </div>
          </LoginUI>
        </Form>
      </div>
    </Fragment>
  );
};

export default ResetPasswordPage;
