// Packages
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './AssignedServicesListTable.scss';

// Main Component
const AssignedServicesListTable = () => {
  // To return view/delete action
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            as={Link}
            to={`/administration/building-list/edit-building/${id}`}
          >
            View/Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            href='#/action-2'
            style={{ color: '#CB1212' }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Assigned Services',
        accessor: 'assignedServices',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        assignedServices: 'Team & Security',
      },
      {
        id: 2,
        assignedServices: 'Team & Housekeeping',
      },
      {
        id: 3,
        assignedServices: 'Technical Team',
      },
      {
        id: 4,
        assignedServices: 'Job Out (Contractors)',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        assignedServices: data.assignedServices,
        action: renderAction(data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <table
      {...getTableProps()}
      className='building-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup, index) => (
            // Apply the header row props
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column, idx) => (
                  // Apply the header cell props
                  <th
                    style={{
                      textAlign: `${
                        column.Header === 'Action' ? 'right' : 'unset'
                      }`,
                    }}
                    key={idx}
                    {...column.getHeaderProps()}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                key={index}
                {...row.getRowProps()}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell, idx) => {
                    // Apply the cell props
                    return (
                      <td
                        style={{
                          textAlign: `${
                            cell.column.Header === 'Action' ? 'right' : 'unset'
                          }`,
                        }}
                        key={idx}
                        {...cell.getCellProps()}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default AssignedServicesListTable;
