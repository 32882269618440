const CaretLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8.444'
      height='14.889'
      viewBox='0 0 8.444 14.889'
    >
      <path
        id='caret-left'
        d='M31.782,108.3v12.883a1,1,0,0,1-1.709.708l-6.441-6.441a1,1,0,0,1,0-1.416l6.441-6.441A1,1,0,0,1,31.782,108.3Z'
        transform='translate(-23.339 -107.298)'
        fill='#fff'
      />
    </svg>
  );
};

export default CaretLeft;
