/* eslint-disable no-unused-vars */
import api from '../api';
import token from '../token';

const submitWorkPermit = (params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/work-permits`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getWorkPermits = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/work-permits${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getWordPermitByReferenceID = (id, params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/work-permits/${id}${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const approveWorkPermit = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/work-permits/${id}/approve`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

const denyWorkPermit = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/work-permits/${id}/deny`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

const cancelWorkPermit = (id, callback = null) => {
  const newToken = token();

  api
    .post(
      `/api/v1/request/work-permits/${id}/cancel`,
      {},
      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

export { submitWorkPermit, getWorkPermits, getWordPermitByReferenceID, approveWorkPermit, denyWorkPermit, cancelWorkPermit };
