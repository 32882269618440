const Download = () => {
  return (
    <svg viewBox='0 0 31.948 31.972'>
      <path
        id='icons8_download'
        d='M21.948,5.973a1.775,1.775,0,0,0-1.749,1.8V24.786L17.9,22.491A1.775,1.775,0,1,0,15.394,25l5.325,5.325a1.775,1.775,0,0,0,2.51,0L28.554,25a1.775,1.775,0,1,0-2.51-2.51l-2.295,2.3V7.772a1.775,1.775,0,0,0-1.8-1.8ZM11.325,15.759A5.352,5.352,0,0,0,6,21.083V32.62a5.352,5.352,0,0,0,5.325,5.325h21.3a5.352,5.352,0,0,0,5.325-5.325V21.083a5.352,5.352,0,0,0-5.325-5.325H29.961a1.775,1.775,0,1,0,0,3.55h2.662A1.749,1.749,0,0,1,34.4,21.083V32.62A1.749,1.749,0,0,1,32.623,34.4h-21.3A1.749,1.749,0,0,1,9.55,32.62V21.083a1.749,1.749,0,0,1,1.775-1.775h2.662a1.775,1.775,0,1,0,0-3.55Z'
        transform='translate(-6 -5.972)'
      />
    </svg>
  );
};

export default Download;
