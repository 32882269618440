/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
// Components

// CSS/SCSS
import './CarTable.scss';

// Main Component
const CarTable = ({ tableData, user }) => {
  const role = user?.attributes?.role;
  const [tableList, setTableList] = useState();
  const [loading, setLoading] = useState(true);

  // use navigate
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: ' Car Sticker No.',
        accessor: 'carStickerNo',
      },
      {
        Header: ' Unit No.',
        accessor: 'unitNo',
      },

      {
        Header: ' Date',
        accessor: 'date',
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'Parking Slot No.',
        accessor: 'parkingSlotNo',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );
  const statusHandler = (status) => {
    return (
      <div>
        {status === 'denied' && <p className='text-danger'>Expired</p>}
        {status === 'approved' && <p className='active'>Active</p>}
        {status === 'pending' && <p className='forPayment'>For Payment</p>}
      </div>
    );
  };
  const viewRequest = (carID, carStickerNo) => {
    return (
      <u
        style={{ cursor: 'pointer' }}
        onClick={() =>
          navigate(
            `/administration/requests/car-sticker/car-view-request/${carStickerNo}`,
          )
        }
      >
        {carStickerNo}
      </u>
    );
  };
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='50.348'
            height='14.617'
            viewBox='0 0 50.348 14.617'
            style={{ fill: 'black' }}
          >
            <path
              id='ellipsis-h'
              d='M40.483,191.309A7.309,7.309,0,1,1,33.174,184,7.3,7.3,0,0,1,40.483,191.309ZM51.039,184a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,51.039,184Zm-35.731,0a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,15.309,184Z'
              transform='translate(-8 -184)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href='#/action-1'>
            <Link
              className='text-dark'
              to={`/administration/requests/car-sticker/upload-proof-of-payment/${id}`}
            >
              Upload Proof of Payment
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            className='text-danger'
            onClick={() => {}}
          >
            Cancel Application
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  // Data of the table
  const data = React.useMemo(() => {
    const reconstructedList = tableData.map((data) => {
      return {
        carStickerNo: viewRequest(data.id, data?.attributes?.reference_id),
        unitNo: data?.relationships?.unit?.attributes?.unit_number,
        date: data?.attributes?.date,
        model: data?.attributes?.model_year,
        parkingSlotNo:
          data?.relationships?.parking_slot?.attributes?.slot_number,
        status: statusHandler(data?.attributes?.status),
        action: renderAction(data?.attributes?.reference_id),
      };
    });
    return reconstructedList;
  }, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='car-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => {
                      if (role === 'admin' && column.Header === 'Action') {
                        return;
                      }

                      return (
                        <th
                          key={idx}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        if (
                          role === 'admin' &&
                          cell.column.Header === 'Action'
                        ) {
                          return;
                        }
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CarTable;
