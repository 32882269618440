const Logo = () => {
  return (
    <svg
      viewBox='0 0 173 218'
      fill='none'
    >
      <g clipPath='url(#clip0_801_6)'>
        <path
          d='M3.83023 92.2194L52.0137 64.0878C52.8934 63.5674 53.8682 63.2246 54.8819 63.0793C55.8956 62.9339 56.9283 62.9888 57.9206 63.2408C58.9129 63.4928 59.8451 63.9369 60.6638 64.5475C61.4824 65.1582 62.1713 65.9234 62.6908 66.7991C63.2102 67.6748 63.5501 68.6438 63.6907 69.6502C63.8314 70.6567 63.7701 71.6808 63.5104 72.6637C63.2506 73.6466 62.7976 74.5688 62.1773 75.3774C61.557 76.1859 60.7817 76.8649 59.8959 77.3751L15.5427 103.271V127.186L52.0147 105.892C53.791 104.855 55.9098 104.561 57.905 105.074C58.8929 105.327 59.8207 105.772 60.6354 106.382C61.4502 106.991 62.1359 107.754 62.6534 108.627C63.171 109.499 63.5102 110.464 63.6518 111.467C63.7934 112.469 63.7345 113.49 63.4785 114.47C63.2226 115.45 62.7745 116.37 62.16 117.179C61.5455 117.987 60.7765 118.667 59.8969 119.18L15.5437 145.076V171.522L52.0157 150.229C53.792 149.192 55.9108 148.898 57.906 149.41C58.8939 149.664 59.8217 150.109 60.6364 150.718C61.4512 151.328 62.1369 152.091 62.6544 152.963C63.172 153.836 63.5112 154.801 63.6528 155.804C63.7944 156.806 63.7355 157.827 63.4795 158.807C63.2236 159.787 62.7755 160.707 62.161 161.515C61.5465 162.324 60.7775 163.004 59.8979 163.517L15.5447 189.412V202.601H58.2292C60.2902 202.601 62.2669 203.413 63.7243 204.859C65.1817 206.305 66.0005 208.266 66.0005 210.31C66.0005 212.355 65.1817 214.316 63.7243 215.762C62.2669 217.207 60.2902 218.02 58.2292 218.02H7.77085C5.70989 218.02 3.73335 217.207 2.27604 215.762C0.81872 214.316 0 212.355 0 210.311V98.8641C2.82715e-06 97.5216 0.353387 96.2024 1.02518 95.0369C1.69697 93.8715 2.66388 92.9003 3.83023 92.2194V92.2194Z'
          fill='#0067B3'
        />
      </g>
      <g clipPath='url(#clip1_801_6)'>
        <path
          d='M169.192 63.7712L62.654 1.06821C61.4796 0.377351 60.1435 0.00910519 58.781 0.00075557C57.4185 -0.00759405 56.078 0.344253 54.8952 1.02067C53.7125 1.69709 52.7294 2.67406 52.0457 3.85263C51.362 5.0312 51.0018 6.36951 51.0017 7.73206V218H58.7326C59.7479 218 60.7532 217.8 61.6912 217.412C62.6292 217.023 63.4815 216.454 64.1995 215.736C64.9174 215.018 65.4868 214.166 65.8753 213.228C66.2637 212.29 66.4636 211.284 66.4635 210.269V21.2534L157.544 74.8557V176.048L119.737 153.797V136.075C126.269 134.21 131.907 130.047 135.612 124.353C139.317 118.659 140.839 111.818 139.897 105.09C138.956 98.3622 135.614 92.2015 130.489 87.7432C125.363 83.2849 118.799 80.8295 112.006 80.8295C105.213 80.8295 98.6484 83.2849 93.5228 87.7432C88.3973 92.2015 85.0558 98.3622 84.1143 105.09C83.1728 111.818 84.6947 118.659 88.3994 124.353C92.1042 130.047 97.7422 134.21 104.274 136.075V210.268C104.274 212.318 105.089 214.285 106.539 215.735C107.988 217.184 109.955 217.999 112.005 217.999H165.275C167.325 217.999 169.292 217.184 170.741 215.735C172.191 214.285 173.006 212.318 173.006 210.268V70.4341C173.005 69.0876 172.653 67.7645 171.984 66.5959C171.315 65.4273 170.353 64.4536 169.192 63.7712V63.7712ZM99.3008 108.996C99.3004 106.483 100.045 104.027 101.44 101.938C102.836 99.8492 104.819 98.2209 107.14 97.2592C109.461 96.2976 112.015 96.0457 114.479 96.5356C116.943 97.0255 119.206 98.235 120.983 100.011C122.759 101.788 123.969 104.051 124.459 106.515C124.95 108.979 124.698 111.533 123.737 113.854C122.776 116.175 121.148 118.159 119.059 119.554C116.97 120.95 114.514 121.695 112.002 121.695C108.636 121.692 105.408 120.353 103.027 117.973C100.646 115.593 99.306 112.366 99.3008 109V108.996ZM157.544 202.537H119.733V171.738L157.544 193.991V202.537Z'
          fill='#00B6E9'
        />
      </g>
      <defs>
        <clipPath id='clip0_801_6'>
          <rect
            width='66'
            height='155'
            fill='white'
            transform='translate(0 63)'
          />
        </clipPath>
        <clipPath id='clip1_801_6'>
          <rect
            width='122'
            height='218'
            fill='white'
            transform='translate(51)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
