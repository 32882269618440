import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Col, Tab } from 'react-bootstrap';
import Main from '../../../components/Main';

import './PaymentHistory.scss';
import PaymentHistoryTable from './PaymentHistoryTable';
function PaymentHistory() {
  return (
    <Fragment>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Payment History'
                id='Payment History'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Payment History'
                  title='Payment History  '
                >
                  <div className='paymnentHistory-container-table'>
                    <PaymentHistoryTable />
                  </div>
                </Tab>

                <nav className='d-flex justify-content-between pagination'>
                  <ul className='pagination'>
                    <ul className='page-link mx-1'>1</ul>
                  </ul>
                  <p>Showing 4 out of 4 entries</p>
                </nav>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default PaymentHistory;
