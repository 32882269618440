/* eslint-disable camelcase */
// import core & vendor packages
import React, { useState, useEffect } from 'react';
import { Form, Button, Badge, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// import components below
import FormUI from '../../../../components/UI/FormUI';

// import assets below
import './Form.scss';

// API
import { getConcernByReferenceId, approveConcern } from 'api/request';

// main component
const ApproveRequestForm = () => {
  // eslint-disable-next-line newline-per-chained-call
  const dateToday = new Date().toISOString().split('T')[0];
  const [msg, setMsg] = useState({
    message: '', status: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    dates: [],
    options: [],
    selectedDates: [],
    dateIssued: dateToday,
    personAssigned: '',
  });

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    getConcernByReferenceId(id, '', callback);
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const onSubmit = (e) => {
    console.log(e);
    const ids = data.selectedDates.map((date) => {
      return date.id;
    });

    const params = {
      person_assigned: data.personAssigned,
      date_issued: data.dateIssued,
      approved_client_date_id: ids,
    };

    approveConcern(id, params, approveCallback);
  };

  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const options = data.data.relationships.concern_dates.map(
          (list, idx) => {
            return {
              id: list?.id,
              value: `${idx + 1}: ${list?.attributes?.date} ${
                list?.attributes?.time_start
              } - ${list?.attributes?.date} ${list?.attributes?.time_end}`,
              label: `${idx + 1}: ${list?.attributes?.date} ${
                list?.attributes?.time_start
              } - ${list?.attributes?.date} ${list?.attributes?.time_end}`,
            };
          },
        );

        setData((prevState) => {
          return {
            ...prevState,
            dates: data.data?.relationships?.concern_dates,
            options: options,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const approveCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Successfully Approved', status: 'success' });
        
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  return (
    <div className='concerns_approveRequest'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormUI>
          <Form.Group className='mb-4'>
            <Form.Label>
              Person Assigned{' '}
              {errors.personAssigned ? (
                <span style={{ color: '#ff5c5c' }}>
                  {errors.personAssigned.message}
                </span>
              ) : (
                ''
              )}
            </Form.Label>
            <Form.Control
              type='text'
              {...register('personAssigned', {
                required: '(Required)',
                onChange: (event) => {
                  setData((prevState) => {
                    return {
                      ...prevState,
                      personAssigned: event.target.value,
                    };
                  });
                },
              })}
              value={data.startDateTime}
            />
          </Form.Group>
          <Form.Group className='mb-4'>
            <Form.Label>Date Issued</Form.Label>
            <Form.Control
              style={{ backgroundColor: '#fff' }}
              {...register('dateIssued', {
                required: '(Required)',
                onChange: (event) => {
                  setData((prevState) => {
                    return {
                      ...prevState,
                      dateIssued: event.target.value,
                    };
                  });
                },
              })}
              value={data.dateIssued}
              type='date'
              readOnly
            />
          </Form.Group>
          <Form.Group className='mb-4'>
            <Form.Label>Client&#39;s Available Schedule for Repair</Form.Label>
            <br />
            <span>
              {data.dates && data.dates.length > 0 && (
                <>
                  {data.dates.map((list, idx) => {
                    return (
                      <Badge
                        className='mx-1'
                        key={idx}
                        bg='secondary'
                      >{`${list?.attributes?.date} ${list?.attributes?.time_start} - ${list?.attributes?.date} ${list?.attributes?.time_end}`}</Badge>
                    );
                  })}
                </>
              )}
            </span>
          </Form.Group>
          <Form.Group className='mb-4'>
            <Form.Label>
              PMO Select Schedule for Repair{' '}
              {errors.selectedDates ? (
                <span style={{ color: '#ff5c5c' }}>
                  {errors.selectedDates.message}
                </span>
              ) : (
                ''
              )}
            </Form.Label>

            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  name='colors'
                  options={data.options}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  {...field}
                />
              )}
              {...register('selectedDates', {
                required: '(Required)',
                value: data.selectedDates,
                onChange: (event) => {
                  setData((prevState) => {
                    return { ...prevState, selectedDates: event.target.value };
                  });
                },
              })}
            />
          </Form.Group>
          <div className='buttons'>
            <Row>
              <Col>
                <span
                  style={{
                    color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                    display: 'block',
                  }}
                >{`${msg.message}`}</span>
              </Col>
              <Col className='d-flex btns-cont'>
                <Button
                  variant='submit'
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  variant='cancel'
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </FormUI>
      </Form>
    </div>
  );
};

export default ApproveRequestForm;
