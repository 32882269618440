// Packages
import React from 'react';
import { FaFilter } from 'react-icons/fa';
import { Row, Form, Dropdown, Button } from 'react-bootstrap';

// Components
import Table from './Table';

// CSS/SCSS
import './TenantRequestList.scss';

const TenantRequestList = () => {
  // To render on screen
  return (
    <div className='my-tenant billing'>
      <Row>
        <div className='col-6'>
          <Row>
            <div className='col-5'>
              <Form.Group
                controlId='formSearch'
                className='mb-5 formSearch'
              >
                <Form.Control
                  type='text'
                  placeholder='Search'
                  className='search'
                />

                <Button className='searchbtn'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25.362'
                    height='25.584'
                    viewBox='0 0 25.362 25.584'
                  >
                    <path
                      id='search'
                      d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                      transform='translate(-336.364 -87.386)'
                      fill='#c7c7c7'
                    />
                  </svg>
                </Button>
              </Form.Group>
            </div>
            <div className='col-2 filter'>
              <Dropdown>
                <Dropdown.Toggle>
                  <FaFilter /> Filter
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#/action-1'>Tenant Name</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Date Requested</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Type</Dropdown.Item>
                  <Dropdown.Item href='#/action-2'>Status</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row>
        </div>
      </Row>

      <Table />
    </div>
  );
};

export default TenantRequestList;
