/* eslint-disable camelcase */
// packages
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';

// CSS
import './EditBuildingForm.scss';

// Components

// API
import { updateBuildingList, getBuildingList } from 'api/request';

// CSS

// Main Component
const EditBuildingForm = () => {
  // States
  const [msg, setMsg] = useState([]);
  const [building, setBuilding] = useState([
    { id: '', buildingName: '', noOfUnits: '', noOfParkingSlots: '' },
  ]);
  const [origData, setOrigData] = useState({
    id: '',
    buildingName: '',
    noOfUnits: '',
    noOfParkingSlots: '',
  });

  // URL ID Parameter
  const { id } = useParams();

  // Will render one time
  useEffect(() => {
    getBuildingList(id, getCallback);
  }, []);

  useEffect(() => { console.log(building); }, [building]);

  const getCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      console.log(data.data);
      setBuilding({ 
        id: data.data.id, 
        buildingName: data.data.attributes.name,
        noOfUnits: data.data.attributes.no_of_units,
        noOfParkingSlots: data.data.attributes.no_of_parking_slots,
      });
      setOrigData({ 
        id: data.data.id, 
        buildingName: data.data.attributes.name,
        noOfUnits: data.data.attributes.no_of_units,
        noOfParkingSlots: data.data.attributes.no_of_parking_slots,
      });
    } else {
      setMsg('Something went wrong');
    }
  };

  // Submit Building List
  const submitBuildingList = () => {
    setMsg({ message: '', status: '' });

    if (origData.buildingName === building.buildingName &&
        origData.noOfUnits === building.noOfUnits &&
        origData.noOfParkingSlots === building.noOfParkingSlots) {
      setMsg({ message: 'Nothing to Update.', status: 'failed' });
      return;
    }

    const params = {
      name: building.buildingName,
      no_of_units: building.noOfUnits,
      no_of_parking_slots: building.noOfParkingSlots,
    };

    updateBuildingList(building.id, params, callback);
  };

  const callback = async (response, params) => {
    const { status, data } = await response;

    console.log(params);

    if (status === 202) {
      setMsg({ message: data.message, status: 'success' });

    } else if (status === 400 || status === 422) {
      if (data.errors) {
        const { errors } = data;
        if (errors['name']) {
          setMsg({ message: errors['name'], status: 'failed' });
        }
      }
    } else {
      setMsg('Something went wrong');
      console.log(data);
    }
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formBuildingName'
            >
              <Form.Label>Building Name</Form.Label>
              <Form.Control
                aria-label='Building Name'
                type='text'
                value={building.buildingName}
                onChange={(e) =>
                  setBuilding((prevState) => {
                    return { ...prevState, buildingName: e.target.value };
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formNoOfUnits'
            >
              <Form.Label>No. of Units</Form.Label>
              <Form.Control
                aria-label='Enter No. of Units'
                type='number'
                value={building.noOfUnits}
                onChange={(e) =>
                  setBuilding((prevState) => {
                    return { ...prevState, noOfUnits: e.target.value };
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formNoOfParkingSlots'
            >
              <Form.Label>No. of Parking Slots</Form.Label>
              <Form.Control
                aria-label='Enter No. of Parking Slots'
                type='number'
                value={building.noOfParkingSlots}
                onChange={(e) =>
                  setBuilding((prevState) => {
                    return { ...prevState, noOfParkingSlots: e.target.value };
                  })
                }
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='buttons'>
          <Container
            fluid
            style={{ padding: 0 }}
          >
            <Row>
              <Col className='message-text'>
                {msg.message && <span style={{ color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c', display: 'block' }}>{`${msg.message}`}</span>}
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  onClick={submitBuildingList}
                >
                  Save
                </Button>
                <Button
                  as={Link}
                  to={'/administration/building-list'}
                  className='cancel'
                  variant='default'
                  style={{ padding: '0.8em 2em' }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default EditBuildingForm;
