// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

// import components below
import LoginRouter from './routers/LoginRouter';
import DashboardRouter from './routers/DashboardRouter';
import AdministrationRouter from './routers/AdministrationRouter';
import EngineeringRouter from './routers/EngineeringRouter';
import FinanceRouter from './routers/FinanceRouter';
import SecurityRouter from './routers/SecurityRouter';
import OthersRouter from './routers/OthersRouter';
import MyTenantRouter from 'routers/MyTenantRouter';

// import context
import userContext from './components/userContext/userContext';

// main component
const AppRouter = () => {
  // state
  const [data, setData] = useState({
    user: {},
    isAuth: false,
    units: [],
    role: '',
    selectedUnit: '',
  });

  // function to get and set isAuth and User state data from local storage
  if (!data.isAuth) {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      let userUnit;

      if (user?.attributes?.role === 'tenant') {
        userUnit = user?.relationships?.tenant?.relationships?.unit
          ? {
              id: user?.relationships?.tenant?.relationships?.unit?.id,
              buildingId: user?.relationships?.tenant?.relationships?.unit?.relationships?.building?.id,
              label: `${user?.relationships?.tenant?.relationships?.unit?.relationships?.building?.attributes?.name}, ${user?.relationships?.tenant?.relationships?.unit?.attributes?.unit_number}`,
              value: `${user?.relationships?.tenant?.relationships?.unit?.relationships?.building?.attributes?.name}, ${user?.relationships?.tenant?.relationships?.unit?.attributes?.unit_number}`,
            }
          : '';
      } else {
        userUnit = user?.relationships?.owner?.relationships?.units
          ? {
              id: user?.relationships?.owner?.relationships?.units[0]?.id,
              buildingId: user?.relationships?.owner?.relationships?.units[0]?.relationships?.building?.id,
              label: `${user?.relationships?.owner?.relationships?.units[0]?.relationships?.building?.attributes?.name}, ${user?.relationships?.owner?.relationships?.units[0]?.attributes?.unit_number}`,
              value: `${user?.relationships?.owner?.relationships?.units[0]?.relationships?.building?.attributes?.name}, ${user?.relationships?.owner?.relationships?.units[0]?.attributes?.unit_number}`,
            }
          : '';
      }

      setData((prevState) => {
        return {
          ...prevState,
          user: user,
          isAuth: true,
          role: user?.attributes?.role,
          units: user?.relationships?.owner?.relationships?.units || [user?.relationships?.tenant?.relationships?.unit] || [],
          userUnit: userUnit,
        };
      });
    }
  }

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <userContext.Provider value={{ data, setData }}>
      <BrowserRouter>
        <DashboardRouter data={data} />
        <AdministrationRouter data={data} />
        <EngineeringRouter data={data} />
        <FinanceRouter data={data} />
        <SecurityRouter data={data} />
        <OthersRouter data={data} />
        <MyTenantRouter data={data} />
        <LoginRouter />
      </BrowserRouter>
    </userContext.Provider>
  );
};

export default AppRouter;
