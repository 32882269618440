/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Button, Form } from 'react-bootstrap';

// Components
import Main from '../../../../components/Main';
import { viewStaffIDDenyForm, viewStaffIDRequestForm } from 'api/request';
import { data } from 'jquery';

function StaffDenyRequest() {
  const [remarks, setRemarks] = useState('');
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [userData, setUserData] = useState({
    name: '',
    home_address: '',
    mobile_no: '---',
    date_of_birth: '',
    gender: '',
    citizenship: '',
    occupation: '',
    contact_person: '',
    contact_no: '',
    id_picture_img: '',
    nbi_clearance_img: '',
    valid_id_img: '',
  });
  const { id, selectedbuilding } = useParams();
  const navigate = useNavigate();
  const onsubmitHandler = (e) => {
    e.preventDefault();
    const params = {
      remarks: remarks,
    };
    console.log(params);
    viewStaffIDDenyForm(id, params, submitcallback);
  };
  useEffect(() => {
    console.log(id);
    viewStaffIDRequestForm(selectedbuilding, id, callback);
  }, []);
  useEffect(() => {
    if (msg.message === 'Request staff denied successfully.') {
      setRemarks('');
      setTimeout(() => {
        navigate(-2);
      }, 2000);
    }
  }, [msg]);
  const submitcallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'success' });
        if (status === 401 || status === 422) {
          console.log(data.message);
          setMsg({ message: data.message, status: 'deny' });
        }
      }
    }
  };
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data?.data[0]);

        setUserData((prev) => {
          return {
            ...prev,
            name: data?.data[0]?.attributes?.name,
            home_address: data?.data[0]?.attributes?.home_address,
            mobile_no: data?.data[0]?.attributes?.mobile_number,
            date_of_birth: data?.data[0]?.attributes?.date_of_birth,
            gender: data?.data[0]?.attributes?.gender,
            citizenship: data?.data[0]?.attributes?.citizenship,
            occupation: data?.data[0]?.attributes?.occupation,
            contact_person: data?.data[0]?.attributes?.emergency_contact_person,
            contact_no: data?.data[0]?.attributes?.emergency_contact_number,
            id_picture_img: data?.data[0]?.attributes?.id_picture_img.name,
            nbi_clearance_img:
              data?.data[0]?.attributes?.nbi_clearance_img.name,
            valid_id_img: data?.data[0]?.attributes?.valid_id_img.name,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Deny Request'
                id='Deny Request'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Deny Request'
                  title='Deny Request'
                >
                  <Form
                    className='staffUpload shadow-md'
                    onSubmit={onsubmitHandler}
                  >
                    <p className='headerInformation'>SUMMARY OF REQUEST</p>
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Staff Name*</p>
                        <p>{userData.name}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Home Address*</p>
                        <p>{userData.home_address}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Mobile No.*</p>
                        <p>{userData.mobile_no}</p>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Date of Birth*</p>
                        <p>{userData.date_of_birth}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Gender*</p>
                        <p>{userData.gender}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Citizenship*</p>
                        <p>{userData.citizenship}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Occupation</p>
                        <p>{userData.occupation}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Emergency Contact Person*</p>
                        <p>{userData.contact_person}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Contact No.*</p>
                        <p>{userData.contact_no}</p>
                      </div>
                    </Row>

                    <p className='headerInformation'>UPLOAD DOCUMENTS</p>

                    <Row>
                      <div className='col'>
                        <div className='text-start'>
                          <Form.Group
                            className='mb-4'
                            controlId='formUpload'
                          >
                            <Form.Label className='my-4 fw-bold h5'>
                              ID Picture*
                            </Form.Label>

                            <div className='upload-cont d-flex justify-content-start'>
                              <div className='upload-input'>
                                <label htmlFor='formUpload'></label>
                                <Form.Control
                                  type='file'
                                  className='custom-file-upload'
                                  aria-label='Click to Select File to Upload'
                                  accept='.xlsx, .xls, .csv'
                                ></Form.Control>
                              </div>

                              <span style={{ color: 'black' }}>
                                {!userData.id_picture_img && '---'}
                              </span>
                              <span>
                                {userData.id_picture_img &&
                                  userData.id_picture_img}
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col'>
                        <Form.Group
                          className='mb-4'
                          controlId='formUpload'
                        >
                          <Form.Label className='my-4 fw-bold h5'>
                            NBI Clearance*
                          </Form.Label>

                          <div className='upload-cont d-flex justify-content-start'>
                            <div className='upload-input'>
                              <label htmlFor='formUpload'></label>
                              <Form.Control
                                type='file'
                                className='custom-file-upload'
                                aria-label='Click to Select File to Upload'
                                accept='.xlsx, .xls, .csv'
                              ></Form.Control>
                            </div>

                            <span style={{ color: 'black' }}>
                              {!userData.nbi_clearance_img && '---'}
                            </span>
                            <span>
                              {userData.nbi_clearance_img &&
                                userData.nbi_clearance_img}
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className='col'>
                        <Form.Group
                          className='mb-4'
                          controlId='formUpload'
                        >
                          <Form.Label className='my-4 fw-bold h5'>
                            Valid ID
                          </Form.Label>

                          <div className='upload-cont d-flex justify-content-start'>
                            <div className='upload-input'>
                              <label htmlFor='formUpload'></label>
                              <Form.Control
                                type='file'
                                className='custom-file-upload'
                                aria-label='Click to Select File to Upload'
                                accept='.xlsx, .xls, .csv'
                              ></Form.Control>
                            </div>

                            <span style={{ color: 'black' }}>
                              {!userData.valid_id_img && '---'}
                            </span>
                            <span>
                              {userData.valid_id_img && userData.valid_id_img}
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                    </Row>

                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlTextarea1'
                    >
                      <Form.Label className='remarksLabel h4 fw-bold'>
                        Remarks
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </Form.Group>

                    <div className='d-flex justify-content-between'>
                      <span
                        style={{
                          color:
                            msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                          display: 'block',
                        }}
                      >{`${msg.message}`}</span>
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='btn mx-1 '
                          type='submit'
                        >
                          Submit
                        </Button>
                        <Button
                          className='btn mx-1 '
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default StaffDenyRequest;
