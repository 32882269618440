// Packages
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaMoneyBillAlt } from 'react-icons/fa';
import AssistiveListening from '../SVG/AssistiveListening';
// Components
import CompanyLogo from '../CompanyLogo';
import Administration from './Administration';
import Request from './Request';
import Finance from './Finance';
import Engineering from './Engineering';
import MyTenant from './MyTenant';
import Clipboard from '../SVG/Clipboard';

// context
import userContext from '../userContext/userContext';

// import assets below
import './style.scss';
import Home from '../SVG/Home';
import Exclamation from '../SVG/Exclamation';
import Phone from '../SVG/Phone';

// main component
const Sidebar = () => {
  const { data } = useContext(userContext);
  const { pathname, search } = useLocation();
  const splitSearch = search.split('?activeKey=').pop();
  const paramsArray = splitSearch.split('&id=');
  const activeKey = paramsArray[0];
  // const id = paramsArray[1];

  const adminOnly = data && data.role === 'admin';
  const ownerOnly = data && data.role === 'owner';
  const tenantOnly = data && data.role === 'tenant';
  const bodOnly = data && data.role === 'bod';
  // Routes under dashboard module
  const dashboardRoutes = [
    '/dashboard',
    '/dashboard/administrative-reports',
    '/dashboard/finance-reports',
    '/dashboard/engineering-reports',
    '/dashboard/requests',
    '/dashboard/payments-due',
    '/dashboard/announcements',
    '/dashboard/building-directory',
    '/dashboard/suppliers-directory',
    '/dashboard/concerns',
    '/security/incident-report',
  ];
  console.log(pathname, search);
  return (
    <div className='sidebar'>
      <div className='logo__wrapper'>
        <CompanyLogo fontSize='2rem' />
      </div>

      <Nav className='sidebar__nav'>
        {!bodOnly && (
          <Nav.Item
            className={`${dashboardRoutes.includes(pathname) && 'active'} ${
              pathname === '/dashboard/edit-profile' && 'active'
            }`}
          >
            <div className='svg__wrapper'>{Home()}</div>
            <Link
              className='nav-link'
              to='/dashboard'
            >
              Dashboard
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Nav.Item
            className={
              pathname === '/administration/requests/concerns' && 'active'
            }
          >
            <div className='svg__wrapper'>{AssistiveListening()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/concerns?'
            >
              Concerns
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Nav.Item
            className={pathname === '/security/incident-report' && 'active'}
          >
            <div className='svg__wrapper'>{Exclamation()}</div>
            <Link
              className='nav-link'
              to='/security/incident-report'
            >
              Incident Report
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Nav.Item
            className={pathname === '/administration/violations' && 'active'}
          >
            <div className='svg__wrapper'>{Exclamation()}</div>
            <Link
              className='nav-link'
              to='/administration/violations'
            >
              Violation Reports
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Nav.Item
            className={
              pathname === '/administration/occupancy-reports' && 'active'
            }
          >
            <div className='svg__wrapper'>{Exclamation()}</div>
            <Link
              className='nav-link'
              to='/administration/occupancy-reports'
            >
              Occupancy Report
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Nav.Item
            className={
              pathname === '/administration/government-requirements' && 'active'
            }
          >
            <div className='svg__wrapper'>{Clipboard()}</div>
            <Link
              className='nav-link'
              to='/administration/government-requirements'
            >
              Government Requirements
            </Link>
          </Nav.Item>
        )}
        {bodOnly && (
          <Accordion defaultActiveKey={activeKey}>
            <Accordion.Item eventKey='maintenance-planner'>
              <Accordion.Header>
                <div className='svg__wrapper'>{Clipboard()}</div>
                <Nav.Link
                  className='nav-link'
                  href='#'
                >
                  Contracts
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Item
                  className={
                    pathname ===
                      '/administration/contracts/service-providers' && 'active'
                  }
                >
                  <div className='svg__wrapper'>{Clipboard()}</div>
                  <Link
                    className='nav-link'
                    to='/administration/contracts/service-providers?activeKey=maintenance-planner'
                  >
                    Service Providers
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname ===
                      '/administration/contracts/general-contracts' && 'active'
                  }
                >
                  <div className='svg__wrapper'>{Clipboard()}</div>
                  <Link
                    className='nav-link'
                    to='/administration/contracts/general-contracts?activeKey=maintenance-planner'
                  >
                    General Contracts
                  </Link>
                </Nav.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}

        {(tenantOnly || ownerOnly) && (
          <Accordion defaultActiveKey={activeKey}>
            <Accordion.Item eventKey='billing'>
              <Accordion.Header>
                <div className='svg__wrapper'>
                  <FaMoneyBillAlt />
                </div>
                <Nav.Link
                  className='nav-link'
                  href='#'
                >
                  Billing
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Item
                  className={
                    pathname === '/billing/StatementofAccount' && 'active'
                  }
                >
                  <div className='svg__wrapper'>{Home()}</div>
                  <Link
                    className='nav-link'
                    to='/billing/StatementofAccount?activeKey=billing'
                  >
                    Statement of Accounts
                  </Link>
                </Nav.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
        {adminOnly && (
          <Administration
            activeKey={activeKey}
            pathname={pathname}
          />
        )}
        {(ownerOnly || tenantOnly) && (
          <Request
            activeKey={activeKey}
            pathname={pathname}
            role={data.role}
          />
        )}
        {ownerOnly && (
          <MyTenant
            activeKey={activeKey}
            pathname={pathname}
          />
        )}
        {(adminOnly || bodOnly) && (
          <Finance
            activeKey={activeKey}
            pathname={pathname}
            bodOnly={bodOnly}
            adminOnly={adminOnly}
          />
        )}
        {(adminOnly || bodOnly) && (
          <Engineering
            activeKey={activeKey}
            pathname={pathname}
            adminOnly={adminOnly}
            bodOnly={bodOnly}
          />
        )}
        {adminOnly && (
          <>
            <Nav.Item>
              <span>Security</span>
            </Nav.Item>

            <Nav.Item
              className={pathname === '/security/incident-report' && 'active'}
            >
              <div className='svg__wrapper'>{Exclamation()}</div>
              <Link
                className='nav-link'
                to='/security/incident-report'
              >
                Incident Report
              </Link>
            </Nav.Item>
          </>
        )}
        {(ownerOnly || tenantOnly) && (
          <Nav.Item className={pathname === '/contact-us' && 'active'}>
            <div className='svg__wrapper'>{Phone()}</div>
            <Link
              className='nav-link'
              to='/contact-us'
            >
              Contact Us
            </Link>
          </Nav.Item>
        )}
      </Nav>

      <div className='sidebar__copyright'>
        <span className='copyright__text'>Copyright © DGCondo 2022</span>
        <span>All Rights Reserved</span>
      </div>
    </div>
  );
};

export default Sidebar;
