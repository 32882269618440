// import assets below
import './style.scss';

// main component
const PaginationUI = (props) => {
  const { children } = props;
  return <div className='paginationUI'>{children}</div>;
};

export default PaginationUI;
