// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import EquipmentInventoryContent from './EquipmentInventoryPageContent/EquipmentInventoryContent';

// API

// CSS
import './EquipmentInventoryPage.scss';

const EquipmentInventoryPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Engineering CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Equipment Inventory'
                className='mb-3 tab-form'
              >
                <Tab
                  eventKey='Equipment Inventory'
                  title='Equipment Inventory'
                >
                  <EquipmentInventoryContent />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EquipmentInventoryPage;
