/* eslint-disable */
import React, { Component, KeyboardEventHandler } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface State {
  readonly inputValue: string;
  readonly value: readonly Option[];
  readonly show: boolean;
  readonly confirmDelete: boolean;
  readonly deleteID: Object;
  readonly currentValue: readonly Option[];
}

type Props = {
  value: any;
  onChangeMultiSelect: (e: any) => void;
  setUpdateMsg: (e: any) => void;
  onDeleteType: (e: any) => void;
  refreshList: () => void;
};

export default class TagsInput extends Component<Props, State> {
  state: State = {
    inputValue: '',
    value: this.props.value,
    show: false,
    confirmDelete: false,
    deleteID: {},
    currentValue: null,
  };

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevState !== this.state) {
      if(prevState.confirmDelete === true) {
        this.props.onDeleteType(this.state.deleteID);
      }
    }
  }

  handleChange = (
    value: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.props.setUpdateMsg({message: '', status: ''});

    if(actionMeta.action === 'remove-value') {
      this.setState({
        show: true, 
        confirmDelete: false, 
        deleteID: actionMeta.removedValue, 
        currentValue: value,
      });
    }
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { inputValue, value } = this.state;
    this.props.setUpdateMsg({message: '', status: ''});
    
    console.log('handleKeyDown');

    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab': 
        let checkDuplicate = value.find(o => o.value === inputValue);

        if(!checkDuplicate) {
          this.props.onChangeMultiSelect([...value, {label: inputValue, value: inputValue}]);

          this.setState({
            inputValue: '',
            value: [...value, createOption(inputValue)],
          });

        } else {
          this.props.setUpdateMsg({message: 'Name exists', status: 'faled'});

          this.setState({
            inputValue: '',
            value: [...value],
          });
        }

      event.preventDefault();
    }
  };

  render() {
    const { inputValue, value } = this.state;
    return (
      <>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable={false}
          isMulti
          menuIsOpen={false}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder='Type something and press enter...'
          value={value}
          onFocus={(e) => {
            if(this.state.confirmDelete === true) {
              this.props.onDeleteType(this.state.deleteID);
            }}
          }
        />

      <Modal
        show={this.state.show}
        onHide={() => {this.setState({show: false})}}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm delete ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {this.setState({show: false})}}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {this.setState({show: false, confirmDelete: true, value: this.state.currentValue})}}>Delete</Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  }
}