// Packages
import React from 'react';
import { Card } from 'react-bootstrap';

// CSS
import './EquipmentInventoryForm.scss';
import EquipmentInventoryFormTable from './EquipmentInventoryFormTable';

// Components

// API

// CSS

// Main Component
const EquipmentInventoryForm = () => {
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <EquipmentInventoryFormTable />
    </Card>
  );
};

export default EquipmentInventoryForm;
