// import assets below
import './style.scss';

// main component
const FormUI = (props) => {
  const { children } = props;
  return <div className='formUI'>{children}</div>;
};

export default FormUI;
