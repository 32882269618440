/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Tabs,
  Col,
  Tab,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// API
import { getPaymentInstruction, submitProofOfPayment } from 'api/request';
import Main from 'components/Main';

import './UploadProofOfPayment.scss';

function UploadProofOfPayment() {
  // get id on URL
  const { id } = useParams();
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [uploadData, setUploadData] = useState({
    paymentInstruction: '',
    images: [],
  });

  console.log(uploadData);
  console.log(id);

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const onSubmit = () => {
    const newFormData = new FormData();

    if (uploadData.images.length === 0) {
      setError('images', {
        type: 'custom',
        message: 'Please Select Image',
      });

      return;
    }

    newFormData.append('proof_of_payment', uploadData.images);

    console.log(newFormData);
    submitProofOfPayment(id, newFormData, proofOfPaymentCallback);
  };

  const proofOfPaymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({
          message: data.message,
          status: 'success',
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }

      if (status === 500) {
        console.log(data.message);
        setMsg({
          message: data.message,
          status: 'failed',
        });
      }
    }
  };

  useEffect(() => {
    getPaymentInstruction(paymentCallback);
  }, []);

  useEffect(() => {
    console.log(uploadData);
    console.log(uploadData.images.name);
  }, [uploadData]);

  const paymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setUploadData((prevState) => {
          return { ...prevState, paymentInstruction: data.data };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Request - Amenities - Upload Proof of Payment</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Proof of Payment'
                id='Upload Proof of Payment'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Upload Proof of Payment'
                  title='Upload Proof of Payment  '
                >
                  <Card className='admin-form shadow-sm'>
                    <Form
                      onSubmit={handleSubmit(onSubmit)}
                      encType='multipart/form-data'
                    >
                      <Row>
                        <Col className='payment-details'>
                          <div style={{ width: '100%' }}>
                            <h5 className='font-weight-bold'>
                              Payment Instruction
                            </h5>
                            <br />
                            <Form.Group>
                              <Form.Control
                                value={
                                  uploadData?.paymentInstruction?.attributes
                                    ?.description
                                }
                                as='textarea'
                                readOnly
                                style={{
                                  backgroundColor: '#fff',
                                  border: 'none',
                                }}
                                rows={5}
                              ></Form.Control>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col>
                          <div className='text-center'>
                            <Form.Group
                              className='mb-3'
                              controlId='formUpload'
                            >
                              <Form.Label className='mb-4'>
                                Upload File{' '}
                                {errors.images ? (
                                  <span style={{ color: '#ff5c5c' }}>
                                    {errors.images.message}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Label>

                              <div className='upload-cont'>
                                <div className='upload-input'>
                                  <label htmlFor='formUpload'>
                                    Select file
                                  </label>
                                  <Form.Control
                                    type='file'
                                    className='custom-file-upload'
                                    aria-label='Click to Select File to Upload'
                                    accept='image/*'
                                    {...register('images', {
                                      value: uploadData.images,
                                      onChange: (e) => {
                                        // eslint-disable-next-line prefer-const
                                        let docuArr = [];

                                        Array.from(e.target.files).forEach(
                                          (file) => {
                                            docuArr.push(file);
                                          },
                                        );

                                        setUploadData((prevState) => {
                                          return {
                                            ...prevState,
                                            images: e.target.files[0],
                                          };
                                        });
                                      },
                                    })}
                                  ></Form.Control>
                                </div>

                                <span>
                                  {uploadData.images && (
                                    <>
                                      {uploadData.images &&
                                        uploadData.images.name}

                                      {(uploadData.images.length === 0 ||
                                        uploadData.images === null) && (
                                        <>Upload file</>
                                      )}
                                    </>
                                  )}
                                  {!uploadData.images && (
                                    <>No Images Uploaded</>
                                  )}
                                </span>
                              </div>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className='message-text-result'
                          style={{ justifyContent: 'flex-start' }}
                        >
                          <span
                            style={{
                              color:
                                msg.status === 'success'
                                  ? '#3bd14d'
                                  : '#ff5c5c',
                              display: 'block',
                            }}
                          >{`${msg.message}`}</span>
                        </Col>
                        <Col style={{ justifyContent: 'flex-end' }}>
                          <div style={{ margin: 0, padding: 0 }}>
                            <Form.Group
                              className='buttons text-end'
                              style={{ justifyContent: 'end' }}
                            >
                              <Button
                                className='submit'
                                variant='primary'
                                type='submit'
                              >
                                Submit
                              </Button>
                              <Button
                                as={Link}
                                to={
                                  '/administration/requests/amenities?activeKey=requests'
                                }
                                className='cancel'
                                variant='default'
                                style={{
                                  padding: '0.8em 2em',
                                  color: '#00B6E9',
                                }}
                              >
                                Cancel
                              </Button>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default UploadProofOfPayment;
