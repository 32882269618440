// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import UpdateAccountForm from './UpdateAccountForm';

// API

// CSS
import './UpdateAccount.scss';

const UpdateAccount = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Update Account</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Update Account'
                id='violations-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='Update Account'
                  title='Update Account'
                >
                  <UpdateAccountForm />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default UpdateAccount;
