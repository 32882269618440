// import core & vendor packages below
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// import components below
import Main from '../../../components/Main';
import AmenitiesTabs from './AmenitiesTabs';

// main component
const AmenitiesPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Amenities</title>
      </Helmet>
      <Main title='Requests - Amenities'>
        <Container fluid>
          <Row>
            <Col>
              <AmenitiesTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default AmenitiesPage;
