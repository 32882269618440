/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// Components
import AmenitiesTable from './AmenitiesTable';
import TablePagination from 'components/Pagination';

// CSS/SCSS
import './AmenitiesContent.scss';
import {
  getPaymentInstruction,
  postDeleteAmenities,
} from '../../../../api/request';

import { getAmenitiesListWithPage } from 'api/Amenities';

// Main Component
const AmenitiesContent = ({ refreshList, setRefreshList, setActiveKey }) => {
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const nav = useNavigate();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [tableList, setTableList] = useState();
  // Static titles
  const titles = [
    {
      id: 1,
      value: 'A',
      title: 'Building 1',
    },
    {
      id: 2,
      value: 'A',
      title: 'Building 2',
    },
    {
      id: 3,
      value: 'A',
      title: 'Building 3',
    },
    {
      id: 4,
      value: 'A',
      title: 'Building 4',
    },
    {
      id: 5,
      value: 'A',
      title: 'Building 5',
    },
  ];

  const selectPage = (page) => {
    const params = `?per_page=5${page}`;
    setLoading(true);
    setTableList([]);
    getAmenitiesListWithPage(params, callback);
  };

  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getAmenitiesListWithPage('?per_page=5', callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList, tableList]);

  // drop down delete
  const deleteSingleListData = (id) => {
    postDeleteAmenities(id, callback);
    setRefreshList(true);
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        // setInputPayment({ payment: data.data.attributes.description });

        setTableList(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
        console.log(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBuilding'
            >
              <Form.Select aria-label='Select Building'>
                {titles.map((val) => {
                  return (
                    <option
                      value={val.value}
                      key={val.id}
                    >
                      {val.title}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={5}>
            <div className='edit-container'>
              <Button
                className='edit'
                variant='primary'
                onClick={() => {
                  return setActiveKey('Amenities Form');
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26.879'
                  height='26.879'
                  viewBox='0 0 26.879 26.879'
                >
                  <path
                    id='plus'
                    d='M24.959,42.56h-8.64V33.92A1.92,1.92,0,0,0,14.4,32h-1.92a1.92,1.92,0,0,0-1.92,1.92v8.64H1.92A1.92,1.92,0,0,0,0,44.479V46.4a1.92,1.92,0,0,0,1.92,1.92h8.64v8.64a1.92,1.92,0,0,0,1.92,1.92H14.4a1.92,1.92,0,0,0,1.92-1.92v-8.64h8.64a1.92,1.92,0,0,0,1.92-1.92v-1.92A1.92,1.92,0,0,0,24.959,42.56Z'
                    transform='translate(0 -32)'
                    fill='#fff'
                  />
                </svg>
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            {tableList && tableList.length === 0 && <p>Table is empty</p>}
            {tableList && tableList.length > 0 && (
              <>
                <AmenitiesTable
                  tableList={tableList}
                  deleteSingleListData={deleteSingleListData}
                />

                <TablePagination
                  links={links}
                  meta={meta}
                  selectPage={selectPage}
                />
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default AmenitiesContent;
