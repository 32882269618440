/* eslint-disable no-unused-vars */
// import core and vendor packages below
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';

// import components below
import TableUI from '../../../components/UI/TableUI';

// import style below
import './Table.scss';
import { useEffect } from 'react';

// main component
const MoveInOutTable = ({
  tableData,
  user,
  refreshTable,
  filterTable,
  refreshList,
}) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  const role = user?.attributes?.role;
  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };
  useEffect(() => {
    console.log(role);
    console.log(filterTable);
  }, [filterTable]);
  const columns = useMemo(
    () => [
      {
        Header: 'Move In No.',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Tenant Name',
        accessor: 'col2',
      },
      {
        Header: 'Unit No.',
        accessor: 'col3',
      },
      {
        Header: 'Move In Date',
        accessor: 'col4',
      },
      {
        Header: 'Lease Expirty Date',
        accessor: 'col5',
      },
      {
        Header: 'Status',
        accessor: 'col6',
      },
      {
        Header: 'Action',
        accessor: 'col7',
      },
    ],
    [],
  );
  const renderAction = (userid, id) => {
    return (
      <Link
        to={
          role === 'admin'
            ? `/administration/requests/move-in-out/view-request/${id}`
            : '#'
        }
      >
        <u>{id}</u>
      </Link>
    );
  };
  const data = useMemo(() => {
    console.log(tableData);
    if (tableData && tableData.length > 0) {
      const newData = tableData.map((list) => {
        return {
          col1: renderAction(
            list?.relationships?.user?.id,
            list?.attributes?.reference_id,
          ),
          col2: list?.relationships?.user?.attributes?.name,
          col3: list?.relationships?.unit?.attributes?.unit_number,
          col4: list?.attributes?.schedule_date,
          col5: list?.attributes?.lease_expiry_date,
          col6: list?.attributes?.status,
          col7: list?.attributes?.reference_id,
        };
      });

      return newData;
    } else {
      return [];
    }
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='moveInOutTable'>
      <TableUI>
        <Table
          responsive
          {...getTableProps()}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => {
                      if (
                        column.Header === 'Lease Expirty Date' &&
                        filterTable === '1'
                      ) {
                        return;
                      }
                      if (column.Header === 'Unit No.' && role === 'owner') {
                        return;
                      }
                      if (column.Header === 'Action' && role === 'admin') {
                        return;
                      }

                      return (
                        <th
                          key={index}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props

                        if (
                          cell.column.Header === 'Lease Expirty Date' &&
                          filterTable === '1'
                        ) {
                          return;
                        }
                        if (
                          cell.column.Header === 'Unit No.' &&
                          role === 'owner'
                        ) {
                          return;
                        }

                        if (
                          (cell.column.Header === 'Name' ||
                            cell.column.Header === 'Account Type' ||
                            cell.column.Header === 'Unit No.') &&
                          (role === 'owner' || role === 'tenant')
                        ) {
                          return;
                        }
                        if (
                          cell.column.Header === 'Action' &&
                          role === 'admin'
                        ) {
                          return;
                        }
                        if (
                          cell.column.Header === 'Action' &&
                          role === 'owner'
                        ) {
                          return (
                            <td
                              key={index}
                              {...cell.getCellProps()}
                            >
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='50.348'
                                    height='14.617'
                                    viewBox='0 0 50.348 14.617'
                                    style={{ fill: 'black' }}
                                  >
                                    <path
                                      id='ellipsis-h'
                                      d='M40.483,191.309A7.309,7.309,0,1,1,33.174,184,7.3,7.3,0,0,1,40.483,191.309ZM51.039,184a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,51.039,184Zm-35.731,0a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,15.309,184Z'
                                      transform='translate(-8 -184)'
                                    />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/administration/requests/move-in-out/view-details/${cell.value}`}
                                    >
                                      <u className='text-dark'>View</u>
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className='text-danger'
                                    onClick={() => {}}
                                  >
                                    Cancel Application
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          );
                        }
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                            className={
                              (cell.value === 'resolved'
                                ? 'tableUI__status--resolved'
                                : null) ||
                              (cell.value === 'denied'
                                ? 'tableUI__status--denied'
                                : null) ||
                              (cell.value === 'approved'
                                ? 'tableUI__status--resolved'
                                : null) ||
                              (cell.value === 'canceled'
                                ? 'tableUI__status--canceled'
                                : null) ||
                              (cell.value === 'pending'
                                ? 'tableUI__status--pending'
                                : null)
                            }
                            style={{ textTransform: 'capitalize' }}
                          >
                            {cell.column.Header === 'Gate Pass No.' ? (
                              <Link
                                to={`/administration/requests/gate-pass/view-request/${cell.value}?activeKey=requests`}
                              >
                                {cell.render('Cell')}
                              </Link>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default MoveInOutTable;
