// import assets below
import './style.scss';

// main component
const CalendarUI = (props) => {
  const { children } = props;
  return <div className='calendarUI'>{children}</div>;
};

export default CalendarUI;
