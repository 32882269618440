// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import EngineeringReportsFilter from '../Filter';
import EngineeringReportsTable from '../Table';
import EngineeringReportsPagination from '../Pagination';

// import assets below

// main component
const EngineeringReportsTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='equipmentStatus'
        className='mb-3'
      >
        <Tab
          eventKey='equipmentStatus'
          title='Equipment Status'
        >
          <EngineeringReportsFilter />
          <EngineeringReportsTable />
          <EngineeringReportsPagination />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default EngineeringReportsTabs;
