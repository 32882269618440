/* eslint-disable no-unused-vars */
import TablePagination from 'components/Pagination';
import React, { Fragment, useState } from 'react';
import {
  Row,
  Form,
  Button,
  Dropdown,
  Col,
  DropdownButton,
} from 'react-bootstrap';
import CollectionTable from './CollectionTable';
import { BsFileEarmarkBarGraph } from 'react-icons/bs';
import { BsDownload } from 'react-icons/bs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
// css
import './CollectionList.scss';
function CollectionList() {
  const [toogleGraph, setToogleGraph] = useState(false);
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className='collectionList'>
      <Row>
        {!toogleGraph && (
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
        )}

        {toogleGraph && (
          <Col>
            {' '}
            <Row>
              <Col className='d-flex py-3 '>
                <span
                  className='date-span me-4 '
                  style={{ color: '#00B6E9' }}
                >
                  From:
                </span>
                <Form.Control
                  className='date '
                  type='date'
                />
              </Col>
              <Col className='d-flex py-3 '>
                <span
                  className='date-span me-4'
                  style={{ color: '#00B6E9' }}
                >
                  to:
                </span>
                <Form.Control
                  className='date '
                  type='date'
                />
              </Col>
              <Col
                lg={2}
                md={12}
              >
                <div
                  className='edit-container'
                  style={{ textAlign: 'left' }}
                >
                  <DropdownButton
                    id='filter'
                    title='Filter'
                  >
                    <Dropdown.Item href='#/action-1'>Total</Dropdown.Item>
                    <Dropdown.Item href='#/action-2'>Monthly</Dropdown.Item>
                    <Dropdown.Item href='#/action-3'>Quarterly</Dropdown.Item>
                    <Dropdown.Item href='#/action-4'>Yearly</Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col>
            </Row>
          </Col>
        )}

        <Col className='text-end'>
          <Button
            className='mx-1 my-1'
            onClick={() =>
              setToogleGraph((prev) => {
                return !prev;
              })
            }
          >
            <BsFileEarmarkBarGraph />
            {toogleGraph ? 'Table' : 'Graph'}
          </Button>
          <Button className='mx-1 my-1'>
            <BsDownload />
            Download
          </Button>
        </Col>
      </Row>

      {!toogleGraph && <CollectionTable />}
      {toogleGraph && (
        <div
          className=' graph-container shadow-lg py-5 rounded-4'
          style={{ height: '800px', width: 'auto' }}
        >
          <ResponsiveContainer
            width='100%'
            height='100%'
          >
            <BarChart
              width={500}
              height={500}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barGap={5}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey='pv'
                fill='#8884d8'
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

export default CollectionList;
