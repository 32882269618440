// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Col, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';

// API

// CSS
import './ExpendituresPage.scss';
import UploadExpenditureFile from './UploadExpenditureFile/UploadExpenditureFile';
import ExpenditureList from './ExpenditureList/ExpenditureList';

const ExpendituresPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Expenditures</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Expenditure File'
                id='Upload Expenditure File'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Upload Expenditure File'
                  title='Upload Expenditure File'
                >
                  <UploadExpenditureFile />
                </Tab>
                <Tab
                  eventKey='Expenditure List'
                  title='Expenditure List'
                >
                  <ExpenditureList />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ExpendituresPage;
