// import core & vendor packages below
import { Chart } from 'react-google-charts';

// main component
const CollectionEfficiencyGraph = (props) => {
  const { selected } = props;

  const optionsCollectionsTotal = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'none' },
    tooltip: { isHtml: true },
    bar: { groupWidth: '50%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'percent',
      minValue: 0,
      ticks: [0, 0.2, 0.4, 0.6, 0.8, 1],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsTotal = [
    [
      'Data',
      'Collections from Current Collections',
      { role: 'style' },
      { role: 'tooltip', p: { html: true } },
    ],
    ['Collections from Current Billings', 1, '#2179BF', '100%'],
    ['YTD Collections', 0.5, '#00B6E9', '50%'],
  ];

  const optionsCollectionsMonthly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    bar: { groupWidth: '90%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'percent',
      minValue: 0,
      ticks: [0, 0.2, 0.4, 0.6, 0.8, 1],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsMonthly = [
    [
      'Month',
      'Collections from Current Billing',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'YTD Collections',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['JAN', 0.1, 200000, 0.2, 100000],
    ['FEB', 0.9, 200000, 0.8, 200000],
    ['MAR', 0.1, 200000, 0.6, 200000],
    ['APR', 0.4, 200000, 0.2, 200000],
    ['MAY', 0.1, 200000, 0.2, 200000],
    ['JUN', 0.1, 200000, 0.3, 200000],
    ['JUL', 0.7, 200000, 0.5, 200000],
    ['AUG', 0.1, 200000, 0.6, 200000],
    ['SEP', 0.8, 200000, 0.9, 200000],
    ['OCT', 0.9, 200000, 0.5, 200000],
    ['NOV', 0.7, 200000, 0.7, 200000],
    ['DEC', 0.3, 200000, 0.4, 200000],
  ];

  const optionsCollectionsQuarterly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'percent',
      minValue: 0,
      ticks: [0, 0.2, 0.4, 0.6, 0.8, 1],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsQuarterly = [
    [
      'Quarter',
      'Collections from Current Billing',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'YTD Collections',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Q1', 0.5, 4200000, 0.5, 500000],
    ['Q2', 0.6, 3400000, 0.7, 700000],
    ['Q3', 0.8, 4800000, 0.9, 900000],
    ['Q4', 0.4, 1900000, 0.2, 200000],
  ];

  const optionsCollectionsYearly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'percent',
      minValue: 0,
      ticks: [0, 0.2, 0.4, 0.6, 0.8, 1],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsYearly = [
    [
      'Year',
      'Collections from Current Billing',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'YTD Collections',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['2021', 0.5, 4200000, 0.5, 500000],
    ['2022', 0.6, 3400000, 0.7, 700000],
  ];

  return (
    <div className='collectionEfficiencyGraph'>
      {selected === 'Total' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsTotal}
          data={dataCollectionsTotal}
        />
      )}
      {selected === 'Monthly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsMonthly}
          data={dataCollectionsMonthly}
        />
      )}
      {selected === 'Quarterly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsQuarterly}
          data={dataCollectionsQuarterly}
        />
      )}
      {selected === 'Yearly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsYearly}
          data={dataCollectionsYearly}
        />
      )}
    </div>
  );
};

export default CollectionEfficiencyGraph;
