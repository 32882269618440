// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
// Components

// CSS/SCSS
import './ExpenditureTable.scss';

// Main Component
const ExpenditureTable = () => {
  // use navigate
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: ' Ref.#',
        accessor: 'refNo',
      },

      {
        Header: ' Payee',
        accessor: 'payee',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Sub-Category',
        accessor: 'subCategory',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Particulars',
        accessor: 'particulars',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // view request
    const viewRequest = (id, action) => {
      return (
        <u
          onClick={() =>
            navigate(`/finance/expenditures/view-expenditure/${id}`)
          }
        >
          {action}
        </u>
      );
    };
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        date: '01/01/2021',
        refNo: '0010',
        payee: 'CondoCorp',
        type: 'C',
        category: 'Security and Safety Equipment',
        subCategory: 'CCTV Camera',
        amount: '1,000,000,000.00',
        particulars: 'Assoc. Dues',
        action: 'View',
      },
      {
        id: 2,
        date: '01/01/2021',
        refNo: '0010',
        payee: 'CondoCorp',
        type: 'C',
        category: 'Taxes and Licences',
        subCategory: 'Audit Fees',
        amount: '1,000,000,000.00',
        particulars: 'Assoc. Dues',
        action: 'View',
      },
      {
        id: 3,
        date: '01/01/2021',
        refNo: '0010',
        payee: 'CondoCorp',
        type: 'C',
        category: 'Repair Maintenance',
        subCategory: 'Civil Works',
        amount: '1,000,000,000.00',
        particulars: 'Assoc. Dues',
        action: 'View',
      },
      {
        id: 4,
        date: '01/01/2021',
        refNo: '0010',
        payee: 'CondoCorp',
        type: 'C',
        category: '	Building Equipment',
        subCategory: 'Elevator 1',
        amount: '1,000,000,000.00',
        particulars: 'Assoc. Dues',
        action: 'View',
      },
      {
        id: 5,
        date: '01/01/2021',
        refNo: '0010',
        payee: 'CondoCorp',
        type: 'C',
        category: 'Service Contractors',
        subCategory: 'CCTV Camera',
        amount: '1,000,000,000.00',
        particulars: 'Janitorial Services',
        action: 'View',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        date: data.date,
        refNo: data.refNo,
        payee: data.payee,
        type: data.type,
        category: data.category,
        subCategory: data.subCategory,
        amount: data.amount,
        particulars: data.particulars,
        action: viewRequest(data.id, data.action),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='car-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpenditureTable;
