// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';

// Admin CMS
import AdminCMSPage from '../pages/AdminCMS/AdminCMSPage';
import HouseRules from '../pages/AdminCMS/HouseRules/HouseRules';
import Amenities from '../pages/AdminCMS/Amenities/Amenities';
import PaymentInstructions from '../pages/AdminCMS/PaymentInstructions/PaymentInstructions';
import Violations from '../pages/AdminCMS/Violations/Violations';
import EditViolation from 'pages/AdminCMS/Violations/EditViolation/EditViolation';
import EmergencyDirectory from '../pages/AdminCMS/EmergencyDirectory/EmergencyDirectory';
import SuppliersDirectory from '../pages/AdminCMS/SuppliersDirectory/SuppliersDirectory';
import Occupancy from '../pages/AdminCMS/Occupancy/Occupancy';

// Administration: Requests
import AmenitiesPage from '../pages/Requests/Amenities';
import AmenitiesViewRequest from '../pages/Requests/Amenities/ViewAmenities';
import AmenitiesDenyRequest from '../pages/Requests/Amenities/DenyRequest';
import ConcernsPage from '../pages/Requests/Concerns';
import ViewConcern from '../pages/Requests/Concerns/ViewConcern';
import ApproveRequest from '../pages/Requests/Concerns/ApproveRequest';
import AddRemarks from '../pages/Requests/Concerns/AddRemarks';
import MoveInOutPage from '../pages/Requests/MoveInOut';
import ViewMoveInOut from '../pages/Requests/MoveInOut/ViewMoveInOut';
import DenyMoveInOut from '../pages/Requests/MoveInOut/DenyRequest';
import GatePassPage from '../pages/Requests/GatePass';
import ViewGatePass from '../pages/Requests/GatePass/ViewGatePass';
import DenyGatePass from '../pages/Requests/GatePass/DenyRequest';
import WorkPermitPage from '../pages/Requests/WorkPermit';
import ViewWorkPermit from '../pages/Requests/WorkPermit/ViewWorkPermit';
import DenyWorkPermit from '../pages/Requests/WorkPermit/DenyRequest';
import Pets from '../pages/Requests/Pets/Pets';
import ViewRequest from '../pages/Requests/Pets/ViewRequestAndDenyReqest/ViewRequest';
import DenyRequest from '../pages/Requests/Pets/ViewRequestAndDenyReqest/DenyRequest';
import StaffID from '../pages/Requests/StaffID/StaffID';
import StaffViewRequest from '../pages/Requests/StaffID/ViewRequestAndDenyRequest/StaffViewRequest';
import StaffDenyRequest from '../pages/Requests/StaffID/ViewRequestAndDenyRequest/StaffDenyRequest';
import CarSticker from '../pages/Requests/CarSticker/CarSticker';
import CarViewRequest from '../pages/Requests/CarSticker/ViewRequestAndDenyRequest/CarViewRequest';
import CarDenyRequest from '../pages/Requests/CarSticker/ViewRequestAndDenyRequest/CarDenyRequest';
import UploadProofOfPayment from 'pages/Requests/Amenities/UploadProofOfPayment/UploadProofOfPayment';
import ViewBooking from 'pages/Requests/Amenities/ViewBooking';

// Administration: Building List
import BuildingListPage from '../pages/BuildingList/BuildingListPage';
import EditUnit from '../pages/BuildingList/EditUnit/EditUnit';
import EditBuilding from '../pages/BuildingList/EditBuilding/EditBuilding';
import AccountManagementPage from '../pages/AccountManagement/AccountManagementPage';
import UpdateAccount from '../pages/AccountManagement/UpdateAccount/UpdateAccount';
import AnnouncementsPage from '../pages/Announcements/AnnouncementsPage';
import ViewAnnouncement from '../pages/Announcements/ViewAnnouncement/ViewAnnouncement';
import ViolationsPage from '../pages/Violations/ViolationsPage';
import ViewViolationReport from '../pages/Violations/ViewViolationReport/ViewViolationReport';
import GovernmentRequirementsPage from '../pages/GovernmentRequirements/GovernmentRequirementsPage';
import UploadDocument from '../pages/GovernmentRequirements/UploadDocument/UploadDocument';
import View from '../pages/GovernmentRequirements/View/View';
import ContractsPage from '../pages/Contracts/ContractsPage';
import ViewAmenities from '../pages/AdminCMS/Amenities/ViewAmenities/ViewAmenities';
import ViewEditSupplierDirectory from '../pages/AdminCMS/SuppliersDirectory/ViewEditSupplierDirectory/ViewEditSupplierDirectory';
import ViewEditEmergencyDirectory from '../pages/AdminCMS/EmergencyDirectory/ViewEditEmergencyDirectory/ViewEditEmergencyDirectory';

// Administration Government Requirements
import Calendar from 'pages/GovernmentRequirements/Calender/Calendar';

// Contracts
import ServiceProvider from 'pages/Contracts/ServiceProvider/ServiceProvider';
import UploadDocumentServiceProvider from 'pages/Contracts/ServiceProvider/UploadDocument/UploadDocument';
import ViewServiceProvider from 'pages/Contracts/ServiceProvider/View/View';

import GeneralContract from 'pages/Contracts/GeneralContract/GeneralContract';
import UploadDocumentGeneralContract from 'pages/Contracts/GeneralContract/UploadDocument/UploadDocument';
import ViewGeneralContract from 'pages/Contracts/GeneralContract/View/View';
import ViewDetails from 'pages/Requests/MoveInOut/ViewDetails';
import UploadProofPayment from 'pages/Requests/CarSticker/UploadProofPayment/UploadProofPayment';
import OccupancyReports from 'pages/OccupancyReports/OccupancyReports';

const AdministrationRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/administration/admin-cms'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AdminCMSPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/house-rules'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <HouseRules />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/amenities'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <Amenities />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/amenities/view-edit-amenities/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewAmenities />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/occupancy-reports'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <OccupancyReports />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/payment-instructions'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <PaymentInstructions />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/violations'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <Violations />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/violations/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <EditViolation />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/emergency-directory'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <EmergencyDirectory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/supplierDirectory/view-edit-emergency-directory/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewEditEmergencyDirectory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/suppliers-directory'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <SuppliersDirectory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/supplierDirectory/view-edit-supplier-directory/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewEditSupplierDirectory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/occupancy'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <Occupancy />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/admin-cms/car-sticker'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <CarSticker />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/building-list'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <BuildingListPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/building-list/edit-unit/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <EditUnit />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/building-list/edit-building/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <EditBuilding />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/account-management'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AccountManagementPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/account-management/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UpdateAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/amenities'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AmenitiesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/amenities/deny-request'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AmenitiesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/amenities/upload-payment/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UploadProofOfPayment />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path='/administration/requests/amenities'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AmenitiesPage />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path='/administration/requests/amenities/view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AmenitiesViewRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/amenities/view-booking/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewBooking />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/amenities/deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AmenitiesDenyRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/concerns'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ConcernsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/concerns/view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewConcern />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/concerns/approve-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ApproveRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/concerns/add-remarks/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AddRemarks />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/move-in-out'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <MoveInOutPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/move-in-out/view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewMoveInOut />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/move-in-out/view-details/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/move-in-out/deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <DenyMoveInOut />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/gate-pass'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <GatePassPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/gate-pass/view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewGatePass />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/gate-pass/deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <DenyGatePass />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/work-permit'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <WorkPermitPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/work-permit/view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewWorkPermit />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/work-permit/deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <DenyWorkPermit />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/car-sticker'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <CarSticker />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/car-sticker/car-view-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <CarViewRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/car-sticker/car-deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <CarDenyRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/car-sticker/upload-proof-of-payment/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UploadProofPayment />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/staff-id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <StaffID />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/staff-id/staff-view-request/:selectedbuilding/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <StaffViewRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/staff-id/staff-deny-request/:selectedbuilding/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <StaffDenyRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/pets'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <Pets />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/pets/view-request/:id/:role'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/requests/pets/deny-request/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <DenyRequest />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/announcements'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <AnnouncementsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/view-announcements/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewAnnouncement />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/violations'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViolationsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/violations/view-violation-report/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewViolationReport />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/government-requirements'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <GovernmentRequirementsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/government-requirements/upload-document/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UploadDocument />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/government-requirements/view/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <View />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/government-requirements/calendar'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <Calendar />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ContractsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/service-providers'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ServiceProvider />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/service-providers/upload-document/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UploadDocumentServiceProvider />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/service-providers/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewServiceProvider />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/general-contracts'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <GeneralContract />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/general-contracts/upload-document/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <UploadDocumentGeneralContract />
          </ProtectedRoute>
        }
      />
      <Route
        path='/administration/contracts/general-contracts/:id'
        element={
          <ProtectedRoute isAuth={data.isAuth}>
            <ViewGeneralContract />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AdministrationRouter;
