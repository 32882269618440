import React, { useState } from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Main from '../../../components/Main';
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Card,
  Button,
} from 'react-bootstrap';
function UploadCapexFile() {
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Budget File'
                id='Upload Budget File'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Upload Budget File'
                  title='Upload Budget File'
                  aria-selected='true'
                >
                  <Card className='admin-form shadow-sm'>
                    <Form>
                      <Row>
                        <Col>
                          <div className='text-center'>
                            <Form.Group
                              className='mb-4'
                              controlId='formUpload'
                            >
                              <Form.Label className='mb-4'>
                                Upload File
                              </Form.Label>

                              <div className='upload-cont'>
                                <div className='upload-input'>
                                  <label htmlFor='formUpload'>
                                    Select file
                                  </label>
                                  <Form.Control
                                    type='file'
                                    className='custom-file-upload'
                                    aria-label='Click to Select File to Upload'
                                    onChange={showFileName}
                                    accept='.xlsx, .xls, .csv'
                                  ></Form.Control>
                                </div>

                                <span>{name && name}</span>
                              </div>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>

                      <Form.Group
                        className='buttons'
                        style={{ justifyContent: 'center' }}
                      >
                        <Button
                          className='submit'
                          variant='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                        <Button
                          className='cancel'
                          variant='default'
                        >
                          Cancel
                        </Button>
                      </Form.Group>
                    </Form>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default UploadCapexFile;
