/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
// scss
import './ScheduleForm.scss';
// component

function ScheduleForm() {
  // select dropdown menu
  const [selectSchedule, setSelectSchdule] = useState('1');
  // calendar
  const [value, onChange] = useState(new Date());

  // selected dropdown equipment
  const equipment = (
    <Row>
      <div className='col-lg-4'>
        <h5>Equipment</h5>
        <Form.Group
          className='mb-5'
          controlId='equipment'
        >
          <Form.Select
            aria-label='Select Equipment Here'
            defaultValue=''
          >
            <option
              hidden
              value=''
            >
              Select Equipment Here
            </option>
            <option value='Male'>1</option>
            <option value='Female'>2</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className='col-lg-4'>
        <h5>Serial No.</h5>
        <Form.Group
          className='mb-5'
          controlId='serialNo'
        >
          <Form.Select
            aria-label='Select Serial No. Here'
            defaultValue=''
          >
            <option
              hidden
              value=''
            >
              Select Serial No. Here
            </option>
            <option value='Male'>1</option>
            <option value='Female'>2</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className='col-lg-4'>
        <h5>Schedule</h5>

        <div>
          <input
            placeholder='dada'
            className='date'
            type='date'
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
        <div className='mt-4'>
          <a className='dailybutton'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='31'
              height='31'
              viewBox='0 0 31 31'
            >
              <path
                id='icons8_today_3'
                d='M11.417,6A5.436,5.436,0,0,0,6,11.417V30.583A5.436,5.436,0,0,0,11.417,36H30.583A5.436,5.436,0,0,0,36,30.583V11.417A5.436,5.436,0,0,0,30.583,6Zm0,2.5H30.583A2.9,2.9,0,0,1,33.5,11.417v1.25H8.5v-1.25A2.9,2.9,0,0,1,11.417,8.5ZM8.5,15.167h25V30.583A2.9,2.9,0,0,1,30.583,33.5H11.417A2.9,2.9,0,0,1,8.5,30.583Zm18.784,3.32a1.25,1.25,0,0,0-.964.426L19.7,26.267l-3.234-3.234A1.25,1.25,0,1,0,14.7,24.8l4.167,4.167a1.25,1.25,0,0,0,1.813-.047l7.5-8.333a1.25,1.25,0,0,0-.9-2.1Z'
                transform='translate(-5.5 -5.5)'
                fill='#fff'
                stroke='#fff'
                strokeWidth='1'
              />
            </svg>
            Daily
          </a>
        </div>
      </div>
      <div className='col-lg-4'>
        <h5>Assigned Services</h5>
        <Form.Group
          className='mb-5'
          controlId='selectAssigned'
        >
          <Form.Select
            aria-label='Select Assigned Here'
            defaultValue=''
          >
            <option
              hidden
              value=''
            >
              Select Assigned Here
            </option>
            <option value='1'>Male</option>
            <option value='2'>Female</option>
          </Form.Select>
        </Form.Group>
      </div>
    </Row>
  );
  // selected dropdown Others
  const others = (
    <Row>
      <div className='col-lg-4'>
        <h5>Task</h5>
        <Form.Group
          className='mb-5'
          controlId='equipment'
        >
          <Form.Select
            aria-label='Enter Task Here'
            defaultValue=''
          >
            <option
              hidden
              value=''
            >
              Enter Task Here
            </option>
            <option>1</option>
            <option>2</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className='col-lg-4'>
        <h5>Schedule</h5>

        <div>
          <input
            placeholder='dada'
            className='date'
            type='date'
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </div>
      <div className='col-lg-4'>
        <h5>Assigned Services</h5>
        <Form.Group
          className='mb-5'
          controlId='assignedServices'
        >
          <Form.Select
            aria-label='Select Assigned Here'
            defaultValue=''
          >
            <option
              hidden
              value=''
            >
              Select Assigned Here
            </option>
            <option>1</option>
            <option>2</option>
          </Form.Select>
        </Form.Group>
      </div>
    </Row>
  );
  return (
    <div className='ScheduleTable'>
      <Row>
        <div className='col-8'>
          <div className='col-3'>
            <Form.Group
              className='mb-5'
              controlId='formTitle'
            >
              <Form.Select
                aria-label='Select Title'
                defaultValue='1'
                onChange={(e) => setSelectSchdule(e.target.value)}
              >
                <option value='1'>Equipment</option>
                <option value='2'>Others</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </Row>
      <div className='schedule-container'>
        <h6>MAINTENANCE SCHEDULE</h6>

        {selectSchedule === '1' && equipment}
        {selectSchedule === '2' && others}

        <Form.Group
          className='buttons text-end'
          style={{ justifyContent: 'end' }}
        >
          <Button
            className='submit mx-1'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel shadow-sm mx-1 '
            style={{ color: '#00B6E9' }}
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </div>
    </div>
  );
}

export default ScheduleForm;
