/* eslint-disable no-unused-vars */
import api from '../api';
import token from '../token';

const getControlNo = (callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/gate-pass/control/number`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const cancelGatePass = (id, callback = null) => {
  const newToken = token();

  api
    .post(
      `/api/v1/request/gate-pass/${id}/cancel`,
      {},
      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

export { getControlNo, cancelGatePass };
