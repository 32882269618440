/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS
import './ViewForm.scss';

// Components

// API

// CSS

// Main Component
const ViewForm = () => {
  // States
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };

  // To render on screen
  return (
    <Card className='admin-form view-form shadow-sm'>
      <Form>
        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='document'
            >
              <Form.Label>Name of Document</Form.Label>

              <Form.Control
                aria-label='Name of Document'
                type='text'
                placeholder='Name of Document here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='entity'
            >
              <Form.Label>Entity</Form.Label>

              <Form.Control
                aria-label='Entity'
                s
                type='text'
                placeholder='Name of Entity here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='validityperiod'
            >
              <Form.Label>Validity Period</Form.Label>

              <Form.Control
                aria-label='Validity Period'
                type='text'
                placeholder='Validity Period here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='renewaldate'
            >
              <Form.Label>Renewal Date</Form.Label>

              <Form.Control
                aria-label='Renewal Date'
                type='text'
                placeholder='Renewal Date here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='documentfile'
            >
              <Form.Label>Document</Form.Label>

              <Form.Control
                aria-label='Document'
                type='text'
                placeholder='Document here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            as={Link}
            to={'/administration/government-requirements'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViewForm;
