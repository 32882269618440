// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row } from 'react-bootstrap';

// Components
import Main from '../../components/Main';

// API

// CSS
import './BillingPage.scss';

const BillingPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row></Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default BillingPage;
