const InventoryFlow = () => {
  return (
    <svg viewBox='0 0 23.222 19'>
      <path
        id='icons8_inventory_flow'
        d='M4.9,6a.792.792,0,0,0-.792.792V15l.013-.013a1.843,1.843,0,0,1,2.967,2.1h3.089a1.847,1.847,0,1,1,0,3.693H7.091a1.836,1.836,0,0,1-2.843,2.2A2.907,2.907,0,0,0,7.014,25H20.208a2.907,2.907,0,0,0,2.9-2.9v-2.93l-.013.013a1.842,1.842,0,0,1-2.967-2.1H17.042a1.847,1.847,0,0,1,0-3.694h3.089a1.842,1.842,0,0,1,2.967-2.1l.013.013V6.792A.792.792,0,0,0,22.32,6Zm6.861,3.694h3.694a.792.792,0,1,1,0,1.583H11.764a.792.792,0,0,1,0-1.583Zm10.02,2.1a.792.792,0,0,0-.551,1.36l1.287,1.288H17.042a.792.792,0,1,0,0,1.583H22.52l-1.287,1.288a.792.792,0,1,0,1.119,1.119L24.99,15.8a.792.792,0,0,0,0-1.119l-2.639-2.639A.792.792,0,0,0,21.784,11.8Zm-16.368,3.7a.792.792,0,0,0-.544.239L2.232,18.371a.792.792,0,0,0,0,1.119l2.639,2.639a.792.792,0,1,0,1.12-1.12L4.7,19.722h5.478a.792.792,0,1,0,0-1.583H4.7L5.99,16.851a.792.792,0,0,0-.575-1.359Z'
        transform='translate(-2 -6)'
      />
    </svg>
  );
};

export default InventoryFlow;
