// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';

// Components

// CSS/SCSS
import './EquipmentStatusTable.scss';

// Main Component
const EquipmentStatusTable = () => {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Equipment',
        accessor: 'equipment',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },

      {
        Header: ' Serial No.',
        accessor: 'serialNo',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // check status
    const statusHandler = (status) => {
      return (
        <>
          {status === 'Pending' && <p className='pending'>Pending</p>}
          {status === 'Completed' && <p className='completed'>Completed</p>}
        </>
      );
    };

    // navigate Request
    const viewRequest = (equipmentName) => {
      return <div>{equipmentName}</div>;
    };
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        equipment: 'Fire Pump',
        location: 'Ground Floor',
        serialNo: '1011',
        date: '01/21/2021',
        remarks: 'In Good Condition',
        status: 'Completed',
      },
      {
        id: 2,
        equipment: 'Fire Pump',
        location: 'Ground Floor',
        serialNo: '0011',
        date: '01/21/2021',
        remarks: 'For Replacement',
        status: 'Pending',
      },
      {
        id: 3,
        equipment: 'Fire Pump',
        location: 'Ground Floor',
        serialNo: '0021',
        date: '01/21/2021',
        remarks: 'For Repair',
        status: 'Pending',
      },
      {
        id: 4,
        equipment: 'Fire Pump',
        location: 'Ground Floor',
        serialNo: '0041',
        date: '01/21/2021',
        remarks: 'For Repair',
        status: 'Pending',
      },
      {
        id: 5,
        equipment: 'Fire Pump',
        location: 'Ground Floor',
        serialNo: '1471',
        date: '01/21/2021',
        remarks: 'For Repair',
        status: 'Pending',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        equipment: viewRequest(data.equipment),
        location: data.location,
        serialNo: data.serialNo,
        date: data.date,
        remarks: data.remarks,
        status: statusHandler(data.status),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='EquipmentStatus-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EquipmentStatusTable;
