/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Button, Form } from 'react-bootstrap';

// Components
import Main from '../../../../components/Main';
import { petsApproveDeny, viewPetsForm } from 'api/request';

function DenyRequest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [remarks, setRemarks] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    home_address: '',
    mobile_no: '---',
    date_of_birth: '',
    gender: '',
    citizenship: '',
    occupation: '',
    contact_person: '',
    contact_no: '',
    id_picture_img: '',
    nbi_clearance_img: '',
    valid_id_img: '',
  });
  const onsubmitHandler = (e) => {
    e.preventDefault();
    const params = {
      remarks: remarks,
    };
    petsApproveDeny(id, 'deny', params, submitcallback);
  };
  useEffect(() => {
    viewPetsForm(id, callback);
  }, []);
  const submitcallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: data.message, status: 'success' });
        setTimeout(() => {
          navigate(-2);
        }, 2000);
        if (status === 401 || status === 422) {
          setMsg({ message: data.message, status: 'deny' });
        }
      }
    }
  };
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prev) => {
          return {
            ...prev,
            name: data?.data?.attributes?.name,
            type: data?.data?.attributes?.type,
            breed: data?.data?.attributes?.breed,
            color: data?.data?.attributes?.color,
            weight: data?.data?.attributes?.weight,
            height: data?.data?.attributes?.height,
            last_vaccine_date: data?.data?.attributes?.last_vaccine_date,
            last_vaccine_location:
              data?.data?.attributes?.last_vaccine_location,
            user_name: data?.data?.relationships?.user?.attributes?.name,
            account_type: data?.data?.relationships?.user?.attributes?.role,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
          };
        });
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Deny Request'
                id='Deny Request'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Deny Request'
                  title='Deny Request'
                >
                  <Form
                    className='viewRequest shadow-md'
                    onSubmit={onsubmitHandler}
                  >
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Unit No.*</p>
                        <p>{userData.unitNo}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Name*</p>
                        <p>{userData.user_name}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Account Type</p>
                        <p>{userData.account_type}</p>
                      </div>
                    </Row>
                    <p className='headerInformation'>Pet Information</p>
                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Type of Pet*</p>
                        <p>{userData.type}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Name of Pet*</p>
                        <p>{userData.breed}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Breed</p>
                        <p>{userData.color}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Color</p>
                        <p>{userData.color}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Weight</p>
                        <p>{userData.weight}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Height</p>
                        <p>{userData.height}</p>
                      </div>
                    </Row>

                    <p className='headerInformation'>RABIES VACCINE</p>
                    <Row>
                      <div className='col-4'>
                        <Row>
                          <div className='col'>
                            <p className='h5 fw-bold'>Date Last Given</p>
                            <p>{userData.last_vaccine_date}</p>
                          </div>
                        </Row>
                      </div>
                      <div className='col-4'>
                        <Row>
                          <div className='col'>
                            <p className='h5 fw-bold'>Location</p>
                            <p>{userData.last_vaccine_location}</p>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlTextarea1'
                    >
                      <Form.Label className='remarksLabel'>Remarks</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </Form.Group>

                    <div className='d-flex justify-content-between'>
                      <div>
                        <div style={{ color: '#3bd14d' }}>{msg.message}</div>
                      </div>
                      <div>
                        <Button
                          className='btn mx-1 '
                          type='submit'
                        >
                          Submit
                        </Button>
                        <Button
                          className='btn mx-1 '
                          onClick={() => navigate(-2)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default DenyRequest;
