import { Helmet } from 'react-helmet';
import Main from '../../components/Main';
import { Fragment, useState } from 'react';

// import components below
import OccupancyReportsFilter from './Filter';
import TableDetails from './TableDetails';
import TableReports from './TableReports';
import Graph from './Graph';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
function OccupancyReports() {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Occupancy Rate');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    console.log(params);
    return params;
  };

  return (
    <Fragment>
      <Helmet>
        <title>Government Requirements</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Occupancy Reports'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Occupancy Reports'
                title='Occupancy Reports'
              >
                {' '}
                <OccupancyReportsFilter
                  toggle={toggle}
                  onToggle={setToggle}
                  selected={selectedFilter}
                  onSelect={onSelectFilter}
                />
                {!toggle && (
                  <div className='occupancyReports'>
                    <Row>
                      <Col lg='4'>
                        <TableDetails />
                      </Col>
                      <Col lg='8'>
                        <TableReports />
                      </Col>
                    </Row>
                  </div>
                )}
                {toggle && <Graph selected={selectedFilter} />}
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default OccupancyReports;
