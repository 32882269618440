const Car = () => {
  return (
    <svg viewBox='0 0 30.667 23'>
      <path
        id='car'
        d='M29.951,70.708H26.365l-1-2.492A6.674,6.674,0,0,0,19.14,64H11.533A6.676,6.676,0,0,0,5.3,68.217l-1,2.492H.723a.718.718,0,0,0-.7.893l.359,1.438a.718.718,0,0,0,.7.544h1.2A3.807,3.807,0,0,0,.962,76.458v2.875a3.8,3.8,0,0,0,.958,2.511v3.239A1.917,1.917,0,0,0,3.837,87H5.753A1.917,1.917,0,0,0,7.67,85.083V83.167H23v1.917A1.917,1.917,0,0,0,24.92,87h1.917a1.917,1.917,0,0,0,1.917-1.917V81.845a3.8,3.8,0,0,0,.958-2.511V76.458a3.808,3.808,0,0,0-1.322-2.875h1.2a.718.718,0,0,0,.7-.544l.359-1.437A.719.719,0,0,0,29.951,70.708ZM8.864,69.64a2.875,2.875,0,0,1,2.67-1.807H19.14a2.875,2.875,0,0,1,2.67,1.807L23,72.625H7.67Zm-3.11,9.681a1.911,1.911,0,1,1,0-3.821,3.687,3.687,0,0,1,2.875,2.866c0,1.146-1.725.955-2.875.955Zm19.167,0c-1.15,0-2.875.191-2.875-.955A3.687,3.687,0,0,1,24.92,75.5a1.911,1.911,0,1,1,0,3.821Z'
        transform='translate(-0.004 -64)'
      />
    </svg>
  );
};

export default Car;
