/* eslint-disable camelcase */
/* eslint-disable newline-per-chained-call */
/* eslint-disable no-unused-vars */
// import core & vendor packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

// import components below
import FormUI from '../../../components/UI/FormUI';

// import assets below
import './Form.scss';
import Add from '../../../components/SVG/Add';
import { resetPass } from 'api/request';

// main component
const GatePassForm = ({
  buildings,
  setSelectedUnit,
  names,
  setSubmit,
  msg,
  user,
  userUnit,
  setMsg,
  controlNo,
}) => {
  // Useform
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // States
  const [listForm, setListForm] = useState([
    { id: 1, quantity: '', unit: '', description: '', tag: '' },
  ]);
  const [formData, setFormData] = useState({
    bearer: '',
    building: '',
    unit: '',
    dateTime: '',
    preparedBy: '',
    controlNo: '',
  });
  const [unitId, setUnitId] = useState();
  const [data, setData] = useState({
    buildings: [],
    units: [],
  });
  const role = user.attributes.role;

  const changeValue = (e, list, type) => {
    const newValue = listForm.map((arrBuilding) => {
      if (list.id === arrBuilding.id) {
        if (type === 'quantity') {
          return { ...list, quantity: e };
        }

        if (type === 'unit') {
          return { ...list, unit: e };
        }

        if (type === 'description') {
          return { ...list, description: e };
        }

        if (type === 'tag') {
          return { ...list, tag: e };
        }
      }

      return arrBuilding;
    });

    setListForm(newValue);
  };

  // To Add List
  const addList = () => {
    const newID = listForm.length + 1;

    const newList = {
      id: newID,
      quantity: '',
      unit: '',
      description: '',
      tag: '',
    };

    setListForm((prevState) => {
      return [...prevState, newList];
    });
  };

  const onSubmit = (e) => {
    console.log(e);
    let checkList = false;

    listForm.forEach((list, index) => {
      if (list.quantity !== '' && list.unit !== '' && list.description !== '') {
        checkList = true;
      }
    });

    if (!checkList) {
      setMsg({
        message: 'Please fill in the required fields',
        status: 'failed',
      });
      return;
    }

    const items = listForm.map((list) => {
      return {
        quantity: list?.quantity,
        unit: list?.unit,
        description: list?.description,
        inventory_tag: list?.tag || null,
      };
    });

    const params = {
      bearer: formData.bearer,
      scheduled_at: formData.dateTime,
      unit_id: formData?.unit?.id,
      prepared_by: formData.preparedBy,
      control_number: formData.controlNo,
      items: items,
    };

    setSubmit(params);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };

  // Use Effects
  useEffect(() => {
    if (buildings && buildings.length > 0) {
      console.log('BUILDINGS', buildings);
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (unitId && buildings && buildings.length > 0) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (user) {
      setFormData((prevState) => {
        return { ...prevState, preparedBy: user?.attributes?.name };
      });

      reset();
    }
  }, [user]);

  useEffect(() => {
    if (msg.message === 'Request Concern created successfully.') {
      setValue('bearer', '');
      setValue('building', '');
      setValue('unit', '');
      setValue('dateTime', '');
      setValue('preparedBy', '');
      setValue('controlNo', '');
      setFormData({
        bearer: '',
        building: '',
        unit: '',
        dateTime: '',
        preparedBy: '',
        controlNo: `${Math.random().toString(36).slice(-5)}`,
      });
    }
  }, [msg]);

  useEffect(() => {
    if (role !== 'admin') {
      console.log(user);
      const bID =
        user?.relationships?.owner?.relationships?.units[0]?.relationships
          ?.building?.id;
      const uID = {
        id: user?.relationships?.owner?.relationships?.units[0]?.id,
        label:
          user?.relationships?.owner?.relationships?.units[0]?.attributes
            ?.unit_number,
        value:
          user?.relationships?.owner?.relationships?.units[0]?.attributes
            ?.unit_number,
      };

      console.log(bID);

      setFormData((prevState) => {
        return {
          ...prevState,
          building: bID,
          unit: uID,
          user: '',
        };
      });
      setUnitId(bID);
      reset();
    }
  }, [role]);

  useEffect(() => {
    if (controlNo) {
      setFormData((prevState) => {
        return { ...prevState, controlNo: controlNo };
      });
    }
  }, [controlNo]);

  useEffect(() => {
    console.log('FORM DATA', formData);
  }, [formData]);

  return (
    <Form
      className='gatePassForm'
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormUI>
        <Row>
          <Col>
            <Form.Group className='mb-5'>
              <Form.Label>
                Bearer{' '}
                {errors.bearer ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.bearer.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Name Here'
                {...register('bearer', {
                  required: '(Required)',
                  onChange: (event) => {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        bearer: event.target.value,
                      };
                    });
                  },
                })}
                value={formData.bearer}
              />
            </Form.Group>
          </Col>
          {role && role === 'admin' && (
            <>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Building Name{' '}
                    {errors.building ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.building.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        options={data.buildings}
                        placeholder='Select Building'
                        {...field}
                      ></Select>
                    )}
                    {...register('building', {
                      required: '(Required)',
                      value: formData.building,
                      onChange: (event) => {
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            building: event.target.value,
                            unit: '',
                            user: '',
                          };
                        });
                        setValue('unit', '');
                        setUnitId(event.target.value.id);
                      },
                    })}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Unit No.
                    {errors.unit ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.unit.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={customStyles}
                        options={data.units}
                        placeholder='Select Unit'
                        {...field}
                      ></Select>
                    )}
                    {...register('unit', {
                      required: '(Required)',
                      value: formData.unit,
                      onChange: (event) => {
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            unit: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </Col>
            </>
          )}

          {role && role !== 'admin' && (
            <Col>
              <Form.Group className='mb-5'>
                <Form.Label>
                  Unit{' '}
                  {errors.unit ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.unit.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('unit', {
                    required: '(Required)',
                    onChange: (event) => {
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          unit: event.target.value,
                        };
                      });
                    },
                  })}
                  value={formData.unit.label}
                  readOnly
                  style={{ backgroundColor: '#fff', border: 'none', padding: '1em 1em 1em 0' }}
                />
              </Form.Group>
            </Col>
          )}

          <Col>
            <Form.Group className='mb-5'>
              <Form.Label>
                Date and Time{' '}
                {errors.dateTime ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.dateTime.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='datetime-local'
                {...register('dateTime', {
                  required: '(Required)',
                  onChange: (event) => {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        dateTime: event.target.value,
                      };
                    });
                  },
                })}
                value={formData.dateTime}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg='3'>
            <Form.Group className='mb-5'>
              <Form.Label>
                Control No{' '}
                {errors.controlNo ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.controlNo.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='text'
                readOnly
                style={{ backgroundColor: '#fff', border: 'none', padding: '1em 1em 1em 0' }}
                placeholder='Control Number Here'
                className='disabled'
                {...register('controlNo', {
                  required: '(Required)',
                  onChange: (event) => {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        controlNo: event.target.value,
                      };
                    });
                  },
                })}
                value={formData.controlNo}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table borderless>
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Description</th>
                  <th>Inventory Tag</th>
                </tr>
              </thead>
              <tbody>
                {listForm &&
                  listForm.length > 0 &&
                  listForm.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span>{item.id}</span>
                        </td>
                        <td>
                          <Form.Control
                            type='number'
                            placeholder='Enter Quantity Here'
                            value={item.quantity}
                            required
                            onChange={(e) =>
                              changeValue(e.target.value, item, 'quantity')
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type='number'
                            placeholder='Enter Unit Here'
                            value={item.unit}
                            required
                            onChange={(e) =>
                              changeValue(e.target.value, item, 'unit')
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type='text'
                            placeholder='Enter Description Here'
                            value={item.description}
                            required
                            onChange={(e) =>
                              changeValue(e.target.value, item, 'description')
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            type='text'
                            placeholder='Enter Tag Here'
                            value={item.tag}
                            onChange={(e) =>
                              changeValue(e.target.value, item, 'tag')
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Button
              variant='primary'
              onClick={addList}
            >
              {Add()}Add
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg='4'>
            <div className='mt-5'>
              <Form.Label>
                Prepared By{' '}
                {errors.preparedBy ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.preparedBy.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='text'
                readOnly
                placeholder='Control Number Here'
                className='disabled prepared-by'
                {...register('preparedBy', {
                  required: '(Required)',
                  onChange: (event) => {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        preparedBy: event.target.value,
                      };
                    });
                  },
                })}
                value={formData.preparedBy}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <div
              className='formUI__footer'
              style={{ margin: 0 }}
            >
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button variant='cancel'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </Form>
  );
};

export default GatePassForm;
