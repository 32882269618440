// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import EditProfileContent from './EditProfileContent';

// API

// CSS
import './EditProfile.scss';

const EditProfile = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <Main title={'Edit Profile'}>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Edit Profile'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Edit Profile'
                title='Edit Profile'
              >
                <EditProfileContent />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EditProfile;
