/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import IncidentReportForm from './IncidentReportForm/IncidentReportForm';
import IncidentReportList from './IncidentReportList/IncidentReportList';

// API

// CSS
import './IncidentReportPage.scss';
import {
  getBuildingLists,
  getIncidentReportList,
  submitIncidentForm,
} from 'api/request';

const IncidentReportPage = () => {
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const refreshTable = () => {
    setLoading(true);
    const params = `/api/v1/incident-reports?include=building&building_id=${selectedBuilding}`;
    getIncidentReportList(params, requestAmenitiesCallback);
  };
  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
    setLoading(true);
    getBuildingLists('?include=units', buildingListCallback);
  }, []);

  // selected building
  useEffect(() => {
    if (selectedBuilding) {
      const params = `/api/v1/incident-reports?include=building&building_id=${selectedBuilding}`;

      getIncidentReportList(params, requestAmenitiesCallback);
    }
  }, [selectedBuilding]);
  useEffect(() => {
    if (submit) {
      setMsg({ message: '', status: '' });
      submitIncidentForm(submit, submitCallback);
    }
  }, [submit]);
  useEffect(() => {
    if (selectedBuilding) {
      const identifier = setTimeout(() => {
        const params = `/api/v1/incident-reports?include=building&filter[search]=${search}&building_id=${selectedBuilding}&per_page=5`;
        getIncidentReportList(params, requestAmenitiesCallback);
      }, 500);
      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }
      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'error' });
      }
    }
  };
  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);

        setLoading(false);
        setSelectedBuilding(data.data[0].id);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const selectPage = (page) => {
    const params = `/api/v1/incident-reports?include=building&building_id=${selectedBuilding}${page}`;
    getIncidentReportList(params, requestAmenitiesCallback);
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);

        refreshTable();
        setMsg({ message: data.message, status: 'success' });
        setTimeout(() => {
          setMsg({
            message: '',
            status: 'cleanup',
          });
        }, 3000);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Incident Report</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Incident Report Form'
                id='house-rules-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='Incident Report Form'
                  title='Incident Report Form'
                >
                  <IncidentReportForm
                    setSubmit={setSubmit}
                    msg={msg}
                    buildings={buildings}
                    setSelectedBuilding={setSelectedBuilding}
                    selectedBuilding={selectedBuilding}
                  />
                </Tab>
                <Tab
                  eventKey='Incident Reports List'
                  title='Incident Reports List'
                >
                  {loading && <p>Loading</p>}
                  {!loading && (
                    <IncidentReportList
                      tableData={tableData}
                      links={links}
                      meta={meta}
                      selectPage={selectPage}
                      msg={msg}
                      setSearch={setSearch}
                      setFilter={setFilter}
                      selectedBuilding={selectedBuilding}
                      setSelectedBuilding={setSelectedBuilding}
                      buildings={buildings}
                    />
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default IncidentReportPage;
