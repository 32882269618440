/* eslint-disable camelcase */
// import core & vendor packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Calendar from 'react-calendar';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { isWithinInterval } from 'date-fns';

// import components below
import FormUI from '../../../components/UI/FormUI';
import CalendarUI from '../../../components/UI/CalendarUI';
import convertDate from '../../../helpers/convertDate';
import DateTimeRange from './DateTimeRange';

// import assets below
import './Form.scss';
import CaretLeft from '../../../components/SVG/CaretLeft';
import CaretRight from '../../../components/SVG/CaretRight';
import RedCircle from 'components/SVG/RedCircle';

// main component
const AmenitiesForm = ({
  amenities,
  buildings,
  paymentInstruction,
  setSelectedUnit,
  names,
  setSubmit,
  msg,
  user,
  userUnit,
  setSelectedAmenities,
  calendar,
}) => {
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(convertDate(new Date()));
  const [disabledDates, setDisabledDates] = useState([]);
  const [unitId, setUnitId] = useState();
  const [submitData, setSubmitData] = useState({
    amenity: '',
    building: '',
    unit: '',
    user: '',
    startDateTime: '',
    endDateTime: '',
  });

  const [data, setData] = useState({
    amenities: [],
    buildings: [],
    units: [],
    names: [],
  });

  const role = user.attributes.role;

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const getNames = (e) => {
    setSelectedUnit(e);
  };

  const onSubmit = (e) => {
    const params = {
      amenity_id: e.amenity.id,
      unit_id: role === 'admin' ? e.unit.id : userUnit?.id,
      user_id: role === 'admin' ? e.user.id : user.id,
      start_datetime: e.startDateTime,
      end_datetime: e.endDateTime,
    };

    setSubmit(params);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is within any of the ranges
      return isWithinRanges(date, disabledDates);
    }
  };

  const isWithinRange = (date, range) => {
    range[0].setHours(0, 0, 0);
    return isWithinInterval(date, { start: range[0], end: range[1] });
  };

  const isWithinRanges = (date, ranges) => {
    return ranges.some((range) => isWithinRange(date, range));
  };

  useEffect(() => {
    console.log(selectedDate);
    console.log(paymentInstruction);
  }, []);

  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (amenities && amenities.length > 0) {
      const newAmenities = amenities.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Amenity ${idx}`,
          label: list?.attributes?.name || `Unnamed Amenity ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, amenities: newAmenities };
      });
    }
  }, [amenities]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);

  useEffect(() => {
    if (msg.message === 'Request Amenity created successfully.') {
      setValue('unit', '');
      setValue('user', '');
      setValue('building', '');
      setValue('amenity', '');
      setValue('user', '');
      setSubmitData({
        amenity: '',
        building: '',
        unit: '',
        user: '',
        startDateTime: '',
        endDateTime: '',
      });
    }
  }, [msg]);

  useEffect(() => {
    if (calendar) {
      const dataCalendar = calendar.map((list) => {
        return [
          new Date(list?.attributes?.start_datetime),
          new Date(list?.attributes?.end_datetime),
        ];
      });

      setDisabledDates(dataCalendar);
    }
  }, [calendar]);

  useEffect(() => {
    console.log(disabledDates);
  }, [disabledDates]);

  return (
    <Form
      className='amenitiesForm'
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormUI>
        <Row>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label>
                Amenities
                {errors.amenity ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.amenity.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    styles={customStyles}
                    options={data.amenities}
                    placeholder='Select Amenity'
                    {...field}
                  ></Select>
                )}
                {...register('amenity', {
                  required: '(Required)',
                  value: submitData.amenity,
                  onChange: (event) => {
                    setSelectedAmenities(event.target.value.id);
                    setSubmitData((prevState) => {
                      return { ...prevState, amenity: event.target.value };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>

          {role && role === 'admin' && (
            <>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Building Name
                    {errors.building ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.building.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.buildings}
                        placeholder='Select Building'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('building', {
                      required: '(Required)',
                      value: submitData.building,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            building: event.target.value,
                            unit: '',
                            user: '',
                          };
                        });
                        setValue('unit', '');
                        setValue('user', '');
                        setUnitId(event.target.value.id);
                      },
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    Unit No.
                    {errors.unit ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.unit.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.units}
                        placeholder='Select Unit'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('unit', {
                      required: '(Required)',
                      value: submitData.unit,
                      onChange: (event) => {
                        getNames(event.target.value.id);
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            unit: event.target.value,
                            user: '',
                          };
                        });
                        setValue('user', '');
                      },
                    })}
                  />
                </Form.Group>
              </Col>
            </>
          )}

          {role && (role === 'owner' || role === 'tenant') && (
            <DateTimeRange
              errors={errors}
              register={register}
              setSubmitData={setSubmitData}
              submitData={submitData}
            />
          )}
        </Row>

        {/* For Admin Only */}
        {role && role === 'admin' && (
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Name{' '}
                  {errors.user ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.user.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={data.names}
                      placeholder='Select User'
                      styles={customStyles}
                      {...field}
                    ></Select>
                  )}
                  {...register('user', {
                    required: '(Required)',
                    value: submitData.user,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          user: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>
            <DateTimeRange
              errors={errors}
              register={register}
              setSubmitData={setSubmitData}
              submitData={submitData}
            />
          </Row>
        )}

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Payment Instructions</Form.Label>
              <Form.Control
                as='textarea'
                className='payment-instruction'
                value={paymentInstruction?.attributes?.description}
                readOnly
                rows={5}
              />
            </Form.Group>
          </Col>
        </Row>

        <CalendarUI>
          <h5>VIEW AVAILABLE DATES</h5>
          <Calendar
            calendarType='US'
            onChange={onChange}
            value={value}
            prevLabel={CaretLeft()}
            nextLabel={CaretRight()}
            minDate={new Date()}
            onClickDay={(value) => {
              setSelectedDate(convertDate(value));
            }}
            // eslint-disable-next-line multiline-comment-style
            // tileDisabled={({ date }) =>
            //   notAvailable.includes(convertDate(date))
            // }
            tileDisabled={tileDisabled}
            tileContent={(value) => {
              return (
                <div
                  className='not-available'
                  style={{
                    display: isWithinRanges(value.date, disabledDates)
                      ? 'flex'
                      : 'none',
                  }}
                >
                  <RedCircle />
                </div>
              );
            }}
          />
        </CalendarUI>
        <Row>
          <Col>
            <div className='legendContainer'>
              <span>Legend: &nbsp;</span>
              <div>
                <RedCircle />
                <span>Date Not Available</span>
              </div>
            </div>
          </Col>
        </Row>
        <Button variant='submit'>View Rules and Regulations</Button>
        <Row>
          <Col className='message-text-result'>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <div
              className='formUI__footer'
              style={{ flexDirection: 'row' }}
            >
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button variant='cancel'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </Form>
  );
};

export default AmenitiesForm;
