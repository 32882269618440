import React from 'react';
import './Capex.scss';
import { useNavigate } from 'react-router-dom';
import { Row, Form, Button } from 'react-bootstrap';
import CapexTable from './CapexTable';
function Capex() {
  const navigate = useNavigate();

  return (
    <div className='Capex'>
      <Row>
        <div className='col-6'>
          <Row>
            <div className='col-6'>
              <Form.Group
                controlId='formSearch'
                className='mb-5 formSearch'
              >
                <Form.Control
                  type='text'
                  placeholder='Search'
                  className='search'
                />

                <Button className='searchbtn'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25.362'
                    height='25.584'
                    viewBox='0 0 25.362 25.584'
                  >
                    <path
                      id='search'
                      d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                      transform='translate(-336.364 -87.386)'
                      fill='#c7c7c7'
                    />
                  </svg>
                </Button>
              </Form.Group>
            </div>
            <div className='col-2'>
              <Form.Group
                className='mb-5'
                controlId='formTitle'
              >
                <Form.Select aria-label='Select Title'>
                  <option value='1'>2021</option>
                  <option value='1'>2022</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col'>
              <button
                className='btn  upload-btn'
                onClick={() => {
                  navigate('/finance/finance-cms/UploadCapex');
                }}
              >
                Upload
              </button>
            </div>
          </Row>
        </div>
      </Row>
      <h4 className='calendarYear'>
        Budget for the Calendar Year Ending December 2022
      </h4>
      <CapexTable />
      <nav className='d-flex justify-content-between pagination'>
        <ul className='pagination'>
          <ul className='page-link mx-1'>1</ul>
          <ul className='page-link mx-1'>2</ul>
        </ul>
        <p>Showing 7 out of 50 entries</p>
      </nav>
    </div>
  );
}

export default Capex;
