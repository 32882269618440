const Request = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='24'
      viewBox='0 0 18 24'
    >
      <path
        id='file-upload'
        d='M10.5,6.375V0H1.125A1.122,1.122,0,0,0,0,1.125v21.75A1.122,1.122,0,0,0,1.125,24h15.75A1.122,1.122,0,0,0,18,22.875V7.5H11.625A1.128,1.128,0,0,1,10.5,6.375ZM13.555,16.5H10.5v3.75a.75.75,0,0,1-.75.75H8.25a.75.75,0,0,1-.75-.75V16.5H4.445a.75.75,0,0,1-.528-1.282l4.52-4.486a.8.8,0,0,1,1.127,0l4.52,4.486A.75.75,0,0,1,13.555,16.5ZM17.672,4.922,13.083.328a1.124,1.124,0,0,0-.8-.328H12V6h6V5.714A1.121,1.121,0,0,0,17.672,4.922Z'
        fill='#fff'
      />
    </svg>
  );
};

export default Request;
