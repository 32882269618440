// import core & vendor packages
import { Chart } from 'react-google-charts';

// import assets below
import './style.scss';

// main component
const OccupancyGraph = (props) => {
  const { selected } = props;

  const optionsOccupancyRate = {
    chartArea: { width: '90%', top: 40, bottom: 180 },
    legend: { position: 'bottom', maxLines: 1 },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: '#' + '%',
      minValue: 0,
      ticks: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    },
  };

  const dataOccupancyRate = [
    [
      'Year',
      'Residential',
      { type: 'string', role: 'tooltip', p: { html: true } },
      'Parking',
      { type: 'string', role: 'tooltip', p: { html: true } },
    ],
    ['Oct-22', 1, 'Residential: 100', 0.5, 'Parking: 50'],
    ['Nov-22', 1, 'Residential: 100', 0.5, 'Parking: 50'],
    ['Dec-22', 1, 'Residential: 100', 0.5, 'Parking: 50'],
  ];

  const optionsOccupancyUnits = {
    chartArea: { width: '90%', top: 40, bottom: 180 },
    legend: { position: 'bottom' },
    colors: ['#0094BD', '#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      minValue: 0,
    },
    isStacked: true,
  };

  const dataOccupancyUnits = [
    [
      'Title',
      'Units Occupied by Tenants',
      { type: 'string', role: 'tooltip', p: { html: true } },
      'Units Occupied by Owners',
      { type: 'string', role: 'tooltip', p: { html: true } },
      'Vacant Units',
      { type: 'string', role: 'tooltip', p: { html: true } },
    ],
    [
      'Residential (Oct-22)',
      56,
      'Units Occupied by Tenants: 56',
      24,
      'Units Occupied by Owners: 24',
      30,
      'Vacant Units: 30',
    ],
    [
      'Parking (Oct-22)',
      56,
      'Units Occupied by Tenants: 56',
      24,
      'Units Occupied by Owners: 24',
      30,
      'Vacant Units: 30',
    ],
  ];

  return (
    <div className='occupancyGraph'>
      <div className='occupancyGraph__header'>
        <span>{selected}</span>
      </div>
      {selected === 'Occupancy Rate' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsOccupancyRate}
          data={dataOccupancyRate}
        />
      )}
      {selected === 'Occupancy Based on Units' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsOccupancyUnits}
          data={dataOccupancyUnits}
        />
      )}
    </div>
  );
};

export default OccupancyGraph;
