// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';

// Components

// CSS/SCSS
import './BudgetTable.scss';

// Main Component
const BudgetTable = () => {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Sub-Category',
        accessor: 'subCategory',
      },
      {
        Header: 'Anual Budget',
        accessor: 'anualBudget',
      },
      {
        Header: 'Jan-22',
        accessor: 'janMonth',
      },
      {
        Header: 'Feb-22',
        accessor: 'febMonth',
      },
      {
        Header: 'Mar-22',
        accessor: 'marchMonth',
      },
      {
        Header: 'April-22',
        accessor: 'aprilMonth',
      },
      {
        Header: 'May-22',
        accessor: 'mayMonth',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        type: 'CapEx',
        category: 'Machinery & Equipment',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 2,
        type: 'CapEx',
        category: 'Machinery & Equipment',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 3,
        type: 'OpEx',
        category: 'Taxes and Licenses',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 4,
        type: 'OpEx',
        category: 'Taxes and Licenses',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 5,
        type: 'OpEx',
        category: 'Professional Fees',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 6,
        type: 'OpEx',
        category: 'Taxes and Licenses',
        subCategory: 'Aircon Unit - Function Room',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
      {
        id: 7,
        type: '',
        category: '',
        subCategory: 'Total',
        anualBudget: '12,500.00',
        janMonth: '12,500.00',
        febMonth: '12,500.00',
        marchMonth: '12,500.00',
        aprilMonth: '12,500.00',
        mayMonth: '12,500.00',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        type: data.type,
        category: data.category,
        subCategory: data.subCategory,
        anualBudget: data.anualBudget,
        janMonth: data.janMonth,
        febMonth: data.febMonth,
        marchMonth: data.marchMonth,
        aprilMonth: data.aprilMonth,
        mayMonth: data.mayMonth,
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetTable;
