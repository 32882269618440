/* eslint-disable no-unused-vars */
// Packages
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './UnitListTable.scss';

// Main Component
const UnitListTable = ({ unitList, selectedBuilding, setUpdateMsg, setUnitID, setDeleteModal }) => {
  // To return view/delete action
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            as={Link}
            to={`/administration/building-list/edit-unit/${id}`}
          >
            View/Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: '#CB1212' }}
            onClick={() => {
              setUnitID(id);
              setDeleteModal(true);
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Unit Area',
        accessor: 'unitArea',
      },
      {
        Header: 'Classification',
        accessor: 'classification',
      },
      {
        Header: 'Building Floor',
        accessor: 'buildingFloor',
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Tenant',
        accessor: 'tenant',
      },
      {
        Header: 'Parking Slot(s)',
        accessor: 'parkingSlots',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    if (unitList.length > 0) {
      const dataFromAPI = unitList.map((list) => {
        return {
          buildingId: selectedBuilding,
          id: list.id,
          unitNo: list.attributes.unit_number ? list.attributes.unit_number : '',
          unitArea: list.attributes.unit_area ? list.attributes.unit_area : '',
          classification: list.relationships.classification ? list.relationships.classification.attributes.name : '',
          buildingFloor: list.attributes.building_floor,
          owner: list.relationships.owners.length,
          tenant: list.relationships.tenants.length,
          parkingSlots: list.relationships.parking_slots.length,
        };
      });
  
      return dataFromAPI.map((data) => {
        return {
          unitNo: data.unitNo,
          unitArea: data.unitArea,
          classification: data.classification,
          buildingFloor: data.buildingFloor,
          owner: data.owner,
          tenant: data.tenant,
          parkingSlots: data.parkingSlots,
          action: renderAction(data.id),
        };
      });
    }
  }, [unitList]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <table
      {...getTableProps()}
      className='admin-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps()}>
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td {...cell.getCellProps()}>
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default UnitListTable;
