// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import SuppliersDirectoryHeader from '../Filter';
import SuppliersDirectoryTable from '../Table';
import SuppliersDirectoryPagination from '../Pagination';

// main component
const SuppliersDirectoryTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='dashboardSuppliersDirectory'
        className='mb-3'
      >
        <Tab
          eventKey='dashboardSuppliersDirectory'
          title='Supplier&#39;s Directory'
        >
          <SuppliersDirectoryHeader />
          <SuppliersDirectoryTable />
          <SuppliersDirectoryPagination />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default SuppliersDirectoryTabs;
