// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
// import EquipmentInventoryContent from './EquipmentInventoryPageContent/EquipmentInventoryContent';
import EditDetailsForm from './EditDetailsForm';

// API

// CSS
import './EditDetails.scss';

const EditDetails = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Engineering CMS - Edit Details</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Edit Details'
                className='mb-3 tab-form'
              >
                <Tab
                  eventKey='Edit Details'
                  title='Edit Details'
                >
                  <EditDetailsForm />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EditDetails;
