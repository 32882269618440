import React, { useState } from 'react';
import Publish from './Publish/Publish';
import ReUpload from './ReUpload/ReUpload';
import Review from './Review/Review';

// tabs
import Upload from './Upload/Upload';
// css
import './UploadStatementAccount.scss';
function UploadStatementAccount() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className='uploadStatementAccount'>
      <div className='bloc-tabs'>
        <button
          className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(1)}
        >
          Upload
        </button>
        <button
          className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(2)}
        >
          Review
        </button>
        <button
          className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(3)}
        >
          Re-upload
        </button>
        <button
          className={toggleState === 4 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(4)}
        >
          Publish
        </button>
      </div>

      <div className='content-tabs'>
        <div
          className={toggleState === 1 ? 'content  active-content' : 'content'}
        >
          <Upload />
        </div>

        <div
          className={toggleState === 2 ? 'content  active-content' : 'content'}
        >
          <Review />
        </div>

        <div
          className={toggleState === 3 ? 'content  active-content' : 'content'}
        >
          <ReUpload />
        </div>
        <div
          className={toggleState === 4 ? 'content  active-content' : 'content'}
        >
          <Publish />
        </div>
      </div>
    </div>
  );
}

export default UploadStatementAccount;
