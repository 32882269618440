// import assets below
import './style.scss';

// main component
const ModalUI = (props) => {
  const { children } = props;

  return <div className='modalUI'>{children}</div>;
};

export default ModalUI;
