// Packages
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// Components
import BuildingListTable from './BuildingListTable';
import TablePagination from 'components/Pagination';

// CSS/SCSS
import './BuildingListContent.scss';

// API
import { getBuildingLists } from 'api/request';

// Main Component
const BuildingListContent = ({ refreshList, setRefreshList }) => {
  // States
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableList, setTableList] = useState();
  const [search, setSearch] = useState();
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);

  const selectPage = (page) => {
    const param = `?filter[search]=${search}${page}`;
    setLoading(true);
    getBuildingLists(param, callback);
    setTableList([]);
    setRefreshList(false);
  };

  useEffect(() => {
    setLoading(true);
    getBuildingLists('', callback);
    setTableList([]);
    setRefreshList(false);
  }, []);

  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getBuildingLists('', callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableList(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setMsg('Something went wrong.');
      }
    }
  };

  const searchBuilding = () => {
    const param = `?filter[search]=${search}`;

    setLoading(true);

    getBuildingLists(param, callback);

    setTableList([]);

    setRefreshList(false);
  };

  // To render on screen
  return (
    <>
      <Form
        className='formBuiling'
        onSubmit={(e) => {
          e.preventDefault();
          searchBuilding();
        }}
      >
        <Row>
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
                value={search}
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
              />

              <Button
                className='searchbtn'
                onClick={() => searchBuilding()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div
              className='edit-container'
              style={{ textAlign: 'left' }}
            >
              <DropdownButton
                id='filter'
                title='Filter'
              >
                <Dropdown.Item href='#/action-1'>Building Name</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>No. of Units</Dropdown.Item>
                <Dropdown.Item href='#/action-3'>
                  No. of Parking Slots
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        {loading && <p>Loading...</p>}
        {!loading && msg && <p>{msg}</p>}
        {!loading && (
          <>
            {tableList && tableList.length === 0 && <p>Table is empty</p>}
            {tableList && tableList.length > 0 && (
              <BuildingListTable tableList={tableList} />
            )}
          </>
        )}
      </Card>

      {loading && <p>Loading...</p>}
      {!loading && msg && <p>{msg}</p>}
      {!loading && (
        <>
          {tableList && tableList.length === 0 && <p>Table is empty</p>}
          {tableList && tableList.length > 0 && (
            <TablePagination
              links={links}
              meta={meta}
              selectPage={selectPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default BuildingListContent;
