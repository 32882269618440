const Clipboard = () => {
  return (
    <svg viewBox='0 0 17.349 23.132'>
      <path
        id='clipboard'
        d='M17.349,5.06v15.9a2.169,2.169,0,0,1-2.169,2.169H2.169A2.169,2.169,0,0,1,0,20.964V5.06A2.169,2.169,0,0,1,2.169,2.892H5.783a2.892,2.892,0,1,1,5.783,0h3.614A2.169,2.169,0,0,1,17.349,5.06ZM8.675,1.807A1.084,1.084,0,1,0,9.759,2.892,1.084,1.084,0,0,0,8.675,1.807m4.337,5.151v-.9a.271.271,0,0,0-.271-.271H4.608a.271.271,0,0,0-.271.271v.9a.271.271,0,0,0,.271.271h8.132A.271.271,0,0,0,13.012,6.958Z'
      />
    </svg>
  );
};

export default Clipboard;
