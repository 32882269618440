/* eslint-disable camelcase */
// packages
import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';

// CSS
import './ParkingSlotsTable.scss';

// Components
import TagsInput from './TagsInput.tsx';

// API
import { updateBuildingUnitParkingSlot, deleteBuildingUnitParkingSlot } from 'api/request';

// CSS

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  column: { Header }, // This is a custom function that we supplied to our table instance
  setUpdateMsg,
  deleteType,
  refreshList,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChangeMultiSelect = (e) => {
    setUpdateMsg({ message: '', status: '' });
    setValue(e);
    updateMyData(index, id, e);
  };

  const onDeleteType = (e) => {
    setUpdateMsg({ message: '', status: '' });
    deleteType(e.type, e.unitID, e.id);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (id === 'parkingSlots') {
    return (
      <TagsInput
        value={value}
        setValue={setValue}
        onChangeMultiSelect={onChangeMultiSelect}
        setUpdateMsg={setUpdateMsg}
        onDeleteType={onDeleteType}
        refreshList={refreshList}
      />
    );
  }

  return (
    <input
      value={value}
      placeholder={`Enter ${Header} Here`}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={id === 'unitNo'}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset, setUpdateMsg, deleteType, refreshList }) {
  // For this example, we're using pagination to illustrate how to stop

  // the current page from resetting when our data changes

  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but

      // anything we put into these options will

      // automatically be available on the instance.

      // That way we can call this function from our

      // cell renderer!
      updateMyData,
      setUpdateMsg,
      deleteType,
      refreshList,
    },
    usePagination,
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, idx) => (
                <th
                  key={idx}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    <td
                      key={idx}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className='pagination'>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
    </>
  );
}

// Main Component
function ParkingSlotsTable({ unitList, setUpdateMsg, refreshList }) {
  // Static columns used in table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Parking Slots',
        accessor: 'parkingSlots',
      },
    ],
    [],
  );

  // Data used in table
  const [data, setData] = React.useState([]);
  // const [originalData] = React.useState(data);

  useEffect(() => {
    setData(() => {
      return unitList.map((list) => {
        const parkingSlots = list.relationships.parking_slots.map((data) => {
          if (!data) return [];

          return {
            id: list.id,
            type: 'parkingSlots',
            unitID: data.id,
            label: data.attributes.slot_number,
            value: data.attributes.slot_number,
          };
        });

        return {
          id: list.id,
          unitNo: list.attributes.unit_number,
          parkingSlots: parkingSlots,
        };
      });
    });
  }, [unitList]);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  /* We need to keep the table from resetting the pageIndex when we
     Update data. So we can keep track of that flag with a ref. */

  // When our cell renderer calls updateMyData, we'll use

  // the rowIndex, columnId and new value to update the

  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(columnId);
          if (columnId === 'parkingSlots') {
            submitUpdateParkingSlot({
              ...old[rowIndex],
              [columnId]: value,
            });
          }

          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        
        return row;
      }),
    );
  };

  const submitUpdateParkingSlot = (list) => {
    setUpdateMsg({ message: '', status: '' });
    const { parkingSlots } = list;

    const { label } = parkingSlots[parkingSlots.length - 1];
    const params = {
      slot_number: label,
    };

    if (params.slot_number.length === 0) return;
    updateBuildingUnitParkingSlot(list.id, params, callback);
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUpdateMsg({ message: 'Successfully Added', status: 'success' });
      }

      if (status === 401 || status === 422) {
        if (!data.message === 'The name has already been taken.') {
          setUpdateMsg({ message: 'Something went wrong.', status: 'failed' });
        }
      }

      refreshList();
    }
  };

  const deleteType = (type, unitID, id) => {
    setUpdateMsg({ message: '', status: '' });

    if (type === 'parkingSlots') {
      deleteBuildingUnitParkingSlot(unitID, id, deleteCallback);
    }
  };

  const deleteCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 204) {
        setUpdateMsg({ message: 'Successfully Deleted', status: 'success' });
      }

      if (status === 401 || status === 422) {
        if (!data.message === 'The name has already been taken.') {
          setUpdateMsg({ message: 'Something went wrong.', status: 'failed' });
        }
      }

      refreshList();
    }
  };

  // After data chagnes, we turn the flag back off

  // so that if data actually changes when we're not

  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help

  // illustrate that flow...

  // const resetData = () => setData(originalData);

  return (
    <>
      {/* <button onClick={resetData}>Reset Data</button> */}
      <div className='unit-table'>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          deleteType={deleteType}
          skipPageReset={skipPageReset}
          setUpdateMsg={setUpdateMsg}
          refreshList={refreshList}
        />
      </div>
    </>
  );
}

export default ParkingSlotsTable;
