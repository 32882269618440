// Packages
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main/';
import EmergencyDirectoryForm from './EmergencyDirectoryForm/EmergencyDirectoryForm';
import EmergencyDirectoryContent from './EmergencyDirectoryList/EmergencyDirectoryContent';

// API

// CSS
import './EmergencyDirectory.scss';

// Main Component
const EmergencyDirectory = () => {
  // handle refresh loading
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const handleSelect = (e) => {
    if (e === 'Emergency Directory List') {
      setRefreshList(true);
    }
    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('Emergency Directory Form');
  }, []);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Emergency Directory</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Emergency Directory Form'
                id='house-rules-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='Emergency Directory Form'
                  title='Emergency Directory Form'
                >
                  <EmergencyDirectoryForm />
                </Tab>
                <Tab
                  eventKey='Emergency Directory List'
                  title='Emergency Directory List'
                >
                  <EmergencyDirectoryContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EmergencyDirectory;
