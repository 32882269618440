/* eslint-disable no-unused-vars */
import { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ApplicationForm from './ApplicationForm/ApplicationForm';
import RequestList from './RequestList/RequestList';
import TabsUI from 'components/UI/TabsUI';

import userContext from 'components/userContext/userContext';

import {
  getAmenitiesList,
  getBuildingLists,
  userAccounts,
  submitRequestAmenities,
  getRequestAmenities,
  moveinMoveout,
  getmoveinmoveoutList,
  getparkingSlotNo,
  carStickerSubmitForm,
  getCarStickerList,
} from 'api/request';
import './CarSticker.scss';
import TablePagination from 'components/Pagination';

function CarSticker() {
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [parkingSlots, setParkingSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();
  // eslint-disable-next-line no-unused-vars
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const refreshTable = () => {
    if (role === 'owner') {
      const params = `/api/v1/request/car-stickers?building_id=${userUnit?.buildingId}&include=building,unit,user,parking_slot,requestedBy&unit_id=${userUnit?.id}`;
      getCarStickerList(params, requestAmenitiesCallback);
    } else {
      setLoading(true);
      const params = `/api/v1/request/car-stickers?building_id=${selectedBuilding}&include=building,unit,user,parking_slot,requestedBy`;

      getCarStickerList(params, requestAmenitiesCallback);
    }
  };
  const handleSelect = (e) => {
    if (e === 'Car Sticker Request List') {
      setMsg({ message: '', status: '' });
      setRefreshList(true);
    }
    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('Car Sticker Application Form');
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
  }, []);

  useEffect(() => {
    setParkingSlots([]);

    if (selectedName !== '') {
      getparkingSlotNo(selectedName, selectedUnit, parkingCallback);
    }
  }, [selectedName]);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      let params;

      if (user.attributes.role === 'owner') {
        params = `?filter[search]=&filter[unit]=${selectedUnit}&filter[role]=tenant`;
      } else {
        params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      }

      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      carStickerSubmitForm(submit, submitCallback);
    }
  }, [submit]);

  // selected building
  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `/api/v1/request/car-stickers?building_id=${userUnit?.buildingId}&include=building,unit,user,parking_slot,requestedBy&unit_id=${userUnit?.id}`;

        getCarStickerList(params, requestAmenitiesCallback);
      } else {
        const params = `/api/v1/request/car-stickers?building_id=${selectedBuilding}&include=building,unit,user,parking_slot,requestedBy`;

        getCarStickerList(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `/api/v1/request/car-stickers?building_id=${userUnit?.buildingId}&include=building,unit,user,parking_slot,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}`;

        getCarStickerList(params, requestAmenitiesCallback);
      } else {
        const params = `/api/v1/request/car-stickers?building_id=${selectedBuilding}&include=building,unit,user,parking_slot,requestedBy&filter[search]=${search}`;

        getCarStickerList(params, requestAmenitiesCallback);
      }
    }
  }, [search]);

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `/api/v1/request/car-stickers?building_id=${userUnit?.buildingId}&include=building,unit,user,parking_slot,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}${page}`;

      getCarStickerList(params, requestAmenitiesCallback);
    } else {
      const params = `/api/v1/request/car-stickers?building_id=${selectedBuilding}&include=building,unit,user,parking_slot,requestedBy&filter[search]=${search}${page}`;

      getCarStickerList(params, requestAmenitiesCallback);
    }
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);

        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const parkingCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setParkingSlots(
          data.data[0]?.relationships?.unit?.relationships.parking_slots,
        );
        setMsg({ message: data.message, status: 'failed' });

        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Request - Car Sticker</title>
      </Helmet>
      <Main title='Request - Car Sticker'>
        <Container fluid>
          <Row>
            <Col>
              <TabsUI>
                <Tabs
                  defaultActiveKey='Car Sticker Application Form'
                  id='Car Sticker Application Form'
                  className='mb-3 finance-nav'
                  onSelect={(e) => handleSelect(e)}
                  activeKey={activeKey}
                >
                  <Tab
                    eventKey='Car Sticker Application Form'
                    title='Car Sticker Application Form'
                  >
                    <ApplicationForm
                      setSelectedUnit={setSelectedUnit}
                      setSelectedName={setSelectedName}
                      buildings={buildings}
                      names={names}
                      setNames={setNames}
                      setSubmit={setSubmit}
                      msg={msg}
                      user={user}
                      userUnit={userUnit}
                      setMsg={setMsg}
                      parkingSlots={parkingSlots}
                    />
                  </Tab>
                  <Tab
                    eventKey='Car Sticker Request List'
                    title='Car Sticker Request List'
                  >
                    <RequestList
                      selectedBuilding={selectedBuilding}
                      user={user}
                      buildings={buildings}
                      setSelectedBuilding={setSelectedBuilding}
                      tableData={tableData}
                      setSearch={setSearch}
                      refreshList={refreshList}
                      setRefreshList={setRefreshList}
                      setActiveKey={setActiveKey}
                      links={links}
                      meta={meta}
                      selectPage={selectPage}
                    />
                  </Tab>
                </Tabs>
              </TabsUI>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default CarSticker;
