// import core & vendor packages below
import { Fragment, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// import components below
import OccupancyReportsFilter from './Filter';
import TableDetails from './TableDetails';
import TableReports from './TableReports';
import Graph from './Graph';

// import assets below
import './style.scss';

// main component
const OccupancyReports = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Occupancy Rate');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    console.log(params);
    return params;
  };

  return (
    <Fragment>
      <OccupancyReportsFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && (
        <div className='occupancyReports'>
          <Row>
            <Col lg='4'>
              <TableDetails />
            </Col>
            <Col lg='8'>
              <TableReports />
            </Col>
          </Row>
        </div>
      )}
      {toggle && <Graph selected={selectedFilter} />}
    </Fragment>
  );
};

export default OccupancyReports;
