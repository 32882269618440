/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { getlistHouseRules } from '../../../../api/request';

// CSS/SCSS
import './HouseRulesContent.scss';

// Main Component
const HouseRulesContent = ({ refreshList, setRefreshList }) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [title, setTitle] = useState([]);
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [msg, setMsg] = useState('');

  const titles = title.map((data) => {
    return {
      id: data.attributes.id,
      title: data.attributes.title,
      content: data.attributes.content,
    };
  });
  // fire refresh status
  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getlistHouseRules(callback);
      setRefreshList(false);
    }
  }, [refreshList]);
  useEffect(() => {
    const test = titles.map((val) => {
      if (category === val.title) {
        setContent(val.content);
      }
      return;
    });
  }, [category]);
  // callback on list data
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setContent(data.data[0].attributes.content);
        setTitle(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formTitle'
            >
              <Form.Select
                aria-label='Select Title'
                onChange={(e) => {
                  console.log(e.target.value);
                  return setCategory(e.target.value);
                }}
              >
                {titles.map((val) => {
                  return (
                    <option
                      value={val.value}
                      key={val.id}
                    >
                      {val.title}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={5}>
            <div className='edit-container'>
              <Button
                className='edit'
                variant='primary'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32.631'
                  height='29'
                  viewBox='0 0 32.631 29'
                >
                  <path
                    id='edit'
                    d='M22.808,4.808l5.11,5.11a.554.554,0,0,1,0,.782L15.545,23.072l-5.257.584A1.1,1.1,0,0,1,9.07,22.438l.584-5.257L22.026,4.808A.554.554,0,0,1,22.808,4.808Zm9.178-1.3L29.221.746a2.216,2.216,0,0,0-3.127,0L24.088,2.751a.554.554,0,0,0,0,.782l5.11,5.11a.554.554,0,0,0,.782,0l2.005-2.005A2.216,2.216,0,0,0,31.986,3.51Zm-10.231,16.2v5.767H3.626V7.346H16.644a.7.7,0,0,0,.482-.2l2.266-2.266A.68.68,0,0,0,18.91,3.72H2.719A2.72,2.72,0,0,0,0,6.439V26.381A2.72,2.72,0,0,0,2.719,29.1H22.661a2.72,2.72,0,0,0,2.719-2.719v-8.94a.681.681,0,0,0-1.161-.482l-2.266,2.266A.7.7,0,0,0,21.754,19.707Z'
                    transform='translate(0 -0.1)'
                    fill='#fff'
                  />
                </svg>
                Edit
              </Button>
            </div>
          </Col>
        </Row>

        <Form.Group
          className='mb-5'
          controlId='formContent'
        >
          <Form.Label>1. GENERAL RULES AND REGULATIONS </Form.Label>
          <Form.Control
            className='form-content'
            as='textarea'
            rows={30}
            defaultValue={content}
            value={content}
          />
        </Form.Group>
      </Form>
    </Card>
  );
};

export default HouseRulesContent;
