// Packages
import React, { useEffect, useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';

// CSS
import './AssignedServicesGroup.scss';

// Components

// API

// CSS

// Main Component
const AssignedServicesGroup = () => {
  // States
  const [services, setServices] = useState([
    { id: 1, value: '' },
    { id: 2, value: '' },
    { id: 3, value: '' },
    { id: 4, value: '' },
    { id: 5, value: '' },
  ]);

  // Will render one time
  useEffect(() => {
    if (services) {
      console.log('Rendering...');
    }
  }, []);

  // To change value on any textbox
  const changeServiceValue = (e, id) => {
    const newValue = services.map((services) => {
      if (id === services.id) {
        return { id: services.id, value: e };
      }

      return { id: services.id, value: services.value };
    });

    setServices(newValue);
  };

  // To add Violation textbox
  const addViolation = () => {
    const newID = services.length + 1;

    const newViolation = { id: newID, value: '' };

    setServices((prevState) => {
      return [...prevState, newViolation];
    });
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Form.Group
          className='mb-3'
          controlId='formViolations'
        >
          <Form.Label>Services</Form.Label>
        </Form.Group>

        {services &&
          services.length > 0 &&
          services.map((services) => {
            return (
              <Form.Group
                className='mb-3'
                controlId={services.id}
                key={services.id}
              >
                <Form.Control
                  aria-label='Service'
                  placeholder='Enter Service Name Here'
                  type='text'
                  value={services.value}
                  onChange={(e) =>
                    changeServiceValue(e.target.value, services.id)
                  }
                />
              </Form.Group>
            );
          })}

        <Form.Group
          className='buttons add mt-5'
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            className='submit'
            variant='primary'
            onClick={addViolation}
          >
            Add
          </Button>
        </Form.Group>

        <Form.Group className='buttons pt-5'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default AssignedServicesGroup;
