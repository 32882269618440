// import core and vendor packages below
import { Form } from 'react-bootstrap';

// import components below
import FilterUI from '../../../../components/UI/FilterUI';

// import assets below
import './style.scss';

// main component
const FinanceReportsFilter = (props) => {
  const { report, onChangeReport } = props;

  return (
    <div className='financeReportsFilter'>
      <FilterUI>
        <Form.Select onChange={(e) => onChangeReport(e.target.value)}>
          <option selected={report === 'Summary Reports'}>
            Summary Reports
          </option>
          <option selected={report === 'Comparative Reports'}>
            Comparative Reports
          </option>
        </Form.Select>
      </FilterUI>
    </div>
  );
};

export default FinanceReportsFilter;
