// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import PaymentHistory from '../pages/Billing/PaymentHistory/PaymentHistory';
import Billing from 'pages/MyTenant/Billing';
import ViewPaymentHistory from 'pages/MyTenant/Billing/ViewPaymentHistory';
import Request from 'pages/MyTenant/Request';
import ViewTenantRequest from 'pages/MyTenant/Request/ViewTenantRequest';

const MyTenantRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/my-tenant/billing'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Billing />
          </ProtectedRoute>
        }
      />

      <Route
        path='/billing/payment-history'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <PaymentHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/billing/payment-history/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewPaymentHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/my-tenant/request'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Request />
          </ProtectedRoute>
        }
      />
      <Route
        path='/my-tenant/request/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewTenantRequest />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default MyTenantRouter;
