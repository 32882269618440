// Packages
import { Link } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';

// import assets below
import Building from '../../SVG/Building';
import Passport from '../../SVG/Passport';
import FileSignature from '../../SVG/FileSignature';
import AssistiveListening from '../../SVG/AssistiveListening';
import ExchangeAlt from '../../SVG/ExchangeAlt';
import Scroll from '../../SVG/Scroll';
import Car from '../../SVG/Car';
import Badge from '../../SVG/Badge';
import Dog from '../../SVG/Dog';

// main component
const Administration = (props) => {
  const { activeKey, pathname, role } = props;

  // routes under amenities module
  const amenitiesRoutes = [
    '/administration/requests/amenities',
    '/administration/requests/amenities/view-request',
    '/administration/requests/amenities/deny-request',
  ];

  // routes under concerns module
  const concernsRoutes = [
    '/administration/requests/concerns',
    '/administration/requests/concerns/view-request',
    '/administration/requests/concerns/approve-request',
    '/administration/requests/concerns/add-remarks',
  ];

  // routes under move in/out module
  const moveInOutRoutes = [
    '/administration/requests/move-in-out',
    '/administration/requests/move-in-out/view-request',
    '/administration/requests/move-in-out/deny-request',
  ];

  // routes under gate pass module
  const gatePassRoutes = [
    '/administration/requests/gate-pass',
    '/administration/requests/gate-pass/view-request',
    '/administration/requests/gate-pass/deny-request',
  ];

  // routes under work permit module
  const workPermitRoutes = [
    '/administration/requests/work-permit',
    '/administration/requests/work-permit/view-request',
    '/administration/requests/work-permit/deny-request',
  ];

  return (
    <Accordion defaultActiveKey={activeKey}>
      <Accordion.Item eventKey='requests'>
        <Accordion.Header>
          <div className='svg__wrapper'>{FileSignature()}</div>
          <Nav.Link
            className='nav-link'
            href='#'
          >
            Requests
          </Nav.Link>
        </Accordion.Header>
        <Accordion.Body>
          <Nav.Item
            className={`${amenitiesRoutes.includes(pathname) && 'active'}`}
          >
            <div className='svg__wrapper'>{Building()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/amenities?activeKey=requests'
            >
              Amenities
            </Link>
          </Nav.Item>
          <Nav.Item
            className={`${concernsRoutes.includes(pathname) && 'active'}`}
          >
            <div className='svg__wrapper'>{AssistiveListening()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/concerns?activeKey=requests'
            >
              Concerns
            </Link>
          </Nav.Item>
          {role && role === 'owner' && (
            <Nav.Item
              className={`${moveInOutRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{ExchangeAlt()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/move-in-out?activeKey=requests'
              >
                Move In/Out
              </Link>
            </Nav.Item>
          )}
          <Nav.Item
            className={`${gatePassRoutes.includes(pathname) && 'active'}`}
          >
            <div className='svg__wrapper'>{Passport()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/gate-pass?activeKey=requests'
            >
              Gate Pass
            </Link>
          </Nav.Item>
          <Nav.Item
            className={`${workPermitRoutes.includes(pathname) && 'active'}`}
          >
            <div className='svg__wrapper'>{Scroll()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/work-permit?activeKey=requests'
            >
              Work Permit
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/administration/requests/car-sticker' && 'active'
            }
          >
            <div className='svg__wrapper'>{Car()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/car-sticker?activeKey=requests'
            >
              Car Sticker
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/administration/requests/staff-id' && 'active'
            }
          >
            <div className='svg__wrapper'>{Badge()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/staff-id?activeKey=requests'
            >
              Staff ID
            </Link>
          </Nav.Item>
          <Nav.Item
            className={pathname === '/administration/requests/pets' && 'active'}
          >
            <div className='svg__wrapper'>{Dog()}</div>
            <Link
              className='nav-link'
              to='/administration/requests/pets?activeKey=requests'
            >
              Pets
            </Link>
          </Nav.Item>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Administration;
