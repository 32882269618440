// import core & vendor packages below
import { Form, FloatingLabel } from 'react-bootstrap';

// main component
const FormForgotPassword = ({ params, setParams, register, errors }) => {
  return (
    <div className='form form--login'>
      <div className='form__body'>
        <FloatingLabel
          className={`mb-3 ${errors.email ? 'error-label' : ''}`}
          controlId='floatingEmail'
          label={`Email Address ${
            errors.email ? `(${errors.email.message})` : ''
          }`}
        >
          <Form.Control
            {...register('email', {
              required: 'Email Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              value: params.email,
              onChange: (e) => {
                setParams((prevState) => {
                  return { ...prevState, email: e.target.value };
                });
              },
            })}
            type='email'
            placeholder='name@example.com'
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

export default FormForgotPassword;
