/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import AccountForm from './AccountForm/AccountForm';
import AccountsList from './AccountsList/AccountsList';
import RemoveAccess from './RemoveAccess/RemoveAccess';

import './AccountManagementPage.scss';
import { deleteAccountManagement, getAccountList } from 'api/request';

const AccountManagementPage = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const refreshTable = () => {
    if (filter === 'all') {
      setLoading(true);

      const params = `/api/v1/accounts?&filter[role]=&include=roles,owner,units,tenant.unit.tenants`;
      getAccountList(params, requestAmenitiesCallback);
    } else {
      setLoading(true);

      const params = `/api/v1/accounts?&filter[role]=${filter}&include=roles,owner,units,tenant.unit.tenants`;
      getAccountList(params, requestAmenitiesCallback);
    }
  };
  useEffect(() => {
    refreshTable();
  }, []);
  useEffect(() => {
    if (filter === 'all') {
      const identifier = setTimeout(() => {
        const params = `/api/v1/accounts?filter[search]=${search}&filter[role]=&include=roles,owner,units,tenant.unit.tenants`;
        getAccountList(params, requestAmenitiesCallback);
      }, 500);
      return () => {
        clearTimeout(identifier);
      };
    } else {
      const identifier = setTimeout(() => {
        const params = `/api/v1/accounts?filter[search]=${search}&filter[role]=${filter}&include=roles,owner,units,tenant.unit.tenants`;
        getAccountList(params, requestAmenitiesCallback);
      }, 500);
      return () => {
        clearTimeout(identifier);
      };
    }
  }, [search]);
  useEffect(() => {
    if (filter === 'all') {
      const params = `/api/v1/accounts?&filter[role]=&include=roles,owner,units,tenant.unit.tenants`;
      getAccountList(params, requestAmenitiesCallback);
    } else {
      const params = `/api/v1/accounts?&filter[role]=${filter}&include=roles,owner,units,tenant.unit.tenants`;
      getAccountList(params, requestAmenitiesCallback);
    }
  }, [filter]);
  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);

        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }
      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'error' });
      }
    }
  };
  const selectPage = (page) => {
    const params = `/api/v1/accounts?filter[role]=${filter}}&include=roles,owner,units,tenant.unit.tenants${page}`;
    getAccountList(params, requestAmenitiesCallback);
  };

  const deleteCallback = () => {
    setLoading(false);

    refreshTable();

    setMsg({ message: 'Delete Succesfully', status: 'success' });
    setTimeout(() => {
      setMsg({ message: '', status: 'success' });
    }, 1000);
  };
  const deleteSingleList = (id) => {
    setLoading(true);
    deleteAccountManagement(id, deleteCallback);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Account Management</title>
      </Helmet>
      <Main title='Account Management'>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Account Form'
                id='violations-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='Account Form'
                  title='Account Form'
                >
                  <AccountForm refreshTable={refreshTable} />
                </Tab>
                <Tab
                  eventKey='Accounts List'
                  title='Accounts List'
                >
                  <AccountsList
                    tableData={tableData}
                    setSearch={setSearch}
                    setFilter={setFilter}
                    loading={loading}
                    links={links}
                    selectPage={selectPage}
                    meta={meta}
                  />
                </Tab>
                <Tab
                  eventKey='Remove Access'
                  title='Remove Access'
                >
                  <RemoveAccess
                    tableData={tableData}
                    setSearch={setSearch}
                    setFilter={setFilter}
                    loading={loading}
                    links={links}
                    meta={meta}
                    selectPage={selectPage}
                    deleteSingleList={deleteSingleList}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default AccountManagementPage;
