// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import DashboardCardList from './DashboardCardList/DashboardCardList';

// API

// CSS
import './style.scss';

const DashboardPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Main title='ADMIN Dashboard'>
        <Container fluid>
          <Row>
            <DashboardCardList />
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default DashboardPage;
