// import core & vendor packages
import { Fragment } from 'react';

// import components below
import IncidentReportsFilter from './Filter';
import IncidentReportsTable from './Table';
import IncidentReportsPagination from './Pagination';

// import assets below

// main component
const IncidentReports = () => {
  return (
    <Fragment>
      <IncidentReportsFilter />
      <IncidentReportsTable />
      <IncidentReportsPagination />
    </Fragment>
  );
};

export default IncidentReports;
