/* eslint-disable no-unused-vars */
// Packages
import React, { useContext, useState, useEffect } from 'react';
import { OverlayTrigger, Popover, Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// Components

// API

// Context
import userContext from '../userContext/userContext';

// CSS
import './style.scss';

const Topbar = ({ title, sidebarToggle, onSidebarToggle }) => {
  const [unitData, setUnitData] = useState({
    units: {},
  });

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // initialize Navigate
  const navigate = useNavigate();

  // Context Data
  const { data, setData } = useContext(userContext);

  const handleLogout = () => {
    localStorage.clear();

    setData((prevState) => {
      return { ...prevState, user: {}, isAuth: false };
    });

    navigate('/');
  };

  useEffect(() => {
    if (data.units && data.units.length > 0) {
      const units = data.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          buildingId:list?.relationships?.building?.id,
          value: `${list?.relationships?.building?.attributes?.name}, ${list?.attributes?.unit_number}` || `Unnamed Building ${idx}`,
          label: `${list?.relationships?.building?.attributes?.name}, ${list?.attributes?.unit_number}` || `Unnamed Building ${idx}`,
        };
      });

      setUnitData((prevState) => {
        return { ...prevState, units: units };
      });
    }
  }, [data]);

  return (
    <div className='topbar'>
      <div className='topbar__header'>
        <Button onClick={() => onSidebarToggle(!sidebarToggle)}>
          <svg
            className='bi bi-list'
            viewBox='0 0 16 16'
          >
            <path
              fillRule='evenodd'
              d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
            />
          </svg>
        </Button>
        <span>{title}</span>
      </div>
      <div className='topbar__account'>
        <OverlayTrigger
          trigger='click'
          placement='bottom'
          overlay={
            <Popover id={'popover-positioned-bottom'}>
              <Popover.Header as='h3'>Profile</Popover.Header>
              <Popover.Body>
                <Link
                  className='topbar__link'
                  to='/dashboard/edit-profile'
                >
                  Edit Profile
                </Link>
              </Popover.Body>
            </Popover>
          }
        >
          <Button>
            <svg viewBox='0 0 48.605 48.605'>
              <path
                id='user-circle'
                d='M24.3,17.408a9.408,9.408,0,1,0,9.408,9.408A9.41,9.41,0,0,0,24.3,17.408Zm0,14.111a4.7,4.7,0,1,1,4.7-4.7A4.7,4.7,0,0,1,24.3,31.519ZM24.3,8a24.3,24.3,0,1,0,24.3,24.3A24.3,24.3,0,0,0,24.3,8Zm0,43.9a19.487,19.487,0,0,1-12.749-4.743,8.442,8.442,0,0,1,6.82-3.871,19.937,19.937,0,0,0,5.929.941,19.627,19.627,0,0,0,5.929-.941,8.467,8.467,0,0,1,6.82,3.871A19.487,19.487,0,0,1,24.3,51.9ZM40.247,43.66a13,13,0,0,0-10.3-5.086c-1,0-2.548.941-5.645.941s-4.645-.941-5.645-.941a13.027,13.027,0,0,0-10.3,5.086A19.589,19.589,0,1,1,43.9,32.3,19.459,19.459,0,0,1,40.247,43.66Z'
                transform='translate(0 -8)'
              />
            </svg>
          </Button>
        </OverlayTrigger>
        {data && (
          <div className='topbar__user'>
            <span className='topbar__name'>{data.user.attributes.name}</span>
            <span className='topbar__unit'>
              {data.role === 'admin' && data.user.attributes.occupation}
              {(data.role === 'owner' || data.role === 'tenant') && (
                <Form>
                  <Form.Group>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={unitData.units}
                          placeholder='Select Unit'
                          className='top-bar-unit'
                          {...field}
                        ></Select>
                      )}
                      {...register('units', {
                        required: '(Required)',
                        value: data.userUnit,
                        onChange: (event) => {
                          setData((prevState) => {
                            return {
                              ...prevState,
                              userUnit: event.target.value,
                            };
                          });
                        },
                      })}
                    />
                  </Form.Group>
                </Form>
              )}
            </span>
          </div>
        )}
        <div
          className='svg__wrapper'
          onClick={() => {
            handleLogout();
          }}
        >
          <svg
            className='bi bi-box-arrow-right'
            viewBox='0 0 16 16'
          >
            <path
              fillRule='evenodd'
              d='M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z'
            />
            <path
              fillRule='evenodd'
              d='M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z'
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
