const CalendarCheck = () => {
  return (
    <svg viewBox='0 0 18.976 21.687'>
      <path
        id='calendar-check'
        d='M18.468,6.777H.508A.508.508,0,0,1,0,6.269V4.744A2.033,2.033,0,0,1,2.033,2.711H4.066V.508A.508.508,0,0,1,4.575,0H6.269a.508.508,0,0,1,.508.508v2.2H12.2V.508A.508.508,0,0,1,12.707,0H14.4a.508.508,0,0,1,.508.508v2.2h2.033a2.033,2.033,0,0,1,2.033,2.033V6.269A.508.508,0,0,1,18.468,6.777ZM.508,8.133h17.96a.508.508,0,0,1,.508.508V19.654a2.033,2.033,0,0,1-2.033,2.033H2.033A2.033,2.033,0,0,1,0,19.654V8.641A.508.508,0,0,1,.508,8.133ZM14.626,12.2l-1.193-1.2a.508.508,0,0,0-.719,0L8.222,15.446,6.275,13.483a.508.508,0,0,0-.719,0l-1.2,1.193a.508.508,0,0,0,0,.719l3.5,3.527a.508.508,0,0,0,.719,0l6.055-6.007a.508.508,0,0,0,0-.719Z'
      />
    </svg>
  );
};

export default CalendarCheck;
