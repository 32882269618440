// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';

// API

// CSS
import './style.scss';
import PaymentDuesTabs from './Tabs';

const PaymentsDuePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Payments Due</title>
      </Helmet>
      <Main title='Payments Due'>
        <Container fluid>
          <Row>
            <Col>
              <PaymentDuesTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default PaymentsDuePage;
