/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

// CSS
import './GeneralContractForm.scss';

// Components

// API

// CSS

// Main Component
const GeneralContractForm = ({ setSubmit, msg }) => {
  // States
  const [data, setData] = useState({
    natureOfEngagement: '',
    entity: '',
    periodCovered: '',
    amount: '',
  });

  // Useform
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // handle Submit Form
  const onSubmit = (e) => {
    console.log(e);
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Nature of Engagement{' '}
                {errors.natureOfEngagement ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.natureOfEngagement.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Document Name'
                type='text'
                {...register('natureOfEngagement', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        natureOfEngagement: event.target.value,
                      };
                    });
                  },
                })}
                value={data.natureOfEngagement}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formEntity'
            >
              <Form.Label>
                Entity{' '}
                {errors.entity ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.entity.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Service Provider'
                type='text'
                {...register('entity', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        entity: event.target.value,
                      };
                    });
                  },
                })}
                value={data.entity}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Period Covered{' '}
                {errors.periodCovered ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.periodCovered.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Period Covered'
                type='date'
                {...register('periodCovered', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        periodCovered: event.target.value,
                      };
                    });
                  },
                })}
                value={data.periodCovered}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Amount{' '}
                {errors.amount ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.amount.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Amount'
                type='number'
                {...register('amount', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, amount: event.target.value };
                    });
                  },
                })}
                value={data.amount}
              />
            </Form.Group>
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      <span
        style={{
          color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
          display: 'block',
        }}
      >{`${msg.message}`}</span>
    </Card>
  );
};

export default GeneralContractForm;
