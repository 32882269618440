// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import BillingPage from '../pages/Billing/BillingPage';
import PaymentHistory from '../pages/Billing/PaymentHistory/PaymentHistory';
import UploadProofOfPayment from '../pages/Billing/UploadProofOfPayment/UploadProofOfPayment';
import StateOfAccount from '../pages/Billing/StateofAccount/StateOfAccount';
import DownloadSOA from '../pages/Billing/DownloadSOA/DownloadSOA';
import MyTenantPage from '../pages/MyTenant/MyTenantPage';
import ContactUsPage from '../pages/ContactUs';
import EditProfile from '../pages/EditProfile/EditProfile';

const OthersRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/billing'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <BillingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/billing/StatementofAccount'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <StateOfAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path='/billing/payment-history'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <PaymentHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path='/billing/Upload-Proof-of-Payment'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <UploadProofOfPayment />
          </ProtectedRoute>
        }
      />
      <Route
        path='billing/Download-SOA'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <DownloadSOA />
          </ProtectedRoute>
        }
      />
      <Route
        path='/my-tenant'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <MyTenantPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/contact-us'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ContactUsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/edit-profile'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EditProfile />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default OthersRouter;
