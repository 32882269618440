/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
// Packages
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// CSS
import './UpdateAccountForm.scss';
import UnitList from './UnitList';
import { updateAccountData, updateAccountDataList } from 'api/request';
import Moment from 'moment';
// Main Component
const UpdateAccountForm = ({ account, names }) => {
  // get id on URL
  const { id } = useParams();
  // States
  const [finance, setFinance] = useState('');
  const [engineering, setEngineering] = useState('');
  const [security, setSecurity] = useState('');
  const [BOD, setBOD] = useState('');
  const [tenant, setTenant] = useState('');
  const [owner, setOwner] = useState('');

  const [stateAccount, setStateAccount] = useState('');
  const [name, setName] = useState('');

  // building info
  const [buildingInfo, setBuildingInfo] = useState({
    reference_id: '',
    buildingName: '---',
    unitNo: '---',
    unitArea: '---',
    parkingSlots: '---',
  });
  // personal information
  const [userData, setUserData] = useState({
    emailAddress: '',
    homeAddress: '',
    mobileNumber: '',
    dob: '',
    gender: '',
    citizenship: '',
    occupation: '',
    companyName: '',
    companyAddress: '',
    emergencyName: '',
    emergencycontactNumber: '',
    emergencyEmailAddress: '',
    brokerName: '',
    brokerNumber: '',
    password: '',
    confirmPassword: '',
    start_period_of_lease: '',
    end_period_of_lease: '',
  });

  const [checkboxValid, setCheckboxValid] = useState({
    finance: false,
    engineering: false,
    security: false,
    BOD: false,
    tenant: false,
    owner: false,
  });
  useEffect(() => {
    console.log(checkboxValid);
  }, [checkboxValid]);
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    updateAccountData(id, callbackUpdate);
  }, []);

  // Handle submit Response from api
  const callbackUpdate = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const datafetch = data?.data;
        console.log(data?.data);
        console.log(datafetch?.attributes?.role);
        setStateAccount(datafetch?.attributes?.role);

        setName(datafetch?.attributes?.name);
        setUserData((prev) => {
          return {
            ...prev,
            emailAddress: datafetch?.attributes?.email,
            homeAddress: datafetch?.attributes?.home_address,
            mobileNumber: datafetch?.attributes?.mobile_number,
            dob: datafetch?.attributes?.birth_date,
            gender: datafetch?.attributes?.gender,
            citizenship: datafetch?.attributes?.citizenship,
            occupation: datafetch?.attributes?.occupation,
            companyName: datafetch?.attributes?.company_name,
            companyAddress: datafetch?.attributes?.company_address,
            emergencyName: datafetch?.attributes?.emergency_contact_name,
            emergencycontactNumber:
              datafetch?.attributes?.emergency_contact_number,
            emergencyEmailAddress:
              datafetch?.attributes?.emergency_contact_email,
            brokerName: datafetch?.attributes?.broker_name,
            brokerNumber: datafetch?.attributes?.broker_contact_no,
            password: '',
            confirmPassword: '',
            start_period_of_lease:
              datafetch?.relationships?.tenant?.relationships?.unit?.attributes
                ?.start_period_of_lease,
            end_period_of_lease:
              datafetch?.relationships?.tenant?.relationships?.unit?.attributes
                ?.end_period_of_lease,
          };
        });
        if (
          datafetch?.attributes?.role === 'owner' ||
          datafetch?.attributes?.role === 'Owner'
        ) {
          setBuildingInfo((prev) => {
            return {
              ...prev,
              buildingName:
                datafetch?.relationships?.owner?.relationships?.unit
                  ?.relationships?.building?.attributes?.name,
              unitNo:
                datafetch?.relationships?.owner?.relationships?.unit?.attributes
                  ?.unit_number,
              unitArea:
                datafetch?.relationships?.owner?.relationships?.unit?.attributes
                  ?.unit_area,
              parkingSlots:
                datafetch?.relationships?.owner?.relationships?.unit
                  ?.relationships?.building?.attributes?.no_of_parking_slots,
            };
          });
        }
        if (
          datafetch?.attributes?.role === 'tenant' ||
          datafetch?.attributes?.role === 'Tenant'
        ) {
          setBuildingInfo((prev) => {
            return {
              ...prev,
              buildingName:
                datafetch?.relationships?.tenant?.relationships?.unit
                  ?.relationships?.building?.attributes?.name,
              unitNo:
                datafetch?.relationships?.tenant?.relationships?.unit
                  ?.attributes?.unit_number,
              unitArea:
                datafetch?.relationships?.tenant?.relationships?.unit
                  ?.attributes?.unit_area,
              parkingSlots:
                datafetch?.relationships?.owner?.relationships?.unit
                  ?.relationships?.building?.attributes?.no_of_parking_slots,
            };
          });

          const tenants = datafetch.relationships.tenants.map((tenant) => {
            const data = {
              id: tenant.attributes.id,
              name: tenant.attributes.name,
              age: tenant.attributes.age,
              gender: tenant.attributes.gender,
              remarks: tenant.attributes.remarks,
            };
            return data;
          });
          console.log(tenants);
          setUnits(tenants);
        }
        setSuccess(data.message);

        datafetch.relationships.roles.forEach((roles) => {
          console.log(roles.attributes.name);
          const rolecheck = roles.attributes.name;
          if (
            rolecheck === 'owner' ||
            rolecheck === 'tenant' ||
            rolecheck === 'finance' ||
            rolecheck === 'engineering' ||
            rolecheck === 'security' ||
            rolecheck === 'BOD'
          ) {
            console.log('checkbox');
            setCheckboxValid((prev) => {
              return { ...prev, [`${rolecheck}`]: true };
            });
          }
        });
        console.log(checkboxValid);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };

  // role side (Tenant)
  const [units, setUnits] = useState([]);
  const submithandler = (e) => {
    console.log('check here');
    e.preventDefault();
    // user roles checkbox selected
    const userRoles = [finance, engineering, security, BOD, tenant, owner];
    // user roles checkbox reconstructed
    const constructedRoles = [];
    Object.keys(userRoles).forEach((key) => {
      if (userRoles[key]) {
        console.log(userRoles[key]);
        constructedRoles.push(userRoles[key].toLowerCase());
      }
    });
    if (stateAccount === 'owner' || stateAccount === 'Owner') {
      const dataSubmit = {
        role: stateAccount,
        user_roles: constructedRoles,
        reference_id: id,
        email: userData.emailAddress,
        home_address: userData.homeAddress,
        mobile_number: userData.mobileNumber,
        birth_date: userData.dob,
        gender: userData.gender,
        citizenship: userData.citizenship,
        occupation: userData.occupation,
        company_name: userData.companyName,
        company_address: userData.companyAddress,
        emergency_contact_name: userData.emergencyName,
        emergency_contact_number: userData.emergencycontactNumber,
        emergency_contact_email: userData.emergencyEmailAddress,
        broker_name: userData.brokerName,
        broker_contact_no: userData.brokerNumber,
        password: userData.password,
        password_confirmation: userData.confirmPassword,
      };
      console.log(dataSubmit);
      updateAccountDataList(id, dataSubmit, callback);
    } else if (stateAccount === 'tenant' || stateAccount === 'Tenant') {
      const reconTenant = units.map((tenant) => {
        return {
          name: tenant.name,
          age: +tenant.age,
          gender: tenant.gender,
        };
      });
      console.log(reconTenant);
      const dataSubmit = {
        role: stateAccount,
        user_roles: constructedRoles,
        reference_id: id,
        start_period_of_lease: userData.start_period_of_lease,
        end_period_of_lease: userData.end_period_of_lease,
        email: userData.emailAddress,
        home_address: userData.homeAddress,
        mobile_number: userData.mobileNumber,
        birth_date: userData.dob,
        gender: userData.gender,
        citizenship: userData.citizenship,
        occupation: userData.occupation,
        company_name: userData.companyName,
        company_address: userData.companyAddress,
        emergency_contact_name: userData.emergencyName,
        emergency_contact_number: userData.emergencycontactNumber,
        emergency_contact_email: userData.emergencyEmailAddress,
        tenants: reconTenant,
        password: userData.password,
        password_confirmation: userData.confirmPassword,
      };
      console.log(dataSubmit);
      updateAccountDataList(id, dataSubmit, callback);
    } else {
      const dataSubmit = {
        role: stateAccount,
        user_roles: constructedRoles,
        reference_id: id,
        email: userData.emailAddress,
        password: userData.password,
        password_confirmation: userData.confirmPassword,
      };
      console.log(dataSubmit);
      updateAccountDataList(id, dataSubmit, callback);
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  return (
    <Card className='card admin-form shadow-sm'>
      <Form
        className='management-form'
        onSubmit={submithandler}
      >
        <Row>
          <Col>
            <Form.Group
              className='mb-5 item-no'
              controlId='selectRole'
            >
              <Form.Label className='mb-3 header'>
                USER ROLES (Please select if the user has other roles)
              </Form.Label>

              {((stateAccount && stateAccount === 'Owner') ||
                (stateAccount && stateAccount === 'owner')) && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='finance'
                        label='Finance'
                        value='Finance'
                        checked={checkboxValid.finance}
                        onChange={() => {
                          !checkboxValid.finance
                            ? setFinance('Finance')
                            : setFinance(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, finance: !prev.finance };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='engineering'
                        label='Engineering'
                        value='Engineering'
                        checked={checkboxValid.engineering}
                        onChange={() => {
                          !checkboxValid.engineering
                            ? setEngineering('Engineering')
                            : setEngineering(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, engineering: !prev.engineering };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='security'
                        label='Security'
                        value='Security'
                        checked={checkboxValid.security}
                        onChange={() => {
                          !checkboxValid.security
                            ? setSecurity('Security')
                            : setSecurity(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, security: !prev.security };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='bod'
                        label='BOD'
                        value='BOD'
                        checked={checkboxValid.BOD}
                        onChange={() => {
                          !checkboxValid.BOD ? setBOD('BOD') : setBOD(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, BOD: !prev.BOD };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {((stateAccount && stateAccount === 'Tenant') ||
                (stateAccount && stateAccount === 'tenant')) && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='finance'
                        label='Finance'
                        value='Finance'
                        checked={checkboxValid.finance}
                        onChange={() => {
                          !checkboxValid.finance
                            ? setFinance('Finance')
                            : setFinance(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, finance: !prev.finance };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='engineering'
                        label='Engineering'
                        value='Engineering'
                        checked={checkboxValid.engineering}
                        onChange={() => {
                          !checkboxValid.engineering
                            ? setEngineering('Engineering')
                            : setEngineering(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, engineering: !prev.engineering };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='security'
                        label='Security'
                        value='Security'
                        checked={checkboxValid.security}
                        onChange={() => {
                          !checkboxValid.security
                            ? setSecurity('Security')
                            : setSecurity(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, security: !prev.security };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='bod'
                        label='BOD'
                        value='BOD'
                        checked={checkboxValid.BOD}
                        onChange={() => {
                          !checkboxValid.BOD ? setBOD('BOD') : setBOD(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, BOD: !prev.BOD };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {((stateAccount && stateAccount === 'Finance') ||
                (stateAccount && stateAccount === 'finance')) && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='engineering'
                        label='Engineering'
                        value='Engineering'
                        checked={checkboxValid.engineering}
                        onChange={() => {
                          !checkboxValid.engineering
                            ? setEngineering('Engineering')
                            : setEngineering(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, engineering: !prev.engineering };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='security'
                        label='Security'
                        value='Security'
                        checked={checkboxValid.security}
                        onChange={() => {
                          !checkboxValid.security
                            ? setSecurity('Security')
                            : setSecurity(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, security: !prev.security };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='bod'
                        label='BOD'
                        value='BOD'
                        checked={checkboxValid.BOD}
                        onChange={() => {
                          !checkboxValid.BOD ? setBOD('BOD') : setBOD(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, BOD: !prev.BOD };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='owner'
                        label='Owner'
                        checked={checkboxValid.owner}
                        onChange={() => {
                          !checkboxValid.owner
                            ? setOwner('Owner')
                            : setOwner(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, owner: !prev.owner };
                          });
                        }}
                      />
                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {((stateAccount && stateAccount === 'Engineering') ||
                (stateAccount && stateAccount === 'engineering')) && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='finance'
                        label='Finance'
                        value='Finance'
                        checked={checkboxValid.finance}
                        onChange={() => {
                          !checkboxValid.finance
                            ? setFinance('Finance')
                            : setFinance(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, finance: !prev.finance };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='security'
                        label='Security'
                        value='Security'
                        checked={checkboxValid.security}
                        onChange={() => {
                          !checkboxValid.security
                            ? setSecurity('Security')
                            : setSecurity(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, security: !prev.security };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='bod'
                        label='BOD'
                        value='BOD'
                        checked={checkboxValid.BOD}
                        onChange={() => {
                          !checkboxValid.BOD ? setBOD('BOD') : setBOD(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, BOD: !prev.BOD };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='owner'
                        label='Owner'
                        checked={checkboxValid.owner}
                        onChange={() => {
                          !checkboxValid.owner
                            ? setOwner('Owner')
                            : setOwner(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, owner: !prev.owner };
                          });
                        }}
                      />
                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {((stateAccount && stateAccount === 'Security') ||
                (stateAccount && stateAccount === 'security')) && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='finance'
                        label='Finance'
                        value='finance'
                        checked={checkboxValid.finance}
                        onChange={() => {
                          !checkboxValid.finance
                            ? setFinance('Finance')
                            : setFinance(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, finance: !prev.finance };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='engineering'
                        label='Engineering'
                        value='Engineering'
                        checked={checkboxValid.engineering}
                        onChange={() => {
                          !checkboxValid.engineering
                            ? setEngineering('Engineering')
                            : setEngineering(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, engineering: !prev.engineering };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='bod'
                        label='BOD'
                        value='BOD'
                        checked={checkboxValid.BOD}
                        onChange={() => {
                          !checkboxValid.BOD ? setBOD('BOD') : setBOD(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, BOD: !prev.BOD };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='owner'
                        label='Owner'
                        checked={checkboxValid.owner}
                        onChange={() => {
                          !checkboxValid.owner
                            ? setOwner('Owner')
                            : setOwner(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, owner: !prev.owner };
                          });
                        }}
                      />
                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              {stateAccount && stateAccount === 'bod' && (
                <Row>
                  <Col>
                    <div className='checkboxes'>
                      <Form.Check
                        type='checkbox'
                        id='finance'
                        label='Finance'
                        value='Finance'
                        checked={checkboxValid.finance}
                        onChange={() => {
                          !checkboxValid.finance
                            ? setFinance('Finance')
                            : setFinance(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, finance: !prev.finance };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='engineering'
                        label='Engineering'
                        value='Engineering'
                        checked={checkboxValid.engineering}
                        onChange={() => {
                          !checkboxValid.engineering
                            ? setEngineering('Engineering')
                            : setEngineering(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, engineering: !prev.engineering };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='security'
                        label='Security'
                        value='Security'
                        checked={checkboxValid.security}
                        onChange={() => {
                          !checkboxValid.security
                            ? setSecurity('Security')
                            : setSecurity(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, security: !prev.security };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='owner'
                        label='Owner'
                        checked={checkboxValid.owner}
                        onChange={() => {
                          !checkboxValid.owner
                            ? setOwner('Owner')
                            : setOwner(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, owner: !prev.owner };
                          });
                        }}
                      />

                      <Form.Check
                        type='checkbox'
                        id='tenant'
                        label='Tenant'
                        value='Tenant'
                        checked={checkboxValid.tenant}
                        onChange={() => {
                          !checkboxValid.tenant
                            ? setTenant('Tenant')
                            : setTenant(null);
                          setCheckboxValid((prev) => {
                            return { ...prev, tenant: !prev.tenant };
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </Form.Group>
          </Col>
        </Row>

        {/* Name / Building Name / Unit No. */}
        {stateAccount &&
          (stateAccount === 'Owner' ||
            stateAccount === 'Tenant' ||
            stateAccount === 'owner' ||
            stateAccount === 'tenant') && (
            <Row>
              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formTitle'
                >
                  <Form.Label className='mb-3'>Name</Form.Label>
                  <Form.Select
                    aria-label='Select Name'
                    defaultValue=''
                  >
                    <option
                      value=''
                      disabled
                    >
                      {name}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formBuildingName'
                >
                  <Form.Label className='mb-3'>Building Name</Form.Label>
                  <Form.Control
                    className='read-only'
                    aria-label='Enter Building Name Here'
                    placeholder={
                      buildingInfo.buildingName
                        ? buildingInfo.buildingName
                        : '---'
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formUnitNo'
                >
                  <Form.Label className='mb-3'>Unit No</Form.Label>
                  <Form.Control
                    className='read-only'
                    aria-label='Enter Unit No Here'
                    placeholder={
                      buildingInfo.unitNo ? buildingInfo.unitNo : '---'
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

        {/* Name / Email Address */}
        {stateAccount &&
          (stateAccount === 'Finance' ||
            stateAccount === 'Engineering' ||
            stateAccount === 'bod' ||
            stateAccount === 'Security' ||
            stateAccount === 'finance' ||
            stateAccount === 'engineering' ||
            stateAccount === 'BOD' ||
            stateAccount === 'security') && (
            <Row>
              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formTitle'
                >
                  <Form.Label className='mb-3'>Name</Form.Label>

                  <Form.Select
                    aria-label='Select Name'
                    defaultValue=''
                  >
                    <option
                      value=''
                      disabled
                    >
                      {name}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formEmailAddress'
                >
                  <Form.Label className='mb-3'>
                    Email Address <span style={{ color: '#CB1212' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    aria-label='Enter Email Here'
                    placeholder='Enter Email Here'
                    value={userData.emailAddress}
                    onChange={(e) => {
                      setUserData((prev) => {
                        return { ...prev, emailAddress: e.target.value };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

        {/* Unit Area / Period Lease Parking Slots */}
        {stateAccount &&
          (stateAccount === 'Owner' ||
            stateAccount === 'Tenant' ||
            stateAccount === 'owner' ||
            stateAccount === 'tenant') && (
            <Row>
              <Col md={3}>
                <Form.Group
                  className='mb-5'
                  controlId='formUnitArea'
                >
                  <Form.Label className='mb-3'>Unit Area</Form.Label>
                  <Form.Control
                    className='read-only'
                    aria-label='Enter Unit Area Here'
                    placeholder={
                      buildingInfo.unitArea ? buildingInfo.unitArea : '---'
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>

              {((stateAccount && stateAccount === 'Owner') ||
                (stateAccount && stateAccount === 'owner')) && (
                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formParkingSlots'
                  >
                    <Form.Label className='mb-3'>Parking Slot(s)</Form.Label>
                    <Form.Control
                      className='read-only'
                      aria-label='Enter Parking Slot(s)s Here'
                      placeholder={
                        buildingInfo.parkingSlot
                          ? buildingInfo.parkingSlot
                          : '---'
                      }
                      readOnly
                    />
                  </Form.Group>
                </Col>
              )}

              {((stateAccount && stateAccount === 'Tenant') ||
                (stateAccount && stateAccount === 'tenant')) && (
                <>
                  <Col md={3}>
                    <Form.Group
                      className='mb-5'
                      controlId='formPeriodLeaseFrom'
                    >
                      <Form.Label className='mb-3'>
                        Period of Lease (From){' '}
                        <span style={{ color: '#CB1212' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type='date'
                        value={userData.start_period_of_lease}
                        placeholder='Select Period Lease'
                        onChange={(e) =>
                          setUserData((prev) => {
                            return {
                              ...prev,
                              start_period_of_lease: e.target.value,
                            };
                          })
                        }
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group
                      className='mb-5'
                      controlId='formPeriodLeaseTo'
                    >
                      <Form.Label className='mb-3'>
                        To <span style={{ color: '#CB1212' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type='date'
                        value={userData.end_period_of_lease}
                        aria-label='Enter Parking Slot(s)s Here'
                        onChange={(e) =>
                          setUserData((prev) => {
                            return {
                              ...prev,
                              end_period_of_lease: e.target.value,
                            };
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
          )}

        {/* User Roles */}
        {stateAccount &&
          (stateAccount === 'Owner' ||
            stateAccount === 'Tenant' ||
            stateAccount === 'owner' ||
            stateAccount === 'tenant') && (
            <>
              <Row>
                <label className='mb-3 header'>PERSONAL INFORMATION</label>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formEmail'
                  >
                    <Form.Label className='mb-3'>
                      Email Address <span style={{ color: '#CB1212' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      aria-label='Enter Email Address Here'
                      placeholder='Enter Email Here'
                      value={userData.emailAddress}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, emailAddress: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formHomeAddress'
                  >
                    <Form.Label className='mb-3'>Home Address</Form.Label>
                    <Form.Control
                      aria-label='Enter Address Here'
                      placeholder='Enter Address Here'
                      value={userData.homeAddress}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, homeAddress: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formMobileNumber'
                  >
                    <Form.Label className='mb-3'>Mobile No.</Form.Label>
                    <Form.Control
                      type='text'
                      maxLength={11}
                      aria-label='Enter Number Here'
                      placeholder='Enter No. Here'
                      value={userData.mobileNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setUserData((prev) => {
                            return { ...prev, mobileNumber: e.target.value };
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formbirthdate'
                  >
                    <Form.Label className='mb-3'>Date of Birth</Form.Label>
                    <Form.Control
                      type='date'
                      aria-label='Select Birthdate'
                      placeholder='Select Birth Date'
                      value={userData.dob}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, dob: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formGender'
                  >
                    <Form.Label className='mb-3'>Gender</Form.Label>
                    <Form.Select
                      aria-label='Select Gender'
                      defaultValue=''
                      value={userData.gender}
                      onChange={(e) => {
                        setUserData((prev) => {
                          return { ...prev, gender: e.target.value };
                        });
                      }}
                    >
                      <option
                        value=''
                        hidden
                      >
                        Gender
                      </option>
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formCitizenship'
                  >
                    <Form.Label className='mb-3'>Citizenship</Form.Label>
                    <Form.Control
                      aria-label='Enter Citizenship Here'
                      placeholder='Enter Citizenship Here'
                      value={userData.citizenship}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, citizenship: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formOccupation'
                  >
                    <Form.Label className='mb-3'>Occupation</Form.Label>
                    <Form.Control
                      aria-label='Enter Occupation Here'
                      placeholder='Enter Occupation Here'
                      value={userData.occupation}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, occupation: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formCompanyName'
                  >
                    <Form.Label className='mb-3'>Company Name</Form.Label>
                    <Form.Control
                      aria-label='Enter Company Name Here'
                      placeholder='Enter Company Name Here'
                      value={userData.companyName}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, companyName: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formCompanyAddress'
                  >
                    <Form.Label className='mb-3'>Company Address</Form.Label>
                    <Form.Control
                      aria-label='Enter Company Address Here'
                      placeholder='Enter Company Address Here'
                      value={userData.companyAddress}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, companyAddress: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <label className='mb-3 header'>EMERGENCY CONTACT PERSON</label>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formEmergencyName'
                  >
                    <Form.Label className='mb-3'>Name</Form.Label>
                    <Form.Control
                      aria-label='Enter Name Here'
                      placeholder='Enter Name Here'
                      value={userData.emergencyName}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return { ...prev, emergencyName: e.target.value };
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formEmergencyContact'
                  >
                    <Form.Label className='mb-3'>Contact Number</Form.Label>
                    <Form.Control
                      type='text'
                      maxLength={11}
                      aria-label='Enter Contact Number Here'
                      placeholder='Enter No. Here'
                      value={userData.emergencycontactNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setUserData((prev) => {
                            return {
                              ...prev,
                              emergencycontactNumber: e.target.value,
                            };
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className='mb-5'
                    controlId='formEmergencyEmail'
                  >
                    <Form.Label className='mb-3'>Email Address</Form.Label>
                    <Form.Control
                      type='text'
                      aria-label='Enter Email Address Here'
                      placeholder='Enter Email Here'
                      value={userData.emergencyEmailAddress}
                      onChange={(e) =>
                        setUserData((prev) => {
                          return {
                            ...prev,
                            emergencyEmailAddress: e.target.value,
                          };
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

        {((stateAccount && stateAccount === 'Owner') ||
          (stateAccount && stateAccount === 'owner')) && (
          <Row>
            <label className='mb-3 header'>BROKER</label>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formBrokerName'
              >
                <Form.Label className='mb-3'>
                  Broker/Property Specialist
                </Form.Label>
                <Form.Control
                  aria-label='Enter Name Here'
                  placeholder='Enter Name Here (Optional)'
                  value={userData.brokerName}
                  onChange={(e) =>
                    setUserData((prev) => {
                      return {
                        ...prev,
                        brokerName: e.target.value,
                      };
                    })
                  }
                />
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group
                className='mb-5'
                controlId='formBrokerContact'
              >
                <Form.Label className='mb-3'>
                  Broker&apos;s Contact No.
                </Form.Label>
                <Form.Control
                  type='text'
                  maxLength={11}
                  aria-label='Enter Contact Number Here'
                  placeholder='Enter No. Here (Optional)'
                  value={userData.brokerNumber}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setUserData((prev) => {
                        return {
                          ...prev,
                          brokerNumber: e.target.value,
                        };
                      });
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {((stateAccount && stateAccount === 'Tenant') ||
          (stateAccount && stateAccount === 'tenant')) && (
          <Row>
            <label className='mb-3 header'>PERSONS STAYING IN THE UNIT</label>

            <UnitList
              units={units}
              setUnits={setUnits}
            />
          </Row>
        )}

        <Row>
          <label className='mb-3 header'>PASSWORD</label>

          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formPassword'
            >
              <Form.Label className='mb-3'>
                Enter Password <span style={{ color: '#CB1212' }}>*</span>
              </Form.Label>
              <Form.Control
                type='password'
                aria-label='Enter Password'
                placeholder='Enter Password Here'
                value={userData.password}
                onChange={(e) =>
                  setUserData((prev) => {
                    return {
                      ...prev,
                      password: e.target.value,
                    };
                  })
                }
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formConfirmPassword'
            >
              <Form.Label className='mb-3'>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                aria-label='Enter Confirm Password'
                placeholder='Enter Confirm Password here'
                value={userData.confirmPassword}
                onChange={(e) =>
                  setUserData((prev) => {
                    return {
                      ...prev,
                      confirmPassword: e.target.value,
                    };
                  })
                }
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>{showResponseAPI}</Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                as={Link}
                to={'/administration/account-management'}
                className='cancel'
                variant='default'
                style={{ padding: '0.8em 2em' }}
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UpdateAccountForm;
