/* eslint-disable camelcase */
// import core and vendor packages below
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { Table, Dropdown, Modal, Button } from 'react-bootstrap';

// import components below
import TableUI from '../../../components/UI/TableUI';
import RebookModal from './Rebook/RebookModal';

// import style below
import './Table.scss';

// api
import { cancelRequestBook } from 'api/request';

// main component
const AmenitiesTable = ({ tableData, user, refreshTable }) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [rebookModal, setRebookModal] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  const [date, setDate] = useState({
    startDate: '',
    endDate: '',
  });
  const role = user?.attributes?.role;

  const deleteSingleListData = () => {
    cancelRequestBook(storeId, callback);
  };

  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };

  const handleRebook = (id, startDate, endDate) => {
    setStoreId(id);
    setDate({
      startDate: startDate,
      endDate: endDate,
    });
    return setRebookModal(true);
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        refreshTable();
        setConfirmationDelete(false);
        setStoreId('');
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  // To return view/delete action
  const renderAction = (id, status, startDate, endDate, user_id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>
        {status !== 'paid' && status !== 'cancelled' && (
          <Dropdown.Menu className='dropdown-menu'>
            {status === 'pending' && user?.id === user_id && (
              <Dropdown.Item
                onClick={() => handleShow(id)}
                style={{ color: '#CB1212' }}
                disabled={status !== 'pending' ? true : ''}
              >
                Cancel Booking
              </Dropdown.Item>
            )}
            {status === 'denied' && (
              <Dropdown.Item
                onClick={() => handleRebook(id, startDate, endDate)}
                disabled={status !== 'pending' ? true : ''}
              >
                Rebook
              </Dropdown.Item>
            )}
            {status === 'approved' && user?.id === user_id && (
              <Dropdown.Item
                as={Link}
                to={`/administration/requests/amenities/upload-payment/${id}?activeKey=requests`}
              >
                Upload Proof of Payment
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Booking No.',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Amenity',
        accessor: 'col2',
      },
      {
        Header: 'Date and Time',
        accessor: 'col3',
      },
      {
        Header: 'Name',
        accessor: 'col4',
      },
      {
        Header: 'Account Type',
        accessor: 'col5',
      },
      {
        Header: 'Unit No.',
        accessor: 'col6',
      },
      {
        Header: 'Status',
        accessor: 'col7',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  const data = useMemo(() => {
    console.log(tableData);
    if (tableData && tableData.length > 0) {
      const newData = tableData.map((list) => {
        return {
          col1: list?.attributes?.reference_id,
          col2: list?.relationships?.amenity?.attributes?.name,
          col3: list?.attributes?.start_datetime,
          col4: list?.relationships?.user?.attributes?.name,
          col5: list?.relationships?.user?.attributes?.unit_role,
          col6: list?.relationships?.unit?.attributes?.unit_number,
          col7: list?.attributes?.status,
          action: renderAction(
            list?.attributes?.reference_id,
            list?.attributes?.status,
            list?.attributes?.start_datetime,
            list?.attributes?.end_datetime,
            list?.relationships?.user?.id,
          ),
        };
      });

      return newData;
    } else {
      return [];
    }
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <div className='amenitiesTable'>
        <TableUI>
          <Table
            responsive
            {...getTableProps()}
          >
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup, index) => (
                  // Apply the header row props
                  <tr
                    key={index}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => {
                        if (column.Header === 'Action' && role === 'admin') {
                          return;
                        }

                        if (
                          (column.Header === 'Account Type' ||
                            column.Header === 'Unit No.') &&
                          (role === 'owner' || role === 'tenant')
                        ) {
                          return;
                        }

                        return (
                          <th
                            key={index}
                            {...column.getHeaderProps()}
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </th>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row, index) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      key={index}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          if (
                            cell.column.Header === 'Action' &&
                            role === 'admin'
                          ) {
                            return;
                          }

                          if (
                            (cell.column.Header === 'Account Type' ||
                              cell.column.Header === 'Unit No.') &&
                            (role === 'owner' || role === 'tenant')
                          ) {
                            return;
                          }

                          return (
                            <td
                              key={index}
                              {...cell.getCellProps()}
                              className={
                                (cell.value === 'paid'
                                  ? 'tableUI__status--paid'
                                  : null) ||
                                (cell.value === 'for verification'
                                  ? 'tableUI__status--paid'
                                  : null) ||
                                (cell.value === 'approved'
                                  ? 'tableUI__status--paid'
                                  : null) ||
                                (cell.value === 'pending' ||
                                cell.value === 'for approval' ||
                                cell.value === 'for payment'
                                  ? 'tableUI__status--pending'
                                  : null) ||
                                (cell.value === 'denied'
                                  ? 'tableUI__status--denied'
                                  : null) ||
                                (cell.value === 'cancelled'
                                  ? 'tableUI__status--denied'
                                  : null) ||
                                (cell.value === 'canceled'
                                  ? 'tableUI__status--canceled'
                                  : null) ||
                                (cell.value === 'resolved'
                                  ? 'tableUI__status--paid'
                                  : null)
                              }
                              style={{ textTransform: 'capitalize' }}
                            >
                              {cell.column.Header === 'Booking No.' ? (
                                <Link
                                  to={
                                    role === 'admin'
                                      ? `/administration/requests/amenities/view-request/${cell.value}?activeKey=requests`
                                      : `/administration/requests/amenities/view-booking/${cell.value}?activeKey=requests`
                                  }
                                >
                                  {cell.render('Cell')}
                                </Link>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </TableUI>
      </div>

      <Modal
        show={confirmationDelete}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this ?</Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={() => deleteSingleListData(storeId)}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <RebookModal
        rebookModal={rebookModal}
        setRebookModal={setRebookModal}
        id={storeId}
        date={date}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default AmenitiesTable;
