// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import BuildingDirectoryFilter from '../Filter';
import BuildingDirectoryTable from '../Table';
import BuildingDirectoryPagination from '../Pagination';

// main component
const BuildingDirectoryTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='dashboardBuildingDirectory'
        className='mb-3'
      >
        <Tab
          eventKey='dashboardBuildingDirectory'
          title='Building Directory'
        >
          <BuildingDirectoryFilter />
          <BuildingDirectoryTable />
          <BuildingDirectoryPagination />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default BuildingDirectoryTabs;
