const ExchangeAlt = () => {
  return (
    <svg viewBox='0 0 24.066 18.804'>
      <path
        id='exchange-alt'
        d='M0,61.242V60.49a1.128,1.128,0,0,1,1.128-1.128H18.05V57.105a1.129,1.129,0,0,1,1.926-.8l3.76,3.76a1.128,1.128,0,0,1,0,1.6l-3.76,3.76a1.129,1.129,0,0,1-1.926-.8V62.37H1.128A1.128,1.128,0,0,1,0,61.242Zm22.938,7.145H6.017V66.13a1.129,1.129,0,0,0-1.926-.8L.33,69.093a1.128,1.128,0,0,0,0,1.6l3.76,3.76a1.129,1.129,0,0,0,1.926-.8V71.395H22.938a1.128,1.128,0,0,0,1.128-1.128v-.752A1.128,1.128,0,0,0,22.938,68.386Z'
        transform='translate(0 -55.976)'
      />
    </svg>
  );
};

export default ExchangeAlt;
