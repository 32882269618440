// import assets below
import './style.scss';

// main component
const TabsUI = (props) => {
  const { children } = props;
  return <div className='tabsUI'>{children}</div>;
};

export default TabsUI;
