/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
  Container,
} from 'react-bootstrap';

// Components
import UnitListTable from './UnitListTable';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import TablePagination from 'components/Pagination';

// API
import {
  getBuildingLists,
  getBuildingUnitDetails,
  deleteBuildingUnit,
} from 'api/request';

// CSS/SCSS
import './UnitList.scss';

// Main Component
const UnitList = ({ refreshList, setRefreshList }) => {
  // States
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableList, setTableList] = useState();
  const [activeTab, setActiveTab] = useState('Unit No');
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [unitList, setUnitList] = useState(null);
  const [updateMsg, setUpdateMsg] = useState({ message: '', status: '' });
  const [unitID, setUnitID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState();
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);

  const selectPage = (page) => {
    const param = `filter[search]=${search}${page}`;

    setLoading(true);
    getBuildingUnitDetails(selectedBuilding, param, unitCallback);
    setUpdateMsg({ message: '', status: '' });
  };

  useEffect(() => {
    getBuildingLists('', callback);
    setTableList([]);
    setRefreshList(false);
  }, []);

  useEffect(() => {
    if (refreshList) {
      getBuildingLists('', callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      setLoading(true);
      getBuildingUnitDetails(selectedBuilding, '', unitCallback);
      setUpdateMsg({ message: '', status: '' });
    }
  }, [selectedBuilding, tableList]);
  
  const searchBuilding = () => {
    const param = `filter[search]=${search}&`;

    setLoading(true);
    getBuildingUnitDetails(selectedBuilding, param, unitCallback);
    setUpdateMsg({ message: '', status: '' });
  };

  const unitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUnitList(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg('Something went wrong.');
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableList(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg('Something went wrong.');
      }
    }
  };

  const confirmDelete = () => {
    deleteBuildingUnit(unitID, deleteCallback);
  };

  const deleteCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 204) {
        setUpdateMsg({
          message: 'Unit successfully deleted',
          status: 'success',
        });
        getBuildingLists('', callback);
        setTableList([]);
        setRefreshList(true);
      }

      if (status === 401 || status === 422) {
        setMsg('Something went wrong.');
      }
    }
  };

  // To render on screen
  return (
    <>
      <Form
        className='formBuiling'
        onSubmit={(e) => {
          e.preventDefault();
          searchBuilding();
        }}
      >
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Select
                aria-label='Select Building'
                value={selectedBuilding}
                onChange={(e) => {
                  setSelectedBuilding(e.target.value);
                }}
              >
                {tableList &&
                  tableList.map((val, idx) => {
                    if (idx === 0 && selectedBuilding === '') {
                      setSelectedBuilding(val.id);
                    }

                    return (
                      <option
                        value={val.id}
                        key={val.id}
                      >
                        {val.attributes.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
                value={search}
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
              />

              <Button
                className='searchbtn'
                onClick={() => searchBuilding()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div
              className='edit-container'
              style={{ textAlign: 'left' }}
            >
              <DropdownButton
                id='filter'
                title='Filter'
              >
                <Dropdown.Item href='#/action-1'>Classification</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Building Floor</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>

        <Card className='card admin-form shadow-sm'>
          {loading && <p>Loading...</p>}
          {!loading && msg && <p>{msg}</p>}
          {!loading && (
            <>
              {unitList && unitList.length === 0 && <p>Table is empty</p>}
              {unitList && unitList.length > 0 && (
                <UnitListTable
                  unitList={unitList}
                  selectedBuilding={selectedBuilding}
                  setUpdateMsg={setUpdateMsg}
                  setUnitID={setUnitID}
                  setDeleteModal={setDeleteModal}
                />
              )}
            </>
          )}

          <Form.Group className='mt-5 buttons'>
            <Container
              fluid
              style={{ padding: 0 }}
            >
              <Row>
                <Col className='message-text'>
                  <span
                    style={{
                      color:
                        updateMsg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                      display: 'block',
                    }}
                  >
                    {`${updateMsg.message}`}
                  </span>
                </Col>
                <Col className='submit-buttons'></Col>
              </Row>
            </Container>
          </Form.Group>
        </Card>

        {loading && <p>Loading...</p>}
        {!loading && msg && <p>{msg}</p>}
        {!loading && (
          <>
            {tableList && tableList.length === 0 && <p>Table is empty</p>}
            {tableList && tableList.length > 0 && (
              <TablePagination
                links={links}
                meta={meta}
                selectPage={selectPage}
              />
            )}
          </>
        )}
      </Form>

      <DeleteModal
        confirmDelete={confirmDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </>
  );
};

export default UnitList;
