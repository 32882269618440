// Packages
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// Components
import GovernmentRequirementListTable from './GovernmentRequirementListTable';

// CSS/SCSS
import './GovernmentRequirementList.scss';

// Main Component
const GovernmentRequirementList = () => {
  // Static titles
  const titles = [
    {
      id: 1,
      value: 'Requirements',
    },
    {
      id: 2,
      value: 'Requirements',
    },
    {
      id: 3,
      value: 'Requirements',
    },
  ];

  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={2}>
            <Form.Group
              controlId='formSearch'
              className='mb-4 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group
              className='mb-5'
              controlId='formBuilding'
            >
              <Form.Select aria-label='Select Building'>
                {titles.map((val) => {
                  return (
                    <option
                      value={val.id}
                      key={val.id}
                    >
                      {val.value}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div
              className='edit-container'
              style={{ textAlign: 'left' }}
            >
              <DropdownButton
                id='filter'
                title='Filter'
              >
                <Dropdown.Item href='#/action-1'>Validity Period</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Renewal Date</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
          <Col md={6}>
            <div className='calendar-cont'>
              <Button
                as={Link}
                to={'/administration/government-requirements/calendar'}
                className='calender-btn'
                style={{
                  padding: '0.8em 2em 0.8em 3em',
                  backgroundColor: '#00B6E9',
                  border: 'none',
                  borderRadius: '7px',
                }}
              >
                Calendar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        <GovernmentRequirementListTable />
      </Card>
    </>
  );
};

export default GovernmentRequirementList;
