// import core & vendor packages
import { Fragment } from 'react';

// import components below
import ViolationReportsFilter from './Filter';
import ViolationReportsTable from './Table';
import ViolationReportsPagination from './Pagination';

// import assets below

// main component
const ViolationReports = () => {
  return (
    <Fragment>
      <ViolationReportsFilter />
      <ViolationReportsTable />
      <ViolationReportsPagination />
    </Fragment>
  );
};

export default ViolationReports;
