// import core and vendor packages below
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../../../components/UI/TableUI';

// import style below
import './style.scss';

// main component
const ViolationReportsTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Violation No.',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Violation',
        accessor: 'col2',
      },
      {
        Header: 'Name',
        accessor: 'col3',
      },
      {
        Header: 'Unit No.',
        accessor: 'col4',
      },
      {
        Header: 'Date & Time',
        accessor: 'col5',
      },
      {
        Header: 'No. of Offense',
        accessor: 'col6',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: 'V-0001',
        col2: 'Smoking',
        col3: 'Carl Dela Cruz',
        col4: 'B 254',
        col5: '01/21/2021 09:45AM',
        col6: '1st',
      },
      {
        col1: 'V-0002',
        col2: 'Noise',
        col3: 'Carl Dela Cruz',
        col4: 'A 254',
        col5: '01/21/2021 09:45AM',
        col6: '2nd',
      },
      {
        col1: 'V-0003',
        col2: 'Garbage',
        col3: 'Carl Dela Cruz',
        col4: 'A 254',
        col5: '01/21/2021 09:45AM',
        col6: '3rd',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='dashboardViolationsReportsTable'>
      <TableUI>
        <Table
          responsive
          {...getTableProps()}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                          >
                            <div
                              className={
                                (cell.value === '1st'
                                  ? 'tableUI__status--1st'
                                  : null) ||
                                (cell.value === '2nd'
                                  ? 'tableUI__status--2nd'
                                  : null) ||
                                (cell.value === '3rd'
                                  ? 'tableUI__status--3rd'
                                  : null)
                              }
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default ViolationReportsTable;
