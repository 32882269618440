import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import CalendarForm from './CalendarForm';

function Calendar() {
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Calendar'
                id='Calendar'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Calendar'
                  title='Calendar'
                >
                  <CalendarForm />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default Calendar;
