/* eslint-disable camelcase */
// packages
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

// CSS
import './BuildingListForm.scss';

// Components

// API
import { submitBuilingList } from 'api/request';

// CSS

// Main Component
const BuildingListForm = ({ setRefreshList }) => {
  // States
  const [msg, setMsg] = useState([]);
  const [buildingForm, setBuildingForm] = useState([
    { id: 1, buildingName: '', noOfUnits: '', noOfParkingSlots: '' },
  ]);

  // This is fire on every first render
  useEffect(() => {
    if (buildingForm) {
      // console.log('Rendering...');
    }
  }, []);

  // To Change Value on any Textbox
  const changeValue = (e, building, type) => {
    const newValue = buildingForm.map((arrBuilding) => {
      if (building.id === arrBuilding.id) {
        if (type === 'building') {
          return { ...building, buildingName: e };
        }

        if (type === 'unit') {
          return { ...building, noOfUnits: e };
        }

        if (type === 'parking') {
          return { ...building, noOfParkingSlots: e };
        }
      }

      return arrBuilding;
    });

    setBuildingForm(newValue);
  };

  // To Add Building
  const addBuilding = () => {
    const newID = buildingForm.length + 1;

    const newBuilding = {
      id: newID,
      buildingName: '',
      noOfUnits: '',
      noOfParkingSlots: '',
    };

    setBuildingForm((prevState) => {
      return [...prevState, newBuilding];
    });
  };

  // Submit Violation/s
  const submitBuildingList = () => {
    setMsg([]);

    buildingForm.forEach((building) => {
      if (building.buildingName !== '') {
        const params = {
          name: building.buildingName,
          no_of_units: building.noOfUnits,
          no_of_parking_slots: building.noOfParkingSlots,
        };

        submitBuilingList(params, callback);
      }
    });
  };

  const callback = async (response, params) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      setMsg((prevState) => {
        return [...prevState, { message: `${data.message} (${data.data.attributes.name})`, status: 'success' }];
      });

      setBuildingForm([
        { id: 1, buildingName: '', noOfUnits: '', noOfParkingSlots: '' },
      ]);
    } else if (status === 400 || status === 422) {
      if (data.errors) {
        const { errors } = data;
        if (errors['name']) {
            setMsg((prevState) => {
              return [...prevState, { message: `${errors['name']} (${params.name})`, status: 'failed' }];
            });
        } else if (errors['no_of_parking_slots'] || errors['no_of_units']) {
            setMsg((prevState) => {
              return [...prevState, { message: `Please fill in the empty fields (${params.name})`, status: 'failed' }];
            });
        }
    }
    } else {
      setMsg([{ message: 'Something went wrong', status: 'failed' }]);
      console.log(data);
    }

    setRefreshList(true);
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        {buildingForm &&
          buildingForm.length > 0 &&
          buildingForm.map((building) => {
            return (
              <Row key={building.id}>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={building.id}
                    key={building.id}
                  >
                    <Form.Label>Building Name</Form.Label>

                    <Form.Control
                      aria-label='Building Name'
                      placeholder='Enter Building Name Here'
                      type='text'
                      value={building.buildingName}
                      onChange={(e) =>
                        changeValue(e.target.value, building, 'building')
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={building.id}
                    key={building.id}
                  >
                    <Form.Label>No. of Units</Form.Label>

                    <Form.Control
                      aria-label='No. of Units'
                      placeholder='Enter No. of Units Here'
                      type='number'
                      value={building.noOfUnits}
                      onChange={(e) =>
                        changeValue(e.target.value, building, 'unit')
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={building.id}
                    key={building.id}
                  >
                    <Form.Label>No. of Parking Slots</Form.Label>

                    <Form.Control
                      aria-label='No. of Parking Slots'
                      placeholder='Enter No. of Parking Slots Here'
                      type='number'
                      value={building.noOfParkingSlots}
                      onChange={(e) =>
                        changeValue(e.target.value, building, 'parking')
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            );
          })}

        <Form.Group
          className='buttons add mt-5'
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            className='submit'
            variant='primary'
            onClick={addBuilding}
          >
            Add
          </Button>
        </Form.Group>

        <Form.Group className='buttons pt-5'>
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col className='message-text'>
                {/* {msg && msg.length > 0 && msg.map((data) => {
                  <span style={{ color: hasErrors ? '#ff5c5c' : '#3bd14d' }}>{data}</span>;
                  })
                } */}
                {msg.map((data, idx) => {
                  return <span key={idx} style={{ color: data.status === 'success' ? '#3bd14d' : '#ff5c5c', display: 'block' }}>{`${data.message}`}</span>;
                })}
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  onClick={submitBuildingList}
                >
                  Submit
                </Button>
                <Button
                  className='cancel'
                  variant='default'
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default BuildingListForm;
