// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import FinanceCMSPage from '../pages/FinanceCMS/FinanceCMSPage';
import ExpendituresPage from '../pages/Expenditures/ExpendituresPage';
import CollectionsPage from '../pages/Collections/CollectionsPage';
import StatementOfAccountsPage from '../pages/StatementOfAccounts/StatementOfAccountsPage';
import ArAgingUploadFile from '../pages/FinanceCMS/ArAging/UploadArAgingFile';
import UploadBudgetFile from '../pages/FinanceCMS/Budget/UploadBudgetFile';
import UploadCapexFile from '../pages/FinanceCMS/CAPEX/UploadCapexFile';
import UploadOpexFile from '../pages/FinanceCMS/OPEX/UploadOpexFile';
import ViewExpenditure from '../pages/Expenditures/ViewExpenditure/ViewExpenditure';
import ViewCollectionDetails from '../pages/Collections/ViewCollectionDetails/ViewCollectionDetails';
import ViewStatementAccount from '../pages/StatementOfAccounts/StatementofAccountList/ViewStatementAccount/ViewStatementAccount';
import SummaryCollections from 'pages/SummaryReports/SummaryCollections/SummaryCollections';
import Expenditures from 'pages/SummaryReports/Expenditures/Expenditures';
import CollectionsEfficiency from 'pages/SummaryReports/CollectionsEfficiency/CollectionsEfficiency';
import SummaryBudget from 'pages/SummaryReports/SummaryBudget/SummaryBudget';
import BudgetvsActual from 'pages/ComparativeReports/BudgetvsActual/BudgetvsActual';
import AssociationDuesvsOpex from 'pages/ComparativeReports/AssociationDuesvsOpex/AssociationDuesvsOpex';
import CollectionsvsExpenditure from 'pages/ComparativeReports/CollectionsvsExpenditure/CollectionsvsExpenditure';
import ViewCollectionGraph from 'pages/SummaryReports/SummaryCollections/ViewCollectionGraph/ViewCollectionGraph';

const FinanceRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/finance/finance-cms'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <FinanceCMSPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/finance-cms/ArAgingUploadFile'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ArAgingUploadFile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/finance-cms/BudgetUploadFile'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <UploadBudgetFile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/finance-cms/UploadCapex'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <UploadCapexFile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/finance-cms/UploadOpex'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <UploadOpexFile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/expenditures'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ExpendituresPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/expenditures/view-expenditure'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewExpenditure />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/collections'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <CollectionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/collections/view-collection-details/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewCollectionDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/statement-of-accounts'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <StatementOfAccountsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/statementofAccounts/statementofAccountList/view-statement-account'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewStatementAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/summary-collections'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <SummaryCollections />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/summary-collections/view-graph'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewCollectionGraph />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/expenditures'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Expenditures />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/collection-effieciency'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <CollectionsEfficiency />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/summary-budget'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <SummaryBudget />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/comparativeReports/budget-vs-actual'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <BudgetvsActual />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/comparativeReports/association-dues-vs-opex'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <AssociationDuesvsOpex />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/comparativeReports/collections-vs-expenditures'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <CollectionsvsExpenditure />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/summary-collections'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <SummaryCollections />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/expenditures'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Expenditures />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/collection-efficiency'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <CollectionsEfficiency />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReports/summary-budget'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <SummaryBudget />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/comparativeReport/budget-vs-actual'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <BudgetvsActual />
          </ProtectedRoute>
        }
      />

      <Route
        path='/finance/comparativeReport/association-dues-vs-opex'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <AssociationDuesvsOpex />
          </ProtectedRoute>
        }
      />
      <Route
        path='/finance/summaryReport/collection-vs-expenditure'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <CollectionsvsExpenditure />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default FinanceRouter;
