/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import PetRegistrationForm from './PetRegistrationForm.js/PetRegistrationForm';
import PetRegistrationList from './PetRegistrationList/PetRegistrationList';
import TabsUI from 'components/UI/TabsUI';
import {
  getBuildingLists,
  getPetList,
  petSubmitForm,
  petTypeList,
  userAccounts,
} from 'api/request';

import userContext from 'components/userContext/userContext';
function Pets() {
  const { data, setData } = useContext(userContext);
  const { user, userUnit, units } = data;
  const role = user?.attributes?.role;
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [petType, setPetType] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedName, setSelectedName] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
      petTypeList(petCallback);
    }
  }, []);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      let params;

      if (user.attributes.role === 'owner') {
        params = `?filter[search]=&filter[unit]=${selectedUnit}&filter[role]=tenant`;
      } else {
        params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      }

      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });

      petSubmitForm(submit, submitCallback);
    }
  }, [submit]);

  // selected building
  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `/api/v1/request/pets?building_id=${userUnit?.buildingId}&include=unit,building,user,requestedBy&unit_id=${userUnit?.id}`;

        getPetList(params, requestAmenitiesCallback);
      } else {
        const params = `/api/v1/request/pets?building_id=${selectedBuilding}&include=unit,building,user,requestedBy`;

        getPetList(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `/api/v1/request/pets?building_id=${userUnit?.buildingId}&include=unit,building,user,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}`;

        getPetList(params, requestAmenitiesCallback);
      } else {
        const params = `/api/v1/request/pets?building_id=${selectedBuilding}&include=unit,building,user,requestedBy&filter[search]=${search}`;

        getPetList(params, requestAmenitiesCallback);
      }
    }
  }, [search]);

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `/api/v1/request/pets?building_id=${userUnit?.buildingId}&include=unit,building,user,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}${page}`;

      getPetList(params, requestAmenitiesCallback);
    } else {
      const params = `/api/v1/request/pets?building_id=${selectedBuilding}&include=unit,building,user,requestedBy&filter[search]=${search}${page}`;

      getPetList(params, requestAmenitiesCallback);
    }
  };

  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      setLoading(true);

      const params = `/api/v1/request/pets?building_id=${userUnit?.buildingId}&include=unit,building,user,requestedBy&unit_id=${userUnit?.id}`;

      getPetList(params, requestAmenitiesCallback);
    } else {
      setLoading(true);

      const params = `/api/v1/request/pets?building_id=${selectedBuilding}&include=unit,building,user,requestedBy`;

      getPetList(params, requestAmenitiesCallback);
    }
  };
  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'fail' });
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setSelectedBuilding(data?.data[0]?.id);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'fail' });
      }
    }
  };
  const petCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setPetType(data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'fail' });
      }
    }
  };
  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'fail' });
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'fail' });
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Request - Pets</title>
      </Helmet>
      <Main title='Request - Pets'>
        <Container fluid>
          <Row>
            <Col>
              <TabsUI>
                <Tabs
                  defaultActiveKey='Pet Registration Form'
                  id='Pet Registration Form'
                  className='mb-3 finance-nav'
                >
                  <Tab
                    eventKey='Pet Registration Form'
                    title='Pet Registration Form'
                  >
                    <PetRegistrationForm
                      setSelectedUnit={setSelectedUnit}
                      setSelectedName={setSelectedName}
                      buildings={buildings}
                      names={names}
                      setNames={setNames}
                      setSubmit={setSubmit}
                      msg={msg}
                      user={user}
                      userUnit={userUnit}
                      setMsg={setMsg}
                      petType={petType}
                      units={units}
                    />
                  </Tab>
                  <Tab
                    eventKey='Pet Registration List'
                    title='Pet Registration List'
                  >
                    <PetRegistrationList
                      selectedBuilding={selectedBuilding}
                      user={user}
                      buildings={buildings}
                      setSelectedBuilding={setSelectedBuilding}
                      tableData={tableData}
                      setSearch={setSearch}
                      links={links}
                      meta={meta}
                      selectPage={selectPage}
                    />
                  </Tab>
                </Tabs>
              </TabsUI>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default Pets;
