/* eslint-disable no-unused-vars */
import api from './api';
import token from './token';

const userLogin = (params, callback = null) => {
  api
    .post('api/v1/login', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const forgotPass = (params, callback = null) => {
  api
    .post('api/v1/forgot-password', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const resetPass = (params, token, email, callback = null) => {
  api
    .post(`api/v1/reset-password?token=${token}&email=${email}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const editProfile = (params, callback = null) => {
  const newToken = token();

  api
    .put('/api/v1/profile', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// administration/admin-cms/payment-instructions
const submitPaymentInstruction = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/payment-instruction', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// amenities submit form
const submitAmenitiesForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/amenities', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// amenites get list

const getAmenitiesList = (callback = null) => {
  const newToken = token();

  api
    .get(
      '/api/v1/amenities',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// amenities view
const viewAmenitiesForm = (id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/amenities/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// amenities edit
const editAmenities = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/amenities/${id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// post delete on amenities
const postDeleteAmenities = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/amenities/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const deleteannouncementList = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/announcements/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// Supplier Directory submit form
const submitSupplierDirectoryForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/supplier-directories', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// Supplier Directory get list

const getSupplierDirectoryList = (callback = null) => {
  const newToken = token();

  api
    .get(
      '/api/v1/supplier-directories',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// supplierDirect view
const viewSupplierDirectoryForm = (id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/supplier-directories/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// editSupplierDirectory edit
const editSupplierDirectory = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/supplier-directories/${id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// post delete on directory supplier
const postDeleteSupplierDirectory = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/supplier-directories/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// getlist House Rules
const getlistHouseRules = (callback = null) => {
  const newToken = token();

  api
    .get(
      'api/v1/house_rules',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// house rules submit form
const submitHouseRulesForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/house_rules', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// view emergency directory
const viewEmergencyDirectoryForm = (id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/emergency-directories/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// emergency directory submit form
const submitEmergencyDirectoryForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/emergency-directories', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// Emergency Directory get list
const getEmergencyDirectoryList = (callback = null) => {
  const newToken = token();

  api
    .get(
      '/api/v1/emergency-directories',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// edit emergency directory edit
const editEmergencyDirectory = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/emergency-directories/${id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// post delete on emergency directory supplier
const postEmergencyDirectory = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/supplier-directories/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// administration/admin-cms/payment-instructions get previous payment
const getPaymentInstruction = (callback = null) => {
  const newToken = token();

  api
    .get(
      '/api/v1/payment-instruction',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitViolationClassification = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/violations', params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response, params);
        }
      }
    });
};
const getViolationList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const updateViolationClassification = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/violations/${id}`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response, params);
        }
      }
    });
};

const getViolationClassifications = (callback = null) => {
  const newToken = token();

  api
    .get('/api/v1/violations', {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getViolationClassification = (id, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/violations/${id}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const deleteViolationClassification = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/violations/${id}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitBuilingList = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/buildings', params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response, params);
        }
      }
    });
};

const getBuildingLists = (param, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/buildings${param}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getBuildingList = (id, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/buildings/${id}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const updateBuildingList = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/buildings/${id}`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response, params);
        }
      }
    });
};

const getBuildingUnitDetails = (id, param, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/buildings/${id}/units?${param}include=building,classification,owners,tenants,parkingSlots`,
      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const updateBuildingUnitDetails = (id, params, callback = null) => {
  const newToken = token();

  api
    .patch(`/api/v1/units/${id}`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const updateBuildingUnitOwner = (
  id,
  params,
  callback = null,
  callbackId = null,
) => {
  const newToken = token();

  api
    .post(`/api/v1/units/${id}/owners`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res, callbackId);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response, callbackId);
        }
      }
    });
};

const updateBuildingUnitTenant = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/units/${id}/tenants`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const deleteBuildingUnitTenant = (ownerId, unitId, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/units/${unitId}/tenants/${ownerId}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const deleteBuildingUnitOwner = (ownerId, unitId, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/units/${unitId}/owners/${ownerId}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const updateBuildingUnitParkingSlot = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/units/${id}/parking-slots`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const deleteBuildingUnitParkingSlot = (ownerId, unitId, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/units/${unitId}/parking-slots/${ownerId}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const deleteBuildingUnit = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/units/${id}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getUnitDetails = (id, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/units/${id}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getGlobalNameList = (role, callback = null) => {
  const newToken = token();
  api
    .get(`/api/v1/names?filter[role]=${role}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getparkingSlotNo = (name, unit, callback = null) => {
  const newToken = token();
  api
    .get(`/api/v1/names?filter[search]=${name}&filter[unit]=${unit}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// account management owner submit form
const accountsManagementForm = (params, callback = null) => {
  const newToken = token();

  console.log('TOKEN', newToken);

  api
    .post('/api/v1/accounts', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// account management get list
const getAccountList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// account management update get data
const updateAccountData = (id, callback = null) => {
  const newToken = token();

  console.log('TOKEN', newToken);

  api
    .get(
      `/api/v1/accounts/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// update account management data form
const updateAccountDataList = (id, params, callback = null) => {
  const newToken = token();

  api
    .put(`/api/v1/accounts/${id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const userAccounts = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/names${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// post delete on account management
const deleteAccountManagement = (id, callback = null) => {
  const newToken = token();

  api
    .delete(`/api/v1/accounts/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const submitRequestAmenities = (params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        callback(err.response);
      }
    });
};

const getRequestAmenities = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/amenities${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getRequestAmenitiesByReferenceId = (id, params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/amenities/${id}${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitDenyRequest = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities/${id}/deny`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitApproveRequest = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities/${id}/approve`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const verifyrequestAmenities = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities/${id}/verify`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const submitProofOfPayment = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities/${id}/payment-receive`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 401 ||
          err.response.status === 422 ||
          err.response.status === 500
        ) {
          callback(err.response);
        }
      }
    });
};

const submitRequestConcern = (params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/concerns`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getRequestConcern = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/concerns${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getConcernByReferenceId = (id, params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/concerns/${id}${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitRebook = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/amenities/${id}/rebook`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const cancelRequestBook = (id, callback = null) => {
  const newToken = token();

  api
    .post(
      `/api/v1/request/amenities/${id}/cancel`,
      {},
      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const approveConcern = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/concerns/${id}/approve`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

const denyConcern = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/concerns/${id}/deny`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

// get building list data from building to dropdown
const getBuildingListData = (callback = null) => {
  const newToken = token();

  api
    .get(
      '/api/v1/buildings',

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

const getGatePass = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/gate-pass${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// emergency directory submit form
const moveinMoveout = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/request/moves', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const cancelConcernBook = (id, callback = null) => {
  const newToken = token();

  api
    .post(
      `/api/v1/request/concerns/${id}/cancel`,
      {},
      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const submitGatePass = (params, callback = null) => {
  const newToken = token();
  api
    .post(`/api/v1/request/gate-pass`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getmoveinmoveoutList = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/moves${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const moveinmoveoutApproveForm = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/moves/${id}/approve`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const staffIDapprove = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/staffs/${id}/approve`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const petsApproveDeny = (id, approvedeny, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/pets/${id}/${approvedeny}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const petTypeList = (callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/pets/type/data`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getGatePassByReferenceId = (id, params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/gate-pass/${id}${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const approveGatePass = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/gate-pass/${id}/approve`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};

const denyGatePass = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/gate-pass/${id}/deny`, params, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 422
        ) {
          callback(err.response);
        }
      }
    });
};
// car sticker  directory submit form
const carStickerSubmitForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/request/car-stickers', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const violationSubmitFOrm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/violation-reports', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// car sticker  directory submit form
const petSubmitForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/request/pets', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// move deny submit form directory submit form
const moveDenySubmitForm = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/moves/${id}/deny`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// move deny submit form directory submit form
const carDenySubmitForm = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/car-stickers/${id}/deny`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// getPetliSt get list
const getPetList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// getCarStickerList get list
const getCarStickerList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

// getAnouncementList get list
const getAnouncementList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getIncidentReportList = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// carStickerViewApprove get list
const carStickerViewApprove = (params, callback = null) => {
  const newToken = token();

  api
    .get(
      params,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const carStickerApprove = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/car-stickers/${id}/approve`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// view viewMoveinMoveout directory
const viewMoveinMoveout = (id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/request/moves/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
}; // submitIncidentForm directory submit form
const submitIncidentForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/incident-reports', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const pmoremarksSubmit = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/incident-reports/${id}/pmo-remarks`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const fileViolation = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/incident-reports/${id}/convert-violation`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// submitAnnouncementForm directory submit form
const submitAnnouncementForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/announcements', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const submitStaffIDForm = (params, callback = null) => {
  const newToken = token();

  api
    .post('/api/v1/request/staffs', params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const updateAnnouncement = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/announcements/${id}`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const getstaffIDList = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/staffs${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
// viewStaffIDRequestForm
const viewStaffIDRequestForm = (building, id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/request/staffs?building_id=${building}&include=building&filter[search]=${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const viewPetsForm = (id, callback = null) => {
  const newToken = token();

  api
    .get(
      `/api/v1/request/pets/${id}`,

      {
        headers: {
          Authorization: newToken,
        },
      },
    )
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};
const viewStaffIDDenyForm = (id, params, callback = null) => {
  const newToken = token();

  api
    .post(`/api/v1/request/staffs/${id}/deny`, params, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

export {
  userLogin,
  forgotPass,
  resetPass,
  editProfile,
  submitPaymentInstruction,
  getPaymentInstruction,
  submitAmenitiesForm,
  getAmenitiesList,
  viewAmenitiesForm,
  editAmenities,
  submitViolationClassification,
  getViolationClassifications,
  getViolationClassification,
  updateViolationClassification,
  deleteViolationClassification,
  submitSupplierDirectoryForm,
  getSupplierDirectoryList,
  editSupplierDirectory,
  viewSupplierDirectoryForm,
  postDeleteSupplierDirectory,
  submitEmergencyDirectoryForm,
  getlistHouseRules,
  submitHouseRulesForm,
  getEmergencyDirectoryList,
  postEmergencyDirectory,
  editEmergencyDirectory,
  viewEmergencyDirectoryForm,
  submitBuilingList,
  getBuildingLists,
  getBuildingList,
  updateBuildingList,
  postDeleteAmenities,
  getBuildingUnitDetails,
  updateBuildingUnitDetails,
  updateBuildingUnitOwner,
  updateBuildingUnitTenant,
  deleteBuildingUnitTenant,
  deleteBuildingUnitOwner,
  updateBuildingUnitParkingSlot,
  deleteBuildingUnitParkingSlot,
  deleteBuildingUnit,
  getUnitDetails,
  getGlobalNameList,
  accountsManagementForm,
  getAccountList,
  updateAccountData,
  userAccounts,
  submitRequestAmenities,
  getRequestAmenities,
  getRequestAmenitiesByReferenceId,
  submitDenyRequest,
  submitApproveRequest,
  submitProofOfPayment,
  submitRequestConcern,
  getRequestConcern,
  getConcernByReferenceId,
  submitRebook,
  cancelRequestBook,
  updateAccountDataList,
  deleteAccountManagement,
  approveConcern,
  denyConcern,
  getBuildingListData,
  moveinMoveout,
  getGatePass,
  cancelConcernBook,
  getmoveinmoveoutList,
  submitGatePass,
  getGatePassByReferenceId,
  denyGatePass,
  approveGatePass,
  getparkingSlotNo,
  carStickerSubmitForm,
  viewMoveinMoveout,
  getCarStickerList,
  moveDenySubmitForm,
  submitAnnouncementForm,
  submitStaffIDForm,
  getstaffIDList,
  viewStaffIDRequestForm,
  viewStaffIDDenyForm,
  moveinmoveoutApproveForm,
  carStickerViewApprove,
  carStickerApprove,
  carDenySubmitForm,
  staffIDapprove,
  getPetList,
  petSubmitForm,
  viewPetsForm,
  petsApproveDeny,
  petTypeList,
  getAnouncementList,
  updateAnnouncement,
  deleteannouncementList,
  submitIncidentForm,
  getIncidentReportList,
  pmoremarksSubmit,
  fileViolation,
  violationSubmitFOrm,
  getViolationList,
  verifyrequestAmenities,
};
