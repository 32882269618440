// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import CollectionsFilter from './Filter';
import CollectionsTable from './Table';
import CollectionsPagination from './Pagination';
import CollectionsGraph from './Graph';

// import assets below

// main component
const Collections = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Monthly');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <CollectionsFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && (
        <>
          <CollectionsTable />
          <CollectionsPagination />
        </>
      )}
      {toggle && <CollectionsGraph selected={selectedFilter} />}
    </Fragment>
  );
};

export default Collections;
