// import assets below
import './style.scss';

// main component
const LoginUI = (props) => {
  const { children } = props;
  return <div className='loginUI'>{children}</div>;
};

export default LoginUI;
