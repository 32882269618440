/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Button } from 'react-bootstrap';

// Components
import Main from '../../../../components/Main';
import './ViewRequest.scss';
import { petsApproveDeny, viewPetsForm } from 'api/request';
function ViewRequest() {
  const { id, role } = useParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    home_address: '',
    mobile_no: '---',
    date_of_birth: '',
    gender: '',
    citizenship: '',
    occupation: '',
    contact_person: '',
    contact_no: '',
    id_picture_img: '',
    nbi_clearance_img: '',
    valid_id_img: '',
  });
  useEffect(() => {
    viewPetsForm(id, callback);
  }, []);
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prev) => {
          return {
            ...prev,
            name: data?.data?.attributes?.name,
            type: data?.data?.attributes?.type,
            breed: data?.data?.attributes?.breed,
            color: data?.data?.attributes?.color,
            weight: data?.data?.attributes?.weight,
            height: data?.data?.attributes?.height,
            last_vaccine_date: data?.data?.attributes?.last_vaccine_date,
            last_vaccine_location:
              data?.data?.attributes?.last_vaccine_location,
            user_name: data?.data?.relationships?.user?.attributes?.name,
            account_type: data?.data?.relationships?.user?.attributes?.role,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
          };
        });
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  const approveHandler = (e) => {
    const params = '';
    petsApproveDeny(id, 'approve', params, approvecallback);
  };
  const approvecallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);

        setMsg({
          message: 'Request pet approved successfully.',
          status: 'success',
        });

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Request'
                id='View Request'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View Request'
                  title='View Request'
                >
                  <div className='viewRequest shadow-md'>
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Unit No.*</p>
                        <p>{userData.unitNo}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Name*</p>
                        <p>{userData.user_name}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Account Type</p>
                        <p>{userData.account_type}</p>
                      </div>
                    </Row>
                    <p className='headerInformation'>Pet Information</p>
                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Type of Pet*</p>
                        <p>{userData.type}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Name of Pet*</p>
                        <p>userData.name</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Breed</p>
                        <p>{userData.breed}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Color</p>
                        <p>{userData.color}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Weight</p>
                        <p>{userData.weight}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Height</p>
                        <p>{userData.height}</p>
                      </div>
                    </Row>

                    <p className='headerInformation'>RABIES VACCINE</p>
                    <Row>
                      <div className='col-4'>
                        <Row>
                          <div className='col'>
                            <p className='h5 fw-bold'>Date Last Given</p>
                            <p>{userData.last_vaccine_date}</p>
                          </div>
                        </Row>
                      </div>
                      <div className='col-4'>
                        <Row>
                          <div className='col'>
                            <p className='h5 fw-bold'>Location</p>
                            <p>{userData.last_vaccine_location}</p>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    {role === 'admin' ? (
                      <div className='d-flex justify-content-between'>
                        <div>
                          <p className='approval'>Owner's Approval</p>
                          <p>Approved</p>
                          <div style={{ color: '#3bd14d' }}>{msg.message}</div>
                        </div>
                        <div>
                          <p className='approval'>PMO Action</p>
                          <Button
                            className='btn mx-1'
                            onClick={approveHandler}
                          >
                            Approve
                          </Button>
                          <Link
                            to={`/administration/requests/pets/deny-request/${id}`}
                            className='btn mx-1 bg-danger  px-5 py-3 text-white '
                          >
                            Deny
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex justify-content-end'>
                        <button
                          className='btn shadow-sm'
                          style={{
                            color: '#00B6E9',
                            BackgroundColor: '#ffffff',
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    )}
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default ViewRequest;
