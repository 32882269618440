import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { FaFilter } from 'react-icons/fa';
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Button,
  Dropdown,
} from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import './StateOfAccount.scss';
import SOATable from './SOATable';

function StateOfAccount() {
  return (
    <Fragment>
      <Helmet>
        <title>Finance CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Transaction Details'
                id='Transaction Details'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Transaction Details'
                  title='Transaction Details'
                >
                  <div className='billing'>
                    <Row>
                      <div className='col-6'>
                        <Row>
                          <div className='col-5'>
                            <Form.Group
                              controlId='formSearch'
                              className='mb-5 formSearch'
                            >
                              <Form.Control
                                type='text'
                                placeholder='Search'
                                className='search'
                              />

                              <Button className='searchbtn'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='25.362'
                                  height='25.584'
                                  viewBox='0 0 25.362 25.584'
                                >
                                  <path
                                    id='search'
                                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                                    transform='translate(-336.364 -87.386)'
                                    fill='#c7c7c7'
                                  />
                                </svg>
                              </Button>
                            </Form.Group>
                          </div>
                          <div className='col-2 filter'>
                            <Dropdown>
                              <Dropdown.Toggle>
                                <FaFilter /> Filter
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href='#/action-1'>
                                  Month
                                </Dropdown.Item>
                                <Dropdown.Item href='#/action-2'>
                                  Payment Due
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <SOATable />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default StateOfAccount;
