/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import React, { Fragment, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
// Components

// CSS/SCSS
import './PetTable.scss';

// Main Component
const PetTable = ({ tableData, user }) => {
  // use navigate
  const navigate = useNavigate();
  const role = user?.attributes?.role;
  const [tableList, setTableList] = useState();
  const [loading, setLoading] = useState(true);
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Registration No.',
        accessor: 'registrationNo',
      },
      {
        Header: 'Name of Pet',
        accessor: 'nameofPet',
      },

      {
        Header: 'Pet Type',
        accessor: 'petType',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Account Type',
        accessor: 'accountType',
      },
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );
  // status handler
  const statusHandler = (status) => {
    return (
      <Fragment>
        {status === 'pending' && <p style={{ color: '#D9A121' }}>Pending</p>}
        {status === 'denied' && <p className='text-danger'>Denied</p>}
        {status === 'approved' && (
          <p style={{ color: 'rgb(72, 217, 111)' }}>Approved</p>
        )}
      </Fragment>
    );
  };
  const viewRequest = (reference_id) => {
    return (
      <u
        onClick={() =>
          navigate(
            `/administration/requests/pets/view-request/${reference_id}/${role}`,
          )
        }
        style={{ cursor: 'pointer' }}
      >
        {reference_id}
      </u>
    );
  };
  const accountType = (role) => {
    return <p>{role}</p>;
  };
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='50.348'
            height='14.617'
            viewBox='0 0 50.348 14.617'
            style={{ fill: 'black' }}
          >
            <path
              id='ellipsis-h'
              d='M40.483,191.309A7.309,7.309,0,1,1,33.174,184,7.3,7.3,0,0,1,40.483,191.309ZM51.039,184a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,51.039,184Zm-35.731,0a7.309,7.309,0,1,0,7.309,7.309A7.3,7.3,0,0,0,15.309,184Z'
              transform='translate(-8 -184)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className='text-danger'
            style={{ borderBottom: 'none' }}
            onClick={() => {}}
          >
            Cancel Application
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  // Data of the table
  const data = React.useMemo(() => {
    return tableData.map((data, idx) => {
      return {
        registrationNo: viewRequest(data?.attributes?.reference_id),
        nameofPet: data?.attributes?.name || `Unnamed Pet ${idx}`,
        petType: data?.attributes?.type,
        name: data?.relationships?.user?.attributes?.name,
        accountType: accountType(data?.relationships?.user?.attributes?.role),
        unitNo: data?.relationships?.unit?.attributes?.unit_number,
        status: statusHandler(data?.attributes?.status),
        action: renderAction(data?.attributes?.reference_id),
      };
    });
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='pet-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => {
                      // Apply the header cell props
                      if (role === 'admin' && column.Header === 'Action') {
                        return;
                      }
                      return (
                        <th
                          key={idx}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        if (
                          role === 'admin' &&
                          cell.column.Header === 'Action'
                        ) {
                          return;
                        }
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PetTable;
