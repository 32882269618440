// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import CollectionEfficiencyFilter from './Filter';
import CollectionEfficiencyTable from './Table';
import CollectionEfficiencyGraph from './Graph';

// import assets below
import './style.scss';

// main component
const CollectionEfficiency = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <CollectionEfficiencyFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && <CollectionEfficiencyTable />}
      {toggle && <CollectionEfficiencyGraph selected={selectedFilter} />}
    </Fragment>
  );
};

export default CollectionEfficiency;
