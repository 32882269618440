/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

// CSS
import './ServiceProviderForm.scss';

// Components

// API

// CSS

// Main Component
const ServiceProviderForm = () => {
  // States
  const [data, setData] = useState({
    documentName: '',
    serviceProvider: '',
    periodCovered: '',
    renewalDate: '',
    amount: '',
  });

  // Useform
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  // handle Submit Form
  const onSubmit = (e) => {
    console.log(e);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Name of Document{' '}
                {errors.documentName ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.documentName.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                className='readonly-cell'
                aria-label='Document Name'
                type='text'
                {...register('documentName', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, documentName: event.target.value };
                    });
                  },
                })}
                value={data.documentName}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Service Provider{' '}
                {errors.serviceProvider ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.serviceProvider.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                className='readonly-cell'
                aria-label='Service Provider'
                type='text'
                {...register('serviceProvider', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        serviceProvider: event.target.value,
                      };
                    });
                  },
                })}
                value={data.serviceProvider}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Period Covered{' '}
                {errors.periodCovered ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.periodCovered.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Period Covered'
                type='date'
                {...register('periodCovered', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, periodCovered: event.target.value };
                    });
                  },
                })}
                value={data.periodCovered}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Label>
                Renewal Date{' '}
                {errors.renewalDate ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.renewalDate.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Renewal Date'
                type='date'
                {...register('renewalDate', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, renewalDate: event.target.value };
                    });
                  },
                })}
                value={data.renewalDate}
              />
          </Col>
          <Col>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>
                Amount{' '}
                {errors.amount ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.amount.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Amount'
                type='number'
                {...register('amount', {
                  required: '(Required)',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, amount: event.target.value };
                    });
                  },
                })}
                value={data.amount}
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      {showResponseAPI}
    </Card>
  );
};

export default ServiceProviderForm;
