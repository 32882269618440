// import core & vendor packages
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../components/UI/TabsUI';

// import assets below
import './Tabs.scss';
import Logo from '../../components/SVG/Logo';
import Phone from '../../components/SVG/Phone';
import Building from '../../components/SVG/Building';
import At from '../../components/SVG/At';

// main component
const ContactUsTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='contactUs'
        className='mb-3'
      >
        <Tab
          eventKey='contactUs'
          title='Contact Us'
        >
          <div className='contactUs'>
            <div className='contactUs__header'>
              {Logo()}
              <div>
                <span>Condo</span>
                <span>Corp</span>
              </div>
            </div>
            <div className='contactUs__body'>
              <div className='contactUs__info'>
                <div className='contactUs__wrapper'>
                  {Phone()}
                  <div>
                    <span>+63927-123-4567</span>
                    <span>+63927-123-4567</span>
                  </div>
                </div>
              </div>
              <div className='contactUs__info'>
                <div className='contactUs__wrapper'>
                  {Building()}
                  <div>
                    <span>
                      104 Tordesillas, Makati, <br /> 1227 Metro Manila
                    </span>
                  </div>
                </div>
              </div>
              <div className='contactUs__info'>
                <div className='contactUs__wrapper'>
                  {At()}
                  <div>
                    <span>condocorp@email.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default ContactUsTabs;
