// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import DashboardPage from '../pages/Dashboard';
import AdministrativeReportsPage from '../pages/Dashboard/AdministrativeReport';
import FinanceReportsPage from '../pages/Dashboard/FinanceReport';
import EngineeringReportsPage from '../pages/Dashboard/EngineeringReport';
import DashboardRequestsPage from '../pages/Dashboard/Requests';
import PaymentsDuePage from '../pages/Dashboard/PaymentsDue';
import DashboardAnnouncementsPage from '../pages/Dashboard/Announcements';
import BuildingDirectoryPage from '../pages/Dashboard/BuildingDirectory';
import SuppliersDirectoryPage from '../pages/Dashboard/SuppliersDirectory';

const DashboardRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/dashboard'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/administrative-reports'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <AdministrativeReportsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/finance-reports'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <FinanceReportsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/engineering-reports'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EngineeringReportsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/requests'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <DashboardRequestsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/payments-due'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <PaymentsDuePage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/announcements'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <DashboardAnnouncementsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/building-directory'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <BuildingDirectoryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/suppliers-directory'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <SuppliersDirectoryPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default DashboardRouter;
