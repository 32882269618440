// import core and vendor packages below
import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { Table, Dropdown, Modal, Button } from 'react-bootstrap';

// import components below
import TableUI from 'components/UI/TableUI';

// import style below
import './Table.scss';

// main component
const WorkPermitTable = ({ tableData, user, deleteSingleListData, msg }) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  const role = user?.attributes?.role;

  const columns = useMemo(
    () => [
      {
        Header: 'Work Permit No.',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Unit No.',
        accessor: 'col2',
      },
      {
        Header: 'Work Type',
        accessor: 'col3',
      },
      {
        Header: 'Date and Time',
        accessor: 'col4',
      },
      {
        Header: 'Status',
        accessor: 'col5',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };

  useEffect(() => {
    if (msg && msg.message === 'Work permit cancel successfully.') {
      setStoreId('');
      handleClose();
    }
  }, [msg]);

  // To return view/delete action
  const renderAction = (id, status) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            onClick={() => handleShow(id)}
            style={{ color: `${status !== 'pending' ? '#bdadad' : '#CB1212'}` }}
            disabled={status !== 'pending' ? true : ''}
          >
            Cancel Application
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const data = useMemo(() => {
    if (tableData && tableData.length > 0) {
      const newData = tableData.map((list) => {
        return {
          col1: list?.attributes?.reference_id,
          col2: list?.relationships?.unit?.attributes?.unit_number,
          col3: list?.attributes?.type || 'N/A',
          col4: list?.attributes
            ? `${list?.attributes?.period_covered_from}`
            : '',
          col5: list?.attributes?.status,
          action: renderAction(
            list?.attributes?.reference_id,
            list?.attributes?.status,
          ),
        };
      });

      return newData;
    } else {
      return [];
    }
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <div className='workPermitTable'>
        <TableUI>
          <Table
            responsive
            {...getTableProps()}
          >
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup, index) => (
                  // Apply the header row props
                  <tr
                    key={index}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => {
                        if (column.Header === 'Action' && role === 'admin') {
                          return;
                        }

                        if (
                          (column.Header === 'Name' ||
                            column.Header === 'Account Type' ||
                            column.Header === 'Unit No.') &&
                          (role === 'owner' || role === 'tenant')
                        ) {
                          return;
                        }

                        return (
                          <th
                            key={index}
                            {...column.getHeaderProps()}
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </th>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row, index) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      key={index}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props

                          if (
                            cell.column.Header === 'Action' &&
                            role === 'admin'
                          ) {
                            return;
                          }

                          if (
                            (cell.column.Header === 'Name' ||
                              cell.column.Header === 'Account Type' ||
                              cell.column.Header === 'Unit No.') &&
                            (role === 'owner' || role === 'tenant')
                          ) {
                            return;
                          }

                          return (
                            <td
                              key={index}
                              {...cell.getCellProps()}
                              className={
                                (cell.value === 'resolved'
                                  ? 'tableUI__status--resolved'
                                  : null) ||
                                (cell.value === 'denied'
                                  ? 'tableUI__status--denied'
                                  : null) ||
                                (cell.value === 'approved'
                                  ? 'tableUI__status--resolved'
                                  : null) ||
                                (cell.value === 'canceled'
                                  ? 'tableUI__status--canceled'
                                  : null) ||
                                (cell.value === 'pending'
                                  ? 'tableUI__status--pending'
                                  : null)
                              }
                              style={{ textTransform: 'capitalize' }}
                            >
                              {cell.column.Header === 'Work Permit No.' ? (
                                <Link
                                  to={`/administration/requests/work-permit/view-request/${cell.value}?activeKey=requests`}
                                >
                                  {cell.render('Cell')}
                                </Link>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </TableUI>
      </div>

      <Modal
        show={confirmationDelete}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this ?</Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => deleteSingleListData(storeId)}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WorkPermitTable;
