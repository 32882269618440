// Packages
import { useState } from 'react';
import { Link } from 'react-router-dom';

// Components

// API

// CSS
import './DashboardCard.scss';

const DashboardCard = (props) => {
  const { color, link, tabSelect, title, icon } = props;

  const [report, setReport] = useState('administrative-reports');

  const reportChangeHandler = (event) => {
    setReport(event.target.value);
  };

  return (
    <div
      className='dashboard_card'
      style={{ background: color }}
    >
      <Link
        className='card__link'
        to={link === '/dashboard' ? link + '/' + report : link}
      ></Link>
      <div className='card__header'>
        {tabSelect && (
          <div className='wrapper'>
            <select
              className='form-select'
              value={report}
              onChange={(event) => reportChangeHandler(event)}
            >
              <option
                value='administrative-reports'
                default
              >
                Administrative
              </option>
              <option value='finance-reports'>Finance</option>
              <option value='engineering-reports'>Engineering</option>
            </select>
          </div>
        )}
        <div>
          <div></div>
        </div>
      </div>
      <div className='card__body'>
        <span className='card__title'>{title}</span>
        {icon}
      </div>
    </div>
  );
};

export default DashboardCard;
