// import core and vendor packages below
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../../../../components/UI/TableUI';

// main component
const TableLeft = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'col1',
      },
      {
        Header: 'Category',
        accessor: 'col2',
      },
      {
        Header: 'Sub-Category',
        accessor: 'col3',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'OpEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'OpEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'OpEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'OpEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: 'CapEx',
        col2: '* insert data *',
        col3: '* insert data *',
      },
      {
        col1: '',
        col2: '',
        col3: 'Total',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <TableUI>
      <Table {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      key={index}
                      rowSpan='2'
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  key={index}
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    </TableUI>
  );
};

export default TableLeft;
