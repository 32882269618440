/* eslint-disable react/destructuring-assignment */
// Packages
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Initialize Localizer
const localizer = momentLocalizer(moment);

// Main Component
const GovCalendar = (props) => {
  // Get data from props
  const { apiData } = props;

  // Convert raw api data to calendar data
  const myEventsList = apiData.map((data) => {
    return {
      start: new Date(data.date),
      end: new Date(data.date),
      title: data.document,
    };
  });

  // Custom Toolbar
  const CustomToolbar = (toolbar) => {
    // Prev Button
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    // Next Button
    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    // Label
    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          <b>{date.format('MMMM')}</b>
          <span> {date.format('YYYY')}</span>
        </span>
      );
    };

    // Set selected month
    const selectMonth = (e) => {
      const now = new Date();
      const selected = new Date(`${e} 1, ${now.getFullYear()}`);

      toolbar.date.setMonth(selected.getMonth());
      toolbar.date.setYear(selected.getFullYear());
      toolbar.onNavigate();
    };

    // To return toolbar
    return (
      <div className='rbc-toolbar'>
        <label className='rbc-toolbar-label'>{label()}</label>

        <div className='rbc-btn-group'>
          <button
            className='btn-back'
            onClick={goToBack}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8.444'
              height='14.889'
              viewBox='0 0 8.444 14.889'
            >
              <path
                id='caret-left'
                d='M31.782,108.3v12.883a1,1,0,0,1-1.709.708l-6.441-6.441a1,1,0,0,1,0-1.416l6.441-6.441A1,1,0,0,1,31.782,108.3Z'
                transform='translate(-23.339 -107.298)'
                fill='#fff'
              />
            </svg>
          </button>
          <button
            className='btn-next'
            onClick={goToNext}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8.444'
              height='14.889'
              viewBox='0 0 8.444 14.889'
            >
              <path
                id='caret-right'
                d='M0,121.184V108.3a1,1,0,0,1,1.709-.708l6.441,6.441a1,1,0,0,1,0,1.416l-6.441,6.441A1,1,0,0,1,0,121.184Z'
                transform='translate(0 -107.298)'
                fill='#fff'
              />
            </svg>
          </button>

          <select
            className='select-month'
            defaultValue=''
            onChange={(e) => selectMonth(e.target.value)}
          >
            <option
              value=''
              hidden
            >
              Month
            </option>
            <option value='Jan'>January</option>
            <option value='Feb'>February</option>
            <option value='Mar'>March</option>
            <option value='Apr'>April</option>
            <option value='May'>May</option>
            <option value='Jun'>June</option>
            <option value='Jul'>July</option>
            <option value='Aug'>August</option>
            <option value='Sep'>September</option>
            <option value='Oct'>October</option>
            <option value='Nov'>November</option>
            <option value='Dec'>December</option>
          </select>
        </div>
      </div>
    );
  };

  // To render on screen
  return (
    <Calendar
      localizer={localizer}
      events={myEventsList}
      startAccessor='start'
      endAccessor='end'
      style={{ height: 800 }}
      views={{ month: true }}
      components={{
        toolbar: CustomToolbar,
      }}
    />
  );
};

export default GovCalendar;
