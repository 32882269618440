// import core & vendor packages below
import { Fragment } from 'react';

// import components below
import ARAgingFilterModal from './FilterModal';
import ARAgingTable from './Table';

// main component
const ARAging = () => {
  return (
    <Fragment>
      <ARAgingFilterModal />
      <ARAgingTable />
    </Fragment>
  );
};

export default ARAging;
