/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import FormUI from 'components/UI/FormUI';
import { useForm, Controller } from 'react-hook-form';
import './PetRegistrationForm.scss';
import Select from 'react-select';

function PetRegistrationForm({
  buildings,
  setSelectedUnit,
  setSelectedName,
  names,
  setSubmit,
  msg,
  user,
  setMsg,
  userUnit,
  petType,
  units,
}) {
  const role = user.attributes.role;
  const [unitId, setUnitId] = useState();
  const [submitData, setSubmitData] = useState({
    name: '',
    type: '',
    breed: '',
    color: '',
    weight: '',
    height: '',
    last_vaccine_date: '',
    last_vaccine_location: '',
    unit_id: '',
    user_id: '',
  });
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
  });
  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const getNames = (e) => {
    setSelectedUnit(e);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const params = {
      name: submitData.name,
      type: submitData.type,
      breed: submitData.breed,
      color: submitData.color,
      weight: submitData.weight,
      height: submitData.height,
      last_vaccine_date: submitData.last_vaccine_date,
      last_vaccine_location: submitData.last_vaccine_location,
      unit_id: role === 'admin' ? submitData.unit_id.id : units[0]?.id,
      user_id: role === 'admin' ? submitData.user_id.id : user?.id,
    };

    setSubmit(params);
  };
  useEffect(() => {
    if (role === 'owner') {
      getNames(userUnit.id);
    }
  }, [role]);
  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);
  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  useEffect(() => {
    if (msg.message === 'Request Pet created successfully.') {
      setValue('name', '');
      setValue('type', '');
      setValue('breed', '');
      setValue('color', '');
      setValue('weight', '');
      setValue('height', '');
      setValue('last_vaccine_date', '');
      setValue('last_vaccine_location', '');
      setValue('unit_id', '');
      setValue('user_id', '');
      setSubmitData({
        name: '',
        type: '',
        breed: '',
        color: '',
        weight: '',
        height: '',
        last_vaccine_date: '',
        last_vaccine_location: '',
        unit_id: '',
        user_id: '',
      });
    }
  }, [msg]);
  // eslint-disable-next-line no-unused-vars
  const [value, onChange] = useState(new Date());
  return (
    <div className='petRegistrationForm'>
      <FormUI>
        <Form
          className=''
          onSubmit={onSubmit}
        >
          {role === 'admin' && (
            <Fragment>
              {' '}
              <Row>
                <div className='col'>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Building Name
                      {errors.building ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.building.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={data.buildings}
                          placeholder='Select Building'
                          styles={customStyles}
                          {...field}
                        ></Select>
                      )}
                      {...register('building', {
                        required: '(Required)',
                        value: submitData.building,
                        onChange: (event) => {
                          setSubmitData((prevState) => {
                            return {
                              ...prevState,
                              building: event.target.value,
                            };
                          });

                          setUnitId(event.target.value.id);
                        },
                      })}
                    />
                  </Form.Group>
                </div>
                <div className='col'>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Unit No.
                      {errors.unit ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.unit.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={data.units}
                          placeholder='Select Unit'
                          styles={customStyles}
                          {...field}
                        ></Select>
                      )}
                      {...register('unit_id', {
                        required: '(Required)',
                        value: submitData.unit_id,
                        onChange: (event) => {
                          getNames(event.target.value.id);
                          setSubmitData((prevState) => {
                            return {
                              ...prevState,
                              unit_id: event.target.value,
                            };
                          });
                        },
                      })}
                    />
                  </Form.Group>
                </div>
                <div className='col'>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Name{' '}
                      {errors.user_id ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.user_id.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={data.names}
                          placeholder='Select User'
                          styles={customStyles}
                          {...field}
                        ></Select>
                      )}
                      {...register('user_id', {
                        required: '(Required)',
                        value: submitData.user_id,
                        onChange: (event) => {
                          setSubmitData((prevState) => {
                            setSelectedName(event.target.value.label);

                            return {
                              ...prevState,
                              user_id: event.target.value,
                            };
                          });
                        },
                      })}
                    />
                  </Form.Group>
                </div>
              </Row>
              <Row>
                <div className='col'>
                  <Form.Label>Account Type</Form.Label>
                  <h5 style={{ margin: '0 0 1rem' }}>Tenant</h5>
                </div>
              </Row>
              <h3>Pet Information</h3>
            </Fragment>
          )}

          <Row>
            <div className='col'>
              <Form.Label>
                Type of Pet*{' '}
                {errors.type ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.type.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='formGender'
              >
                <Form.Select
                  {...register('type', {
                    required: '(Required)',
                    value: submitData.type,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          type: event.target.value,
                        };
                      });
                    },
                  })}
                >
                  <option
                    hidden
                    value=''
                  >
                    Select Pet Type
                  </option>
                  {petType &&
                    petType.map((data) => {
                      return (
                        <option
                          key={data.value}
                          value={data.value}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  {!petType && <option value=''>none</option>}
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Label>Name of Pet</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('name', {
                    value: submitData.name,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          name: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Label>Breed</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='breed'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Breed Here'
                  {...register('breed', {
                    value: submitData.breed,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          breed: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
          </Row>
          <Row>
            <div className='col'>
              <Form.Label>Color</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='color'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Color Here'
                  {...register('color', {
                    value: submitData.color,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          color: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Label>Weight</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='weight'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Weight Here'
                  {...register('weight', {
                    value: submitData.weight,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          weight: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Label>Height</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='height'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Height Here'
                  {...register('height', {
                    value: submitData.height,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          height: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
          </Row>

          <h3>RABIES VACCINE</h3>
          <Row>
            <div className='col-lg-4'>
              <Form.Label>Date Last Given</Form.Label>
              <div>
                <Form.Control
                  className='date'
                  type='date'
                  {...register('last_vaccine_date', {
                    required: '(Required)',
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          last_vaccine_date: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Location</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='location'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Location Here'
                  {...register('last_vaccine_location', {
                    value: submitData.last_vaccine_location,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          last_vaccine_location: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
          </Row>
          <div className='d-flex justify-content-between'>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
            <Form.Group
              className='buttons text-end'
              style={{ justifyContent: 'end' }}
            >
              <Button
                className='submit mx-1'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel shadow-sm mx-1 '
                style={{ color: '#00B6E9' }}
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </div>
        </Form>
      </FormUI>
    </div>
  );
}

export default PetRegistrationForm;
