// import core and vendor packages below
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../../../../components/UI/TableUI';

// import assets below
import './style.scss';

// main component
const CollectionEfficiencyTableLeft = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Month and Year',
        accessor: 'col1',
      },
      {
        Header: 'Billed',
        accessor: 'col2',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: 'Jan-21',
        col2: '* insert data *',
      },
      {
        col1: 'Feb-21',
        col2: '* insert data *',
      },
      {
        col1: 'Mar-21',
        col2: '* insert data *',
      },
      {
        col1: 'Apr-21',
        col2: '* insert data *',
      },
      {
        col1: 'May-21',
        col2: '* insert data *',
      },
      {
        col1: 'Jun-21',
        col2: '* insert data *',
      },
      {
        col1: 'Jul-21',
        col2: '* insert data *',
      },
      {
        col1: 'Aug-21',
        col2: '* insert data *',
      },
      {
        col1: 'Sep-21',
        col2: '* insert data *',
      },
      {
        col1: 'Oct-21',
        col2: '* insert data *',
      },
      {
        col1: 'Nov-21',
        col2: '* insert data *',
      },
      {
        col1: 'Dec-21',
        col2: '* insert data *',
      },
      {
        col1: '',
        col2: 'Total',
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='dashboardCollectionEfficiencyTable tableOne'>
      <TableUI>
        <Table {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr
                  key={null}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(() => (
                      // Apply the header cell props
                      <th key={null}></th>
                    ))
                  }
                </tr>
              ))
            }
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        rowSpan='2'
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default CollectionEfficiencyTableLeft;
