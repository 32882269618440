/* eslint-disable */
import React, { Component, KeyboardEventHandler } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface State {
  readonly inputValue: string;
  readonly value: readonly Option[];
  readonly loading: Boolean;
}

type Props = {
  value: any;
  setData: (e: any) => void;
  className: string;
  inputName: string;
  setUpdateMsg: (e: any) => void;
};

export default class CreatableInputOnly extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: '',
      value: [],
      loading: true,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevState !== this.state) {
      this.props.setData((prevState: any) => {
        return {...prevState, [`${this.props.inputName}`]: this.state.value}
      })
    }

    if(prevProps !== this.props) {
      if(this.state.value.length === 0 && this.state.loading === true) {
        this.setState({
          value: this.props.value,
          loading: false,
        })
      }
    }
  }

  handleChange = (
    value: OnChangeValue<Option, true>,
    actionMeta: ActionMeta<Option>
  ) => {
    this.setState({ value });

    this.props.setData((prevState: any) => {
      return { ...prevState, [`${this.props.inputName}`]: value };
    });
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let checkDuplicate = value.find(o => o.value === inputValue);

        if(!checkDuplicate) {
          this.setState({
            inputValue: '',
            value: [...value, createOption(inputValue)],
          });

        } else {
          this.props.setUpdateMsg({message: 'Name exists', status: 'faled'});

          this.setState({
            inputValue: '',
            value: [...value],
          });
        }

        event.preventDefault();
    }
  };

  render() {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type something and press enter..."
        value={value}
        className={this.props.className}
      />
    );
  }
}