/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// CSS
import './ViewAnnouncementContent.scss';
import {
  getAnouncementList,
  getBuildingLists,
  updateAnnouncement,
} from 'api/request';

import Select from 'react-select';
// Components

// API

// CSS

// Main Component
const ViewAnnouncementContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [submit, setSubmit] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [userData, setUserData] = useState({
    building: '',
    recipient: '',
    entity: '',
    subject: '',
    content: '',
    image: '',
    store_image: [],
  });
  const [data, setData] = useState({
    buildings: [],
  });

  const recipientList = [
    {
      id: 1,
      value: 'All',
    },
    {
      id: 2,
      value: 'Owner',
    },
    {
      id: 3,
      value: 'Tenant',
    },
  ];

  const onSubmit = () => {
    const newFormData = new FormData();

    newFormData.append('_method', 'PUT');
    newFormData.append('building', userData.building.id);
    newFormData.append('recipient', userData.recipient.toLowerCase());
    newFormData.append('entity', userData.entity);
    newFormData.append('subject', userData.subject);
    newFormData.append('content', userData.content);
    newFormData.append('image', userData.image);

    setSubmit(newFormData);
  };

  useEffect(() => {
    const param = `/api/v1/announcements/${id}`;
    getAnouncementList(param, callback);
    if (buildings.length === 0) {
      getBuildingLists('?include=units', buildingListCallback);
    }

    getBuildingLists('?include=units', buildingListCallback);
  }, []);
  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);
  useEffect(() => {
    if (submit) {
      setMsg({ message: '', status: '' });
      updateAnnouncement(id, submit, submitCallback);
    }
  }, [submit]);
  useEffect(() => {
    if (msg.message === 'Announcement updated successfully.') {
      setValue('building', '');
      setValue('recipient', '');
      setValue('entity', '');
      setValue('subject', '');
      setValue('content', '');
      setValue('image', '');

      setUserData({
        building: '',
        recipient: '',
        entity: '',
        subject: '',
        content: '',
        images: '',
      });
    }
  }, [msg]);
  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({
          message: 'Announcement updated successfully.',
          status: 'success',
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prev) => {
          return {
            ...prev,
            building: {
              id: data?.data?.relationships?.building?.id,
              value: data?.data?.attributes?.building,
              label: data?.data?.attributes?.building,
            },
            recipient: data?.data?.attributes?.recipient,
            entity: data?.data?.attributes?.entity,
            content: data?.data?.attributes?.content,
            store_image: data?.data?.relationships?.image,
            subject: data?.data?.attributes?.subject,
          };
        });
      }

      setValue('recipient', data?.data?.attributes?.recipient);
      setValue('entity', data?.data?.attributes?.entity);
      setValue('content', data?.data?.attributes?.content);
      setValue('subject', data?.data?.attributes?.subject);
      setValue('image', '');
      setValue('building', {
        id: data?.data?.attributes?.id,
        value: data?.data?.attributes?.building,
        label: data?.data?.attributes?.building,
      });

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };
  // To render on screen
  return (
    <Card className='admin-form announcement shadow-sm'>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        encType='multipart/form-data'
      >
        <Row>
          <Col md={3}>
            <Form.Group className='mb-5'>
              <Form.Label>
                Building Name
                {errors.building ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.building.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    value={userData.building.id}
                    options={data.buildings}
                    placeholder='Select Building'
                    styles={customStyles}
                    {...field}
                  ></Select>
                )}
                {...register('building', {
                  required: '(Required)',
                  value: userData.building,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        building: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formRecipient'
            >
              <Form.Label>Recipient</Form.Label>
              <Form.Select
                aria-label='Select Title'
                {...register('recipient', {
                  required: '(Required)',
                  value: userData.recipient,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        recipient: event.target.value,
                      };
                    });
                  },
                })}
              >
                <option
                  value=''
                  hidden
                >
                  Select Recipient Here
                </option>
                {recipientList.map((val) => {
                  return (
                    <option
                      key={val.id}
                      value={val.value.toLowerCase()}
                    >
                      {val.value}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEntity'
            >
              <Form.Label>Entity</Form.Label>
              <Form.Control
                aria-label='Enter Entity Here'
                placeholder='Enter Entity Here'
                {...register('entity', {
                  required: '(Required)',
                  value: userData.entity,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        entity: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formSubject'
            >
              <Form.Label>Subject</Form.Label>
              <Form.Control
                aria-label='Enter Subject Here'
                placeholder='Enter Subject Here'
                {...register('subject', {
                  required: '(Required)',
                  value: userData.subject,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        subject: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formUpload'
            >
              <Form.Label>
                Upload Image{' '}
                {errors.car_sticker_image ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.car_sticker_image.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>

              <div className='upload-cont image-upload'>
                <div className='upload-input'>
                  <label htmlFor='formUpload'>Select file</label>

                  <Form.Control
                    type='file'
                    className='custom-file-upload'
                    aria-label='Click to Select File to Upload'
                    {...register('image', {
                      onChange: (e) => {
                        // eslint-disable-next-line prefer-const
                        let docuArr = [];

                        Array.from(e.target.files).forEach((file) => {
                          docuArr.push(file);
                        });

                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            image: e.target.files[0],
                            store_image: null,
                          };
                        });
                      },
                    })}
                    accept='image/*'
                  ></Form.Control>
                </div>
                {userData.store_image && (
                  <Fragment>
                    <div
                      onClick={() => {
                        enlargePhoto(userData.store_image.url);
                      }}
                      style={{
                        backgroundImage: `url('${userData.store_image.url}')`,
                        backgroundRepeat: 'no-repeat',
                        height: '50px',
                        width: '50px',
                      }}
                      className='image-cont'
                    ></div>
                    <span>{userData.store_image.name}</span>
                  </Fragment>
                )}
                {userData.image && <span>{userData.image.name}</span>}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formContent'
            >
              <Form.Label>Content</Form.Label>
              <Form.Control
                as='textarea'
                rows={10}
                placeholder='Enter Content Here'
                {...register('content', {
                  required: '(Required)',
                  value: userData.content,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        content: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel'
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ViewAnnouncementContent;
