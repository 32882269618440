/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
// CSS
import './IncidentReportForm.scss';

// Main Component
const IncidentReportForm = ({
  setSubmit,
  msg,
  buildings,
  setSelectedBuilding,
  selectedBuilding,
}) => {
  const [userData, setUserData] = useState({
    subject: '',
    date: '',
    summary: '',
    action_taken: '',
    recommendation: '',
    prepared_by: '',
    pmo_remarks: '',
    building_id: '',
    image: [],
  });
  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const onSubmit = () => {
    const newFormData = new FormData();
    if (!userData.image) {
      setError('image', {
        type: 'custom',
        message: 'Please Select Image',
      });

      return;
    }
    newFormData.append('building_id', userData.building_id);
    newFormData.append('subject', userData.subject);
    newFormData.append('date', userData.date);
    newFormData.append('summary', userData.summary);
    newFormData.append('action_taken', userData.action_taken);
    newFormData.append('recommendation', userData.recommendation);
    newFormData.append('prepared_by', userData.prepared_by);
    newFormData.append('pmo_remarks', userData.pmo_remarks);
    newFormData.append('image', userData.image);

    setSubmit(newFormData);
  };
  useEffect(() => {
    setSubmit(null);

    setSelectedBuilding(null);
  }, []);
  useEffect(() => {
    if (msg.message === 'Incident Report created successfully.') {
      setValue('subject', 'all');
      setValue('date', '');
      setValue('summary', '');
      setValue('action_taken', '');
      setValue('recommendation', '');
      setValue('prepared_by', '');
      setValue('pmo_remarks', '');
      setValue('image', '');

      setUserData({
        subject: 'all',
        date: '',
        summary: '',
        action_taken: '',
        recommendation: '',
        prepared_by: '',
        pmo_remarks: '',
        image: [],
      });
    }
  }, [msg]);
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formBuilding'
            >
              <Form.Label>Building</Form.Label>
              <Form.Select
                {...register('building', {
                  required: '(Required)',
                  value: userData.building,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        building_id: event.target.value,
                      };
                    });
                  },
                })}
              >
                <option value='all'>All</option>
                {buildings &&
                  buildings.length > 0 &&
                  buildings.map((list, idx) => {
                    if (selectedBuilding === '' && idx === 0) {
                      setSelectedBuilding(list.id);
                    }
                    return (
                      <option
                        key={list.id}
                        value={list.id}
                      >
                        {list?.attributes?.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formSubject'
            >
              <Form.Label>Subject</Form.Label>
              <Form.Control
                aria-label='Enter Subject Here'
                placeholder='Enter Subject Here'
                {...register('subject', {
                  required: '(Required)',
                  value: userData.subject,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        subject: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formDate'
            >
              <Form.Label>Date</Form.Label>
              <Form.Control
                type='date'
                aria-label='Enter Date'
                placeholder='Enter Date'
                {...register('date', {
                  required: '(Required)',
                  value: userData.date,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        date: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='float-start'>
              <Form.Group
                className='mb-2'
                controlId='formUpload'
              >
                <Form.Label>
                  Image
                  {errors.image ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.image.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>

                <div className='upload-cont image-upload'>
                  <div className='upload-input'>
                    <label htmlFor='formUpload'>Select file</label>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      {...register('image', {
                        value: userData.image,
                        onChange: (e) => {
                          // eslint-disable-next-line prefer-const
                          let docuArr = [];

                          Array.from(e.target.files).forEach((file) => {
                            docuArr.push(file);
                          });

                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              image: e.target.files[0],
                            };
                          });
                        },
                      })}
                      accept='image/*'
                    ></Form.Control>
                  </div>

                  <span>
                    {userData.image && (
                      <Fragment>
                        {userData.image.length !== 0 && (
                          <span>1 File Selected</span>
                        )}
                        {!userData.image && <span>No Images Uploaded</span>}
                      </Fragment>
                    )}
                  </span>
                </div>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formIncidentSummary'
            >
              <Form.Label>Incident Summary</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder='Enter Summary Here'
                {...register('summary', {
                  required: '(Required)',
                  value: userData.summary,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        summary: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formActionTaken'
            >
              <Form.Label>Action Taken</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder='Enter Action Here'
                {...register('action_taken', {
                  required: '(Required)',
                  value: userData.action_taken,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        action_taken: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formRecommendation'
            >
              <Form.Label>Recommendation</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder='Enter Recommendation Here'
                {...register('recommendation', {
                  required: '(Required)',
                  value: userData.recommendation,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        recommendation: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formPreparedBy'
            >
              <Form.Label>Prepared By:</Form.Label>
              <Form.Control
                aria-label='Enter Full Name Here'
                placeholder='Enter Full Name Here'
                {...register('prepared_by', {
                  required: '(Required)',
                  value: userData.prepared_by,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        prepared_by: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel'
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default IncidentReportForm;
