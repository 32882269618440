const Home = () => {
  return (
    <svg viewBox='0 0 30.862 24'>
      <path
        id='home'
        d='M15.007,38.276,5.129,46.412v8.781a.857.857,0,0,0,.857.857l6-.016a.857.857,0,0,0,.853-.857V50.049a.857.857,0,0,1,.857-.857h3.429a.857.857,0,0,1,.857.857v5.124a.857.857,0,0,0,.857.86l6,.017a.857.857,0,0,0,.857-.857V46.406l-9.876-8.13a.653.653,0,0,0-.82,0Zm15.6,5.53-4.479-3.692V32.693a.643.643,0,0,0-.643-.643h-3a.643.643,0,0,0-.643.643v3.89l-4.8-3.946a2.572,2.572,0,0,0-3.268,0L.218,43.806a.643.643,0,0,0-.086.905L1.5,46.372a.643.643,0,0,0,.906.087l12.6-10.38a.653.653,0,0,1,.82,0l12.6,10.38a.643.643,0,0,0,.905-.086L30.7,44.713a.643.643,0,0,0-.091-.907Z'
        transform='translate(0.015 -32.05)'
      />
    </svg>
  );
};

export default Home;
