// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tab, Tabs, Col } from 'react-bootstrap';

// Components
import Main from '../../components/Main';

// API

// CSS
import './EquipmentStatusPage.scss';
import EquipmentStatus from './EquipmentStatus';

const EquipmentStatusPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Equipment Status</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            {' '}
            <Col>
              <Tabs
                defaultActiveKey='Equipment Status'
                id='Equipment Status'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Equipment Status'
                  title='Equipment Status'
                >
                  <EquipmentStatus />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EquipmentStatusPage;
