// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row } from 'react-bootstrap';

// Components
import Main from '../../components/Main';

// API

// CSS
import './ContractsPage.scss';

const ContractsPage = () => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Fragment>
      <Helmet>
        <title>Contracts</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row></Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ContractsPage;
