/* eslint-disable react/no-unescaped-entities */
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import './ViewExpenditure.scss';

function ViewExpenditure() {
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View '
                id='View '
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View '
                  title='View '
                >
                  <div className='viewRequest shadow-md'>
                    <Row>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Category</p>
                        <p>Security and Safety Equipment</p>
                      </div>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Reference #</p>
                        <p>0001</p>
                      </div>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Payee</p>
                        <p>CondoCorp</p>
                      </div>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Type</p>
                        <p>C</p>
                      </div>
                    </Row>

                    <Row className='mt-5'>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Date</p>
                        <p>01/01/2021</p>
                      </div>
                      <div className='col-lg-6'>
                        <p className='h5 fw-bold'>Particulars</p>
                        <p>
                          Staff Salaries and Benefits for the month of January
                          2021.
                        </p>
                      </div>
                      <div className='col-lg-3'>
                        <p className='h5 fw-bold'>Amount</p>
                        <p>10,000.00</p>
                      </div>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default ViewExpenditure;
