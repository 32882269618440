/* eslint-disable no-unused-vars */
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import './ViewCollectionGraph.scss';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Components
import Main from '../../../../components/Main';
function ViewCollectionGraph() {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Collections</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Collections'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Collections'
                title='Collections'
              >
                <div
                  className=' graph-container shadow-lg'
                  style={{ height: '500px', width: 'auto' }}
                >
                  <ResponsiveContainer
                    width='100%'
                    height='100%'
                  >
                    <BarChart
                      width={500}
                      height={300}
                      data={data}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barGap={5}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey='pv'
                        fill='#8884d8'
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default ViewCollectionGraph;
