/* eslint-disable no-unused-vars */
import React from 'react';
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab, Form, Button } from 'react-bootstrap';
// scss
import './EditSchedule.scss';
// Components
import Main from '../../../../../components/Main';
function EditSchedule() {
  // date
  const [value, onChange] = useState(new Date());
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Schedule'
                id='Schedule'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Schedule'
                  title='Schedule'
                >
                  <div className='editschedule'>
                    <h6 className='fw-bold'>MAINTENANCE SCHEDULE</h6>

                    <Row className='my-4'>
                      <div className='col-lg-4'>
                        <h5 className='fw-bold mb-4'>Equipment</h5>
                        <p>Transfer Pump</p>
                      </div>
                      <div className='col-lg-4'>
                        <h5 className='fw-bold mb-4'>Serial No.</h5>
                        <p>0010</p>
                      </div>
                      <div className='col-lg-4'>
                        <h5 className='fw-bold mb-4'>Assigned Services</h5>
                        <Form.Group
                          className='mb-5'
                          controlId='assigned'
                        >
                          <Form.Select
                            aria-label='Select Assigned Here'
                            defaultValue=''
                          >
                            <option
                              hidden
                              value=''
                            >
                              Select Assigned Here
                            </option>
                            <option>1</option>
                            <option>2</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className='col-lg-4'>
                        <h5 className='fw-bold mb-4'>Schedule</h5>
                        <div>
                          <input
                            placeholder='dada'
                            className='date'
                            type='date'
                            onChange={(e) => onChange(e.target.value)}
                          />
                        </div>
                        <div className='mt-4'>
                          <a className='dailybutton'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='31'
                              height='31'
                              viewBox='0 0 31 31'
                            >
                              <path
                                id='icons8_today_3'
                                d='M11.417,6A5.436,5.436,0,0,0,6,11.417V30.583A5.436,5.436,0,0,0,11.417,36H30.583A5.436,5.436,0,0,0,36,30.583V11.417A5.436,5.436,0,0,0,30.583,6Zm0,2.5H30.583A2.9,2.9,0,0,1,33.5,11.417v1.25H8.5v-1.25A2.9,2.9,0,0,1,11.417,8.5ZM8.5,15.167h25V30.583A2.9,2.9,0,0,1,30.583,33.5H11.417A2.9,2.9,0,0,1,8.5,30.583Zm18.784,3.32a1.25,1.25,0,0,0-.964.426L19.7,26.267l-3.234-3.234A1.25,1.25,0,1,0,14.7,24.8l4.167,4.167a1.25,1.25,0,0,0,1.813-.047l7.5-8.333a1.25,1.25,0,0,0-.9-2.1Z'
                                transform='translate(-5.5 -5.5)'
                                fill='#fff'
                                stroke='#fff'
                                strokeWidth='1'
                              />
                            </svg>
                            Daily
                          </a>
                        </div>
                      </div>
                    </Row>

                    <Form.Group
                      className='buttons text-end'
                      style={{ justifyContent: 'end' }}
                    >
                      <Button
                        className='submit mx-1'
                        variant='primary'
                        type='submit'
                      >
                        Submit
                      </Button>
                      <Button
                        className='cancel shadow-sm mx-1 '
                        style={{ color: '#00B6E9' }}
                        variant='default'
                      >
                        Cancel
                      </Button>
                    </Form.Group>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default EditSchedule;
