const User = () => {
  return (
    <svg viewBox='0 0 20.521 23.453'>
      <path
        id='user'
        d='M10.261,11.727A5.863,5.863,0,1,0,4.4,5.863,5.863,5.863,0,0,0,10.261,11.727Zm4.1,1.466H13.6a7.974,7.974,0,0,1-6.679,0H6.156A6.158,6.158,0,0,0,0,19.349v1.906a2.2,2.2,0,0,0,2.2,2.2H18.323a2.2,2.2,0,0,0,2.2-2.2V19.349A6.158,6.158,0,0,0,14.365,13.192Z'
      />
    </svg>
  );
};

export default User;
