/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { submitEmergencyDirectoryForm } from '../../../../api/request';

// CSS
import './EmergencyDirectoryForm.scss';

// Components

// API

// CSS

// Main Component
const EmergencyDirectoryForm = () => {
  const [entity, setEntity] = useState('');
  const [contact, setContact] = useState('');
  const [service, setService] = useState('');
  // response api here
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  // handle Submit Form
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataSubmit = {
      entity: entity,
      contact_number: contact,
      service: service,
    };
    console.log(dataSubmit);
    submitEmergencyDirectoryForm(dataSubmit, callback);
  };
  // Handle Response from api's
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
        setEntity('');
        setContact('');
        setService('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  const cleanForm = () => {
    setEntity('');
    setContact('');
    setService('');
  };
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className='mb-5'
          controlId='formTitle'
        >
          <Form.Label>Entity</Form.Label>
          <Form.Control
            aria-label='Enter Entity'
            placeholder='Enter Entity'
            value={entity}
            onChange={(e) => setEntity(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-5'
          controlId='formTitle'
        >
          <Form.Label>Contact No.</Form.Label>
          <Form.Control
            aria-label='Enter Contact No.'
            placeholder='Enter Contact No.'
            value={contact}
            type='text'
            maxLength={11}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setContact(e.target.value);
              }
            }}
          />
        </Form.Group>

        <Form.Group
          className='mb-5'
          controlId='formContent'
        >
          <Form.Label>Service</Form.Label>
          <Form.Control
            aria-label='Enter Service'
            as='textarea'
            rows={10}
            value={service}
            placeholder='Enter Service'
            onChange={(e) => setService(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
            onClick={cleanForm}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      {showResponseAPI}
    </Card>
  );
};

export default EmergencyDirectoryForm;
