// import core & vendor packages below
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// import components below
import Main from 'components/Main';
import ViewBookingTabs from './Tabs';

// main component
const ViewBooking = () => {
  return (
    <Fragment>
      <Helmet>
        <title>View Booking</title>
      </Helmet>
      <Main title='Requests - Amenities'>
        <Container fluid>
          <Row>
            <Col>
              <ViewBookingTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ViewBooking;
