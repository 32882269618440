const Badge = () => {
  return (
    <svg viewBox='0 0 17.25 23'>
      <path
        id='id-badge'
        d='M15.094,0H2.156A2.157,2.157,0,0,0,0,2.156V20.844A2.157,2.157,0,0,0,2.156,23H15.094a2.157,2.157,0,0,0,2.156-2.156V2.156A2.157,2.157,0,0,0,15.094,0ZM6.469,1.437h4.312a.719.719,0,0,1,0,1.437H6.469a.719.719,0,1,1,0-1.437Zm2.156,5.75A2.875,2.875,0,1,1,5.75,10.062,2.878,2.878,0,0,1,8.625,7.187Zm5.031,10.637a.942.942,0,0,1-1.006.862H4.6a.942.942,0,0,1-1.006-.862v-.862a2.829,2.829,0,0,1,3.019-2.587h.225a4.627,4.627,0,0,0,3.576,0h.225a2.829,2.829,0,0,1,3.019,2.587Z'
      />
    </svg>
  );
};

export default Badge;
