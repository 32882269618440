// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import BuildingDirectoryTabs from './Tabs';

// CSS
import './style.scss';

const BuildingDirectoryPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Building Directory</title>
      </Helmet>
      <Main title='Building Directory'>
        <Container fluid>
          <Row>
            <Col>
              <BuildingDirectoryTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default BuildingDirectoryPage;
