/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { submitSupplierDirectoryForm } from '../../../../api/request';

// CSS
import './SuppliersDirectoryForm.scss';

// Components

// API

// CSS

// Main Component
const SuppliersDirectoryForm = () => {
  // Static titles
  const titles = [
    {
      id: 1,
      title: 'Administration',
    },
    {
      id: 2,
      title: 'Finance',
    },
    {
      id: 3,
      title: 'Engineering',
    },
    {
      id: 4,
      title: 'Others',
    },
  ];

  const [category, setCategory] = useState(titles[0].title);
  const [entity, setEntity] = useState('');
  const [service, setService] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  // handle Submit Form
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataSubmit = {
      category: category,
      entity: entity,
      service: service,
      contact_number: contact,
      email: email,
    };
    submitSupplierDirectoryForm(dataSubmit, callback);
  };
  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
        if (data.message === 'Supplier directory created successfully.') {
          setEntity('');
          setService('');
          setContact('');
          setEmail('');
        }
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  const cleanForm = () => {
    setCategory('');
    setEntity('');
    setContact('');
    setService('');
    setEmail('');
  };
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className='mb-4'
          controlId='formAmenity'
        >
          <Form.Label>Category</Form.Label>
          <Form.Select
            value={category}
            onChange={(e) => {
              return setCategory(e.target.value);
            }}
          >
            {titles.map((val) => {
              return (
                <option
                  key={val.id}
                  value={val.title}
                >
                  {val.title}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group
          className='mb-4'
          controlId='formTitle'
        >
          <Form.Label>Entity</Form.Label>
          <Form.Control
            aria-label='Enter Entity'
            type='text'
            placeholder='Enter Entity'
            value={entity}
            onChange={(e) => setEntity(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-4'
          controlId='formContent'
        >
          <Form.Label>Service</Form.Label>
          <Form.Control
            aria-label='Enter Service'
            as='textarea'
            rows={10}
            value={service}
            placeholder='Enter Service'
            onChange={(e) => setService(e.target.value)}
          />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formTitle'
            >
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                aria-label='Enter Contact No.'
                value={contact}
                placeholder='Enter Contact No.'
                type='text'
                maxLength={11}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setContact(e.target.value);
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formTitle'
            >
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                aria-label='Enter Email Address'
                type='email'
                value={email}
                placeholder='Enter Email Address'
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
            onClick={cleanForm}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      {showResponseAPI}
    </Card>
  );
};

export default SuppliersDirectoryForm;
