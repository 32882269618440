/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './IncidentReportListTable.scss';

// Main Component
const IncidentReportListTable = ({ tableData, deleteSingleList }) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  // modal show confirmation delete
  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };
  // To return view/delete action
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            as={Link}
            to={`/engineering/incident-report/view-incident-report/${id}`}
          >
            View/Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            href='#/action-2'
            style={{ color: '#CB1212' }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const referenceID = (reference_id, id) => {
    return (
      <Link
        className='text-dark'
        to={`/security/incident-report/view-incident-report/${id}`}
      >
        {reference_id}
      </Link>
    );
  };
  const status = (status) => {
    return;
  };
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'IR No.',
        accessor: 'irno',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Prepared By',
        accessor: 'preparedby',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      // },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's Assume this data is from API

    return tableData.map((data) => {
      return {
        irno: referenceID(data?.attributes?.reference_id, data?.attributes?.id),
        subject: data?.attributes?.subject,
        preparedby: data?.attributes?.prepared_by,
        date: data?.attributes?.date,
        status: data?.attributes?.status,
        // action: renderAction(data?.attributes?.id),
      };
    });
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /**
     * canPreviousPage,
     * canNextPage,
     * pageOptions,
     * pageCount,
     * gotoPage,
     * nextPage,
     * previousPage,
     * setPageSize,
     * state: { pageIndex, pageSize },
     */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <table
      {...getTableProps()}
      className='admin-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup, index) => (
            // Apply the header row props
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column, index) => (
                  // Apply the header cell props
                  <th
                    key={index}
                    {...column.getHeaderProps()}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                key={index}
                {...row.getRowProps()}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell, index) => {
                    if (cell.column.Header === 'Status') {
                      const statusvalue = cell.value;
                      `${cell.value === 'resolved' ? 'green' : 'yellow'}`;
                      return (
                        <td
                          key={index}
                          style={{
                            color:
                              cell.value === 'resolve' ? '#45C33B' : '#EAB82B',
                          }}
                        >
                          {statusvalue.charAt(0).toUpperCase() +
                            statusvalue.slice(1)}
                        </td>
                      );
                    }
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default IncidentReportListTable;
