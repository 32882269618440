// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components

import Main from '../../../../components/Main/';
import ViewEditContent from './ViewEditContent';

// API

// CSS

// Main Component
const ViewAmenities = () => {
  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>View Supplier Directory</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View-Edit-Form'
                id='amenities-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='View-Edit-Form'
                  title='View Edit Form'
                >
                  <ViewEditContent />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ViewAmenities;
