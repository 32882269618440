/* eslint-disable no-unused-vars */
// Packages
import React, { useState, Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';

// Components

// CSS/SCSS
import './RemoveAccessTable.scss';

// Main Component
const RemoveAccessTable = ({ tableData, deleteSingleList }) => {
  // To return view/delete action
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  const renderAction = (id) => {
    return (
      <Button
        className='remove-btn'
        onClick={() => handleShow(id)}
      >
        Remove
      </Button>
    );
  };
  // modal show confirmation delete
  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit No.',
        accessor: 'unit',
      },
      {
        Header: 'Account Type',
        accessor: 'accounttype',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Mobile No.',
        accessor: 'contact',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );
  const unitList = (id) => {
    return (
      <Fragment>
        {typeof id === 'object' && (
          <p>
            {id.map((data) => {
              return <p key={data.id}>{data.attributes?.unit_number}</p>;
            })}
          </p>
        )}
        {typeof id !== 'object' && <p>{id}</p>}
      </Fragment>
    );
  };
  // Data of the table
  const data = React.useMemo(() => {
    const reconstructedList = tableData.map((role) => {
      return {
        unit: unitList(
          role?.relationships?.tenant?.relationships?.unit?.attributes
            ?.unit_number || role?.relationships?.units,
        ),
        accounttype: role?.attributes?.role,
        name: role?.attributes?.name,
        email: role?.attributes?.email,
        contact: role?.attributes?.mobile_number,
        action: renderAction(role?.id),
      };
    });
    return reconstructedList;
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <>
      <Modal
        show={confirmationDelete}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              setConfirmationDelete(false);
              deleteSingleList(storeId);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <table
        {...getTableProps()}
        className='admin-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.name}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      key={column.name}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  key={row.name}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={cell.name}
                          style={{ textAlign: 'left' }}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  );
};

export default RemoveAccessTable;
