const Add = () => {
  return (
    <svg viewBox='0 0 30 30'>
      <path
        id='plus'
        d='M27.857,43.786H18.214V34.143A2.143,2.143,0,0,0,16.071,32H13.929a2.143,2.143,0,0,0-2.143,2.143v9.643H2.143A2.143,2.143,0,0,0,0,45.929v2.143a2.143,2.143,0,0,0,2.143,2.143h9.643v9.643A2.143,2.143,0,0,0,13.929,62h2.143a2.143,2.143,0,0,0,2.143-2.143V50.214h9.643A2.143,2.143,0,0,0,30,48.071V45.929A2.143,2.143,0,0,0,27.857,43.786Z'
        transform='translate(0 -32)'
      />
    </svg>
  );
};

export default Add;
