/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  editAmenities,
  submitAmenitiesForm,
  viewAmenitiesForm,
} from '../../../../api/request';
import { useParams, useNavigate } from 'react-router-dom';

// CSS
import './ViewAmenitiesContent.scss';

// Components

// API

// CSS

// Main Component
const ViewAmenitiesContent = () => {
  const nav = useNavigate();
  // get id on URL
  const { id } = useParams();
  // useState
  const [Amenity, setAmenity] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [rules, setRules] = useState('');
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  // modal show
  const [showModal, setshowModal] = useState(false);
  const handleClose = () => {
    setshowModal(false);
  };
  const handleShow = () => setshowModal(true);
  // useEffect
  useEffect(() => {
    viewAmenitiesForm(id, callback);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataSubmit = {
      name: Amenity,
      price: price,
      description: description,
      rules: rules,
    };
    console.log(dataSubmit);
    editAmenities(id, dataSubmit, callbackUpdate);
  };
  // Handle submit Response from api
  const callbackUpdate = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setAmenity(data.data.attributes.name);
        setPrice(data.data.attributes.price);
        setDescription(data.data.attributes.description);
        setMsg('');
        if (data.message === 'Amenity updated successfully.') {
          handleShow();
        }
      } else if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data);
        setAmenity(data.data.attributes.name);
        setPrice(data.data.attributes.price);
        setDescription(data.data.attributes.description);

        setRules(data.data.attributes.rules);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };

  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  const clearForm = () => {
    setAmenity('');
    setPrice('');
    setPrice('');
    setDescription('');
    setRules('');
  };
  // To render on screen
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Amenity updated successfully.</Modal.Title>
        </Modal.Header>
        <Modal.Body>You want to Go back to List ? </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant='primary'
            onClick={() => nav('/administration/admin-cms/amenities')}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className='admin-form shadow-sm'>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            className='mb-4'
            controlId='formAmenity'
          >
            <Form.Label>Amenity</Form.Label>
            <Form.Control
              value={Amenity}
              type='text'
              onChange={(e) => setAmenity(e.target.value)}
            />
          </Form.Group>

          <Form.Group
            className='mb-4'
            controlId='formPrice'
          >
            <Form.Label>Price</Form.Label>
            <Form.Control
              type='number'
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group
            className='mb-4'
            controlId='formDescription'
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              name='description'
              as='textarea'
              rows={7}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group
            className='mb-5'
            controlId='formRules'
          >
            <Form.Label>Rules and Regulations</Form.Label>
            <Form.Control
              name='rules'
              as='textarea'
              rows={7}
              value={rules}
              onChange={(e) => setRules(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='buttons'>
            <Button
              className='submit'
              variant='primary'
              type='submit'
            >
              Edit
            </Button>
            <Button
              className='cancel'
              variant='default'
              onClick={clearForm}
            >
              Cancel
            </Button>
          </Form.Group>
        </Form>

        {showResponseAPI}
      </Card>
    </>
  );
};

export default ViewAmenitiesContent;
