/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// Components
import TabsUI from '../../../components/UI/TabsUI';
import ConcernForm from './Form';
import ConcernsFilter from './Filter';
import ConcernsTable from './Table';
import ConcernsPagination from './Pagination';
import userContext from 'components/userContext/userContext';

// API
import {
  getBuildingLists,
  userAccounts,
  submitRequestConcern,
  getRequestConcern,
  cancelConcernBook,
} from 'api/request';

const ConcernsTabs = () => {
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState({});
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      setLoading(true);
      const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}${page}`;

      getRequestConcern(params, requestAmenitiesCallback);
    } else {
      setLoading(true);
      const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,requestedBy${page}&filter[search]=${search}`;

      getRequestConcern(params, requestAmenitiesCallback);
    }
  };

  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      setLoading(true);
      const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,requestedBy&unit_id=${userUnit?.id}`;

      getRequestConcern(params, requestAmenitiesCallback);
    } else {
      setLoading(true);
      const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,requestedBy`;

      getRequestConcern(params, requestAmenitiesCallback);
    }
  };

  const deleteSingleListData = (storeId) => {
    cancelConcernBook(storeId, cancelCallback);
  };

  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        setLoading(true);
        const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,requestedBy&unit_id=${userUnit?.id}`;

        getRequestConcern(params, requestAmenitiesCallback);
      } else {
        setLoading(true);
        const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,requestedBy`;

        getRequestConcern(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        setLoading(true);
        const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,requestedBy&filter[search]=${search}&unit_id=${userUnit?.id}`;

        getRequestConcern(params, requestAmenitiesCallback);
      } else {
        setLoading(true);
        const params = `?building_id=${selectedBuilding}&include=user,building,unit,user&filter[search]=${search}`;

        getRequestConcern(params, requestAmenitiesCallback);
      }
    }
  }, [search]);

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
  }, []);

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      submitRequestConcern(submit, submitCallback);
    }
  }, [submit]);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      const params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setSelectedBuilding(data?.data[0]?.id);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const cancelCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        refreshTable();
        setMsg({ message: data.message, status: 'success' });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='concernsList'
        className='mb-3'
      >
        {!role === 'bod' && (
          <Tab
            eventKey='concernForms'
            title='Concern Forms'
          >
            {loading && <p>Loading...</p>}
            {!loading && (
              <ConcernForm
                buildings={buildings}
                setSelectedUnit={setSelectedUnit}
                names={names}
                setSubmit={setSubmit}
                msg={msg}
                user={user}
                userUnit={userUnit}
              />
            )}
          </Tab>
        )}
        <Tab
          eventKey='concernsList'
          title='Concerns List'
        >
          <ConcernsFilter
            setSearch={setSearch}
            buildings={buildings}
            setSelectedBuilding={setSelectedBuilding}
            selectedBuilding={selectedBuilding}
            user={user}
            userUnit={userUnit}
          />
          {loading && <p>Loading</p>}
          {!loading && (
            <>
              <ConcernsTable
                tableData={tableData}
                user={user}
                refreshTable={refreshTable}
                deleteSingleListData={deleteSingleListData}
                msg={msg}
              />
              <ConcernsPagination
                links={links}
                meta={meta}
                selectPage={selectPage}
                msg={msg}
              />
            </>
          )}
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default ConcernsTabs;
