// Packages
import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import BuildingListForm from './BuildingForm/BuildingListForm';
import BuildingListContent from './BuildingInfo/BuildingListContent';
import UnitForm from './UnitForm/UnitForm';
import UnitList from './UnitList/UnitList';

// API

// CSS
import './BuildingListPage.scss';

// Main Component
const BuildingListPage = () => {
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const handleSelect = (e) => {
    setActiveKey(e);
  };

  useEffect(() => {
    setActiveKey('Building Form');
  }, []);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Building List</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Building Form'
                id='violations-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='Building Form'
                  title='Building Form'
                >
                  <BuildingListForm setRefreshList={setRefreshList} />
                </Tab>
                <Tab
                  eventKey='Building Info'
                  title='Building Info'
                >
                  <BuildingListContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
                <Tab
                  eventKey='Unit Form'
                  title='Unit Form'
                >
                  <UnitForm
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                    handleSelect={handleSelect}
                  />
                </Tab>
                <Tab
                  eventKey='Unit List'
                  title='Unit List'
                >
                  <UnitList
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default BuildingListPage;
