import { Fragment, useState } from 'react';

import { Helmet } from 'react-helmet';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
// import components below
import Filter from './Filter/Filter';

// Components
import Main from '../../../components/Main';
import SummaryBudgetList from './SummaryBudgetList/SummaryBudgetList';

function SummaryBudget() {
  const [selectedFilter, setSelectedFilter] = useState('All');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };
  return (
    <Fragment>
      <Helmet>
        <title>Budget</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Budget'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Budget'
                title='Budget'
              >
                <Filter
                  selected={selectedFilter}
                  onSelect={onSelectFilter}
                />
                <SummaryBudgetList />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default SummaryBudget;
