// import core & vendor packages
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Nav, Accordion } from 'react-bootstrap';
// import assets below
import ChartPie from '../../SVG/ChartPie';
import ListAlt from '../../SVG/ListAlt';
import CalendarCheck from '../../SVG/CalendarCheck';

// main component
const Finance = (props) => {
  const { pathname, activeKey, bodOnly, adminOnly } = props;
  console.log(activeKey);
  return (
    <Fragment>
      <Nav.Item>
        <span>Finance</span>
      </Nav.Item>
      {adminOnly && (
        <Fragment>
          <Nav.Item className={pathname === '/finance/finance-cms' && 'active'}>
            <div className='svg__wrapper'>{ChartPie()}</div>
            <Link
              className='nav-link'
              to='/finance/finance-cms'
            >
              Finance CMS
            </Link>
          </Nav.Item>
          <Nav.Item
            className={pathname === '/finance/expenditures' && 'active'}
          >
            <div className='svg__wrapper'>{ListAlt()}</div>
            <Link
              className='nav-link'
              to='/finance/expenditures'
            >
              Expenditures
            </Link>
          </Nav.Item>
          <Nav.Item className={pathname === '/finance/collections' && 'active'}>
            <div className='svg__wrapper'>{ListAlt()}</div>
            <Link
              className='nav-link'
              to='/finance/collections'
            >
              Collections
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/finance/statement-of-accounts' && 'active'
            }
          >
            <div className='svg__wrapper'>{CalendarCheck()}</div>
            <Link
              className='nav-link'
              to='/finance/statement-of-accounts'
            >
              Statement of Accounts
            </Link>
          </Nav.Item>
        </Fragment>
      )}
      {bodOnly && (
        <Fragment>
          <Accordion defaultActiveKey={activeKey}>
            <Accordion.Item eventKey='summary-reports'>
              <Accordion.Header>
                <div className='svg__wrapper'>{ListAlt()}</div>
                <Nav.Link
                  className='nav-link'
                  href='#'
                >
                  Summary Reports
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Item
                  className={
                    pathname ===
                      '/finance/summaryReports/summary-collections' && 'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/summaryReports/summary-collections?activeKey=summary-reports'
                  >
                    Collections
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname === '/finance/summaryReports/expenditures' &&
                    'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/summaryReports/expenditures?activeKey=summary-reports'
                  >
                    Expenditures
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname ===
                      '/finance/summaryReports/collection-effieciency' &&
                    'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/summaryReports/collection-effieciency?activeKey=summary-reports'
                  >
                    Collection Efficiency
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname === '/finance/summaryReports/summary-budget' &&
                    'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/summaryReports/summary-budget?activeKey=summary-reports'
                  >
                    Budget
                  </Link>
                </Nav.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey={activeKey}>
            <Accordion.Item eventKey='comparative-reports'>
              <Accordion.Header>
                <div className='svg__wrapper'>{ListAlt()}</div>
                <Nav.Link
                  className='nav-link'
                  href='#'
                >
                  Comparative Reports
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Item
                  className={
                    pathname ===
                      '/finance/comparativeReport/budget-vs-actual' && 'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/comparativeReport/budget-vs-actual?activeKey=comparative-reports'
                  >
                    Budget vs. Actual
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname ===
                      '/finance/comparativeReport/association-dues-vs-opex' &&
                    'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/comparativeReport/association-dues-vs-opex??activeKey=comparative-reports'
                  >
                    Association Dues vs. OPEX
                  </Link>
                </Nav.Item>
                <Nav.Item
                  className={
                    pathname ===
                      '/finance/summaryReport/collection-vs-expenditure' &&
                    'active'
                  }
                >
                  <div className='svg__wrapper'>{ListAlt()}</div>
                  <Link
                    className='nav-link'
                    to='/finance/summaryReport/collection-vs-expenditure?activeKey=comparative-reports'
                  >
                    Collections vs. Expenditures
                  </Link>
                </Nav.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Finance;
