// Packages
import React, { Fragment, useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';

// CSS
import './AccountForm.scss';

// Components
import ManagementForm from './ManagementForm';

// API

// CSS

// Main Component
const AccountForm = ({ refreshTable }) => {
  // States
  const [stateAccount, setStateAccount] = useState('Owner');

  // Static Roles
  const titles = [
    {
      id: 1,
      title: 'Owner',
    },
    {
      id: 2,
      title: 'Tenant',
    },
    {
      id: 3,
      title: 'Finance',
    },
    {
      id: 4,
      title: 'Engineering',
    },
    {
      id: 5,
      title: 'Security',
    },
    {
      id: 6,
      title: 'BOD',
    },
  ];

  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={2}>
            <Form.Group
              className='mb-5'
              controlId='formAccount'
            >
              <Form.Select
                aria-label='Select Account'
                onChange={(e) => setStateAccount(e.target.value)}
              >
                {titles.map((val) => {
                  return <option key={val.id}>{val.title}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Card className='admin-form shadow-sm'>
        <ManagementForm
          stateAccount={stateAccount}
          setStateAccount={setStateAccount}
          refreshTable={refreshTable}
        />
      </Card>
    </>
  );
};

export default AccountForm;
