/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// Components

import Main from '../../../components/Main';
import userContext from '../../../components/userContext/userContext';
// API

import { getPaymentInstruction, submitPaymentInstruction } from '../../../api/request';
// CSS
import './PaymentInstructions.scss';

// Main Component
const PaymentInstructions = () => {
  // To render on screen

  // useContext Data
  const { data, setData } = useContext(userContext);
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  // useform
  // state
  const [inputPayment, setInputPayment] = useState({ payment: '' });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // get previous payment
  useEffect(() => {
    console.log(data.user.id);

    getPaymentInstruction(getpaymentcallback);
  }, []);

  const getpaymentcallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setInputPayment({ payment: data.data.attributes.description });

        console.log(data.data.attributes.description);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  // on submit form

  const onSubmit = (submitdata) => {
    console.log(submitdata);
    const params = { description: inputPayment.payment };
    console.log(params);

    setSuccess('');
    setMsg('');
    submitPaymentInstruction(params, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        console.log(data.data);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Amenities</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Card className='admin-form shadow-sm'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className='mb-5'
                    controlId='formPayment'
                  >
                    <Form.Label>Payment Instructions</Form.Label>

                    <Form.Control
                      as='textarea'
                      placeholder='Enter Payment Instructions Here'
                      rows='10'
                      value={inputPayment.payment}
                      {...register('payment', {
                        onChange: (event) => {
                          setInputPayment((prevState) => {
                            return { ...prevState, payment: event.target.value };
                          });
                        },
                      })}
                    ></Form.Control>
                  </Form.Group>
                  <Row>
                    <div className='col-lg-6  pt-5'>
                      <p className='error'>{msg}</p>
                      <p style={{ color: '#3bd14d' }}>{success}</p>
                    </div>
                    <div className='col-lg-6'>
                      <Form.Group className='buttons pt-5'>
                        <Button
                          className='submit'
                          variant='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                        <Button
                          className='cancel'
                          variant='default'
                        >
                          Cancel
                        </Button>
                      </Form.Group>
                    </div>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default PaymentInstructions;