// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import ExpendituresFilter from './FilterModal';
import ExpendituresTable from './Table';
import ExpendituresGraph from './Graph';

// main component
const Expenditures = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <Fragment>
      <ExpendituresFilter
        toggle={toggle}
        onToggle={setToggle}
      />
      {!toggle && <ExpendituresTable />}
      {toggle && <ExpendituresGraph />}
    </Fragment>
  );
};

export default Expenditures;
