// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import EngineeringCMSPage from '../pages/EngineeringCMS/EngineeringCMSPage';
import EquipmentInventoryPage from '../pages/EquipmentInventory/EquipmentInventoryPage';
import EditDetails from '../pages/EquipmentInventory/EditDetails/EditDetails';
import ViewDetails from '../pages/EquipmentInventory/ViewDetails/ViewDetails';
import EquipmentStatusPage from '../pages/EquipmentStatus/EquipmentStatusPage';
import Calendar from '../pages/MaintenancePlanner/Calendar/Calendar';
import Schedule from '../pages/MaintenancePlanner/Schedule/Schedule';
import EditSchedule from '../pages/MaintenancePlanner/Schedule/ScheduleList/EditSchedule/EditSchedule';

const FinanceRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/engineering/engineering-cms'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EngineeringCMSPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/equipment-status'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EquipmentStatusPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/equipment-inventory'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EquipmentInventoryPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/equipment-inventory/edit-details/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EditDetails />
          </ProtectedRoute>
        }
        ConcernsPage
      />
      <Route
        path='/engineering/equipment-inventory/view-details/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/maintenance-planner/calendar'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Calendar />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/maintenance-planner/schedule'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <Schedule />
          </ProtectedRoute>
        }
      />
      <Route
        path='/engineering/maintenance-planner/schedule/edit-schedule/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <EditSchedule />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default FinanceRouter;
