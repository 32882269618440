/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages
import React, { useState, useEffect } from 'react';

import { Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
// import components below
import FormUI from '../../../components/UI/FormUI';
import Moment from 'moment';
// import assets below
import './Form.scss';
import { moveinMoveout } from 'api/request';

// main component
const MoveInOutForm = (props) => {
  const {
    filter,
    onFilter,
    buildings,
    setSelectedUnit,
    names,
    setSubmit,
    msg,
    user,
    setMsg,
    userUnit,
  } = props;
  const [unitId, setUnitId] = useState();
  const [submitData, setSubmitData] = useState({
    building: '',
    unit: '',
    user: '',
    moveinDate: '',
    moveoutDate: '',
    leaseExpiryDate: '',
    representativeName: '',
    representativeContact: '',
    propertyEngrName: '',
  });
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
  });

  const role = user.attributes.role;

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const getNames = (e) => {
    setSelectedUnit(e);
  };

  const onSubmit = (e) => {
    let params;

    if (filter === '0') {
      params = {
        schedule_date: e.moveinDate,
        lease_expiry_date: e.leaseExpiryDate,
        representative_name: e.representativeName,
        representative_contact_number: e.representativeContact,
        property_engineer_name: e?.propertyEngrName || 'owner property name',
        type: filter,
        unit_id: role === 'admin' ? e.unit.id : userUnit?.id,
        user_id: e.user.id,
      };
      console.log(params);
      // moveinMoveout(data, callback);
    } else {
      console.log(e.user);
      params = {
        schedule_date: e.moveinDate,
        representative_name: e.representativeName,
        representative_contact_number: e.representativeContact,
        property_engineer_name: e?.propertyEngrName || 'owner property name',
        type: filter,
        unit_id: role === 'admin' ? e.unit.id : userUnit?.id,
        user_id: e.user.id,
      };
      console.log(params);
    }

    setSubmit(params);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);

  useEffect(() => {
    if (role === 'owner') {
      console.log(user.id);
      console.log(userUnit);
      getNames(userUnit.id);
    }
  }, [role]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.message);
        setMsg('');
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        console.log('');
      }
    }
  };

  useEffect(() => {
    if (
      msg.message === 'Request Move In created successfully.' ||
      msg.message === 'Request Move Out created successfully.'
    ) {
      setValue('user', '');
      setValue('moveinDate', '');
      setValue('leaseExpiryDate', '');
      setValue('building', '');
      setValue('unit', '');
      setValue('user', '');
      setValue('representativeName', '');
      setValue('representativeContact', '');
      setValue('propertyEngrName', '');
      setSubmitData({
        building: '',
        unit: '',
        user: '',
        moveinDate: '',
        moveoutDate: '',
        leaseExpiryDate: '',
        representativeName: '',
        representativeContact: '',
        propertyEngrName: '',
      });
    }
  }, [msg]);

  return (
    <Form
      className='moveInOutForm'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Select onChange={(event) => onFilter(event.target.value)}>
        <option value={0}>Move In Form</option>
        <option value={1}>Move Out Form</option>
      </Form.Select>
      <FormUI>
        <div>
          <Row>
            {role === 'owner' && (
              <Col md={4}>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Tenant Name{' '}
                    {errors.user ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.user.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.names}
                        placeholder='Select User'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('user', {
                      required: '(Required)',
                      value: submitData.user,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            user: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md={4}>
              <Form.Group className='mb-5'>
                <Form.Label>
                  {filter === '0' ? 'Move In Date' : 'Move Out Date'}{' '}
                  {errors.moveinDate ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.moveinDate.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  type='date'
                  {...register('moveinDate', {
                    required: '(Required)',
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          moveinDate: event.target.value,
                        };
                      });
                    },
                  })}
                  value={submitData.moveinDate}
                />
              </Form.Group>
            </Col>
            {filter === '0' && (
              <Col md={4}>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Lease Expiry Date{' '}
                    {errors.leaseExpiryDate ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.leaseExpiryDate.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='date'
                    {...register('leaseExpiryDate', {
                      required: '(Required)',
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            leaseExpiryDate: event.target.value,
                          };
                        });
                      },
                    })}
                    value={submitData.leaseExpiryDate}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          {role === 'admin' && (
            <Row>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Building Name
                    {errors.building ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.building.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.buildings}
                        placeholder='Select Building'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('building', {
                      required: '(Required)',
                      value: submitData.building,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            building: event.target.value,
                            unit: '',
                            user: '',
                          };
                        });
                        setValue('unit', '');
                        setValue('user', '');
                        setUnitId(event.target.value.id);
                      },
                    })}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Unit No.
                    {errors.unit ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.unit.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.units}
                        placeholder='Select Unit'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('unit', {
                      required: '(Required)',
                      value: submitData.unit,
                      onChange: (event) => {
                        getNames(event.target.value.id);
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            unit: event.target.value,
                            user: '',
                          };
                        });
                        setValue('user', '');
                      },
                    })}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Tenant Name{' '}
                    {errors.user ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.user.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={data.names}
                        placeholder='Select User'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('user', {
                      required: '(Required)',
                      value: submitData.user,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            user: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row>
            <Col md='4'>
              <Form.Group className='mb-5'>
                <Form.Label>
                  Authorized Representative Name{' '}
                  {errors.representativeName ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.representativeName.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('representativeName', {
                    required: '(Required)',
                    value: submitData.representativeName,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          representativeName: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>
            <Col md='4'>
              <Form.Group className='mb-5'>
                <Form.Label>
                  Representative Contact No.{' '}
                  {errors.representativeContact ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.representativeContact.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter Contact No. Here'
                  {...register('representativeContact', {
                    required: '(Required)',
                    maxLength: {
                      value: 11,
                      message: 'Max 11 digit', // JS only: <p>error message</p> TS only support string
                    },
                    value: submitData.representativeContact,
                    onChange: (event) => {
                      setSubmitData((prevState) => {
                        return {
                          ...prevState,
                          representativeContact: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>
            {role === 'admin' && (
              <Col md='4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Property Engr. Name{' '}
                    {errors.propertyEngrName ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.propertyEngrName.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Name Here'
                    {...register('propertyEngrName', {
                      required: '(Required)',
                      value: submitData.propertyEngrName,
                      onChange: (event) => {
                        setSubmitData((prevState) => {
                          return {
                            ...prevState,
                            propertyEngrName: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
        </div>

        <Row>
          <Col className='message-text-result'>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <div
              className='formUI__footer'
              style={{ flexDirection: 'row' }}
            >
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button variant='cancel'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </Form>
  );
};

export default MoveInOutForm;
