/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

// import assets below
import './View.scss';
import Download from '../../../../components/SVG/Download';

// API
import { getWordPermitByReferenceID, approveWorkPermit } from 'api/WorkPermit';

// Context
import userContext from 'components/userContext/userContext';

// main component
const View = () => {
  const { id } = useParams();
  const { data } = useContext(userContext);
  const { role } = data;
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [userData, setUserData] = useState({
    buildingName: '',
    unitNo: '',
    name: '',
    workType: '',
    dateFiled: '',
    periodFromTo: '',
    scopeOfWork: '',
    status: '',
    personnels: [],
  });
  const navigate = useNavigate();

  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };

  const approveWorkPermitFunction = () => {
    approveWorkPermit(id, '', approveCallback);
  };

  useEffect(() => {
    getWordPermitByReferenceID(id, '', callback);
  }, []);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);

        const items = data.data.relationships.personnel.map((item, idx) => {
          return {
            id: item?.id,
            name: item?.attributes?.name,
            image: item?.attributes?.personnel_id,
          };
        });

        setUserData((prevState) => {
          return {
            ...prevState,
            buildingName:
              data.data?.relationships?.building[0]?.attributes?.name,
            unitNo: data.data?.relationships?.unit[0]?.attributes?.unit_number,
            name: data.data?.relationships?.user[0]?.attributes?.name,
            workType: data.data?.attributes?.type,
            dateFiled: data.data?.attributes?.date_filed,
            periodFromTo: `${data.data?.attributes?.period_covered_from} - ${data.data?.attributes?.period_covered_to}`,
            scopeOfWork: data.data?.attributes?.description,
            status: data?.data?.attributes?.status,
            personnels: items,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const approveCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Successfully Approved', status: 'success' });
        getWordPermitByReferenceID(id, '', callback);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <div className='workPermit_viewRequest'>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Building Name</h6>
            <span>{userData.buildingName}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Unit No.</h6>
            <span>{userData.unitNo}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Name</h6>
            <span>{userData.name}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <h6 className='mb-3'>Work Type</h6>
            <span>{userData.workType}</span>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <h6 className='mb-3'>Date Filed</h6>
            <span>{userData.dateFiled}</span>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <h6 className='mb-3'>Period Covered From / To</h6>
            <span>{userData.periodFromTo}</span>
          </Form.Group>
        </Col>
      </Row>
      <Row className='mb-5'>
        <Col lg='4'>
          <Form.Group>
            <h6 className='mb-3'>Scope of Work</h6>
            <span>{userData.scopeOfWork}</span>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='personnelList'>
            <div className='personnelList__header'>
              <h6>List of Personnel</h6>
              <h6>View Worker ID</h6>
            </div>
            <div className='personnelList__body'>
              {userData.personnels &&
                userData.personnels.map((list) => {
                  return (
                    <div key={list.id}>
                      <Form.Group>
                        <span>{list.name}</span>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <span
                            onClick={() => {
                              enlargePhoto(list.image);
                            }}
                          >
                            View
                          </span>
                          <Form.Control type='file' />
                        </Form.Label>
                        <span>{list.image}</span>
                      </Form.Group>
                    </div>
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>

      {role === 'admin' && (
        <Row>
          <Col>
            <div>
              <h6 className='mt-4 mb-3'>Owner&#39;s Approval / Status</h6>
              <span style={{ textTransform: 'capitalize' }}>
                {userData.status}
              </span>

              {userData && userData.status === 'approved' && (
                <Button variant='submit'>{Download()} Download</Button>
              )}
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col
          className='message-text-result'
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
              display: 'block',
            }}
          >{`${msg.message}`}</span>
        </Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <div
            style={{ margin: 0, padding: 0 }}
            className='buttons-cont'
          >
            {role &&
              role === 'admin' &&
              userData.status &&
              userData.status === 'pending' && (
                <h6 className='pmo-action'>PMO Action</h6>
              )}

            <div className='buttons'>
              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='approve'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      approveWorkPermitFunction();
                    }}
                  >
                    Approve
                  </Button>
                )}

              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='deny'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(
                        `/administration/requests/work-permit/deny-request/${id}?activeKey=requests`,
                      );
                    }}
                  >
                    Deny
                  </Button>
                )}

              {role && (
                <Button
                  variant='light'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
