// packages
import React from 'react';
import { Card } from 'react-bootstrap';

// Components
import GovCalendar from './GovCalender';
import CalendarList from './CalendarList';

// CSS
import './CalendarContainer.scss';

// API

// CSS

// Main Component
const CalendarContainer = () => {
  // Sample Api data
  const apiData = [
    {
      id: 1,
      date: 'Mar 8, 2022',
      document: 'Permit',
      entity: 'BIR',
      assigned: 'Juan Dela Cruz',
    },
    {
      id: 2,
      date: 'Mar 9, 2022',
      document: 'Permit',
      entity: 'BIR',
      assigned: 'Juan Dela Cruz',
    },
    {
      id: 3,
      date: 'Mar 10, 2022',
      document: 'Permit',
      entity: 'BIR',
      assigned: 'Juan Dela Cruz',
    },
    {
      id: 4,
      date: 'Mar 11, 2022',
      document: 'Permit',
      entity: 'BIR',
      assigned: 'Juan Dela Cruz',
    },
    {
      id: 5,
      date: 'Mar 12, 2022',
      document: 'Permit',
      entity: 'BIR',
      assigned: 'Juan Dela Cruz',
    },
  ];

  // To render on screen
  return (
    <Card className='admin-form calendar shadow-sm'>
      <h2>VIEW CALENDAR</h2>

      <div className='calendar-container mb-5'>
        <GovCalendar apiData={apiData} />
      </div>

      <div className='calendar-container-list'>
        <CalendarList apiData={apiData} />
      </div>
    </Card>
  );
};

export default CalendarContainer;
