const ListAlt = () => {
  return (
    <svg viewBox='0 0 22.655 19.823'>
      <path
        id='list-alt'
        d='M20.531,51.823H2.124A2.124,2.124,0,0,1,0,49.7V34.124A2.124,2.124,0,0,1,2.124,32H20.531a2.124,2.124,0,0,1,2.124,2.124V49.7A2.124,2.124,0,0,1,20.531,51.823ZM5.664,35.894a1.77,1.77,0,1,0,1.77,1.77A1.77,1.77,0,0,0,5.664,35.894Zm0,4.248a1.77,1.77,0,1,0,1.77,1.77A1.77,1.77,0,0,0,5.664,40.142Zm0,4.248a1.77,1.77,0,1,0,1.77,1.77A1.77,1.77,0,0,0,5.664,44.39Zm12.744-6.018V36.956a.531.531,0,0,0-.531-.531H9.027a.531.531,0,0,0-.531.531v1.416a.531.531,0,0,0,.531.531h8.85A.531.531,0,0,0,18.407,38.372Zm0,4.248V41.2a.531.531,0,0,0-.531-.531H9.027A.531.531,0,0,0,8.5,41.2V42.62a.531.531,0,0,0,.531.531h8.85A.531.531,0,0,0,18.407,42.62Zm0,4.248V45.451a.531.531,0,0,0-.531-.531H9.027a.531.531,0,0,0-.531.531v1.416a.531.531,0,0,0,.531.531h8.85A.531.531,0,0,0,18.407,46.867Z'
        transform='translate(0 -32)'
      />
    </svg>
  );
};

export default ListAlt;
