// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import AnnouncementsTabs from './Tabs';
import AnnouncementsTable from './Table';
import AnnouncementsPagination from './Pagination';

const AnnouncementsPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Announcements</title>
      </Helmet>
      <Main title='Announcements'>
        <Container fluid>
          <Row>
            <Col>
              <AnnouncementsTabs />
              <AnnouncementsTable />
              <AnnouncementsPagination />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default AnnouncementsPage;
