// import core & vendor packages
import { useState } from 'react';

// import components below
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import Content from '../Content';
import Footer from '../Footer';

// import assets below
import './style.scss';

// main component
const Main = (props) => {
  const { title, children } = props;
  const [sidebarToggle, setSidebarToggle] = useState(false);

  return (
    <div className='main'>
      {!sidebarToggle && <Sidebar />}
      <div
        className='main__body'
        style={{ maxWidth: !sidebarToggle ? 'calc(100% - 320px)' : null }}
      >
        <Topbar
          title={title}
          sidebarToggle={sidebarToggle}
          onSidebarToggle={setSidebarToggle}
        />
        <Content>{children}</Content>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
