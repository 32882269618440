/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Row, Form, Dropdown, Table } from 'react-bootstrap';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import {
  Col,
  Input,
  InputGroup,
  FormGroup,
  Label,
  Button,
  Fade,
  FormFeedback,
  Container,
  Card,
} from 'reactstrap';
import './CalendarForm.scss';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
class CalendarForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dataLoaded: false,
      isFormInvalid: false,
      rows: null,
      cols: null,
      fileStore: null,
    };
    this.fileHandler = this.fileHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.openNewPage = this.openNewPage.bind(this);
    this.fileInput = React.createRef();
  }

  renderFile = (fileObj) => {
    // just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          dataLoaded: true,
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  fileHandler = (event) => {
    if (event.target.files.length) {
      this.setState({ fileStore: event.target.files[0] });
      const fileObj = event.target.files[0];
      const fileName = fileObj.name;

      // check for file extension and pass only if it is .xlsx and display error message otherwise
      if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false,
        });
        this.renderFile(fileObj);
      } else {
        this.setState({
          isFormInvalid: true,
          uploadedFileName: '',
        });
      }
    }
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  openFileBrowser = () => {
    this.fileInput.current.click();
  };

  openNewPage = (chosenItem) => {
    const url =
      chosenItem === 'github'
        ? 'https://github.com/ashishd751/react-excel-renderer'
        : 'https://medium.com/@ashishd751/render-and-display-excel-sheets-on-webpage-using-react-js-af785a5db6a7';
    window.open(url, '_blank');
  };

  render() {
    return (
      <div className='calendarForm'>
        <div className='d-flex justify-content-between'>
          <div className='col-8 mt-1'>
            <Row>
              <div className='col-5'>
                <Form.Group
                  controlId='formSearch'
                  className='mb-5 formSearch'
                >
                  <Form.Control
                    type='text'
                    placeholder='Search'
                    className='search'
                  />

                  <Button className='searchbtn'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25.362'
                      height='25.584'
                      viewBox='0 0 25.362 25.584'
                    >
                      <path
                        id='search'
                        d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                        transform='translate(-336.364 -87.386)'
                        fill='#c7c7c7'
                      />
                    </svg>
                  </Button>
                </Form.Group>
              </div>

              <div className='col'>
                <div className='col-2 filter'>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <svg
                        className='mx-2'
                        xmlns='http://www.w3.org/2000/svg'
                        width='29.498'
                        height='25.811'
                        viewBox='0 0 29.498 25.811'
                      >
                        <path
                          id='sliders-h'
                          d='M28.576,52.28H9.218v-.922a.925.925,0,0,0-.922-.922H6.453a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,53.2v1.844a.925.925,0,0,0,.922.922H5.531v.922a.925.925,0,0,0,.922.922H8.3a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V53.2A.925.925,0,0,0,28.576,52.28Zm0-9.218H23.967V42.14a.925.925,0,0,0-.922-.922H21.2a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,43.984v1.844a.925.925,0,0,0,.922.922H20.28v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922h4.609a.925.925,0,0,0,.922-.922V43.984A.925.925,0,0,0,28.576,43.062Zm0-9.218H16.593v-.922A.925.925,0,0,0,15.671,32H13.827a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,34.765v1.844a.925.925,0,0,0,.922.922H12.905v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V34.765A.925.925,0,0,0,28.576,33.844Z'
                          transform='translate(0 -32)'
                          fill='#fff'
                        />
                      </svg>
                      Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href='#/action-1'>All</Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-2'
                      >
                        Technical Team
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-1'
                      >
                        Team & HouseKeeping
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-1'
                      >
                        Job Out(Contractor)
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-1'
                      >
                        Team & Security
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-1'
                      >
                        Equipments
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled
                        href='#/action-1'
                      >
                        Others
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Row>
          </div>
          <div className='mt-3 '>
            {/* <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='ExcelTable2007'
              filename='tablexls'
              sheet='tablexls'
              buttonText='Download'
            /> */}
          </div>
        </div>
        <div className='calendar-container-table'>
          <div className='greyish-container'>
            <a
              className='year py-3 px-4'
              style={{
                backgroundColor: '#00B6E9',
                color: 'white',
                borderRadius: '5px',
                textDecoration: 'none',
              }}
            >
              Year
            </a>
            <div className='mt-5'>
              <div>
                <Container>
                  <form>
                    <FormGroup row>
                      <Label
                        for='exampleFile'
                        xs={6}
                        sm={4}
                        lg={2}
                        size='lg'
                      >
                        Upload
                      </Label>
                      <Col
                        xs={4}
                        sm={8}
                        lg={10}
                      >
                        <InputGroup>
                          <Button
                            color='info'
                            style={{ color: 'white', zIndex: 0 }}
                            onClick={this.openFileBrowser.bind(this)}
                          >
                            <i className='cui-file'></i> Browse&hellip;
                          </Button>
                          <input
                            type='file'
                            hidden
                            onChange={this.fileHandler.bind(this)}
                            ref={this.fileInput}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            style={{ padding: '10px' }}
                          />

                          <Input
                            type='text'
                            className='form-control'
                            readOnly
                            invalid={this.state.isFormInvalid}
                          />
                          <FormFeedback>
                            <Fade
                              in={this.state.isFormInvalid}
                              tag='h6'
                              style={{ fontStyle: 'italic' }}
                            >
                              Please select a .xlsx file only !
                            </Fade>
                          </FormFeedback>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                  </form>

                  {this.state.dataLoaded && (
                    <div>
                      <Card
                        body
                        outline
                        color='secondary'
                        className='restrict-card'
                      >
                        <Table id='ExcelTable2007'>
                          <OutTable
                            id='ExcelTable2007'
                            tableID='ExcelTable2007'
                            data={this.state.rows}
                            columns={this.state.cols}
                            tableI
                            tableClassName='ExcelTable2007'
                            tableHeaderRowClass='heading'
                          />
                        </Table>
                      </Card>
                    </div>
                  )}
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalendarForm;
