const Billing = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.103'
      height='17.572'
      viewBox='0 0 25.103 17.572'
    >
      <path
        id='money-check-alt'
        d='M23.848,32H1.255A1.255,1.255,0,0,0,0,33.255V48.317a1.255,1.255,0,0,0,1.255,1.255H23.848A1.255,1.255,0,0,0,25.1,48.317V33.255A1.255,1.255,0,0,0,23.848,32ZM6.9,43.606v.632a.314.314,0,0,1-.314.314H5.962a.314.314,0,0,1-.314-.314V43.6a2.246,2.246,0,0,1-1.23-.445.314.314,0,0,1-.022-.476l.461-.44a.322.322,0,0,1,.4-.029.944.944,0,0,0,.5.146h1.1a.493.493,0,0,0,.463-.517.512.512,0,0,0-.344-.5l-1.765-.53a1.775,1.775,0,0,1-1.239-1.7,1.747,1.747,0,0,1,1.674-1.768v-.632a.314.314,0,0,1,.314-.314h.628a.314.314,0,0,1,.314.314v.639a2.243,2.243,0,0,1,1.23.445.314.314,0,0,1,.022.476l-.461.44a.322.322,0,0,1-.4.029.941.941,0,0,0-.5-.146h-1.1a.493.493,0,0,0-.463.517.512.512,0,0,0,.344.5l1.765.53a1.775,1.775,0,0,1,1.239,1.7A1.746,1.746,0,0,1,6.9,43.606Zm9.414-.623A.314.314,0,0,1,16,43.3H11.61a.314.314,0,0,1-.314-.314v-.628a.314.314,0,0,1,.314-.314H16a.314.314,0,0,1,.314.314Zm6.276,0a.314.314,0,0,1-.314.314H19.141a.314.314,0,0,1-.314-.314v-.628a.314.314,0,0,1,.314-.314h3.138a.314.314,0,0,1,.314.314Zm0-3.766a.314.314,0,0,1-.314.314H11.61a.314.314,0,0,1-.314-.314V38.59a.314.314,0,0,1,.314-.314H22.279a.314.314,0,0,1,.314.314Z'
        transform='translate(0 -32)'
        fill='#fff'
      />
    </svg>
  );
};

export default Billing;
