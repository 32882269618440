// Packages
import { Tabs, Tab } from 'react-bootstrap';

// Components
import TabsUI from '../../../../components/UI/TabsUI';
import IncidentReports from '../IncidentReports';
import ViolationReports from '../ViolationReports';
import OccupancyReport from '../OccupancyReport';

const AdministrativeReportTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='incident'
        className='mb-3'
      >
        <Tab
          eventKey='incident'
          title='Incident Reports'
        >
          <IncidentReports />
        </Tab>
        <Tab
          eventKey='violations'
          title='Violations Reports'
        >
          <ViolationReports />
        </Tab>
        <Tab
          eventKey='occupancy'
          title='Occupancy Reports'
        >
          <OccupancyReport />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default AdministrativeReportTabs;
