// Packages

// Components

// API

// CSS
import './style.scss';

const Content = (props) => {
  const { children } = props;

  return <div className='content'>{children}</div>;
};

export default Content;
