// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import ArAging from './ArAging/ArAging';
// API

// CSS
import './FinanceCMSPage.scss';
import Budget from './Budget/Budget';
import Capex from './CAPEX/Capex';
import Opex from './OPEX/Opex';

const FinanceCMSPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='AR Aging'
                id='AR Aging'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='AR Aging'
                  title='AR Aging'
                >
                  <ArAging />
                </Tab>
                <Tab
                  eventKey='Budget'
                  title='Budget'
                >
                  <Budget />
                </Tab>
                <Tab
                  eventKey='CAPEX'
                  title='CAPEX'
                >
                  <Capex />
                </Tab>
                <Tab
                  eventKey='OPEX'
                  title='OPEX'
                >
                  <Opex />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default FinanceCMSPage;
