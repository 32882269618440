/* eslint-disable no-unused-vars */
// Packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// Components
import SuppliersDirectoryTable from './SuppliersDirectoryTable';
import {
  getSupplierDirectoryList,
  postDeleteSupplierDirectory,
} from '../../../../api/request';
// CSS/SCSS
import './SuppliersDirectoryContent.scss';

// Main Component
const SuppliersDirectoryContent = ({
  refreshList,
  setRefreshList,
  setActiveKey,
}) => {
  const nav = useNavigate();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [tableList, setTableList] = useState();
  // fire refresh status
  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getSupplierDirectoryList(callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList, tableList]);
  // callback on list data
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableList(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  // drop down delete
  const deleteSingleListData = (id) => {
    postDeleteSupplierDirectory(id, callback);
    setRefreshList(true);
  };
  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={5}>
            <div className='edit-container mb-3'>
              <Button
                className='edit'
                variant='primary'
                onClick={() => setActiveKey('Suppliers Directory Form')}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26.879'
                  height='26.879'
                  viewBox='0 0 26.879 26.879'
                >
                  <path
                    id='plus'
                    d='M24.959,42.56h-8.64V33.92A1.92,1.92,0,0,0,14.4,32h-1.92a1.92,1.92,0,0,0-1.92,1.92v8.64H1.92A1.92,1.92,0,0,0,0,44.479V46.4a1.92,1.92,0,0,0,1.92,1.92h8.64v8.64a1.92,1.92,0,0,0,1.92,1.92H14.4a1.92,1.92,0,0,0,1.92-1.92v-8.64h8.64a1.92,1.92,0,0,0,1.92-1.92v-1.92A1.92,1.92,0,0,0,24.959,42.56Z'
                    transform='translate(0 -32)'
                    fill='#fff'
                  />
                </svg>
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            {tableList && tableList.length === 0 && <p>Table is empty</p>}
            {tableList && tableList.length > 0 && (
              <SuppliersDirectoryTable
                tableList={tableList}
                deleteSingleListData={deleteSingleListData}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default SuppliersDirectoryContent;
