// packages
import React from 'react';
// import { Form } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';

// CSS
import './EquipmentInventoryFormTable.scss';

// Components

// API

// CSS

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  column: { Header }, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (id === 'dateInstalled') {
    return (
      <input
        type='date'
        value={value}
        placeholder={`Enter ${Header} Here`}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={id === 'number'}
      />
    );
  }

  return (
    <input
      value={value}
      placeholder={`Enter ${
        Header === 'Inventory Tag No.' ? 'Tag No.' : Header
      } Here`}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={id === 'number'}
    />
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
  // For this example, we're using pagination to illustrate how to stop

  // the current page from resetting when our data changes

  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but

        // anything we put into these options will

        // automatically be available on the instance.

        // That way we can call this function from our

        // cell renderer!
        updateMyData,
      },
      usePagination,
    );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, idx) => (
                <th
                  key={idx}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, ri) => {
            prepareRow(row);
            return (
              <tr
                key={ri}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, ci) => {
                  return (
                    <td
                      key={ci}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className='pagination'>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div> */}
    </>
  );
}

// Main Component
function EquipmentInventoryFormTable() {
  // Static columns used in table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Item No.',
        accessor: 'itemNo',
      },
      {
        Header: 'Equipment',
        accessor: 'equipment',
      },
      {
        Header: 'Brand',
        accessor: 'brand',
      },
      {
        Header: 'Serial No.',
        accessor: 'serialNo',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Date Installed',
        accessor: 'dateInstalled',
      },
      {
        Header: 'Inventory Tag No.',
        accessor: 'inventoryTagNo',
      },
    ],
    [],
  );

  // Data used in table
  const [data, setData] = React.useState(() => [
    {
      itemNo: 1,
      equipment: 'Generator',
      brand: 'Generac',
      serialNo: '0010',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 2,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 3,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 4,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 5,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 6,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 7,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 8,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 9,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 10,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
    {
      itemNo: 11,
      equipment: '',
      brand: '',
      serialNo: '',
      location: '',
      dateInstalled: '',
      inventoryTagNo: '',
    },
  ]);

  // const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we

  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use

  // the rowIndex, columnId and new value to update the

  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  // After data chagnes, we turn the flag back off

  // so that if data actually changes when we're not

  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  // Let's add a data resetter/randomizer to help

  // illustrate that flow...

  return (
    <>
      {/* <button onClick={resetData}>Reset Data</button> */}
      <div className='unit-table shadow-sm'>
        <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
        />
      </div>
    </>
  );
}

export default EquipmentInventoryFormTable;
