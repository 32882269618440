/* eslint-disable no-unused-vars */
// Packages
import React, { useEffect, useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import {
  getlistHouseRules,
  submitHouseRulesForm,
} from '../../../../api/request';

// CSS
import './HouseRulesForm.scss';

// Components

// API

// CSS

// Main Component
const HouseRulesForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  // on submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    const params = { title: title, content: content };
    console.log(params);
    setSuccess('');
    setMsg('');
    submitHouseRulesForm(params, callback);
    setTitle('');
    setContent('');
  };

  // callback for submitting data
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);

        console.log(data);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className='mb-5'
          controlId='formTitle'
        >
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group
          className='mb-5'
          controlId='formContent'
        >
          <Form.Label>Content</Form.Label>
          <Form.Control
            as='textarea'
            rows={10}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
      {showResponseAPI}
    </Card>
  );
};

export default HouseRulesForm;
