// Packages
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';

// import assets below
import ChartPie from '../../SVG/ChartPie';
import Home from '../../SVG/Home';
import Building from '../../SVG/Building';
import PurchaseOrder from '../../SVG/PurchaseOrder';
import Exclamation from '../../SVG/Exclamation';
import Passport from '../../SVG/Passport';
import InventoryFlow from '../../SVG/InventoryFlow';
import UserFriends from '../../SVG/UserFriends';
import User from '../../SVG/User';
import FileSignature from '../../SVG/FileSignature';
import AssistiveListening from '../../SVG/AssistiveListening';
import ExchangeAlt from '../../SVG/ExchangeAlt';
import Scroll from '../../SVG/Scroll';
import Car from '../../SVG/Car';
import Badge from '../../SVG/Badge';
import Dog from '../../SVG/Dog';
import Clipboard from '../../SVG/Clipboard';

// main component
const Administration = (props) => {
  const { activeKey, pathname } = props;
  
  // routes under amenities module
  const amenitiesRoutes = [
    '/administration/requests/amenities',
    '/administration/requests/amenities/view-request',
    '/administration/requests/amenities/deny-request',
  ];

  // routes under concerns module
  const concernsRoutes = [
    '/administration/requests/concerns',
    '/administration/requests/concerns/view-request',
    '/administration/requests/concerns/approve-request',
    '/administration/requests/concerns/add-remarks',
  ];

  // routes under move in/out module
  const moveInOutRoutes = [
    '/administration/requests/move-in-out',
    '/administration/requests/move-in-out/view-request',
    '/administration/requests/move-in-out/deny-request',
  ];

  // routes under gate pass module
  const gatePassRoutes = [
    '/administration/requests/gate-pass',
    '/administration/requests/gate-pass/view-request',
    '/administration/requests/gate-pass/deny-request',
  ];

  // routes under work permit module
  const workPermitRoutes = [
    '/administration/requests/work-permit',
    '/administration/requests/work-permit/view-request',
    '/administration/requests/work-permit/deny-request',
  ];

  return (
    <Fragment>
      <Nav.Item>
        <span>Administration</span>
      </Nav.Item>
      <Accordion defaultActiveKey={activeKey}>
        <Accordion.Item eventKey='admin-cms'>
          <Accordion.Header>
            <div className='svg__wrapper'>{ChartPie()}</div>
            <Nav.Link
              className='nav-link'
              href='#'
            >
              Admin CMS
            </Nav.Link>
          </Accordion.Header>
          <Accordion.Body>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/house-rules' && 'active'
              }
            >
              <div className='svg__wrapper'>{Home()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/house-rules?activeKey=admin-cms'
              >
                House Rules
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/amenities' && 'active'
              }
            >
              <div className='svg__wrapper'>{Building()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/amenities?activeKey=admin-cms'
              >
                Amenities
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/payment-instructions' &&
                'active'
              }
            >
              <div className='svg__wrapper'>{PurchaseOrder()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/payment-instructions?activeKey=admin-cms'
              >
                Payment Instructions
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/violations' && 'active'
              }
            >
              <div className='svg__wrapper'>{Exclamation()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/violations?activeKey=admin-cms'
              >
                Violations
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/emergency-directory' &&
                'active'
              }
            >
              <div className='svg__wrapper'>{Passport()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/emergency-directory?activeKey=admin-cms'
              >
                Emergency Directory
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/suppliers-directory' &&
                'active'
              }
            >
              <div className='svg__wrapper'>{InventoryFlow()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/suppliers-directory?activeKey=admin-cms'
              >
                Supplier&#39;s Directory
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/admin-cms/occupancy' && 'active'
              }
            >
              <div className='svg__wrapper'>{UserFriends()}</div>
              <Link
                className='nav-link'
                to='/administration/admin-cms/occupancy?activeKey=admin-cms'
              >
                Occupancy
              </Link>
            </Nav.Item>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Nav.Item
        className={pathname === '/administration/building-list' && 'active'}
      >
        <div className='svg__wrapper'>{Building()}</div>
        <Link
          className='nav-link'
          to='/administration/building-list'
        >
          Building List
        </Link>
      </Nav.Item>
      <Nav.Item
        className={
          pathname === '/administration/account-management' && 'active'
        }
      >
        <div className='svg__wrapper'>{User()}</div>
        <Link
          className='nav-link'
          to='/administration/account-management'
        >
          Account Management
        </Link>
      </Nav.Item>
      <Accordion defaultActiveKey={activeKey}>
        <Accordion.Item eventKey='requests'>
          <Accordion.Header>
            <div className='svg__wrapper'>{FileSignature()}</div>
            <Nav.Link
              className='nav-link'
              href='#'
            >
              Requests
            </Nav.Link>
          </Accordion.Header>
          <Accordion.Body>
            <Nav.Item
              className={`${amenitiesRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{Building()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/amenities?activeKey=requests'
              >
                Amenities
              </Link>
            </Nav.Item>
            <Nav.Item
              className={`${concernsRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{AssistiveListening()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/concerns?activeKey=requests'
              >
                Concerns
              </Link>
            </Nav.Item>
            <Nav.Item
              className={`${moveInOutRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{ExchangeAlt()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/move-in-out?activeKey=requests'
              >
                Move In/Out
              </Link>
            </Nav.Item>
            <Nav.Item
              className={`${gatePassRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{Passport()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/gate-pass?activeKey=requests'
              >
                Gate Pass
              </Link>
            </Nav.Item>
            <Nav.Item
              className={`${workPermitRoutes.includes(pathname) && 'active'}`}
            >
              <div className='svg__wrapper'>{Scroll()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/work-permit?activeKey=requests'
              >
                Work Permit
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/requests/car-sticker' && 'active'
              }
            >
              <div className='svg__wrapper'>{Car()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/car-sticker?activeKey=requests'
              >
                Car Sticker
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/requests/staff-id' && 'active'
              }
            >
              <div className='svg__wrapper'>{Badge()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/staff-id?activeKey=requests'
              >
                Staff ID
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/requests/pets' && 'active'
              }
            >
              <div className='svg__wrapper'>{Dog()}</div>
              <Link
                className='nav-link'
                to='/administration/requests/pets?activeKey=requests'
              >
                Pets
              </Link>
            </Nav.Item>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Nav.Item
        className={pathname === '/administration/announcements' && 'active'}
      >
        <div className='svg__wrapper'>{Scroll()}</div>
        <Link
          className='nav-link'
          to='/administration/announcements'
        >
          Announcements
        </Link>
      </Nav.Item>
      <Nav.Item
        className={pathname === '/administration/violations' && 'active'}
      >
        <div className='svg__wrapper'>{Exclamation()}</div>
        <Link
          className='nav-link'
          to='/administration/violations'
        >
          Violations
        </Link>
      </Nav.Item>
      <Nav.Item
        className={
          pathname === '/administration/government-requirements' && 'active'
        }
      >
        <div className='svg__wrapper'>{Clipboard()}</div>
        <Link
          className='nav-link'
          to='/administration/government-requirements'
        >
          Government Requirements
        </Link>
      </Nav.Item>
      <Accordion defaultActiveKey={activeKey}>
        <Accordion.Item eventKey='maintenance-planner'>
          <Accordion.Header>
            <div className='svg__wrapper'>{Clipboard()}</div>
            <Nav.Link
              className='nav-link'
              href='#'
            >
              Contracts
            </Nav.Link>
          </Accordion.Header>
          <Accordion.Body>
            <Nav.Item
              className={
                pathname === '/administration/contracts/service-providers' &&
                'active'
              }
            >
              <div className='svg__wrapper'>{Clipboard()}</div>
              <Link
                className='nav-link'
                to='/administration/contracts/service-providers?activeKey=maintenance-planner'
              >
                Service Providers
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                pathname === '/administration/contracts/general-contracts' &&
                'active'
              }
            >
              <div className='svg__wrapper'>{Clipboard()}</div>
              <Link
                className='nav-link'
                to='/administration/contracts/general-contracts?activeKey=maintenance-planner'
              >
                General Contracts
              </Link>
            </Nav.Item>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  );
};

export default Administration;
