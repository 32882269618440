// import core and vendor packages below
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';

// import components below
import TableUI from '../../../components/UI/TableUI';

// import style below
import './style.scss';

// main component
const OccupancyReportsTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'Residential',
        accessor: 'col1',
      },
      {
        Header: 'Parking',
        accessor: 'col2',
      },
      {
        Header: 'Residential',
        accessor: 'col3',
      },
      {
        Header: 'Parking',
        accessor: 'col4',
      },
      {
        Header: 'Residential',
        accessor: 'col5',
      },
      {
        Header: 'Parking',
        accessor: 'col6',
      },
      {
        Header: 'Residential',
        accessor: 'col7',
      },
      {
        Header: 'Parking',
        accessor: 'col8',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
      {
        col1: '* insert data *',
        col2: '* insert data *',
        col3: '* insert data *',
        col4: '* insert data *',
        col5: '* insert data *',
        col6: '* insert data *',
        col7: '* insert data *',
        col8: '* insert data *',
        col9: '* insert data *',
        col10: '* insert data *',
      },
    ],
    [],
  );

  const months = ['Oct-2022', 'Nov-2022', 'Dec-2022', 'Jan-2023'];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className='dashboardOccupancyReports reportsTable'>
      <TableUI>
        <Table
          responsive
          {...getTableProps()}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    months.map((month) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        colSpan='2'
                      >
                        <span>{month}</span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        key={index}
                        {...column.getHeaderProps()}
                        className={
                          column.Header === 'Parking' ? 'divider' : null
                        }
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            key={index}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </TableUI>
    </div>
  );
};

export default OccupancyReportsTable;
