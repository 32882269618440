const Passport = () => {
  return (
    <svg viewBox='0 0 20.173 23.055'>
      <path
        id='passport'
        d='M5.837,7.925H7.6a10.161,10.161,0,0,1,.64-3.17A4.316,4.316,0,0,0,5.837,7.925Zm0,1.441a4.317,4.317,0,0,0,2.4,3.17,10.148,10.148,0,0,1-.64-3.17Zm4.25,3.543a6.126,6.126,0,0,0,1.055-3.543H9.031A6.13,6.13,0,0,0,10.086,12.909ZM9.031,7.925h2.11a6.127,6.127,0,0,0-1.055-3.543A6.123,6.123,0,0,0,9.031,7.925Zm2.9,4.611a4.316,4.316,0,0,0,2.4-3.17h-1.76A10.148,10.148,0,0,1,11.936,12.536ZM18.732,0H2.882A2.882,2.882,0,0,0,0,2.882V20.173a2.882,2.882,0,0,0,2.882,2.882h15.85a1.441,1.441,0,0,0,1.441-1.441V1.441A1.441,1.441,0,0,0,18.732,0Zm-3.6,18.732H5.043a.72.72,0,0,1,0-1.441H15.13a.72.72,0,1,1,0,1.441Zm-5.043-4.323A5.764,5.764,0,1,1,15.85,8.646,5.764,5.764,0,0,1,10.086,14.409Zm1.85-9.655a10.148,10.148,0,0,1,.64,3.17h1.76A4.316,4.316,0,0,0,11.936,4.755Z'
      />
    </svg>
  );
};

export default Passport;
