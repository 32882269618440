/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {
  editEmergencyDirectory,
  editSupplierDirectory,
  submitSupplierDirectoryForm,
  viewEmergencyDirectoryForm,
  viewSupplierDirectoryForm,
} from '../../../../api/request';
import { useParams, useNavigate } from 'react-router-dom';
// CSS

// Components

// API

// CSS

// Main Component
const SuppliersDirectoryForm = () => {
  const nav = useNavigate();
  // Static titles
  const titles = [
    {
      id: 1,
      title: 'Administration',
    },
    {
      id: 2,
      title: 'Finance',
    },
    {
      id: 3,
      title: 'Engineering',
    },
    {
      id: 4,
      title: 'Others',
    },
  ];
  // get id on URL
  const { id } = useParams();

  const [entity, setEntity] = useState('');
  const [service, setService] = useState('');
  const [contact, setContact] = useState('');
  // response api
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');
  // modal show
  const [showModal, setshowModal] = useState(false);
  const handleClose = () => {
    setshowModal(false);
  };
  const handleShow = () => setshowModal(true);
  // useEffect
  useEffect(() => {
    viewEmergencyDirectoryForm(id, callback);
  }, []);
  // handle Submit Form
  const handleSubmit = (e) => {
    e.preventDefault();
    const dataSubmit = {
      entity: entity,
      contact_number: contact,
      service: service,
    };
    console.log(dataSubmit);
    setMsg('');
    setSuccess('');
    editEmergencyDirectory(id, dataSubmit, callbackUpdate);
  };
  // Handle submit Response from api
  const callbackUpdate = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
        if (data.message === 'Emergency directory updated successfully.') {
          handleShow();
        }
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);
        setMsg('');
        console.log(data.data);
        setEntity(data.data.attributes.entity);
        setContact(data.data.attributes.contact_number);
        setService(data.data.attributes.service);
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setSuccess('');
      }
    }
  };
  // api response callback screen
  const showResponseAPI = (
    <div>
      <p className='error'>{msg}</p>
      <p style={{ color: '#3bd14d' }}>{success}</p>
    </div>
  );
  // To render on screen
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Amenity updated successfully.</Modal.Title>
        </Modal.Header>
        <Modal.Body>You want to Go back to List ? </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant='primary'
            onClick={() => nav('/administration/admin-cms/emergency-directory')}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className='admin-form shadow-sm'>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            className='mb-5'
            controlId='formTitle'
          >
            <Form.Label>Entity</Form.Label>
            <Form.Control
              aria-label='Enter Entity'
              placeholder='Enter Entity'
              value={entity}
              onChange={(e) => setEntity(e.target.value)}
            />
          </Form.Group>

          <Form.Group
            className='mb-5'
            controlId='formTitle'
          >
            <Form.Label>Contact No.</Form.Label>
            <Form.Control
              aria-label='Enter Contact No.'
              placeholder='Enter Contact No.'
              value={contact}
              type='text'
              maxLength={11}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setContact(e.target.value);
                }
              }}
            />
          </Form.Group>

          <Form.Group
            className='mb-5'
            controlId='formContent'
          >
            <Form.Label>Service</Form.Label>
            <Form.Control
              aria-label='Enter Service'
              as='textarea'
              rows={10}
              placeholder='Enter Service'
              value={service}
              onChange={(e) => setService(e.target.value)}
            />
          </Form.Group>

          <Form.Group className='buttons'>
            <Button
              className='submit'
              variant='primary'
              type='submit'
            >
              Submit
            </Button>
            <Button
              className='cancel'
              variant='default'
            >
              Cancel
            </Button>
          </Form.Group>
        </Form>
        {showResponseAPI}
      </Card>
    </>
  );
};

export default SuppliersDirectoryForm;
