const RedCircle = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <circle
        id='Ellipse_22'
        dataName='Ellipse 22'
        cx='12'
        cy='12'
        r='12'
        fill='#cb1212'
      />
    </svg>
  );
};

export default RedCircle;
