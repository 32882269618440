/* eslint-disable prefer-const */

// Packages
import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';

// Components

// CSS/SCSS
import './OccupancyTable.scss';

// Example Data from API
const datafromAPI = [
  {
    id: 1,
    date: 'Thu Jan 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 1,
            TotalNoOfUnits: 1,
            TurnedOverUnitsfromDevelopertoOwner: 2,
            UnitsUnderOwner: 3,
            UnitsOccupiedByTenants: 4,
            UnitsOccupiedByOwners: 5,
            VacantUnits: 6,
            OccupancyRate: '7%',
          },
        ],
        parking: [
          {
            id: 2,
            TotalNoOfUnits: 1,
            TurnedOverUnitsfromDevelopertoOwner: 2,
            UnitsUnderOwner: 3,
            UnitsOccupiedByTenants: 4,
            UnitsOccupiedByOwners: 5,
            VacantUnits: 6,
            OccupancyRate: '7%',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    date: 'Thu Feb 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 3,
            TotalNoOfUnits: 107,
            TurnedOverUnitsfromDevelopertoOwner: 107,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 19,
            UnitsOccupiedByOwners: 39,
            VacantUnits: 49,
            OccupancyRate: '48%',
          },
        ],
        parking: [
          {
            id: 4,
            TotalNoOfUnits: 110,
            TurnedOverUnitsfromDevelopertoOwner: 110,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 34,
            UnitsOccupiedByOwners: 44,
            VacantUnits: 32,
            OccupancyRate: '77%',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    date: 'Thu Mar 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 5,
            TotalNoOfUnits: 107,
            TurnedOverUnitsfromDevelopertoOwner: 107,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 19,
            UnitsOccupiedByOwners: 39,
            VacantUnits: 49,
            OccupancyRate: '48%',
          },
        ],
        parking: [
          {
            id: 6,
            TotalNoOfUnits: 110,
            TurnedOverUnitsfromDevelopertoOwner: 110,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 34,
            UnitsOccupiedByOwners: 44,
            VacantUnits: 32,
            OccupancyRate: '77%',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    date: 'Thu Apr 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 7,
            TotalNoOfUnits: 107,
            TurnedOverUnitsfromDevelopertoOwner: 107,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 19,
            UnitsOccupiedByOwners: 39,
            VacantUnits: 49,
            OccupancyRate: '48%',
          },
        ],
        parking: [
          {
            id: 8,
            TotalNoOfUnits: 110,
            TurnedOverUnitsfromDevelopertoOwner: 110,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 34,
            UnitsOccupiedByOwners: 44,
            VacantUnits: 32,
            OccupancyRate: '77%',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    date: 'Thu May 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 9,
            TotalNoOfUnits: 107,
            TurnedOverUnitsfromDevelopertoOwner: 107,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 19,
            UnitsOccupiedByOwners: 39,
            VacantUnits: 49,
            OccupancyRate: '48%',
          },
        ],
        parking: [
          {
            id: 10,
            TotalNoOfUnits: 110,
            TurnedOverUnitsfromDevelopertoOwner: 110,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 34,
            UnitsOccupiedByOwners: 44,
            VacantUnits: 32,
            OccupancyRate: '77%',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    date: 'Thu Apr 01 2022 15:40:27 GMT+0800 (China Standard Time)',
    data: [
      {
        residential: [
          {
            id: 11,
            TotalNoOfUnits: 107,
            TurnedOverUnitsfromDevelopertoOwner: 107,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 19,
            UnitsOccupiedByOwners: 39,
            VacantUnits: 49,
            OccupancyRate: '48%',
          },
        ],
        parking: [
          {
            id: 12,
            TotalNoOfUnits: 110,
            TurnedOverUnitsfromDevelopertoOwner: 110,
            UnitsUnderOwner: 107,
            UnitsOccupiedByTenants: 34,
            UnitsOccupiedByOwners: 44,
            VacantUnits: 32,
            OccupancyRate: '77%',
          },
        ],
      },
    ],
  },
];

// Main Component
const OccupancyTable = () => {
  // States
  const [allData] = useState(datafromAPI);

  // Dynamic columns based on given API
  const columns = React.useMemo(() => {
    const testColumn = [];

    allData.forEach((data) => {
      const dataDate = new Date(data.date);
      const month = dataDate.toLocaleString('en-US', { month: 'short' });
      // eslint-disable-next-line newline-per-chained-call
      const year = dataDate.getFullYear().toString().substr(-2);

      if (data.data) {
        testColumn.push({
          Header: `${month}-${year}`,
          columns: [
            {
              Header: 'Residential',
              accessor: `${data.data[0].residential[0].id}`,
            },
            {
              Header: 'Parking',
              accessor: `${data.data[0].parking[0].id}`,
            },
          ],
        });
      }
    });

    return testColumn;
  }, []);

  // Dynamic data based on given API
  const data = React.useMemo(() => {
    let TotalNoOfUnits = {};
    let TurnedOverUnitsfromDevelopertoOwner = {};
    let UnitsUnderOwner = {};
    let UnitsOccupiedByTenants = {};
    let UnitsOccupiedByOwners = {};
    let VacantUnits = {};
    let OccupancyRate = {};

    allData.forEach((data) => {
      TotalNoOfUnits[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].TotalNoOfUnits;
      TotalNoOfUnits[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].TotalNoOfUnits;

      TurnedOverUnitsfromDevelopertoOwner[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].TurnedOverUnitsfromDevelopertoOwner;
      TurnedOverUnitsfromDevelopertoOwner[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].TurnedOverUnitsfromDevelopertoOwner;

      UnitsUnderOwner[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].UnitsUnderOwner;
      UnitsUnderOwner[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].UnitsUnderOwner;

      UnitsOccupiedByTenants[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].UnitsOccupiedByTenants;
      UnitsOccupiedByTenants[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].UnitsOccupiedByTenants;

      UnitsOccupiedByOwners[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].UnitsOccupiedByOwners;
      UnitsOccupiedByOwners[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].UnitsOccupiedByOwners;

      VacantUnits[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].VacantUnits;
      VacantUnits[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].VacantUnits;

      OccupancyRate[`${data.data[0].residential[0].id}`] =
        data.data[0].residential[0].OccupancyRate;
      OccupancyRate[`${data.data[0].parking[0].id}`] =
        data.data[0].parking[0].OccupancyRate;
    });

    console.log(TotalNoOfUnits);

    return [
      TotalNoOfUnits,
      TurnedOverUnitsfromDevelopertoOwner,
      UnitsUnderOwner,
      UnitsOccupiedByTenants,
      UnitsOccupiedByOwners,
      VacantUnits,
      OccupancyRate,
    ];
  }, []);

  // Initialize useTable and get its properties and functions
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <div className='table-container'>
      <table
        {...getTableProps()}
        className='admin-table occupy-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => {
                    // Apply the header cell props
                    console.log(column);
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={column.parent ? 'sub-header' : ''}
                        key={index}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    );
                  })
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              console.log('ROW', row);
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  key={index}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default OccupancyTable;
