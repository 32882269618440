// Packages
import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

// Components
import OccupancyTable from './OccupancyTable';

// CSS/SCSS
import './OccupanyContent.scss';

const OccupanyContent = () => {
  // Static titles
  const titles = [
    {
      id: 1,
      title: 'Building 1',
    },
    {
      id: 2,
      title: 'Building 2',
    },
    {
      id: 3,
      title: 'Building 3',
    },
    {
      id: 4,
      title: 'Building 4',
    },
    {
      id: 5,
      title: 'Building 5',
    },
  ];

  // Static details
  const details = [
    {
      id: 1,
      name: 'Total No. of Units',
    },
    {
      id: 2,
      name: 'Turned Over Units from Developer to Owner',
    },
    {
      id: 3,
      name: 'Units Under Owner',
    },
    {
      id: 4,
      name: 'Units Occupied by Tenants',
    },
    {
      id: 5,
      name: 'Units Occupied by Owners',
    },
    {
      id: 6,
      name: 'Vacant Units',
    },
    {
      id: 7,
      name: 'Occupancy Rate',
    },
  ];

  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={2}>
            <Form.Group
              controlId='formSearch'
              className='mb-4 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group
              className='mb-4'
              controlId='formBuilding'
            >
              <Form.Select aria-label='Select Building'>
                {titles.map((val) => {
                  return (
                    <option
                      key={val.id}
                      value={val.id}
                    >
                      {val.title}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        <Row>
          <Col md={5}>
            <div className='details'>
              <h3>Details</h3>
              <ul className='detail-lists'>
                {details &&
                  details.map((detail) => {
                    return <li key={detail.id}>{detail.name}</li>;
                  })}
              </ul>
            </div>
          </Col>
          <Col md={7}>
            <OccupancyTable />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default OccupanyContent;
