// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import CollectionList from './CollectionList/CollectionList';

function SummaryCollections() {
  return (
    <Fragment>
      <Helmet>
        <title>Government Requirements</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Collections'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Collections'
                title='Collections'
              >
                <CollectionList />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default SummaryCollections;
