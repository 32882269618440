// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { Dropdown } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
// Components

// CSS/SCSS
import './OpexTable.scss';

function OpexTable() {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Sub-Category',
        accessor: 'subCategory',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // dropdown on table action
    const renderAction = () => {
      return (
        <Dropdown>
          <Dropdown.Toggle>
            <BsThreeDots size={40} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href='#/action-1'>Upload Document</Dropdown.Item>
            <Dropdown.Item href='#/action-2'>View</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        category: 'Building Equipment',
        subCategory: 'Repainting of Building Exterior',
        amount: '1,234,567,890',
      },
      {
        id: 2,
        category: 'Machinery & Equipment',
        subCategory: 'Lawnmower',
        amount: '1,234,567,890',
      },
      {
        id: 3,
        category: 'Machinery & Equipment',
        subCategory: 'Lawnmower',
        amount: '1,234,567,890',
      },
      {
        id: 4,
        category: 'Office Furnitures & Equipment',
        subCategory: 'Printer',
        amount: '1,234,567,890',
      },
      {
        id: 5,
        category: 'Security & Safety Equipement',
        subCategory: 'CCTV Camera',
        amount: '1,234,567,890',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        category: data.category,
        subCategory: data.subCategory,
        amount: data.amount,
        action: renderAction(data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='opex-container-table'>
      <table
        style={{ width: '100%' }}
        {...getTableProps()}
        className='data-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, idx) => (
                    // Apply the header cell props
                    <th
                      key={idx}
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  key={index}
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, idx) => {
                      // Apply the cell props
                      return (
                        <td
                          key={idx}
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default OpexTable;
