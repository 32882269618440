/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Packages
import {
  fileViolation,
  getAnouncementList,
  getIncidentReportList,
  pmoremarksSubmit,
} from 'api/request';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
// CSS
import './ViewIncidentReportContent.scss';

// Main Component
const ViewIncidentReportContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [msg, setMsg] = useState({ message: '', status: '' });

  const [userData, setUserData] = useState({
    reference_id: '',
    subject: '',
    date: '',
    summary: '',
    action_taken: '',
    recommendation: '',
    prepared_by: '',
    pmo_remarks: '',
    image: [],
  });
  useEffect(() => {
    const param = `/api/v1/incident-reports/${id}`;
    getIncidentReportList(param, callback);
  }, []);

  const fileViolationhandler = (e) => {
    fileViolation(id, '', submitCallback);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const params = { pmo_remarks: userData.pmo_remarks };
    pmoremarksSubmit(id, params, submitCallback);
  };
  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({
          message: data.message,
          status: 'success',
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prev) => {
          return {
            ...prev,
            reference_id: data?.data?.attributes?.reference_id,
            subject: data?.data?.attributes?.subject,
            date: data?.data?.attributes?.date,
            summary: data?.data?.attributes?.summary,
            action_taken: data?.data?.attributes?.action_taken,
            recommendation: data?.data?.attributes?.recommendation,
            prepared_by: data?.data?.attributes?.prepared_by,
            image: data?.data?.attributes?.image,
          };
        });
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm view-incident'>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='incidentno'
            >
              <Form.Label>Incident No.</Form.Label>
              <Form.Control
                placeholder={userData.reference_id}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formSubject'
            >
              <Form.Label>Subject</Form.Label>
              <Form.Control
                aria-label='Enter Subject Here'
                placeholder={userData.subject}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-2'
              controlId='formDate'
            >
              <Form.Label>Date</Form.Label>
              <Form.Control
                type='text'
                aria-label='Enter Date'
                placeholder={userData.date}
                readOnly
              />
            </Form.Group>
          </Col>

          <Col>
            <div className='d-flex'>
              <div
                onClick={() => {
                  enlargePhoto(userData.image.url);
                }}
                style={{
                  cursor: 'pointer',
                  backgroundImage: `url('${userData.image.url}')`,
                  width: '100px',
                  height: '100px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                }}
                className='image-cont'
              ></div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>{userData.image.name}</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formIncidentSummary'
            >
              <Form.Label>Incident Summary</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder={userData.summary}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formActionTaken'
            >
              <Form.Label>Action Taken</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder={userData.action_taken}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formRecommendation'
            >
              <Form.Label>Recommendation</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder={userData.recommendation}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formPreparedBy'
            >
              <Form.Label>Prepared By:</Form.Label>
              <Form.Control
                aria-label='Enter Full Name Here'
                placeholder={userData.prepared_by}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formPMORemarks'
            >
              <Form.Label>PMO Remarks</Form.Label>
              <Form.Control
                as='textarea'
                aria-label='Enter Remarks Here'
                placeholder='Enter Remarks Here'
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, pmo_remarks: e.target.value };
                  })
                }
                rows={5}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              controlId='formViolation'
              className='mb-2 violation-btn'
            >
              <Form.Label>
                Filing a violation? File this report as a Violation instead:
              </Form.Label>
              <Button
                className='violation'
                onClick={fileViolationhandler}
              >
                File Violation
              </Button>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                as={Link}
                to={'/security/incident-report'}
                className='cancel'
                variant='default'
                style={{ padding: '0.8em 2em' }}
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <span
          style={{
            color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
            display: 'block',
          }}
        >{`${msg.message}`}</span>
      </Form>
    </Card>
  );
};

export default ViewIncidentReportContent;
