/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Download from '../../../../components/SVG/Download';

// import components below

// import assets below
import './View.scss';
import { useEffect } from 'react';
import { moveinmoveoutApproveForm, viewMoveinMoveout } from 'api/request';

// main component
const View = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // response api here
  const [msg, setMsg] = useState({ message: '', status: '' });

  const [submitData, setSubmitData] = useState({
    utility_electricity_serial: '',
    utility_electricity_reading: '',
    utility_water_serial: '',
    utility_water_reading: '',
  });
  const [submit, setSubmit] = useState(null);
  const [getData, setData] = useState({
    lease_expiry_date: '',
    property_engineer_name: '',
    reference_id: '',
    remarks: '',
    representative_contact_number: '',
    representative_name: '',
    request_move_id: '',
    schedule_date: '',
    status: '',
    type: '',
    utility_electricity_reading: '',
    utility_electricity_serial: '',
    utility_water_reading: '',
    utility_water_serial: '',
    unit_number: '',
    name: '',
  });

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const params = {
      utility_electricity_serial: submitData.utility_electricity_serial,
      utility_electricity_reading: submitData.utility_electricity_reading,
      utility_water_serial: submitData.utility_water_serial,
      utility_water_reading: submitData.utility_water_reading,
    };
    console.log(params);

    setSubmit(params);
  };
  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      moveinmoveoutApproveForm(id, submit, submitcallback);
    }
  }, [submit]);

  useEffect(() => {
    viewMoveinMoveout(id, callback);
  }, []);
  useEffect(() => {
    if (msg.message === 'Request Move approved successfully.') {
      console.log('suc');
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    }
  }, [msg]);
  const submitcallback = async (res) => {
    const { data, status } = await res;
    console.log('test');
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'success' });
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
        setMsg({ message: data.message, status: 'deny' });
      }
    }
  };
  // Handle Response from api's
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setData((prev) => {
          return {
            ...prev,
            schedule_date: data?.data?.attributes?.schedule_date,
            lease_expiry_date: data?.data?.attributes?.lease_expiry_date,
            unit_number:
              data?.data?.relationships?.unit?.attributes.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            representative_name: data?.data?.attributes?.representative_name,
            representative_contact_number:
              data?.data?.attributes?.representative_contact_number,
            property_engineer_name:
              data?.data?.attributes?.property_engineer_name,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  return (
    <div>
      <Form
        className='moveInOut_viewRequest'
        onSubmit={onSubmitHandler}
      >
        {' '}
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Move In Date</h6>
              <span>{getData.schedule_date}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Lease Expiry Date</h6>
              <span>{getData.lease_expiry_date}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Unit No.</h6>
              <span>{getData.unit_number}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Name</h6>
              <span>{getData.name}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Authorized Representative Name</h6>
              <span>{getData.representative_name}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Representative Contact No.</h6>
              <span>{getData.representative_contact_number}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg='4'>
            <div>
              <h6 className='mb-3'>Property Engr. Name</h6>
              <span>{getData.property_engineer_name}</span>
            </div>
          </Col>
        </Row>
        <h6>UTILITY READING</h6>
        <Row>
          <Col>
            <div>
              <h6>Utility</h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>Serial No.</h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>Move In Reading</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Form.Label>Electricity</Form.Label>
            </div>
          </Col>
          <Col>
            <div>
              <Form.Control
                type='text'
                value={submitData.utility_electricity_serial}
                onChange={(e) => {
                  setSubmitData((prev) => {
                    return {
                      ...prev,
                      utility_electricity_serial: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <div>
              <Form.Control
                type='text'
                value={submitData.utility_electricity_reading}
                onChange={(e) => {
                  setSubmitData((prev) => {
                    return {
                      ...prev,
                      utility_electricity_reading: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Form.Label>Water</Form.Label>
            </div>
          </Col>
          <Col>
            <div>
              <Form.Control
                type='text'
                value={submitData.utility_water_serial}
                onChange={(e) => {
                  setSubmitData((prev) => {
                    return {
                      ...prev,
                      utility_water_serial: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </Col>
          <Col>
            <div>
              <Form.Control
                type='text'
                value={submitData.utility_water_reading}
                onChange={(e) => {
                  setSubmitData((prev) => {
                    return {
                      ...prev,
                      utility_water_reading: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mt-4 mb-3'>Date Taken</h6>
              <span>Jan. 20, 2021</span>
              <Button variant='submit'>{Download()} Download</Button>
            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-between'>
          <div>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </div>
          <div>
            <Button
              variant='approve'
              type='submit'
            >
              Approve
            </Button>
            <Link
              className='btn btn-deny'
              to={`/administration/requests/move-in-out/deny-request/${id}/?activeKey=requests`}
            >
              Deny
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default View;
