// import core & vendor packages below
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

// import components below
import LoginUI from '../../components/UI/LoginUI';
import CompanyLogo from '../../components/CompanyLogo';
import FormForgotPassword from './FormForgotPassword';

// import api requests below
import { forgotPass } from '../../api/request';

// import assets below
import './style.scss';

// main component
const ForgotPasswordPage = () => {
  // State
  const [params, setParams] = useState({ email: '' });
  const [msg, setMsg] = useState('');

  // useForm
  const {
    register,
    handleSubmit,
    // watch,
    reset,
    formState: { errors },
  } = useForm();

  // Handle on Forgot Pass Button click
  const handleForgotPass = (data) => {
    const params = {
      email: data.email,
    };

    setMsg('');
    forgotPass(params, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        setParams((prevState) => {
          return { ...prevState, email: '' };
        });
        reset();
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>DGCondo</title>
      </Helmet>
      <div className='login'>
        <Form onSubmit={handleSubmit(handleForgotPass)}>
          <LoginUI>
            <div className='loginUI__header'>
              <CompanyLogo fontSize='3rem' />
              <h3 className='loginUI__title'>Forgot Password</h3>
              <p>
                Enter your email address and we will send you a link to reset
                your password.
              </p>
            </div>
            <div className='loginUI__body'>
              <FormForgotPassword
                params={params}
                register={register}
                setParams={setParams}
                errors={errors}
              />
            </div>
            <div className='login_error_label mb-2'>
              <label>{msg}</label>
            </div>
            <div className='loginUI__footer'>
              <Link
                className='loginUI__link'
                to='/forgot-password'
              >
                Forgot password?
              </Link>
              <Button
                type='submit'
                className='loginUI__button'
              >
                Send Link
              </Button>
            </div>
          </LoginUI>
        </Form>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordPage;
