import React from 'react';
import { Col } from 'react-bootstrap';

// import components below
import CollectionEfficiencyTableLeft from './TableLeft';
import CollectionEfficiencyTableRight from './TableRight';

function CollectionsEfficiencyList() {
  return (
    <div className='collectionEfficiency'>
      <Col lg='4'>
        <CollectionEfficiencyTableLeft />
      </Col>
      <Col lg='8'>
        <CollectionEfficiencyTableRight />
      </Col>
    </div>
  );
}

export default CollectionsEfficiencyList;
