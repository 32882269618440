/* eslint-disable no-unused-vars */
// Packages
import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// Components
import ViolationReportTable from './ViolationReportTable';

// CSS/SCSS
import './ViolationReport.scss';
import TablePagination from 'components/Pagination';

// Main Component
const ViolationReport = ({
  setSelectedBuilding,
  selectedBuilding,
  buildings,
  tableData,
  setSearch,
  setViolation,
  links,
  meta,
  selectPage,
  setViolationForm,
  setActiveKey,
  violationFormHandler,
}) => {
  useEffect(() => {
    setSelectedBuilding(selectedBuilding);
    setSearch('');
  }, []);
  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={2}>
            {' '}
            <Form.Group
              className='mb-5'
              controlId='formTitle'
            >
              <Form.Select
                onChange={(e) => {
                  setSelectedBuilding(e.target.value);
                }}
              >
                {buildings &&
                  buildings.length > 0 &&
                  buildings.map((list, idx) => {
                    if (selectedBuilding === '' && idx === 0) {
                      setSelectedBuilding(list.id);
                    }
                    return (
                      <option
                        key={list.id}
                        value={list.id}
                      >
                        {list?.attributes?.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-4 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
                onChange={(e) => setSearch(e.target.value)}
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div
              className='edit-container'
              style={{ textAlign: 'left' }}
            >
              <DropdownButton
                id='filter'
                title='Filter'
              >
                <Dropdown.Item href='#/action-1'>Name</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Violation</Dropdown.Item>
                <Dropdown.Item href='#/action-3'>Date and Time</Dropdown.Item>
                <Dropdown.Item href='#/action-4'>No. of Offense</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        <ViolationReportTable
          tableData={tableData}
          setViolation={setViolation}
          setViolationForm={setViolationForm}
          setActiveKey={setActiveKey}
          violationFormHandler={violationFormHandler}
        />
        <TablePagination
          links={links}
          meta={meta}
          selectPage={selectPage}
        />
      </Card>
    </>
  );
};

export default ViolationReport;
