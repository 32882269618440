// packages
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Main Component
const CalendarList = (props) => {
  // get data from props
  const { apiData } = props;

  // To render on screen
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className='calendar-list'>
      {apiData &&
        apiData.map((data) => {
          return (
            <div
              className='list'
              key={data.id}
            >
              <div className='text'>
                <h4>{data.date}</h4>
                <p>{data.document}</p>
                <p>{data.entity}</p>
                <p>Assigned To: {data.assigned}</p>
              </div>
              <div>
                <Button
                  as={Link}
                  to={`/administration/government-requirements/view/${data.id}`}
                  className='view'
                  variant='primary'
                  style={{
                    padding: '0.8em 2em',
                    backgroundColor: '#00B6E9',
                    border: 'none',
                    borderRadius: '7px',
                  }}
                >
                  View
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CalendarList;
