/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS

// Components

// API

// CSS

// Main Component
const ViewForm = () => {
  // States
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };

  // To render on screen
  return (
    <Card className='admin-form view-form shadow-sm'>
      <Form>
        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='name'
            >
              <Form.Label>Tenant Name</Form.Label>

              <Form.Control
                aria-label='Tenant Name'
                type='text'
                placeholder='Tenant Name here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='month'
            >
              <Form.Label>Month</Form.Label>

              <Form.Control
                aria-label='Month'
                s
                type='text'
                placeholder='Month here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='billing'
            >
              <Form.Label>Billing</Form.Label>

              <Form.Control
                aria-label='Billing'
                type='text'
                placeholder='Billing here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='amountPaid'
            >
              <Form.Label>Amount Paid</Form.Label>

              <Form.Control
                aria-label='Amount Paid'
                type='number'
                placeholder='Amount Paid here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='paymentDue'
            >
              <Form.Label>Payment Due</Form.Label>

              <Form.Control
                aria-label='Payment Due'
                type='date'
                placeholder='Payment Due here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            as={Link}
            to={'/billing/payment-history'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Back
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViewForm;
