/* eslint-disable camelcase */
// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';

// import components below
import CalendarUI from '../../../../components/UI/CalendarUI';
import FormUI from '../../../../components/UI/FormUI';
import convertDate from '../../../../helpers/convertDate';

// import assets below
import './View.scss';
import Search from '../../../../components/SVG/Search';
import CaretLeft from '../../../../components/SVG/CaretLeft';
import CaretRight from '../../../../components/SVG/CaretRight';

// API
import { getConcernByReferenceId, denyConcern } from 'api/request';

// main component
const View = () => {
  // Useform
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(convertDate(new Date()));
  const [msg, setMsg] = useState({
    message: '',
    status: '',
  });
  const [pmoRemarks, setPmoRemarks] = useState([]);
  const [showAddRemark, setShowAddRemark] = useState(false);
  const [userData, setUserData] = useState({
    concern: '',
    subject: '',
    unitNo: '',
    name: '',
    accountType: '',
    description: '',
    status: '',
    images: [],
  });

  // eslint-disable-next-line no-unused-vars
  const [submitData, setSubmitData] = useState({
    options: [],
    selectedOption: [],
    remarks: '',
  });

  const onAddRemarks = (e) => {
    const listData = {
      id: e?.selectedDates?.id,
      date: e?.selectedDates.label,
      remarks: e?.remarks,
    };

    setPmoRemarks((prevState) => {
      return [...prevState, listData];
    });

    setSubmitData((prevState) => {
      return {
        ...prevState,
        selectedOption: [],
        remarks: '',
      };
    });

    reset();
    setShowAddRemark(false);
  };

  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };

  const onSubmit = () => {
    setMsg({ message: '', status: '' });

    if (pmoRemarks.length === 0) {
      setMsg({ message: 'Please add remarks', status: 'failed' });
      return;
    }

    const ids = pmoRemarks.map((list) => {
      return {
        declined_client_date_id: list.id,
        remarks: list.remarks,
      };
    });

    const params = {
      concern_date: ids,
    };

    console.log(ids);
    denyConcern(id, params, denyCallback);
  };

  useEffect(() => {
    getConcernByReferenceId(id, '', callback);
  }, []);

  const denyCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Successfully Approved', status: 'success' });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const options = data.data.relationships.concern_dates.map(
          (list, idx) => {
            return {
              id: list?.id,
              value: `${idx + 1}: ${list?.attributes?.date} ${
                list?.attributes?.time_start
              } - ${list?.attributes?.date} ${list?.attributes?.time_end}`,
              label: `${idx + 1}: ${list?.attributes?.date} ${
                list?.attributes?.time_start
              } - ${list?.attributes?.date} ${list?.attributes?.time_end}`,
            };
          },
        );

        setUserData((prevState) => {
          return {
            ...prevState,
            concern: data?.data?.attributes?.type,
            subject: data?.data?.attributes?.subject,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            accountType: data?.data?.relationships?.user?.attributes?.role,
            description: data?.data?.attributes?.description,
            status: data?.data?.attributes?.status,
            images: data?.data?.attributes?.concern_images,
          };
        });

        setSubmitData((prevState) => {
          return {
            ...prevState,
            options: options,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <div className='concerns_addRemarks'>
      <Row>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Concern</h6>
            <span>{userData.concern}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Subject</h6>
            <span>{userData.subject}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Unit No.</h6>
            <span>{userData.unitNo}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Name</h6>
            <span>{userData.name}</span>
          </div>
        </Col>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Account Type</h6>
            <span>{userData.accountType}</span>
          </div>
        </Col>
        <Col lg='12'>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Description</h6>
            <span>{userData.description}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='d-flex flex-column'>
            <h6 className='mb-3'>Images</h6>
            <Row>
              {userData && userData.images.length > 0 && (
                <>
                  {userData.images.map((list, idx) => {
                    return (
                      <Col
                        md={4}
                        key={idx}
                      >
                        <div
                          onClick={() => {
                            enlargePhoto(list.url);
                          }}
                          style={{ backgroundImage: `url('${list.url}')` }}
                          className='photo'
                        >
                          <div>
                            {Search()}
                            <span>Click to Enlarge Photo</span>
                          </div>
                          <div className='image'></div>
                        </div>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <h6>CLIENT&#39;S AVAILABLE SCHEDULE FOR REPAIR</h6>
        <CalendarUI>
          <Calendar
            calendarType='US'
            onChange={onChange}
            value={value}
            prevLabel={CaretLeft()}
            nextLabel={CaretRight()}
            minDate={new Date()}
            onClickDay={(value) => {
              setSelectedDate(convertDate(value));
            }}
            tileContent={(value) => {
              return (
                <div
                  className='available-time'
                  style={{
                    display:
                      convertDate(value.date) === selectedDate
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <h6>Available Time:</h6>
                  <span>9:00AM - 4:00PM</span>
                </div>
              );
            }}
          />
        </CalendarUI>
      </div>
      <Row>
        <Col>
          {pmoRemarks && pmoRemarks.length > 0 && (
            <>
              {pmoRemarks.map((list, idx) => {
                return (
                  <div
                    key={idx}
                    className='pmoRemarks'
                  >
                    <div className='header'>
                      <h6 className='mb-3'>PMO Remarks</h6>
                      <div>
                        <span>{list?.date}</span>
                        <Button variant='submit'>Completed</Button>
                      </div>
                    </div>
                    <div className='body'>
                      <p>{list?.remarks}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => {
              setShowAddRemark(true);
            }}
          >
            Add Remark
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {showAddRemark && (
            <div className='addNewRemark'>
              <Form onSubmit={handleSubmit(onAddRemarks)}>
                <FormUI>
                  <div className='header'>
                    <h6 className='mb-4'>Add New Remark</h6>
                    <Form.Group className='mb-4'>
                      <Form.Label>
                        Select Date and Time{' '}
                        {errors.selectedDates ? (
                          <span style={{ color: '#ff5c5c' }}>
                            {errors.selectedDates.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Select
                            // isMulti
                            name='colors'
                            options={submitData.options}
                            className='basic-multi-select'
                            classNamePrefix='select'
                            {...field}
                          />
                        )}
                        {...register('selectedDates', {
                          required: '(Required)',
                          value: submitData.selectedOption,
                          onChange: (event) => {
                            setSubmitData((prevState) => {
                              return {
                                ...prevState,
                                selectedOption: event.target.value,
                              };
                            });
                          },
                        })}
                      />
                    </Form.Group>
                  </div>
                  <div className='body'>
                    <Form.Group className='mb-4'>
                      <Form.Label>
                        Remarks{' '}
                        {errors.remarks ? (
                          <span style={{ color: '#ff5c5c' }}>
                            {errors.remarks.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <Form.Control
                        as='textarea'
                        rows='5'
                        {...register('remarks', {
                          required: '(Required)',
                          onChange: (event) => {
                            setSubmitData((prevState) => {
                              return {
                                ...prevState,
                                remarks: event.target.value,
                              };
                            });
                          },
                        })}
                        value={submitData.remarks}
                      />
                    </Form.Group>
                  </div>
                  <Row>
                    <Col className='remarks-button'>
                      <Button type='submit'>Add</Button>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          setShowAddRemark(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </FormUI>
              </Form>
            </div>
          )}
        </Col>
      </Row>
      <Row className='send-button'>
        <Col className='message-cont'>
          <span
            style={{
              color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
              display: 'block',
            }}
          >{`${msg.message}`}</span>
        </Col>
        <Col className='send-button-cont'>
          <div>
            <Button
              variant='submit'
              onClick={onSubmit}
            >
              Send
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
