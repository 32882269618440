/* eslint-disable no-unused-vars */
// import core & vendor packages
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../components/UI/TabsUI';
import GatePassForm from './Form';
import GatePassFilter from './Filter';
import GatePassTable from './Table';
import GatePassPagination from './Pagination';
import userContext from 'components/userContext/userContext';

// API
import {
  getBuildingLists,
  getPaymentInstruction,
  userAccounts,
  submitGatePass,
  getGatePass,
} from 'api/request';

import { getControlNo, cancelGatePass } from 'api/GatePass';

// main component
const GatePassTabs = () => {
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const [controlNo, setControlNo] = useState('');

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?building_id=${userUnit?.buildingId}${page}&filter[search]=${search}&include=building,unit,items,requestedBy&unit_id=${userUnit?.id}${page}`;

      getGatePass(params, gatepassCallback);
    } else {
      const params = `?building_id=${selectedBuilding}${page}&filter[search]=${search}&include=building,unit,items,requestedBy`;

      getGatePass(params, gatepassCallback);
    }
  };

  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?building_id=${userUnit?.buildingId}&unit_id=${userUnit?.id}&include=building,unit,items,requestedBy`;
      getGatePass(params, getGatePassCallback);
      getControlNo(controlCallback);
    } else {
      setLoading(true);
      const params = `?building_id=${selectedBuilding}&include=building,unit,items,requestedBy`;

      getGatePass(params, getGatePassCallback);
      getControlNo(controlCallback);
    }
  };

  const deleteSingleListData = (storeId) => {
    cancelGatePass(storeId, cancelCallback);
  };

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      submitGatePass(submit, submitCallback);
    }
  }, [submit]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `?building_id=${userUnit?.buildingId}&unit_id=${userUnit?.id}&include=building,unit,items,requestedBy`;

        getGatePass(params, gatepassCallback);
      } else {
        const params = `?building_id=${selectedBuilding}&include=building,unit,items,requestedBy`;

        getGatePass(params, gatepassCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `?building_id=${userUnit?.buildingId}&filter[search]=${search}&unit_id=${userUnit?.id}&include=building,unit,items,requestedBy`;

        getGatePass(params, gatepassCallback);
      } else {
        const params = `?building_id=${selectedBuilding}&filter[search]=${search}&include=building,unit,items,requestedBy`;

        getGatePass(params, gatepassCallback);
      }
    }
  }, [search]);

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
  }, []);

  useEffect(() => {
    if (controlNo === '') {
      getControlNo(controlCallback);
    }
  }, [controlNo]);

  const controlCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setControlNo(data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const gatepassCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        getControlNo(controlCallback);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const getGatePassCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const cancelCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        refreshTable();
        setMsg({ message: data.message, status: 'success' });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='gatePassForm'
        className='mb-3'
      >
        <Tab
          eventKey='gatePassForm'
          title='Gate Pass Form'
        >
          {loading && <p>Loading...</p>}
          {!loading && (
            <GatePassForm
              buildings={buildings}
              setSelectedUnit={setSelectedUnit}
              names={names}
              setSubmit={setSubmit}
              msg={msg}
              user={user}
              setMsg={setMsg}
              userUnit={userUnit}
              controlNo={controlNo}
            />
          )}
        </Tab>
        <Tab
          eventKey='gatePassList'
          title='Gate Pass List'
        >
          {loading && <p>Loading...</p>}
          {!loading && (
            <>
              <GatePassFilter
                setSearch={setSearch}
                buildings={buildings}
                setSelectedBuilding={setSelectedBuilding}
                selectedBuilding={selectedBuilding}
                user={user}
                userUnit={userUnit}
              />
              <GatePassTable
                tableData={tableData}
                user={user}
                refreshTable={refreshTable}
                deleteSingleListData={deleteSingleListData}
                msg={msg}
              />
              <GatePassPagination
                links={links}
                meta={meta}
                selectPage={selectPage}
              />
            </>
          )}
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default GatePassTabs;
