// CSS
import './style.scss';

const CompanyLogo = (props) => {
  const { fontSize } = props;

  return (
    <div
      className='companyLogo'
      style={{ fontSize }}
    >
      <span>DG</span>
      <span>CONDO</span>
    </div>
  );
};

export default CompanyLogo;
