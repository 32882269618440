// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Col, Tab } from 'react-bootstrap';

// Components
import Main from '../../components/Main';

// API

// CSS
import './CollectionsPage.scss';
import UploadCollectionFile from './UploadCollectionFile/UploadCollectionFile';
import CollectionList from './CollectionList/CollectionList';

const CollectionsPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Collections</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Upload Collection File'
                id='Upload Collection File'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Upload Collection File'
                  title='Upload Collection File'
                >
                  <UploadCollectionFile />
                </Tab>
                <Tab
                  eventKey='Collection List'
                  title='Collection List'
                >
                  <CollectionList />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default CollectionsPage;
