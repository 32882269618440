// import core and vendor packages below
import { Pagination } from 'react-bootstrap';
import PaginationUI from 'components/UI/PaginationUI';

// main component
const TablePagination = ({ links, meta, selectPage }) => {
  return (
    <PaginationUI>
      <Pagination>
        {links?.prev !== null && (
          <Pagination.Item
            onClick={() => {
              selectPage(`&page=${parseInt(meta?.current_page - 1)}`);
            }}
          >
            {parseInt(meta?.current_page - 1)}
          </Pagination.Item>
        )}
        <Pagination.Item active>{meta?.current_page}</Pagination.Item>

        {links?.next !== null && (
          <Pagination.Item
            onClick={() => {
              selectPage(`&page=${parseInt(meta?.current_page + 1)}`);
            }}
          >
            {parseInt(meta?.current_page + 1)}
          </Pagination.Item>
        )}
      </Pagination>
      <div className='pagination__summary'>
        <span>
          Showing {meta?.to || 0} out of {meta?.total} entries
        </span>
      </div>
    </PaginationUI>
  );
};

export default TablePagination;
