/* eslint-disable no-unused-vars */
// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS
import './ViewForm.scss';

// Components

// API

// CSS

// Main Component
const ViewForm = () => {
  // States
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };

  // To render on screen
  return (
    <Card className='admin-form view-form shadow-sm'>
      <Form>
        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='document'
            >
              <Form.Label>Nature of Engagement</Form.Label>

              <Form.Control
                aria-label='Nature of Engagement'
                type='text'
                placeholder='Nature of Engagement here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='entity'
            >
              <Form.Label>Entity</Form.Label>

              <Form.Control
                aria-label='Entity'
                s
                type='text'
                placeholder='Entity here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='periodCovered'
            >
              <Form.Label>Period Covered</Form.Label>

              <Form.Control
                aria-label='Period Covered'
                type='text'
                placeholder='Period Covered here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='amount'
            >
              <Form.Label>Amount</Form.Label>

              <Form.Control
                aria-label='Amount'
                type='number'
                placeholder='Amount here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='projectDescription'
            >
              <Form.Label>Project Description</Form.Label>

              <Form.Control
                aria-label='Project Description'
                type='number'
                placeholder='Project Description here'
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5 document-no'
              controlId='document'
            >
              <Form.Label>Document</Form.Label>

              <Form.Control
                aria-label='Document'
                type='number'
                placeholder='Document here'
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='buttons'>
          <Button
            as={Link}
            to={'/administration/contracts/general-contracts'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Back
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViewForm;
