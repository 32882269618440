/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import FormUI from 'components/UI/FormUI';
import { useForm, Controller } from 'react-hook-form';
import './ApplicationForm.scss';
import Select from 'react-select';
import Moment from 'moment';
function ApplicationForm({
  setSubmit,
  buildings,
  msg,
  search,
  user,
  userUnit,
}) {
  const [value, onChange] = useState(new Date());
  const role = user.attributes.role;
  // Upload File
  const [name, setName] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    home_address: '',
    mobile_no: '',
    date_of_birth: '',
    gender: '',
    citizenship: '',
    occupation: '',
    email: '',
    company_name: '',
    company_address: '',
    contact_person: '',
    contact_no: '',
    contact_email: '',
    id_picture_img: '',
    nbi_clearance_img: '',
    valid_id_img: '',
    building_id: '',
  });
  const [buildingList, setbuildingList] = useState([]);
  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const onSubmit = (e) => {
    const newFormData = new FormData();
    newFormData.append('name', userData.name);

    newFormData.append(
      'building_id',
      role === 'admin'
        ? userData?.building_id?.id
        : user?.relationships?.owner?.relationships?.units[0]
            ?.relationships?.building?.id,
    );

    newFormData.append('home_address', userData.home_address);
    newFormData.append('mobile_number', userData.mobile_no);
    newFormData.append('date_of_birth', userData.date_of_birth);
    newFormData.append('gender', userData.gender);
    newFormData.append('citizenship', userData.citizenship);
    newFormData.append('occupation', userData.occupation);
    newFormData.append('email', userData.email);
    newFormData.append('company_name', userData.company_name);
    newFormData.append('company_address', userData.company_address);
    newFormData.append('emergency_contact_person', userData.contact_person);
    newFormData.append('emergency_contact_number', userData.contact_no);
    newFormData.append('emergency_contact_email', userData.contact_email);
    newFormData.append('id_picture_img', userData.id_picture_img);
    newFormData.append('nbi_clearance_img', userData.nbi_clearance_img);
    newFormData.append('valid_id_img', userData.valid_id_img);

    console.log(newFormData);
    console.log(userData);
    setSubmit(newFormData);
  };
  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setbuildingList(newBuildings);
    }
  }, [buildings]);
  useEffect(() => {
    if (msg.message === 'Staff created successfully.') {
      setValue('name', '');
      setValue('home_address', '');
      setValue('mobile_no', '');
      setValue('date_of_birth', '');
      setValue('gender', '');
      setValue('citizenship', '');
      setValue('occupation', '');
      setValue('email', '');
      setValue('company_name', '');
      setValue('company_address', '');
      setValue('contact_person', '');
      setValue('contact_no', '');
      setValue('contact_email', '');
      setValue('id_picture_img', '');
      setValue('nbi_clearance_img', '');
      setValue('valid_id_img', '');

      setUserData({
        name: '',
        home_address: '',
        mobile_no: '',
        date_of_birth: '',
        gender: '',
        citizenship: '',
        occupation: '',
        email: '',
        company_name: '',
        company_address: '',
        contact_person: '',
        contact_no: '',
        contact_email: '',
        id_picture_img: '',
        nbi_clearance_img: '',
        valid_id_img: '',
      });
    }
  }, [msg]);
  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };
  return (
    <div className='applicationForm staff-id'>
      <FormUI>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <div className='col-lg-4'>
              <Form.Label>
                Staff Name{' '}
                {errors.name ? (
                  <span style={{ color: '#ff5c5c' }}>*</span>
                ) : (
                  <span>*</span>
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('name', {
                    required: '(Required)',
                    value: userData.name,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          name: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            {role === 'admin' && (
              <div className='col-lg-4'>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Building Name
                    {errors.building_id ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.building_id.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={buildingList}
                        placeholder='Select Building'
                        styles={customStyles}
                        {...field}
                      ></Select>
                    )}
                    {...register('building_id', {
                      required: '(Required)',
                      value: userData.building_id,
                      onChange: (event) => {
                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            building_id: event.target.value,
                          };
                        });
                      },
                    })}
                  />
                </Form.Group>
              </div>
            )}
            <div className='col-lg-4'>
              <Form.Label>
                Home Address{' '}
                {errors.home_address ? (
                  <span style={{ color: '#ff5c5c' }}>*</span>
                ) : (
                  <span>*</span>
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='home_address'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('home_address', {
                    required: '(Required)',
                    value: userData.home_address,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          home_address: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Mobile No.{' '}
                {errors.mobile_no ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.mobile_no.message}
                  </span>
                ) : (
                  <span>*</span>
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='mobile_no'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('mobile_no', {
                    required: '(Required)',
                    maxLength: {
                      value: 11,
                      message: 'Max 11 digit', // JS only: <p>error message</p> TS only support string
                    },
                    value: userData.mobile_no,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          mobile_no: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Date of Birth*</Form.Label>
              <div>
                <Form.Control
                  placeholder='dada'
                  className='date'
                  type='date'
                  {...register('date_of_birth', {
                    required: '(Required)',
                    maxLength: {
                      value: 11,
                      message: 'Max 11 digit', // JS only: <p>error message</p> TS only support string
                    },
                    value: userData.date_of_birth,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          date_of_birth: Moment(event.target.value).format(
                            'YYYY-DD-MM',
                          ),
                        };
                      });
                    },
                  })}
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Gender{' '}
                {errors.name ? (
                  <span style={{ color: '#ff5c5c' }}>*</span>
                ) : (
                  <span>*</span>
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='formGender'
              >
                <Form.Select
                  aria-label='Select Gender'
                  defaultValue=''
                  {...register('gender', {
                    required: '(Required)',
                    value: userData.gender,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          gender: event.target.value,
                        };
                      });
                    },
                  })}
                >
                  <option
                    hidden
                    value=''
                  >
                    Select Gender
                  </option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Citizenship{' '}
                {errors.citizenship ? (
                  <span style={{ color: '#ff5c5c' }}>*</span>
                ) : (
                  <span>*</span>
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='citizenship'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Citizenship Here'
                  {...register('citizenship', {
                    required: '(Required)',
                    value: userData.citizenship,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          citizenship: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Occupation</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='occupation'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Occupation Here(Optional)'
                  {...register('occupation', {
                    value: userData.occupation,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          occupation: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Email Address</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='email'
                  placeholder='Enter Email Here (Optional)'
                  {...register('email', {
                    value: userData.email,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          email: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Company Name</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='company_name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Company Here (Optional)'
                  {...register('company_name', {
                    value: userData.company_name,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          company_name: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>

            <div className='col-lg-4'>
              <Form.Label>Address</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Address Here (Optional)'
                  {...register('company_address', {
                    value: userData.company_address,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          company_address: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Emergency Contact Person*</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='contact_person'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Name Here'
                  {...register('contact_person', {
                    required: '(Required)',

                    value: userData.contact_person,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          contact_person: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>
                Contact No.{' '}
                {errors.contact_no ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.contact_no.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Group
                className='mb-5'
                controlId='contact_no'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Contact No. Here'
                  {...register('contact_no', {
                    required: '(Required)',
                    maxLength: {
                      value: 11,
                      message: 'Max 11 digit', // JS only: <p>error message</p> TS only support string
                    },
                    value: userData.contact_no,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          contact_no: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-4'>
              <Form.Label>Contact Email Address</Form.Label>
              <Form.Group
                className='mb-5'
                controlId='name'
              >
                <Form.Control
                  type='text'
                  placeholder='Enter Email Here (Optional)'
                  {...register('contact_email', {
                    required: '(Required)',
                    value: userData.contact_email,
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          contact_email: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </div>
          </Row>

          <h3>Upload Documents</h3>

          <Row>
            <div className='col'>
              <div className='text-start'>
                <Form.Group
                  className='mb-4'
                  controlId='id_picture_img'
                >
                  <Form.Label className='my-4 fw-bold Form.Label'>
                    ID Picture*
                  </Form.Label>

                  <div className='upload-cont d-flex justify-content-start'>
                    <div className='upload-input'>
                      <label htmlFor='id_picture_img'>Select file</label>
                      <span>
                        {userData.id_picture_img && (
                          <div>
                            {userData.id_picture_img.length > 0 && (
                              <div>
                                {userData.id_picture_img.length} File
                                {userData.id_picture_img.length === 1 ? '' : ''}
                                Selected
                              </div>
                            )}
                            {(userData.id_picture_img.length === 0 ||
                              userData.id_picture_img === null) && (
                              <div>Upload file</div>
                            )}
                          </div>
                        )}
                      </span>
                      <Form.Control
                        type='file'
                        className='custom-file-upload'
                        aria-label='Click to Select File to Upload'
                        {...register('id_picture_img', {
                          value: userData.id_picture_img,
                          onChange: (e) => {
                            // eslint-disable-next-line prefer-const
                            let docuArr = [];

                            Array.from(e.target.files).forEach((file) => {
                              docuArr.push(file);
                            });
                            console.log(e.target.files[0]);
                            setUserData((prevState) => {
                              return {
                                ...prevState,
                                id_picture_img: e.target.files[0],
                              };
                            });
                          },
                        })}
                        accept='image/*'
                      ></Form.Control>
                    </div>

                    <span style={{ color: '#AFAFAF' }}>
                      {userData.id_picture_img && <>1 File Selected</>}

                      {userData.id_picture_img === '' && 'No Image Uploaded'}
                    </span>
                    <span>
                      {!userData.id_picture_img === '' &&
                        userData.id_picture_img}
                    </span>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className='col'>
              <Form.Group
                className='mb-4'
                controlId='nbi_clearance_img'
              >
                <Form.Label className='my-4 fw-bold Form.Label'>
                  NBI Clearance*
                </Form.Label>

                <div className='upload-cont d-flex justify-content-start'>
                  <div className='upload-input'>
                    <label htmlFor='nbi_clearance_img'>Select file</label>
                    <span>
                      {userData.nbi_clearance_img && (
                        <div>
                          {userData.nbi_clearance_img.length > 0 && (
                            <div>
                              {userData.nbi_clearance_img.length} File
                              {userData.nbi_clearance_img.length === 1
                                ? ''
                                : ''}{' '}
                              Selected
                            </div>
                          )}
                          {(userData.nbi_clearance_img.length === 0 ||
                            userData.nbi_clearance_img === null) && (
                            <div>Upload file</div>
                          )}
                        </div>
                      )}
                    </span>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      {...register('nbi_clearance_img', {
                        value: userData.nbi_clearance_img,
                        onChange: (e) => {
                          // eslint-disable-next-line prefer-const
                          let docuArr = [];

                          Array.from(e.target.files).forEach((file) => {
                            docuArr.push(file);
                          });
                          console.log(e.target.files);
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              nbi_clearance_img: e.target.files[0],
                            };
                          });
                        },
                      })}
                      accept='image/*'
                    ></Form.Control>
                  </div>

                  <span style={{ color: '#AFAFAF' }}>
                    {userData.nbi_clearance_img && <>1 File Selected</>}

                    {userData.nbi_clearance_img === '' && 'No Image Uploaded'}
                  </span>
                  <span>
                    {!userData.nbi_clearance_img === '' &&
                      userData.nbi_clearance_img}
                  </span>
                </div>
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group
                className='mb-4'
                controlId='valid_id_img'
              >
                <Form.Label className='my-4 fw-bold Form.Label'>
                  Valid ID
                </Form.Label>

                <div className='upload-cont d-flex justify-content-start'>
                  <div className='upload-input'>
                    <label htmlFor='valid_id_img'>Select file</label>
                    <span></span>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      {...register('valid_id_img', {
                        value: userData.valid_id_img,
                        onChange: (e) => {
                          // eslint-disable-next-line prefer-const
                          let docuArr = [];

                          Array.from(e.target.files).forEach((file) => {
                            docuArr.push(file);
                          });
                          console.log(e.target.files[0]);
                          setUserData((prevState) => {
                            return {
                              ...prevState,
                              valid_id_img: e.target.files[0],
                            };
                          });
                        },
                      })}
                      accept='image/*'
                    ></Form.Control>
                  </div>

                  <span style={{ color: '#AFAFAF' }}>
                    {userData.valid_id_img && <>1 File Selected</>}
                    {!userData.valid_id_img && <>No Images Uploaded</>}
                  </span>
                  <span>
                    {!userData.valid_id_img === '' && userData.valid_id_img}
                  </span>
                </div>
              </Form.Group>
            </div>
          </Row>
          <div className='d-flex justify-content-between'>
            {' '}
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
            <Form.Group
              className='buttons text-end'
              style={{ justifyContent: 'end' }}
            >
              <Button
                className='submit mx-1'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel shadow-sm mx-1 '
                style={{ color: '#00B6E9' }}
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </div>
        </Form>
      </FormUI>
    </div>
  );
}

export default ApplicationForm;
