// import core & vendor packages below
import { Chart } from 'react-google-charts';

// import assets below
import './style.scss';

// main component
const BudgetVSActualGraph = (props) => {
  const { selected } = props;

  const optionsTotal = {
    chartArea: { width: '90%', top: 100, right: 200, bottom: 100, left: 180 },
    legend: { position: 'top' },
    tooltip: { isHtml: true },
    bar: { groupWidth: '90%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 500000, 1000000, 1500000, 2000000],
    },
    colors: ['#2179BF', '#00B6E9'],
  };

  const dataTotal = [
    [
      'Record',
      'Budget',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'Actual',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Service Contractors', 1900000, 1900000, 1750000, 1750000],
    ['Repair & Maintenance', 1090000, 1090000, 1050000, 1050000],
    ['Taxes & Licenses', 1600000, 1600000, 250000, 250000],
    ['Utilities', 1300000, 1300000, 550000, 550000],
    ['Consumables & Supplies', 900000, 900000, 750000, 750000],
    ['Property Insurance', 1600000, 1600000, 1600000, 1600000],
    ['Professional Fees', 1200000, 1200000, 1100000, 1100000],
    ['Emergency Fund', 1900000, 1900000, 1200000, 1200000],
    ['Communication', 2000000, 2000000, 1350000, 1350000],
    ['Office Supplies', 2000000, 2000000, 1750000, 1750000],
  ];

  const optionsMonthly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    bar: { groupWidth: '90%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [
        0, 200000, 400000, 600000, 800000, 1000000, 1200000, 1400000, 1600000,
        1800000, 2000000,
      ],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsMonthly = [
    [
      'Month',
      'Budget',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'Actual',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Jan', 200000, 200000, 200000, 200000],
    ['Feb', 400000, 400000, 400000, 400000],
    ['Mar', 800000, 800000, 800000, 800000],
    ['Apr', 900000, 900000, 900000, 900000],
    ['May', 500000, 500000, 500000, 500000],
    ['Jun', 1900000, 1900000, 1900000, 1900000],
    ['Jul', 450000, 450000, 450000, 450000],
    ['Aug', 200000, 200000, 200000, 200000],
    ['Sep', 1200000, 1200000, 1200000, 1200000],
    ['Oct', 230000, 230000, 230000, 230000],
    ['Nov', 600000, 600000, 600000, 600000],
    ['Dec', 1200000, 1200000, 1200000, 1200000],
  ];

  const optionsQuarterly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 1000000, 2000000, 3000000, 4000000, 5000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsQuarterly = [
    [
      'Quarter',
      '2021',
      { type: 'number', role: 'tooltip', p: { html: true } },
      '2022',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Q1', 4200000, 4200000, 4200000, 4200000],
    ['Q2', 3400000, 3400000, 3400000, 3400000],
    ['Q3', 4800000, 4800000, 4800000, 4800000],
    ['Q4', 1900000, 1900000, 1900000, 1900000],
  ];

  const optionsYearly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 1000000, 2000000, 3000000, 4000000, 5000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsYearly = [
    [
      'Type',
      '2021',
      { type: 'number', role: 'tooltip', p: { html: true } },
      '2022',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Association Dues', 4600000, 4600000, 4600000, 4600000],
    ['OPEX', 1400000, 1400000, 1400000, 1400000],
  ];

  return (
    <div className='budgetVSActualGraph'>
      {selected === 'Total' && (
        <Chart
          chartType='BarChart'
          width='100%'
          height='1250px'
          options={optionsTotal}
          data={dataTotal}
        />
      )}
      {selected === 'Monthly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsMonthly}
          data={dataCollectionsMonthly}
        />
      )}
      {selected === 'Quarterly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsQuarterly}
          data={dataCollectionsQuarterly}
        />
      )}
      {selected === 'Yearly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsYearly}
          data={dataCollectionsYearly}
        />
      )}
    </div>
  );
};

export default BudgetVSActualGraph;
