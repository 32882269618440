const Building = () => {
  return (
    <svg viewBox='0 0 52.884 60.439'>
      <path
        id='building'
        d='M51.468,56.662H49.107V2.833A2.833,2.833,0,0,0,46.274,0H6.611A2.833,2.833,0,0,0,3.777,2.833V56.662H1.417A1.417,1.417,0,0,0,0,58.078v2.361H52.884V58.078A1.417,1.417,0,0,0,51.468,56.662ZM15.11,8.971a1.417,1.417,0,0,1,1.417-1.417h4.722a1.417,1.417,0,0,1,1.417,1.417v4.722a1.417,1.417,0,0,1-1.417,1.417H16.526a1.417,1.417,0,0,1-1.417-1.417Zm0,11.332a1.417,1.417,0,0,1,1.417-1.417h4.722A1.417,1.417,0,0,1,22.665,20.3v4.722a1.417,1.417,0,0,1-1.417,1.417H16.526a1.417,1.417,0,0,1-1.417-1.417Zm6.138,17.471H16.526a1.417,1.417,0,0,1-1.417-1.417V31.636a1.417,1.417,0,0,1,1.417-1.417h4.722a1.417,1.417,0,0,1,1.417,1.417v4.722A1.417,1.417,0,0,1,21.248,37.774ZM30.22,56.662H22.665V46.746a1.417,1.417,0,0,1,1.417-1.417H28.8a1.417,1.417,0,0,1,1.417,1.417Zm7.555-20.3a1.417,1.417,0,0,1-1.417,1.417H31.636a1.417,1.417,0,0,1-1.417-1.417V31.636a1.417,1.417,0,0,1,1.417-1.417h4.722a1.417,1.417,0,0,1,1.417,1.417Zm0-11.332a1.417,1.417,0,0,1-1.417,1.417H31.636a1.417,1.417,0,0,1-1.417-1.417V20.3a1.417,1.417,0,0,1,1.417-1.417h4.722A1.417,1.417,0,0,1,37.775,20.3Zm0-11.332a1.417,1.417,0,0,1-1.417,1.417H31.636a1.417,1.417,0,0,1-1.417-1.417V8.971a1.417,1.417,0,0,1,1.417-1.417h4.722a1.417,1.417,0,0,1,1.417,1.417Z'
      />
    </svg>
  );
};

export default Building;
