// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import SuppliersDirectoryTabs from './Tabs';

// API

// CSS
import './style.scss';

const SuppliersDirectoryPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Supplier&#39;s Directory</title>
      </Helmet>
      <Main title='Supplier&#39;s Directory'>
        <Container fluid>
          <Row>
            <Col>
              <SuppliersDirectoryTabs />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default SuppliersDirectoryPage;
