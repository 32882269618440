/* eslint-disable no-unused-vars */
// import core & vendor packages
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Calendar from 'react-calendar';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

// import components below
import FormUI from '../../../components/UI/FormUI';
import CalendarUI from '../../../components/UI/CalendarUI';
import convertDate from '../../../helpers/convertDate';

// import assets below
import './Form.scss';
import CaretLeft from '../../../components/SVG/CaretLeft';
import CaretRight from '../../../components/SVG/CaretRight';

// main component
const ConcernForm = ({
  buildings,
  setSelectedUnit,
  names,
  setSubmit,
  msg,
  user,
  userUnit,
}) => {
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(convertDate(new Date()));
  const [formData, setFormData] = useState({
    concernType: '1',
    subject: '',
    building: '',
    unit: '',
    user: '',
    details: '',
    images: [],
  });

  const [unitId, setUnitId] = useState();
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
  });

  const role = user.attributes.role;

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // Functions

  const getNames = (e) => {
    setSelectedUnit(e);
  };

  const onSubmit = (e) => {
    const newFormData = new FormData();

    if (formData.images.length === 0) {
      setError('images', {
        type: 'custom',
        message: 'Please Select Image',
      });

      return;
    }

    console.log('formData.unit.id', formData.unit.id);

    newFormData.append('type', formData.concernType);
    newFormData.append('subject', formData.subject);
    // newFormData.append('building_id', formData.building.id); // To Remove in the future
    newFormData.append(
      'unit_id',
      role === 'admin' ? formData.unit.id : userUnit?.id,
    );
    newFormData.append(
      'user_id',
      role === 'admin' ? formData.user.id : user.id,
    );
    // newFormData.append('concern_images', [formData.images]);

    // eslint-disable-next-line multiline-comment-style
    for (let i = 0; i < formData.images.length; i++) {
      newFormData.append(`concern_images[${i}]`, formData.images[i]);
    }

    newFormData.append('description', formData.details);
    newFormData.append('client_date[0][date]', '2022-01-01');
    newFormData.append('client_date[1][date]', '2022-01-01');
    newFormData.append('client_date[0][time_start]', '00:00');
    newFormData.append('client_date[1][time_start]', '00:00');
    newFormData.append('client_date[0][time_end]', '00:00');
    newFormData.append('client_date[1][time_end]', '00:00');

    // Display the key/value pairs
    // eslint-disable-next-line no-var
    for (var pair of newFormData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    setSubmit(newFormData);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };

  // Use Effects
  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    console.log('NAMES', names);

    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);

  useEffect(() => {
    if (msg.message === 'Request Concern created successfully.') {
      setValue('concernType', '');
      setValue('subject', '');
      setValue('building', '');
      setValue('unit', '');
      setValue('user', '');
      setValue('details', '');
      setValue('images', '');
      setFormData({
        concernType: '1',
        subject: '',
        building: '',
        unit: '',
        user: '',
        details: '',
        images: [],
      });
    }
  }, [msg]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Form
      className='concernForm'
      onSubmit={handleSubmit(onSubmit)}
      encType='multipart/form-data'
    >
      <Form.Select
        {...register('concernType', {
          required: '(Required)',
          onChange: (event) => {
            setFormData((prevState) => {
              return {
                ...prevState,
                concernType: event.target.value,
              };
            });
          },
        })}
        value={formData.concernType}
      >
        <option value='1'>Maintenance</option>
        <option value='0'>Others</option>
      </Form.Select>

      <FormUI>
        <Row>
          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>
                Subject{' '}
                {errors.subject ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.subject.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Subject Here'
                {...register('subject', {
                  required: '(Required)',
                  onChange: (event) => {
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        subject: event.target.value,
                      };
                    });
                  },
                })}
                value={formData.subject}
              />
            </Form.Group>
          </Col>
          {role && role === 'admin' && (
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Building Name
                  {errors.building ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.building.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={customStyles}
                      options={data.buildings}
                      placeholder='Select Building'
                      {...field}
                    ></Select>
                  )}
                  {...register('building', {
                    required: '(Required)',
                    value: formData.building,
                    onChange: (event) => {
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          building: event.target.value,
                          unit: '',
                          user: '',
                        };
                      });
                      setValue('unit', '');
                      setValue('user', '');
                      setUnitId(event.target.value.id);
                    },
                  })}
                />
              </Form.Group>
            </Col>
          )}
        </Row>

        {role && role === 'admin' && (
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Unit No.
                  {errors.unit ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.unit.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={data.units}
                      styles={customStyles}
                      placeholder='Select Unit'
                      {...field}
                    ></Select>
                  )}
                  {...register('unit', {
                    required: '(Required)',
                    value: formData.unit,
                    onChange: (event) => {
                      getNames(event.target.value.id);
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          unit: event.target.value,
                          user: '',
                        };
                      });
                      setValue('user', '');
                    },
                  })}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Name{' '}
                  {errors.user ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.user.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={data.names}
                      styles={customStyles}
                      placeholder='Select User'
                      {...field}
                    ></Select>
                  )}
                  {...register('user', {
                    required: '(Required)',
                    value: formData.user,
                    onChange: (event) => {
                      console.log(event.target.value);

                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          user: event.target.value,
                        };
                      });
                    },
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Form.Group className='mb-3'>
          <Form.Label>
            Details{' '}
            {errors.details ? (
              <span style={{ color: '#ff5c5c' }}>{errors.details.message}</span>
            ) : (
              ''
            )}
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={8}
            {...register('details', {
              required: '(Required)',
              onChange: (event) => {
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    details: event.target.value,
                  };
                });
              },
            })}
            value={formData.details}
            placeholder='Enter Details Here'
          />
        </Form.Group>

        <Form.Group className='mb-3'>
          <h5>
            Upload Image{' '}
            {errors.images ? (
              <span style={{ color: '#ff5c5c' }}>{errors.images.message}</span>
            ) : (
              ''
            )}
          </h5>
          <div className='input_file mb-3'>
            <Form.Label>
              <div className='input_file__body'>
                <div className='svg__wrapper'>
                  <svg
                    width='46.549'
                    height='46.542'
                    viewBox='0 0 46.549 46.542'
                  >
                    <path
                      id='upload'
                      d='M26.911,34.98H19.638A2.177,2.177,0,0,1,17.456,32.8V17.524H9.483a1.815,1.815,0,0,1-1.282-3.1L22.029.586a1.761,1.761,0,0,1,2.482,0L38.348,14.424a1.815,1.815,0,0,1-1.282,3.1H29.093V32.8A2.177,2.177,0,0,1,26.911,34.98Zm19.638-.727V44.435a2.177,2.177,0,0,1-2.182,2.182H2.182A2.177,2.177,0,0,1,0,44.435V34.253a2.177,2.177,0,0,1,2.182-2.182H14.547V32.8a5.1,5.1,0,0,0,5.091,5.091h7.273A5.1,5.1,0,0,0,32,32.8v-.727H44.367A2.177,2.177,0,0,1,46.549,34.253Zm-11.274,8a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,35.275,42.253Zm5.819,0a1.818,1.818,0,1,0-1.818,1.818A1.824,1.824,0,0,0,41.094,42.253Z'
                      transform='translate(0 -0.075)'
                      fill='#afafaf'
                    />
                  </svg>
                </div>
                <span>
                  {formData.images && (
                    <>
                      {formData.images.length > 0 && (
                        <>
                          {formData.images.length} File
                          {formData.images.length === 1 ? '' : ''} Selected
                        </>
                      )}
                      {(formData.images.length === 0 ||
                        formData.images === null) && <>Upload file</>}
                    </>
                  )}
                  {!formData.images && <>No Images Uploaded</>}
                </span>
              </div>
              <Form.Control
                accept='image/*'
                id='file'
                type='file'
                {...register('images', {
                  value: formData.images,
                  onChange: (e) => {
                    // eslint-disable-next-line prefer-const
                    let docuArr = [];

                    Array.from(e.target.files).forEach((file) => {
                      docuArr.push(file);
                    });

                    setFormData((prevState) => {
                      return { ...prevState, images: docuArr };
                    });
                  },
                })}
                multiple
              />
            </Form.Label>
          </div>
        </Form.Group>

        <CalendarUI>
          <h5>SCHEDULE FOR VERIFICATION/INSPECTION</h5>
          <Calendar
            calendarType='US'
            onChange={onChange}
            value={value}
            prevLabel={CaretLeft()}
            nextLabel={CaretRight()}
            minDate={new Date()}
            onClickDay={(value) => {
              setSelectedDate(convertDate(value));
            }}
            tileContent={(value) => {
              return (
                <div
                  className='available-time'
                  style={{
                    display:
                      convertDate(value.date) === selectedDate
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <h6>Available Time:</h6>
                  <span>9:00AM - 4:00PM</span>
                </div>
              );
            }}
          />
        </CalendarUI>

        <Row>
          <Col className='message-text-result'>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <div
              className='formUI__footer'
              style={{ flexDirection: 'row' }}
            >
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button variant='cancel'>Cancel</Button>
            </div>
          </Col>
        </Row>
      </FormUI>
    </Form>
  );
};

export default ConcernForm;
