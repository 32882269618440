// Packages
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

// CSS
import './EditViolationForm.scss';

// Components

// API
import {
  updateViolationClassification,
  getViolationClassification,
} from 'api/request';

// CSS

// Main Component
const EditViolationForm = () => {
  // States
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [violations, setViolations] = useState({ id: '', value: '' });
  const [origData, setOrigData] = useState({ id: '', value: '' });

  // URL ID Parameter
  const { id } = useParams();

  // Will render one time
  useEffect(() => {
    getViolationClassification(id, getCallback);
  }, []);

  const getCallback = async (response) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      setViolations({ id: data.data.id, value: data.data.attributes.name });
      setOrigData({ id: data.data.id, value: data.data.attributes.name });
    } else {
      setMsg('Something went wrong');
    }
  };

  // Submit Violation/s
  const submitViolations = () => {
    setMsg({ message: '', status: '' });

    if (origData.value === violations.value) {
      setMsg({ message: 'Nothing to Update.', status: 'failed' });
      return;
    }

    const params = {
      name: violations.value,
    };

    updateViolationClassification(violations.id, params, callback);
  };

  const callback = async (response, params) => {
    const { status, data } = await response;

    console.log(params);

    if (status === 202) {
      setMsg({ message: data.message, status: 'success' });

    } else if (status === 400 || status === 422) {
      if (data.errors) {
        const { errors } = data;
        if (errors['name']) {
          setMsg({ message: errors['name'], status: 'failed' });
        }
      }
    } else {
      setMsg('Something went wrong');
      console.log(data);
    }
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Form.Group
          className='mb-3'
          controlId='formViolations'
        >
          <Form.Label>Violation Classification</Form.Label>
        </Form.Group>

        {violations && (
          <Form.Group
            className='mb-3'
            controlId={violations.id}
            key={violations.id}
          >
            <Form.Control
              aria-label='Violation'
              placeholder='Enter Violation Here'
              type='text'
              value={violations.value}
              onChange={(e) =>
                setViolations((prevState) => {
                  return { ...prevState, value: e.target.value };
                })
              }
            />
          </Form.Group>
        )}

        <Form.Group className='buttons pt-5'>
          <Container
            fluid
            style={{ padding: 0 }}
          >
            <Row>
              <Col className='message-text'>
                {msg.message && <span style={{ color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c', display: 'block' }}>{`${msg.message}`}</span>}
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  onClick={submitViolations}
                >
                  Submit
                </Button>
                <Button
                  as={Link}
                  to={'/administration/admin-cms/violations'}
                  className='cancel'
                  variant='default'
                  style={{ padding: '0.8em 2em' }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default EditViolationForm;
