// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import EditBuildingForm from './EditBuildingForm';

// API

// CSS
import './EditBuilding.scss';

// Main Component
const EditBuilding = () => {
  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Edit Building</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Edit Building'
              id='violations-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Edit Building'
                title='Edit Building'
              >
                <EditBuildingForm />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EditBuilding;
