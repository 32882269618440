// Packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Tabs, Tab } from 'react-bootstrap';

// Components
import UnitNumber from './UnitNumber/UnitNumber';
import UnitDetails from './UnitDetails/UnitDetails';
import ParkingSlots from './ParkingSlots/ParkingSlots';

// CSS/SCSS
import './UnitForm.scss';

// API
import { getBuildingLists } from 'api/request';

// Main Component
const UnitForm = ({ refreshList, setRefreshList, handleSelect }) => {
  // States
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableList, setTableList] = useState();
  const [activeTab, setActiveTab] = useState('Unit No');
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [search, setSearch] = useState();
  const [searchNow, setSearchNow] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBuildingLists('', callback);
    setTableList([]);
    setRefreshList(false);
  }, []);

  useEffect(() => {
    if (refreshList) {
      setLoading(true);
      getBuildingLists('', callback);
      setTableList([]);
      setRefreshList(false);
    }
  }, [refreshList]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableList(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setMsg('Something went wrong.');
      }
    }
  };

  // Function to go to next tab
  const handleNext = (e) => {
    setRefreshList(true);
    setActiveTab(e);
  };

  // Function to handle cancel
  const handleCancel = () => {
    handleSelect('Building Form');
  };

  const handleBack = (e) => {
    setActiveTab(e);
  };

  const searchBuilding = () => {
    setSearchNow(true);
  };

  // Function to go to clicked tab
  const handeClickTab = (e) => {
    setActiveTab(e);
  };

  // To render on screen
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <Form
        className='formBuiling'
        onSubmit={(e) => {
          e.preventDefault();
          searchBuilding();
        }}
      >
        <Row>
          <Col md={3}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
                value={search}
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
              />

              <Button
                className='searchbtn'
                onClick={() => searchBuilding()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          {loading && <p>Loading...</p>}
          {!loading && msg && <p>{msg}</p>}
          {!loading && (
            <>
              {tableList && tableList.length === 0 && <p>Table is empty</p>}
              {tableList && tableList.length > 0 && (
                <Tabs
                  activeKey={activeTab}
                  id='unit-form'
                  className='unit-tabs'
                  onSelect={(e) => handeClickTab(e)}
                >
                  <Tab
                    eventKey='Unit No'
                    title='Unit No'
                  >
                    <UnitNumber
                      setSelectedBuilding={setSelectedBuilding}
                      selectedBuilding={selectedBuilding}
                      tableList={tableList}
                      handleNext={handleNext}
                      handleCancel={handleCancel}
                      search={search}
                      searchNow={searchNow}
                      setSearchNow={setSearchNow}
                    />
                  </Tab>
                  <Tab
                    eventKey='Unit Details'
                    title='Unit Details'
                  >
                    <UnitDetails
                      setSelectedBuilding={setSelectedBuilding}
                      selectedBuilding={selectedBuilding}
                      tableList={tableList}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      search={search}
                      searchNow={searchNow}
                      setSearchNow={setSearchNow}
                    />
                  </Tab>
                  <Tab
                    eventKey='Parking Slots'
                    title='Parking Slots'
                  >
                    <ParkingSlots
                      setSelectedBuilding={setSelectedBuilding}
                      selectedBuilding={selectedBuilding}
                      tableList={tableList}
                      handleBack={handleBack}
                      search={search}
                      searchNow={searchNow}
                      setSearchNow={setSearchNow}
                    />
                  </Tab>
                </Tabs>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UnitForm;
