/* eslint-disable camelcase */
// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Components

// API
import { editProfile } from '../../api/request';

// CSS
import './EditProfileContent.scss';

// Context
import userContext from '../../components/userContext/userContext';

// Main Component
const EditProfileContent = () => {
  // useContext Data
  const { data, setData } = useContext(userContext);

  // States
  const [inputData, setInputData] = useState({
    name: '',
    email: '',
    mobile_number: '',
    occupation: '',
    company_name: '',
    emergency_contact_name: '',
    emergency_contact_number: '',
    emergency_contact_email: '',
    current_password: '',
    new_password: '',
  });
  const [msg, setMsg] = useState('');
  const [success, setSuccess] = useState('');

  // useForm
  const {
    register,
    handleSubmit,
    setError,
    // watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    console.log(inputData);
  }, [inputData]);

  useEffect(() => {
    console.log(data);
    console.log(setData);

    if (data) {
      const { attributes } = data.user;

      setInputData((prevState) => {
        return {
          ...prevState,
          name: attributes.name,
          email: attributes.email,
          mobile_number: attributes.mobile_number,
          occupation: attributes.occupation,
          company_name: attributes.company_name,
          emergency_contact_name: attributes.emergency_contact_name,
          emergency_contact_number: attributes.emergency_contact_number,
          emergency_contact_email: attributes.emergency_contact_email,
        };
      });

      reset();
    }
  }, [data]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // Will fire after submitting form
  const onSubmit = (data) => {
    console.log(data);

    if (data.current_password !== '' && data.new_password === '') {
      setError('new_password', { type: 'custom', message: '(Required)' });
      return;
    }

    const params = {
      name: inputData.name,
      email: inputData.email,
      mobile_number: inputData.mobile_number,
      occupation: inputData.occupation,
      company_name: inputData.company_name,
      emergency_contact_name: inputData.emergency_contact_name,
      emergency_contact_number: inputData.emergency_contact_number,
      emergency_contact_email: inputData.emergency_contact_email,
      current_password: inputData.current_password,
      new_password: inputData.new_password,
    };

    setSuccess('');
    setMsg('');
    editProfile(params, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setSuccess(data.message);

        setData((prevState) => {
          return { ...prevState, user: data.data, isAuth: true };
        });

        localStorage.setItem('user', JSON.stringify(data.data));
      }

      if (status === 401 || status === 422) {
        setMsg(data.message);
      }
    }
  };

  // To render on screen
  return (
    <Card className='admin-form edit-form shadow-sm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* First Row */}
        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formName'
            >
              <Form.Label>
                Name{' '}
                {errors.name ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.name.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('name', {
                  required: '(Required)',
                  pattern: {
                    value: /^[a-zA-Z ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.name,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, name: e.target.value };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Name here'
                placeholder='Enter Name Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEmail'
            >
              <Form.Label>
                Email{' '}
                {errors.email ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.email.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('email', {
                  required: 'Email Required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                  value: inputData.email,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, email: e.target.value };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Email here'
                placeholder='Enter Email Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formMobileNo'
            >
              <Form.Label>
                Mobile No.*{' '}
                {errors.mobile_number ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.mobile_number.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('mobile_number', {
                  required: '(Required)',
                  pattern: {
                    value: /^[0-9 ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.mobile_number.replace(/[^a-zA-Z0-9]/g, ''),
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, mobile_number: e.target.value };
                    });
                  },
                })}
                type='number'
                aria-label='Enter Mobile No. here'
                placeholder='Enter Mobile No. Here'
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Second Row */}
        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formOccupation'
            >
              <Form.Label>
                Occupation{' '}
                {errors.occupation ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.occupation.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('occupation', {
                  required: '(Required)',
                  pattern: {
                    value: /^[a-zA-Z0-9 ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.occupation,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, occupation: e.target.value };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Occupation here'
                placeholder='Enter Occupation Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formCompanyName'
            >
              <Form.Label>
                Company Name{' '}
                {errors.company_name ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.company_name.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('company_name', {
                  required: '(Required)',
                  pattern: {
                    value: /^[a-zA-Z0-9\- ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.company_name,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, company_name: e.target.value };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Company Name here'
                placeholder='Enter Company Name Here'
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        {/* Third Row */}
        <Row>
          <h3 className='mb-4'>EMERGENCY CONTACT PERSON</h3>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEmergencyName'
            >
              <Form.Label>
                Name{' '}
                {errors.emergency_contact_name ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.emergency_contact_name.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('emergency_contact_name', {
                  required: '(Required)',
                  pattern: {
                    value: /^[a-zA-Z ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.emergency_contact_name,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return {
                        ...prevState,
                        emergency_contact_name: e.target.value,
                      };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Name here'
                placeholder='Enter Name Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEmergencyContactNumber'
            >
              <Form.Label>
                Contact Number*{' '}
                {errors.emergency_contact_number ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.emergency_contact_number.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('emergency_contact_number', {
                  required: '(Required)',
                  pattern: {
                    value: /^[0-9 ]*$/g,
                    message: 'Special Characters not Allowed',
                  },
                  value: inputData.emergency_contact_number,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return {
                        ...prevState,
                        emergency_contact_number: e.target.value,
                      };
                    });
                  },
                })}
                type='text'
                aria-label='Enter Contact Number here'
                placeholder='Enter Contact Number Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEmail'
            >
              <Form.Label>
                Email{' '}
                {errors.emergency_contact_email ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.emergency_contact_email.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('emergency_contact_email', {
                  required: '(Required)',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                  value: inputData.emergency_contact_email,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return {
                        ...prevState,
                        emergency_contact_email: e.target.value,
                      };
                    });
                  },
                })}
                type='email'
                aria-label='Enter Email here'
                placeholder='Enter Email Here'
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Fourth Row */}
        <Row>
          <h3 className='mb-4'>UPDATE PASSWORD</h3>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formCurrentPassword'
            >
              <Form.Label>
                Current Password{' '}
                {errors.current_password ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.current_password.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('current_password', {
                  value: inputData.current_password,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, current_password: e.target.value };
                    });
                  },
                })}
                type='password'
                aria-label='Enter Current Password here'
                placeholder='Enter Current Password Here'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formNewPassword'
            >
              <Form.Label>
                New Password{' '}
                {errors.new_password ? (
                  <span style={{ color: '#dc3545' }}>
                    {errors.new_password.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                {...register('new_password', {
                  value: inputData.new_password,
                  onChange: (e) => {
                    setInputData((prevState) => {
                      return { ...prevState, new_password: e.target.value };
                    });
                  },
                })}
                type='password'
                aria-label='Enter New Password here'
                placeholder='Enter New Password Here'
              />
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>

        {/* Buttons */}
        <Row>
          <Col>
            <div className='message-label'>
              <p className='error'>{msg}</p>
              <p className='success'>{success}</p>
            </div>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                as={Link}
                to={'/dashboard'}
                className='cancel'
                variant='default'
                style={{ padding: '0.8em 2em' }}
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EditProfileContent;
