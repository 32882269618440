import React from 'react';
import './Publish.scss';
function Publish() {
  return (
    <div className='Publish'>
      <p className='text-center fw-bold'>
        Your file has been successfully sent!
      </p>
    </div>
  );
}

export default Publish;
