// Packages
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line no-unused-vars
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';

// import assets below
import BudgetVSActualFilter from './Filter/Filter';

import BudgetVSActualGraph from './Graph/Graph';
import BudgetVSActualTable from './Table/Table';

function BudgetvsActual() {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };
  return (
    <Fragment>
      <Helmet>
        <title>Budget vs. Actual</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Budget vs. Actual'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Budget vs. Actual'
                title='Budget vs. Actual'
              >
                <BudgetVSActualFilter
                  toggle={toggle}
                  onToggle={setToggle}
                  selected={selectedFilter}
                  onSelect={onSelectFilter}
                />
                {!toggle && <BudgetVSActualTable />}
                {toggle && <BudgetVSActualGraph selected={selectedFilter} />}
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default BudgetvsActual;
