/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';

// Components
import Main from '../../components/Main';
import AnnouncementForm from './AnnouncementForm/AnnouncementForm';
import AnnouncementList from './AnnouncementList/AnnouncementList';
import userContext from 'components/userContext/userContext';
// API

// CSS
import './AnnouncementsPage.scss';
import {
  deleteannouncementList,
  getAnouncementList,
  getBuildingLists,
  submitAnnouncementForm,
} from 'api/request';

const AnnouncementsPage = () => {
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user.attributes.role;
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const refreshTable = () => {
    setLoading(true);
    const params = `/api/v1/announcements?filter[recipient]=all&per_page=5`;
    getAnouncementList(params, requestAmenitiesCallback);
  };
  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
    refreshTable();
    setLoading(true);
    getBuildingLists('?include=units', buildingListCallback);
  }, []);

  useEffect(() => {
    if (submit) {
      setMsg({ message: '', status: '' });
      submitAnnouncementForm(submit, submitCallback);
    }
  }, [submit]);
  useEffect(() => {
    const identifier = setTimeout(() => {
      const params = `/api/v1/announcements?filter[search]=${search}&&filter[recipient]=${filter} &per_page=5`;
      getAnouncementList(params, requestAmenitiesCallback);
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [search]);
  useEffect(() => {
    const params = `/api/v1/announcements?filter[recipient]=${filter}&per_page=5`;

    getAnouncementList(params, requestAmenitiesCallback);
  }, [filter]);
  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }
      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'error' });
      }
    }
  };
  const selectPage = (page) => {
    const params = `/api/v1/announcements?filter[recipient]=all&per_page=5${page}`;

    getAnouncementList(params, requestAmenitiesCallback);
  };
  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);

        refreshTable();
        setMsg({ message: data.message, status: 'success' });
        setTimeout(() => {
          setMsg({ message: '', status: 'cleanup' });
        }, 3000);
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  const deleteCallback = async (res) => {
    setLoading(false);

    refreshTable();

    setMsg({ message: 'Delete Succesfully', status: 'success' });
    setTimeout(() => {
      setMsg({ message: '', status: 'success' });
    }, 1000);
  };
  const deleteSingleList = (id) => {
    setLoading(true);
    deleteannouncementList(id, deleteCallback);
  };
  return (
    <Fragment>
      <Helmet>
        <title>Announcements</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='AnnouncementsForm'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              {role === 'admin' && (
                <Tab
                  eventKey='AnnouncementsForm'
                  title='Announcements Form'
                >
                  <AnnouncementForm
                    buildings={buildings}
                    setSelectedBuilding={setSelectedBuilding}
                    selectedBuilding={selectedBuilding}
                    setSubmit={setSubmit}
                    msg={msg}
                  />
                </Tab>
              )}
              <Tab
                eventKey='Announcements List'
                title='Announcements List'
              >
                {loading && <p>Loading</p>}
                {!loading && (
                  <AnnouncementList
                    deleteSingleList={deleteSingleList}
                    tableData={tableData}
                    links={links}
                    meta={meta}
                    selectPage={selectPage}
                    msg={msg}
                    setSearch={setSearch}
                    setFilter={setFilter}
                  />
                )}
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default AnnouncementsPage;
