// import core & vendor packages
import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

// import components below
import LoginUI from '../../components/UI/LoginUI';
import CompanyLogo from '../../components/CompanyLogo';
import FormLogin from './FormLogin';

// import api requests below
import { userLogin } from '../../api/request';

// import assets below
import './style.scss';

// context
import userContext from '../../components/userContext/userContext';

// main component
const LoginPage = () => {
  // Initialize Navigate
  const navigate = useNavigate();

  // States
  const [params, setParams] = useState({ email: '', pasword: '' });
  const [msg, setMsg] = useState('');

  // Context Values
  const { data, setData } = useContext(userContext);

  // useForm
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  // Handle on Sign In Button click
  const handleLogin = (data) => {
    const params = {
      email: data.email,
      password: data.password,
    };
    setMsg('');
    userLogin(params, callback);
  };

  // Handle Response from api
  const callback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200) {
        localStorage.setItem('token', `Bearer ${data.access_token}`);
        localStorage.setItem('user', JSON.stringify(data.data));

        let userUnit;

        if (data.data?.attributes?.role === 'tenant') {
          userUnit = data.data?.relationships?.tenant?.relationships?.unit
            ? {
                id: data.data?.relationships?.tenant?.relationships?.unit?.id,
                buildingId:
                  data.data?.relationships?.tenant?.relationships?.unit
                    ?.relationships?.building?.id,

                label: `${data.data?.relationships?.tenant?.relationships?.unit?.relationships?.building?.attributes?.name}, ${data.data?.relationships?.tenant?.relationships?.unit?.attributes?.unit_number}`,
                value: `${data.data?.relationships?.tenant?.relationships?.unit?.relationships?.building?.attributes?.name}, ${data.data?.relationships?.tenant?.relationships?.unit?.attributes?.unit_number}`,
              }
            : '';
        } else {
          userUnit = data.data?.relationships?.owner?.relationships?.units
            ? {
                id: data.data?.relationships?.owner?.relationships?.units[0]
                  ?.id,
                buildingId:
                  data.data?.relationships?.owner?.relationships?.units[0]
                    ?.relationships?.building?.id,

                label: `${data.data?.relationships?.owner?.relationships?.units[0]?.relationships?.building?.attributes?.name}, ${data.data?.relationships?.owner?.relationships?.units[0]?.attributes?.unit_number}`,
                value: `${data.data?.relationships?.owner?.relationships?.units[0]?.relationships?.building?.attributes?.name}, ${data.data?.relationships?.owner?.relationships?.units[0]?.attributes?.unit_number}`,
              }
            : '';
        }

        setData((prevState) => {
          return {
            ...prevState,
            user: data?.data,
            isAuth: true,
            role: data?.data?.attributes?.role,
            units:
              data?.data?.relationships?.owner?.relationships?.units || [
                data.data?.relationships?.tenant?.relationships?.unit,
              ] ||
              [],
            userUnit: userUnit,
          };
        });

        navigate('/dashboard');
      }

      if (status === 401) {
        setMsg(data.message);
      }
    }
  };

  useEffect(() => {
    if (data.isAuth) {
      navigate('/dashboard');
    }
  }, [data]);

  return (
    <Fragment>
      <Helmet>
        <title>DGCondo</title>
      </Helmet>
      <Form
        className='login'
        onSubmit={handleSubmit(handleLogin)}
      >
        <LoginUI>
          <div className='loginUI__header'>
            <CompanyLogo fontSize='3rem' />
            <h3 className='loginUI__title'>Sign in to continue</h3>
          </div>
          <div className='loginUI__body'>
            <FormLogin
              params={params}
              setParams={setParams}
              register={register}
              errors={errors}
            />
          </div>
          <div className='login_error_label mb-2'>
            <label>{msg}</label>
          </div>
          <div className='loginUI__footer'>
            <Link
              className='loginUI__link'
              to='/forgot-password'
            >
              Forgot password?
            </Link>
            <Button
              type='submit'
              className='loginUI__button'
            >
              Sign in
            </Button>
          </div>
        </LoginUI>
      </Form>
    </Fragment>
  );
};

export default LoginPage;
