// import core & vendor packages
import React from 'react';
import ReactDOM from 'react-dom';

// import components below
import App from './App';

// import assets below
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
