/* eslint-disable camelcase */
import React from 'react';
import { useTable, usePagination } from 'react-table';

// import style below
import './style.scss';

// main component
const CollecetionvsExpendituresTable = () => {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Period',
        accessor: 'col1',
      },
      {
        Header: 'Collections',
        accessor: 'col2',
      },

      {
        Header: 'CapEx',
        accessor: 'col3',
      },
      {
        Header: 'OpEx',
        accessor: 'col4',
      },
      {
        Header: 'Variance',
        accessor: 'col5',
      },
    ],
    [],
  );
  const staticData = [
    {
      col1: 'Jan-21',
      col2: '100,000,000.00',
      col3: '100,000,000.00',
      col4: '100,000,000.00',
      col5: '25%',
    },
    {
      col1: 'Jan-21',
      col2: '100,000,000.00',
      col3: '100,000,000.00',
      col4: '100,000,000.00',
      col5: '25%',
    },
    {
      col1: 'Jan-21',
      col2: '100,000,000.00',
      col3: '100,000,000.00',
      col4: '100,000,000.00',
      col5: '25%',
    },
  ];

  // Data of the table
  const data = React.useMemo(() => {
    return staticData.map((data) => {
      return {
        col1: data?.col1,
        col2: data?.col2,
        col3: data?.col3,
        col4: data?.col4,
        col5: data?.col5,
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='pet-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => {
                      // Apply the header cell props

                      return (
                        <th
                          key={idx}
                          {...column.getHeaderProps()}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CollecetionvsExpendituresTable;
