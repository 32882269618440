/* eslint-disable no-unused-vars */
import api from '../api';
import token from '../token';

const getAmenitiesListWithPage = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/amenities${params}`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

const getAmenitiesCalendar = (params, callback = null) => {
  const newToken = token();

  api
    .get(`/api/v1/request/amenities/${params}/booked-requests`, {
      headers: {
        Authorization: newToken,
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err.response.status === 401 || err.response.status === 422) {
          callback(err.response);
        }
      }
    });
};

export { getAmenitiesListWithPage, getAmenitiesCalendar };
