// import core and vendor packages below
import { Pagination } from 'react-bootstrap';
import PaginationUI from '../../../../../components/UI/PaginationUI';

// main component
const ViolationReportsPagination = () => {
  return (
    <PaginationUI>
      <Pagination>
        <Pagination.Item active>{1}</Pagination.Item>
        <Pagination.Item>{2}</Pagination.Item>
        <Pagination.Item>{3}</Pagination.Item>
        <Pagination.Item>{4}</Pagination.Item>
        <Pagination.Item>{5}</Pagination.Item>
      </Pagination>
      <div className='pagination__summary'>
        <span>Showing 7 out of 50 entries</span>
      </div>
    </PaginationUI>
  );
};

export default ViolationReportsPagination;
