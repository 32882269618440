/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';

// Components
import Download from 'components/SVG/Download';

// import assets below
import './View.scss';

// API
import { getGatePassByReferenceId, approveGatePass } from 'api/request';

// Context
import userContext from 'components/userContext/userContext';

// main component
const View = () => {
  const { id } = useParams();
  const { data } = useContext(userContext);
  const { role } = data;
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [userData, setUserData] = useState({
    bearer: '',
    unitNo: '',
    dateTime: '',
    controlNo: '',
    preparedBy: '',
    ownersApproval: '',
    items: '',
    status: '',
  });
  const navigate = useNavigate();

  const approveGatepass = () => {
    approveGatePass(id, '', approveCallback);
  };

  useEffect(() => {
    getGatePassByReferenceId(id, '', callback);
  }, []);

  const approveCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Successfully Approved', status: 'success' });
        getGatePassByReferenceId(id, '', callback);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        const items = data.data.relationships.items.map((item, idx) => {
          return {
            id: item?.id,
            itemNo: parseInt(idx + 1),
            quantity: item?.attributes?.quantity,
            unit: item?.attributes?.unit,
            description: item?.attributes?.description,
            inventoryTag: item?.attributes?.inventory_tag,
          };
        });

        setUserData((prevState) => {
          return {
            ...prevState,
            bearer: data?.data?.attributes?.bearer,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            dateTime: data?.data?.attributes?.scheduled_at,
            controlNo: data?.data?.attributes?.control_number,
            preparedBy: data?.data?.attributes?.prepared_by,
            status: data?.data?.attributes?.status,
            ownersApproval: '',
            items: items,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  console.log(role);
  console.log(userData.status);

  return (
    <div className='gatePass_viewRequest'>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Bearer</h6>
            <span>{userData.bearer}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Unit No.</h6>
            <span>{userData.unitNo}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Date and Time</h6>
            <span>{userData.dateTime}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg='4'>
          <Form.Group>
            <h6 className='mb-3'>Control No.</h6>
            <span>{userData.controlNo}</span>
          </Form.Group>
        </Col>
      </Row>
      <h6>DESCRIPTION</h6>
      <Row>
        <Col>
          <div>
            <h6>Item No.</h6>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Quantity</h6>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Unit</h6>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Description</h6>
          </div>
        </Col>
        <Col>
          <div>
            <h6>Inventory Tag</h6>
          </div>
        </Col>
      </Row>
      {userData.items &&
        userData.items.map((list) => {
          return (
            <Row key={list.id}>
              <Col>
                <div>
                  <span>{list.itemNo}</span>
                </div>
              </Col>
              <Col>
                <div>
                  <span>{list.quantity}</span>
                </div>
              </Col>
              <Col>
                <div>
                  <span>{list.unit}</span>
                </div>
              </Col>
              <Col>
                <div>
                  <span>{list.description}</span>
                </div>
              </Col>
              <Col>
                <div>
                  <span>{list.inventoryTag}</span>
                </div>
              </Col>
            </Row>
          );
        })}
      <Row>
        <Col>
          <div>
            <h6 className='mt-4 mb-3'>Prepared by</h6>
            <span>{userData.preparedBy}</span>
          </div>
        </Col>
      </Row>
      {role === 'admin' && (
        <Row>
          <Col>
            <div>
              <h6 className='mt-4 mb-3'>Owner&#39;s Approval / Status</h6>
              <span style={{ textTransform: 'capitalize' }}>
                {userData.status}
              </span>

              {userData && userData.status === 'approved' && (
                <Button variant='submit'>{Download()} Download</Button>
              )}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          className='message-text-result'
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
              display: 'block',
            }}
          >{`${msg.message}`}</span>
        </Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <div
            style={{ margin: 0, padding: 0 }}
            className='buttons-cont'
          >
            {role &&
              role === 'admin' &&
              userData.status &&
              userData.status === 'pending' && (
                <h6 className='pmo-action'>PMO Action</h6>
              )}

            <div className='buttons'>
              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='approve'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      approveGatepass();
                    }}
                  >
                    Approve
                  </Button>
                )}

              {role &&
                role === 'admin' &&
                userData.status &&
                userData.status === 'pending' && (
                  <Button
                    variant='deny'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate(
                        `/administration/requests/gate-pass/deny-request/${id}?activeKey=requests`,
                      );
                    }}
                  >
                    Deny
                  </Button>
                )}

              {role && (
                <Button
                  variant='light'
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
