const Violation = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.535'
      height='24.535'
      viewBox='0 0 24.535 24.535'
    >
      <path
        id='exclamation-circle'
        d='M32.535,20.267A12.267,12.267,0,1,1,20.267,8,12.267,12.267,0,0,1,32.535,20.267ZM20.267,22.741a2.275,2.275,0,1,0,2.275,2.275A2.275,2.275,0,0,0,20.267,22.741Zm-2.16-8.179.367,6.727a.594.594,0,0,0,.593.561h2.4a.594.594,0,0,0,.593-.561l.367-6.727a.594.594,0,0,0-.593-.626H18.7A.594.594,0,0,0,18.107,14.562Z'
        transform='translate(-8 -8)'
        fill='#fff'
      />
    </svg>
  );
};

export default Violation;
