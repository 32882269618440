// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
// Components

// CSS/SCSS
import './ScheduleTable.scss';

// Main Component
const ScheduleTable = () => {
  // use navigate
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },

      {
        Header: ' Serial No.',
        accessor: 'serialNo',
      },
      {
        Header: 'Schedule',
        accessor: 'schedule',
      },
      {
        Header: 'Assigned Service',
        accessor: 'assignedService',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // navigate Request
    const editRequest = (action, id) => {
      return (
        <div>
          {
            <u
              onClick={() =>
                navigate(
                  `/engineering/maintenance-planner/schedule/edit-schedule/${id}`,
                )
              }
            >
              {action}
            </u>
          }
        </div>
      );
    };
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        type: 'Equipment',
        description: 'fire Pump',
        serialNo: '0010',
        schedule: '01/21/2021',
        assignedService: 'Team & Security',
        action: 'Edit',
      },
      {
        id: 2,
        type: 'Others',
        description: 'Plumbing',
        serialNo: 'N/A',
        schedule: '01/21/2021',
        assignedService: 'Team & Security',
        action: 'Edit',
      },
      {
        id: 3,
        type: 'Equipment',
        description: 'fire Pump',
        serialNo: '0010',
        schedule: '01/21/2021',
        assignedService: 'Team & Security',
        action: 'Edit',
      },
      {
        id: 4,
        type: 'Equipment',
        description: 'fire Pump',
        serialNo: '0010',
        schedule: '01/21/2021',
        assignedService: 'Technical Team',
        action: 'Edit',
      },
      {
        id: 5,
        type: 'Equipment',
        description: 'fire Pump',
        serialNo: '0010',
        schedule: '01/21/2021',
        assignedService: 'Technical Team',
        action: 'Edit',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        type: data.type,
        description: data.description,
        serialNo: data.serialNo,
        schedule: data.schedule,
        assignedService: data.assignedService,
        action: editRequest(data.action, data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='schedule-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTable;
