/* eslint-disable camelcase */

/* eslint-disable no-unused-vars */
// packages
import { getViolationList } from 'api/request';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// CSS
import './ViewViolationReportContent.scss';

// Main Component
const ViewViolationReportContent = () => {
  const { id } = useParams();
  // States

  const [msg, setMsg] = useState({ message: '', status: '' });
  const [userData, setUserData] = useState({
    violation_type: '',
    reference_id: '',
    name: '',
    unit_no: '',
    date: '',
    time: '',
    noOfOffense: '',
    reportDetails: '',
    penalty: '',
    images: [],
  });

  useEffect(() => {
    const params = `/api/v1/violation-reports/${id}`;
    getViolationList(params, callback);
  }, []);
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setUserData((prev) => {
          return {
            ...prev,
            reference_id: data?.data?.attributes?.reference_id,
            violation_type:
              data?.data?.relationships?.violation?.attributes?.name,
            name: data?.data?.relationships?.user?.attributes?.name,
            unit_no: data?.data?.relationships?.unit?.attributes?.unit_number,
            time: data?.data?.attributes?.time,
            date: data?.data?.attributes?.date,
            noOfOffense: data?.data?.attributes?.offense_no,
            reportDetails: data?.data?.attributes?.report_details,
            penalty: data?.data?.attributes?.penalty,
            images: data?.data?.attributes?.violation_report_images,
          };
        });
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };
  // To render on screen
  return (
    <Card className='admin-form view-violation shadow-sm view-incident'>
      <Form>
        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formViolationNumber'
            >
              <Form.Label>Violation No.</Form.Label>
              <Form.Control
                placeholder={userData.reference_id}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formViolationTypes'
            >
              <Form.Label>Violation Types</Form.Label>
              <Form.Control
                placeholder={userData.violation_type}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formTenant'
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder={userData.name}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formUnitNo'
            >
              <Form.Label>Unit No.</Form.Label>
              <Form.Control
                placeholder={userData.unit_no}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formDateTime'
            >
              <Form.Label>Date and Time</Form.Label>
              <Form.Control
                placeholder={`${userData.date} | ${userData.time}`}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col>
            <div className='violation-upload-images'>
              <Form.Group
                className='mb-2'
                controlId='formUpload'
              >
                <Form.Label>Image</Form.Label>

                <div className='upload-cont image-upload'>
                  <div className='upload-input'>
                    <label htmlFor='formUpload'>View Image</label>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      accept='.png, .jpg, .jpeg'
                    ></Form.Control>
                  </div>

                  <span>
                    {userData.images
                      ? `${userData.images.length} File Selected`
                      : 'No Image Selected'}
                  </span>
                </div>
              </Form.Group>
            </div>
          </Col>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formNoOfOffense'
            >
              <Form.Label>Number of Offense</Form.Label>
              <Form.Select
                aria-label='Select Title'
                readOnly
              >
                <option
                  value=''
                  disabled
                >
                  {userData.noOfOffense === 1 && '1st offense'}
                  {userData.noOfOffense === 2 && '2nd offense'}
                  {userData.noOfOffense === 3 && '3rd offense'}
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formReportDetails'
            >
              <Form.Label>Report Details</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder={userData.reportDetails}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formPenalty'
            >
              <Form.Label>Penalty</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                placeholder={userData.penalty}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className='buttons'>
              <Button
                as={Link}
                to={'/administration/violations'}
                className='cancel'
                variant='default'
                style={{ padding: '0.8em 2em', color: '#00B6E9' }}
              >
                Back
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ViewViolationReportContent;
