// Packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS
import './ViewDetailsForm.scss';

// Sample API
const dataAPI = [
  {
    id: 1,
    type: 'For Replacement',
    date: '01/22/2021',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Leo integer malesuada nunc vel risus commodo viverra. Aliquam vestibulum morbi blandit cursus risus. Fusce id velit ut tortor pretium. Tempor id eu nisl nunc mi ipsum faucibus vitae alique.',
    isCompleted: false,
  },
  {
    id: 2,
    type: 'For Replacement',
    date: '01/22/2021',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Leo integer malesuada nunc vel risus commodo viverra. Aliquam vestibulum morbi blandit cursus risus. Fusce id velit ut tortor pretium. Tempor id eu nisl nunc mi ipsum faucibus vitae alique.',
    isCompleted: false,
  },
];

// Main Component
const ViewDetailsForm = () => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    if (!history) {
      setHistory(dataAPI);
    }
  }, []);

  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formEquipment'
            >
              <Form.Label>Equipment</Form.Label>
              <Form.Control
                aria-label='Equipment'
                type='text'
                className='border-disabled'
                placeholder='Equipment Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBrand'
            >
              <Form.Label>Brand</Form.Label>
              <Form.Control
                aria-label='Brand'
                type='text'
                className='border-disabled'
                placeholder='Brand Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formModelNo'
            >
              <Form.Label>Model No.</Form.Label>
              <Form.Control
                aria-label='Model No.'
                type='text'
                className='border-disabled'
                placeholder='Model No. Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formSerialNo'
            >
              <Form.Label>Serial No.</Form.Label>
              <Form.Control
                aria-label='Serial No.'
                type='text'
                className='border-disabled'
                placeholder='Serial No. Here'
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formLocation'
            >
              <Form.Label>Location</Form.Label>
              <Form.Control
                aria-label='Location'
                type='text'
                className='border-disabled'
                placeholder='Location Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formDateInstalled'
            >
              <Form.Label>Date Installed</Form.Label>
              <Form.Control
                aria-label='Date Installed'
                type='text'
                className='border-disabled'
                placeholder='Date Installed Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formStatus'
            >
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label='Default select example'
                defaultValue='In Good Condition'
                className='border-disabled'
                disabled
              >
                <option value='In Good Condition'>In Good Condition</option>
                <option value='For Replacement'>For Replacement</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {history && <label className='mb-3'>History</label>}
        {history &&
          history.map((data) => {
            return (
              <>
                <Row key={data.id}>
                  <Col>
                    <label className='history-label'>{`${data.date} - ${data.type}`}</label>
                  </Col>
                  <Col>
                    <Form.Group className='buttons'>
                      <Button
                        className='completed'
                        variant='primary'
                      >
                        Completed
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>

                <Row key={data.id}>
                  <Col>
                    <Form.Group
                      className='mb-5'
                      controlId='formRemarks'
                    >
                      <Form.Control
                        aria-label='Remarks here'
                        as='textarea'
                        rows={5}
                        className='remarks border-disabled'
                        placeholder='Remarks Here'
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}

        <Form.Group className='buttons'>
          <Button
            as={Link}
            to={'/engineering/equipment-inventory'}
            className='cancel cancel-back'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Back
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViewDetailsForm;
