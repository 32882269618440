// import core & vendor packages below
import { Routes, Route } from 'react-router-dom';

// import components below
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import IncidentReportPage from '../pages/IncidentReport/IncidentReportPage';
import ViewIncidentReport from '../pages/IncidentReport/ViewIncidentReport/ViewIncidentReport';

const SecurityRouter = (props) => {
  const { data } = props;

  return (
    <Routes>
      <Route
        path='/security/incident-report'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <IncidentReportPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/security/incident-report/view-incident-report/:id'
        element={
          <ProtectedRoute isAuth={data && data.isAuth}>
            <ViewIncidentReport />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default SecurityRouter;
