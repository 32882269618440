// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import TenantRequestList from './TenantRequestList';

// API

// CSS
import './index.scss';

const Request = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Tenant Request</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Col>
            <Tabs
              defaultActiveKey='Tenant Request List'
              id='tenant-billing-list'
              className='mb-3 finance-nav'
            >
              <Tab
                eventKey='Tenant Request List'
                title='Tenant Request List'
              >
                <TenantRequestList />
              </Tab>
            </Tabs>
          </Col>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Request;
