// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ViewIncidentReportContent from './ViewIncidentReportContent';

// API

// CSS
import './ViewIncidentReport.scss';

const ViewIncidentReport = () => {
  return (
    <Fragment>
      <Helmet>
        <title>View Incident Report</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Incident Report'
                id='house-rules-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='View Incident Report'
                  title='View Incident Report'
                >
                  <ViewIncidentReportContent />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ViewIncidentReport;
