// import core & vendor packages below
import { Chart } from 'react-google-charts';

// import assets below
import './style.scss';

// main component
const ExpendituresGraph = () => {
  const options = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 200, left: 120 },
    legend: { position: 'none' },
    colors: ['#00B6E9', '#2179BF'],
    tooltip: { isHtml: true },
    bar: { groupWidth: '50%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [
        0, 50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000,
        450000, 500000,
      ],
    },
    hAxis: {
      slantedText: true,
      textStyle: { color: '#00B6E9' },
    },
  };

  const data = [
    [
      'Category',
      'Value',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Office Suppplies', 120000, 120000],
    ['Communication', 55000, 55000],
    ['Emergency Fund', 55000, 55000],
    ['Professional fees', 450000, 450000],
    ['Utilities', 210000, 210000],
    ['Consumables & Supplies', 290000, 290000],
    ['Repair & Maintenance', 230000, 110000],
    ['Service Contractors', 440000, 440000],
    ['Building Improvements', 130000, 130000],
    ['Machinery & Equipment', 300000, 300000],
  ];

  const headerRowOne = [
    { title: 'Office Supplies', value: 210000 },
    { title: 'Communication', value: 210000 },
    { title: 'Emergency Funds', value: 210000 },
    { title: 'Professional Fees', value: 210000 },
    { title: 'Utilities', value: 210000 },
  ];

  const headerRowTwo = [
    { title: 'Consumables & Supplies', value: 210000 },
    { title: 'Repair & Maintenance', value: 210000 },
    { title: 'Service Contractors', value: 210000 },
    { title: 'Building Improvement', value: 210000 },
    { title: 'Machinery & Equipment', value: 210000 },
  ];

  return (
    <div className='expendituresGraph'>
      <div className='expendituresGraph__header'>
        <div className='expendituresGraph__row'>
          {headerRowOne.map((item, index) => {
            return (
              <div
                className='expendituresGraph__head'
                key={index}
              >
                <h6>{item.title}</h6>
                <p>{item.value}</p>
              </div>
            );
          })}
        </div>
        <div className='expendituresGraph__row'>
          {headerRowTwo.map((item, index) => {
            return (
              <div
                className='expendituresGraph__head'
                key={index}
              >
                <h6>{item.title}</h6>
                <p>{item.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Chart
        chartType='ColumnChart'
        width='100%'
        height='650px'
        options={options}
        data={data}
      />
    </div>
  );
};

export default ExpendituresGraph;
