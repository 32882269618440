const Wrench = () => {
  return (
    <svg viewBox='0 0 19.341 19.341'>
      <path
        id='wrench'
        d='M19.177,4.123a.453.453,0,0,0-.76-.208L15.608,6.724,13.044,6.3l-.427-2.564L15.426.923a.454.454,0,0,0-.214-.762A5.413,5.413,0,0,0,8.766,7.155L.706,15.216a2.417,2.417,0,0,0,3.419,3.419l8.054-8.054a5.422,5.422,0,0,0,7-6.458ZM2.415,17.831a.907.907,0,1,1,.907-.907A.907.907,0,0,1,2.415,17.831Z'
        transform='translate(0.003 -0.001)'
      />
    </svg>
  );
};

export default Wrench;
