const Scroll = () => {
  return (
    <svg viewBox='0 0 28.75 23'>
      <path
        id='scroll'
        d='M2.156,0A2.159,2.159,0,0,0,0,2.156V5.031a.719.719,0,0,0,.719.719H4.313V2.156A2.159,2.159,0,0,0,2.156,0ZM11.5,18.533V15.813H24.438V4.313A4.317,4.317,0,0,0,20.125,0H5.013A3.559,3.559,0,0,1,5.75,2.156V18.688a2.877,2.877,0,0,0,3.358,2.835A2.982,2.982,0,0,0,11.5,18.533Zm1.438-1.283v1.438A4.317,4.317,0,0,1,8.625,23H23.719a5.031,5.031,0,0,0,5.031-5.031.719.719,0,0,0-.719-.719Z'
      />
    </svg>
  );
};

export default Scroll;
