/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// import core & vendor packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal, Image } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

// import components below
import FormUI from '../../../components/UI/FormUI';

// import assets below
import './Form.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import logo from 'assets/img/logo.png';

import Add from '../../../components/SVG/Add';

// main component
const WorkPermitForm = ({
  buildings,
  setSelectedUnit,
  names,
  setSubmit,
  msg,
  user,
  userUnit,
  setMsg,
}) => {
  const [listForm, setListForm] = useState([{ id: 1, name: '', fileName: '' }]);
  const [formData, setFormData] = useState({
    building: '',
    unit: '',
    user: '',
    workType: '',
    dateFiled: '',
    periodCovered: ['', ''],
    scopeOfWork: '',
  });

  const [unitId, setUnitId] = useState();
  const [renovation, showRenovation] = useState(false);
  const [data, setData] = useState({
    buildings: [],
    units: [],
    names: [],
  });

  const role = user.attributes.role;

  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
    setError,
    getValues,
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  // To Add List
  const addList = () => {
    const newID = listForm.length + 1;

    const newList = {
      id: newID,
      name: '',
      fileName: '',
    };

    setListForm((prevState) => {
      return [...prevState, newList];
    });
  };

  const changeValue = (e, list, type) => {
    const newValue = listForm.map((arrBuilding) => {
      if (list.id === arrBuilding.id) {
        if (type === 'name') {
          return { ...list, name: e };
        }

        if (type === 'fileName') {
          return { ...list, fileName: e };
        }
      }

      return arrBuilding;
    });

    setListForm(newValue);
  };

  const getNames = (e) => {
    setSelectedUnit(e);
  };

  const onSubmit = (e) => {
    setMsg({ message: '', status: 'failed' });
    let checkList = false;

    listForm.forEach((list, index) => {
      if (list.name === '' || !list.fileName) {
        checkList = true;
      }
    });

    if (checkList === true) {
      setMsg({
        message: 'Please fill in the required fields',
        status: 'failed',
      });
      return;
    }

    const from = new Date(formData.periodCovered[0]);
    const filteredFrom = new Date(
      from.getTime() - from.getTimezoneOffset() * 60000,
    ).toISOString();

    const to = new Date(formData.periodCovered[0]);
    const filteredTo = new Date(
      to.getTime() - to.getTimezoneOffset() * 60000,
    ).toISOString();

    const paramsData = new FormData();

    paramsData.append(
      'unit_id',
      role === 'admin' ? formData.unit.id : userUnit?.id,
    );
    paramsData.append('user_id', role === 'admin' ? formData.user.id : user.id);
    paramsData.append('type', formData.workType);
    paramsData.append('date_filed', formData.dateFiled);
    paramsData.append('period_covered_from', filteredFrom);
    paramsData.append('period_covered_to', filteredTo);
    paramsData.append('description', formData.scopeOfWork);

    listForm.forEach((list, idx) => {
      paramsData.append(`personnel[${idx}][name]`, list.name);
      paramsData.append(`personnel[${idx}][personnel_id]`, list.fileName);
    });

    setSubmit(paramsData);
  };

  const customStyles = {
    control: () => ({
      padding: '10px 8px',
      display: 'flex',
      border: '1px solid #707070',
      borderRadius: '5px',
    }),
  };

  // Use Effects
  useEffect(() => {
    if (buildings && buildings.length > 0) {
      const newBuildings = buildings.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, buildings: newBuildings };
      });
    }
  }, [buildings]);

  useEffect(() => {
    if (unitId) {
      const filterUnit = buildings.filter((list) => {
        return list.id === unitId;
      });

      const newUnits = filterUnit[0].relationships.units.map((list, idx) => {
        return {
          id: list?.id || idx,
          value: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
          label: list?.attributes?.unit_number || `Unamed Unit (${idx})`,
        };
      });

      setData((prevState) => {
        return { ...prevState, units: newUnits };
      });
    }
  }, [unitId]);

  useEffect(() => {
    if (names && names.length > 0) {
      const newNames = names.map((list, idx) => {
        return {
          id: list?.relationships?.user?.id || idx,
          value: list?.attributes?.name || `Unnamed Building ${idx}`,
          label: list?.attributes?.name || `Unnamed Building ${idx}`,
        };
      });

      setData((prevState) => {
        return { ...prevState, names: newNames };
      });
    } else {
      setData((prevState) => {
        return { ...prevState, names: [] };
      });
    }
  }, [names]);

  useEffect(() => {
    if (msg.message === 'Request Concern created successfully.') {
      setValue('concernType', '');
      setValue('subject', '');
      setValue('building', '');
      setValue('unit', '');
      setValue('user', '');
      setValue('details', '');
      setValue('images', '');
      setFormData({
        concernType: '1',
        subject: '',
        building: '',
        unit: '',
        user: '',
        details: '',
        images: [],
      });
    }
  }, [msg]);

  return (
    <>
      <Form
        className='workPermitForm'
        onSubmit={handleSubmit(onSubmit)}
        encType='multipart/form-data'
      >
        <FormUI>
          <div>
            {role && role === 'admin' && (
              <Row>
                <Col>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Building Name
                      {errors.building ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.building.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={customStyles}
                          options={data.buildings}
                          placeholder='Select Building'
                          {...field}
                        ></Select>
                      )}
                      {...register('building', {
                        required: '(Required)',
                        value: formData.building,
                        onChange: (event) => {
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              building: event.target.value,
                              unit: '',
                              user: '',
                            };
                          });
                          setValue('unit', '');
                          setValue('user', '');
                          setUnitId(event.target.value.id);
                        },
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Unit No.
                      {errors.unit ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.unit.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={data.units}
                          styles={customStyles}
                          placeholder='Select Unit'
                          {...field}
                        ></Select>
                      )}
                      {...register('unit', {
                        required: '(Required)',
                        value: formData.unit,
                        onChange: (event) => {
                          getNames(event.target.value.id);
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              unit: event.target.value,
                              user: '',
                            };
                          });
                          setValue('user', '');
                        },
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-5'>
                    <Form.Label>
                      Name{' '}
                      {errors.user ? (
                        <span style={{ color: '#ff5c5c' }}>
                          {errors.user.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={data.names}
                          styles={customStyles}
                          placeholder='Select User'
                          {...field}
                        ></Select>
                      )}
                      {...register('user', {
                        required: '(Required)',
                        value: formData.user,
                        onChange: (event) => {
                          setFormData((prevState) => {
                            return {
                              ...prevState,
                              user: event.target.value,
                            };
                          });
                        },
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Work Type{' '}
                    {errors.workType ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.workType.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Select
                    placeholder='Enter Subject Here'
                    {...register('workType', {
                      required: '(Required)',
                      onChange: (event) => {
                        if (event.target.value === '3') {
                          showRenovation(true);
                          return;
                        }

                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            workType: event.target.value,
                          };
                        });
                      },
                    })}
                    value={formData.workType}
                  >
                    <option
                      value=''
                      hidden
                    >
                      Select Work Type
                    </option>
                    <option value='1'>Maintenance/Repair</option>
                    <option value='2'>Installation</option>
                    {role && role !== 'admin' && (
                      <option value='3'>Renovation</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Date Filed{' '}
                    {errors.dateFiled ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.dateFiled.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    type='date'
                    {...register('dateFiled', {
                      required: '(Required)',
                      onChange: (event) => {
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            dateFiled: event.target.value,
                          };
                        });
                      },
                    })}
                    value={formData.dateFiled}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Period Covered From / To{' '}
                    {errors.periodCovered ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.periodCovered.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <DateTimeRangePicker
                    value={formData.periodCovered}
                    {...register('periodCovered', {
                      required: '(Required)',
                    })}
                    onChange={(event) => {
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          periodCovered: event,
                        };
                      });

                      setValue('periodCovered', event);
                      reset({
                        ...getValues(),
                        periodCovered: event,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className='mb-5'>
                  <Form.Label>
                    Scope of Work{' '}
                    {errors.scopeOfWork ? (
                      <span style={{ color: '#ff5c5c' }}>
                        {errors.scopeOfWork.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='12'
                    {...register('scopeOfWork', {
                      required: '(Required)',
                      onChange: (event) => {
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            scopeOfWork: event.target.value,
                          };
                        });
                      },
                    })}
                    value={formData.scopeOfWork}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='personnelList'>
                  <div className='personnelList__header'>
                    <h6>List of Personnel</h6>
                    <h6>Upload Worker ID</h6>
                  </div>
                  <div className='personnelList__body'>
                    {listForm.map((item) => {
                      return (
                        <div key={item.id}>
                          <Form.Group>
                            <Form.Control
                              text='text'
                              required
                              placeholder='Enter Name Here'
                              value={item.description}
                              onChange={(e) =>
                                changeValue(e.target.value, item, 'name')
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>
                              <span>Upload</span>
                              <Form.Control
                                type='file'
                                accept='image/*'
                                onChange={(e) => {
                                  changeValue(
                                    e.target.files[0],
                                    item,
                                    'fileName',
                                  );
                                }}
                              />
                            </Form.Label>
                            <span>
                              {item.fileName
                                ? item?.fileName?.name
                                : 'No Image Uploaded'}
                            </span>
                          </Form.Group>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <Button
                  variant='primary'
                  onClick={addList}
                >
                  {Add()}Add
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                  display: 'block',
                }}
              >{`${msg.message}`}</span>
            </Col>
            <Col>
              <div
                className='formUI__footer'
                style={{ margin: 0 }}
              >
                <Button
                  variant='submit'
                  type='submit'
                >
                  Submit
                </Button>
                <Button variant='cancel'>Cancel</Button>
              </div>
            </Col>
          </Row>
        </FormUI>
      </Form>

      <Modal
        className='modal-renovation'
        size='lg'
        show={renovation}
        onHide={() => {
          showRenovation(false);
        }}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='d-flex modal-cont'>
              <Image
                src={logo}
                className='logo'
              />
              <h2>For Renovation Only</h2>
              <p>
                For renovation, kindly coordinate with the property management
                office. Contact No. +639271234567
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkPermitForm;
