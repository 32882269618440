// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import Form from './Form';

// main component
const DenyAmenitiesTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='denyRequest'
        className='mb-3'
      >
        <Tab
          eventKey='denyRequest'
          title='Deny Request'
        >
          <Form />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default DenyAmenitiesTabs;
