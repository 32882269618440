// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import BudgetFilter from './Filter';
import BudgetTable from './Table';

// main component
const Budget = () => {
  const [selectedFilter, setSelectedFilter] = useState('All');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <BudgetFilter
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      <BudgetTable />
    </Fragment>
  );
};

export default Budget;
