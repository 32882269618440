import React from 'react';
import './Review.scss';
import { Row } from 'react-bootstrap';
function Review() {
  return (
    <>
      <div className='review'>
        <Row>
          <div className='col'>
            <p className='fw-bold '>Name</p>
            <p>Juan Dela Cruz</p>
          </div>
          <div className='col'>
            <p className='fw-bold '>Unit No.</p>
            <p>A 101</p>
          </div>
          <div className='col'>
            <p className='fw-bold '>Payor Type</p>
            <p>AD</p>
          </div>
        </Row>
        <Row>
          <div className='col-lg-4'>
            <p className='fw-bold'>Billing Date</p>
            <p>01/01/2022</p>
          </div>
          <div className='col-lg-4'>
            <p className='fw-bold'>Due Date</p>
            <p>01/31/2022</p>
          </div>
        </Row>

        <p className='headerInformation'>Previous Charges</p>
        <div className='container-table'>
          <table className='table'>
            <thead>
              <tr className='red'>
                <th scope='col'>Particulars</th>
                <th scope='col'>Period Covered</th>
                <th scope='col'>Bill Amount</th>
                <th scope='col'>Payment</th>
                <th scope='col'>Ref OR#</th>
                <th scope='col'>Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assoc. Dues</td>
                <td>Jan-Dec 2021</td>
                <td>10,000,000.00</td>
                <td>10,000,000.00</td>
                <td>#00001</td>
                <td>0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className='mt-4'>
          <div className='col-3'>
            <p className='fw-bold'>Penalty</p>
            <p>0.00</p>
          </div>
          <div className='col-3'>
            <p className='fw-bold'>Total Amount</p>
            <p>10,000,000.00</p>
          </div>
        </Row>

        <p className='headerInformation'>Current Charges</p>
        <p>Regular Assessment of Operating Expenses:</p>
        <div className='container-table'>
          <table className='table'>
            <thead>
              <tr className='red'>
                <th scope='col'>Particulars</th>
                <th scope='col'>Period Covered</th>
                <th scope='col'>Unit Area</th>
                <th scope='col'>Rate</th>
                <th scope='col'>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assoc. Dues</td>
                <td>Jan-Dec 2021</td>
                <td>131.41</td>
                <td>90.00</td>
                <td>90.00</td>
              </tr>
              <tr>
                <td>Real Estate Tax</td>
                <td>Jan-Dec 2021</td>
                <td>131.41</td>
                <td>90.00</td>
                <td>90.00</td>
              </tr>
              <tr>
                <td>Bldg. Insurance</td>
                <td>Jan-Dec 2021</td>
                <td>131.41</td>
                <td>90.00</td>
                <td>90.00</td>
              </tr>
              <tr>
                <td>Electricity</td>
                <td>Jan-Dec 2021</td>
                <td>131.41</td>
                <td>90.00</td>
                <td>90.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className='headerInformation mt-4'>Other Charges</p>
        <div className='container-table'>
          <table className='table'>
            <thead>
              <tr className='red'>
                <th scope='col'>Particulars</th>
                <th scope='col'>Previous Reading</th>
                <th scope='col'>Present Reading</th>
                <th scope='col'>Consumption</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Water Charges</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className='mt-4'>
          <div className='col-3'>
            <p className='fw-bold'> Penalty</p>
            <p>2%</p>
          </div>
          <div className='col-3'>
            <p className='fw-bold'> Total Current Charges</p>
            <p>10,000.00</p>
          </div>
        </Row>
        <p className='headerInformation'>Advance/Overpayment</p>
        <div className='container-table'>
          <table className='table'>
            <thead>
              <tr className='red'>
                <th scope='col'>Particulars</th>
                <th scope='col'>Remarks</th>
                <th scope='col'>Balance</th>
                <th scope='col'>Amt. Appled</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assoc.Dues</td>
              </tr>
              <tr>
                <td>Real Estate Tax</td>
              </tr>
              <tr>
                <td>Bldg. Insurance</td>
              </tr>
              <tr>
                <td>Water Charges</td>
              </tr>
              <tr>
                <td>Discount (2%)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='d-flex justify-content-between mt-4'>
          <div>
            <p className='fw-bold'>Total Advances</p>
            <p>0.00</p>
          </div>
          <div className='mx-lg-5 mx-1 text-end'>
            <p className='fw-bold'>TOTAL AMOUNT DUE</p>
            <p className='headerInformation '>10,000.00</p>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-5'>
        <div>
          <button
            className='btn mx-1 text-white'
            style={{ background: '#00b6e9' }}
          >
            Publish
          </button>
          <button
            className='btn mx-1 shadow-sm bg-white'
            style={{ color: '#00b6e9' }}
          >
            Re-upload
          </button>
        </div>
        <div className='d-flex pagination-review'>
          <button
            disabled
            className='btn'
            style={{ background: 'rgb(221 221 221)', padding: '0.05rem 1rem' }}
          >
            {'<'}
          </button>
          <p>Showing 1 out of 50 entries</p>
          <button
            className='btn text-white'
            style={{ background: '#00b6e9', padding: '0.05rem 1rem' }}
          >
            {'>'}
          </button>
        </div>
      </div>
    </>
  );
}

export default Review;
