// import core and vendor packages below
import { Form, InputGroup } from 'react-bootstrap';

// import components below
import FilterUI from '../../../../components/UI/FilterUI';

// main component
const AnnouncementsFilter = () => {
  return (
    <FilterUI>
      <Form.Select>
        <option>Building 1</option>
        <option>Building 2</option>
        <option>Building 3</option>
      </Form.Select>
      <InputGroup>
        <Form.Control
          type='search'
          placeholder='Search'
        />
        <InputGroup.Text>
          <svg
            width='22'
            height='22'
            viewBox='0 0 25.362 25.584'
          >
            <path
              id='search'
              d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
              transform='translate(-336.364 -87.386)'
              fill='#c7c7c7'
            />
          </svg>
        </InputGroup.Text>
      </InputGroup>
    </FilterUI>
  );
};

export default AnnouncementsFilter;
