// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Import API
import {
  getRequestAmenitiesByReferenceId,
  getPaymentInstruction,
  submitApproveRequest,
  submitProofOfPayment,
  verifyrequestAmenities,
} from 'api/request';

// import assets below
import './View.scss';

// main component
const View = () => {
  const { id } = useParams();
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [name, setName] = useState('');
  const [userData, setUserData] = useState({
    amenity: '',
    buildingName: '',
    unitNo: '',
    name: '',
    startDate: '',
    endDate: '',
    paymentInstructions: '',
    bookingNo: '',
    status: '',
    proofOfPayment: '',
  });

  // Show filename after selecting
  const showFileName = (e) => {
    if (e.target.files.length === 0) {
      return;
    }

    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);

    setUserData((prevState) => {
      return { ...prevState, proofOfPayment: e.target.files[0] };
    });
  };

  useEffect(() => {
    getRequestAmenitiesByReferenceId(
      id,
      '?include=payment_instructions',
      callback,
    );
    getPaymentInstruction(paymentCallback);
  }, []);

  useEffect(() => {
    console.log('USER DATA', userData);
  }, [userData]);

  const approveRequest = () => {
    submitApproveRequest(id, '', approveCallback);
  };
  verifyrequestAmenities;
  const verifyRequest = () => {
    verifyrequestAmenities(id, '', approveCallback);
  };
  const paymentReceived = () => {
    if (userData.proofOfPayment === '') {
      setMsg({ message: 'Please add Proof of payment', status: 'failed' });
      return;
    }

    // eslint-disable-next-line prefer-const
    let newData = new FormData();
    newData.append('proof_of_payment', userData.proofOfPayment);

    submitProofOfPayment(id, newData, proofOfPaymentCallback);
  };

  const proofOfPaymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({
          message: data.message,
          status: 'success',
        });
        getRequestAmenitiesByReferenceId(
          id,
          '?include=payment_instructions',
          callback,
        );
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const approveCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: data.message, status: 'success' });
        getRequestAmenitiesByReferenceId(
          id,
          '?include=payment_instructions',
          callback,
        );
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            amenity: data?.data?.relationships?.amenity?.attributes?.name,
            buildingName: data?.data?.relationships?.building?.attributes?.name,
            unitNo: data?.data?.relationships?.unit?.attributes?.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            startDate: data?.data?.attributes?.start_datetime,
            endDate: data?.data?.attributes?.end_datetime,
            bookingNo: data?.data?.attributes?.reference_id,
            status: data?.data?.attributes?.status,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const paymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUserData((prevState) => {
          return {
            ...prevState,
            paymentInstructions: data?.data?.attributes?.description,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <div className='view-concern-amenities'>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Amenity</h6>
            <span>{userData?.amenity}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Building Name</h6>
            <span>{userData?.buildingName}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Unit No.</h6>
            <span>{userData?.unitNo}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <h6 className='mb-3'>Name</h6>
            <span>{userData?.name}</span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Start/End Date and Time</h6>
            <span>
              {userData.startDate} <br />
              {userData.endDate}
            </span>
          </div>
        </Col>
        <Col>
          <div>
            <h6 className='mb-3'>Payment Instructions</h6>

            <textarea
              rows='2'
              readOnly
              style={{ border: 'none' }}
              value={userData.paymentInstructions}
            ></textarea>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg='4'>
          <div>
            <h6 className='mb-3'>Booking No.</h6>
            <span>{userData.bookingNo}</span>
          </div>
        </Col>
        <Col lg='4'>
          {(userData.status === 'approved' || userData.status === 'paid') && (
            <div>
              <h6 className='mb-3'>Status</h6>
              <span style={{ textTransform: 'capitalize' }}>
                {userData.status}
              </span>
            </div>
          )}
        </Col>
      </Row>
      {userData.status === 'approved' && (
        <Row>
          <h3 className='mb-4 proof-of-payment'>Proof of Payment</h3>
          <Col lg='4'>
            <Form>
              <Form.Group
                className='mb-4'
                controlId='proof-of-payment'
              >
                <h6 className='mb-3'>Proof of Payment</h6>

                <div className='upload-cont'>
                  <div className='upload-input proof-of-payment'>
                    <label htmlFor='proof-of-payment'>Select file</label>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      onChange={showFileName}
                      style={{ padding: 0 }}
                      accept='.xlsx, .xls, .csv, .png, .jpg, .jpeg'
                    ></Form.Control>
                  </div>

                  <span>{name && name}</span>
                </div>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          className='message-text-result'
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
              display: 'block',
            }}
          >{`${msg.message}`}</span>
        </Col>
        <Col style={{ justifyContent: 'flex-end' }}>
          <div style={{ margin: 0, padding: 0 }}>
            <div className='buttons-amenity'>
              {userData.status === 'approved' && (
                <Button
                  variant='approve'
                  onClick={paymentReceived}
                  className='payment-received'
                >
                  Payment Received
                </Button>
              )}
              {(userData.status === 'for approval' ||
                userData.status === 'pending' ||
                userData.status === 'denied') && (
                <Button
                  variant='approve'
                  onClick={approveRequest}
                >
                  Approve
                </Button>
              )}
              {userData.status === 'for verification' && (
                <Button
                  variant='approve'
                  onClick={verifyRequest}
                >
                  Verify
                </Button>
              )}
              <Link
                className='btn btn-deny'
                to={`/administration/requests/amenities/deny-request/${id}?activeKey=requests`}
              >
                Deny
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default View;
