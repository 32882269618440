const Exclamation = () => {
  return (
    <svg viewBox='0 0 24.445 24.445'>
      <path
        id='exclamation-circle'
        d='M32.445,20.222A12.222,12.222,0,1,1,20.222,8,12.222,12.222,0,0,1,32.445,20.222ZM20.222,22.687a2.267,2.267,0,1,0,2.267,2.267A2.267,2.267,0,0,0,20.222,22.687ZM18.07,14.538l.366,6.7a.591.591,0,0,0,.591.559h2.393a.591.591,0,0,0,.591-.559l.366-6.7a.591.591,0,0,0-.591-.624H18.661A.591.591,0,0,0,18.07,14.538Z'
        transform='translate(-8 -8)'
      />
    </svg>
  );
};

export default Exclamation;
