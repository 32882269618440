/* eslint-disable camelcase */
// packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// CSS
import './EditUnitForm.scss';

// Components
import TagsInput from './TagsInput.tsx';

// API
import { getUnitDetails, updateBuildingUnitDetails } from 'api/request';

// CSS

// Main Component
const EditUnitForm = ({ id }) => {
  const [updateMsg, setUpdateMsg] = useState({ message: '', status: '' });
  const [data, setData] = useState({
    buildingName: '',
    unitNo: '',
    unitArea: '',
    classification: '',
    buildingFloor: '',
    parkingSlots: [],
    owner: [],
    tenant: [],
  });

  // Useform
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Will fire after submitting form
  const onSubmit = () => {
    setUpdateMsg({ message: '', status: '' });

    const owners = data.owner.map((list) => {
      return list.label;
    });

    const tenants = data.tenant.map((list) => {
      return list.label;
    });

    const parkingSlots = data.parkingSlots.map((list) => {
      return list.label;
    });

    const params = {
      unit_number: data.unitNo,
      unit_area: data.unitArea,
      building_floor: data.buildingFloor,
      classification: data.classification,
      parking_slots: parkingSlots,
      owners: owners,
      tenants: tenants,
    };

    updateBuildingUnitDetails(id, params, updateCallback);
  };

  const updateCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUpdateMsg({ message: 'Successfully updated', status: 'success' });
        setTimeout(() => {}, 2000);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setUpdateMsg({ message: 'Something went wrong.', status: 'failed' });
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUnitDetails(id, callback);
    }
  }, [id]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setData((prevState) => {
          return {
            ...prevState,
            buildingName: data.data.relationships.building
              ? data.data.relationships.building.attributes.name
              : '',
            classification: data.data.relationships.classification
              ? data.data.relationships.classification.attributes.name
              : '',
            buildingFloor: data.data.attributes.building_floor,
            unitNo: data.data.attributes.unit_number,
            unitArea: data.data.attributes.unit_area,
            parkingSlots: data.data.relationships.parking_slots
              ? data.data.relationships.parking_slots.map((list) => {
                  return {
                    id: list.id,
                    label: list.attributes.slot_number,
                    value: list.attributes.slot_number,
                  };
                })
              : [],
            owner: data.data.relationships.owners
              ? data.data.relationships.owners.map((list) => {
                  return {
                    id: list.id,
                    referenceId: list.attributes.reference_id,
                    label: list.attributes.name,
                    value: list.attributes.name,
                  };
                })
              : [],
            tenant: data.data.relationships.tenants
              ? data.data.relationships.tenants.map((list) => {
                  return {
                    id: list.id,
                    referenceId: list.attributes.reference_id,
                    label: list.attributes.name,
                    value: list.attributes.name,
                  };
                })
              : [],
          };
        });

        reset();
      }

      if (status === 401 || status === 422) {
        // setMsg('Something went wrong.');
        setUpdateMsg({
          message: 'Something went wrong. Please refresh the page',
          status: 'failed',
        });
      }
    }
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm edit-unit'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formBuildingName'
            >
              <Form.Label>
                Building Name{' '}
                {errors.buildingName ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.buildingName.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                className='readonly-cell'
                aria-label='Building Name'
                type='text'
                readOnly
                {...register('buildingName', {
                  required: 'Building Name is Required',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, buildingName: event.target.value };
                    });
                  },
                })}
                value={data.buildingName}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formUnitNo'
            >
              <Form.Label>
                Unit No.{' '}
                {errors.unitNo ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.unitNo.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Unit No.'
                className='readonly-cell'
                readOnly
                type='text'
                {...register('unitNo', {
                  required: 'Unit Number is Required',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, unitNo: event.target.value };
                    });
                  },
                })}
                value={data.unitNo}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formUnitArea'
            >
              <Form.Label>
                Unit Area{' '}
                {errors.unitArea ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.unitArea.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Unit Area'
                type='text'
                {...register('unitArea', {
                  required: 'Unit Number is Required',
                  onChange: (event) => {
                    setData((prevState) => {
                      return { ...prevState, unitArea: event.target.value };
                    });
                  },
                })}
                value={data.unitArea}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formClassification'
            >
              <Form.Label>
                Classification{' '}
                {errors.classification ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.classification.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Select
                aria-label='Select Classification'
                {...register('classification', {
                  required: 'Select Classifcation',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        classification: event.target.value,
                      };
                    });
                  },
                })}
                value={data.classification}
              >
                <option
                  value=''
                  hidden
                >
                  Select Classification
                </option>
                <option value='commercial'>Commercial</option>
                <option value='residential'>Residential</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formBuildingFloor'
            >
              <Form.Label>
                Building Floor{' '}
                {errors.buildingFloor ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.buildingFloor.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>
              <Form.Control
                aria-label='Building Floor'
                type='text'
                {...register('buildingFloor', {
                  required: 'Building Floor is Required',
                  onChange: (event) => {
                    setData((prevState) => {
                      return {
                        ...prevState,
                        buildingFloor: event.target.value,
                      };
                    });
                  },
                })}
                value={data.buildingFloor}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-5'
              controlId='formParkingSlots'
            >
              <Form.Label>Parking Slot(s)</Form.Label>
              <TagsInput
                className='formParkingSlots'
                value={data.parkingSlots}
                setData={setData}
                inputName={'parkingSlots'}
                setUpdateMsg={setUpdateMsg}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group
              className='mb-4'
              controlId='formOwner'
            >
              <Form.Label>Owner</Form.Label>
              <TagsInput
                className='formOwner'
                value={data.owner}
                setData={setData}
                inputName={'owner'}
                setUpdateMsg={setUpdateMsg}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group
              className='mb-4'
              controlId='formTenant'
            >
              <Form.Label>Tenant</Form.Label>
              <TagsInput
                className='formTenant'
                value={data.tenant}
                setData={setData}
                inputName={'tenant'}
                setUpdateMsg={setUpdateMsg}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className='buttons'>
          <Container
            fluid
            style={{ padding: 0 }}
          >
            <Row>
              <Col className='message-text'>
                <span
                  style={{
                    color:
                      updateMsg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                    display: 'block',
                  }}
                >
                  {`${updateMsg.message}`}
                </span>
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  type='submit'
                >
                  Save
                </Button>
                <Button
                  as={Link}
                  to={'/administration/building-list'}
                  className='cancel'
                  variant='default'
                  style={{ padding: '0.8em 2em' }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default EditUnitForm;
