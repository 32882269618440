/* eslint-disable no-unused-vars */
import { Fragment, useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ApplicationForm from './ApplicationForm/ApplicationForm';
import RequestList from './RequestList/RequestList';
import TabsUI from 'components/UI/TabsUI';
import {
  getAmenitiesList,
  getBuildingLists,
  getPaymentInstruction,
  userAccounts,
  submitRequestAmenities,
  getRequestAmenities,
  moveinMoveout,
  getmoveinmoveoutList,
  submitStaffIDForm,
  getstaffIDList,
} from 'api/request';
import userContext from 'components/userContext/userContext';
function StaffID() {
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [activeKey, setActiveKey] = useState();
  const [refreshList, setRefreshList] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?include=building,requestedBy&building_id=${userUnit?.buildingId}`;
      getstaffIDList(params, requestAmenitiesCallback);
    } else {
      setLoading(true);

      const params = `?include=building,requestedBy&building_id=${selectedBuilding}`;
      getstaffIDList(params, requestAmenitiesCallback);
    }
  };

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
  }, []);

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?include=building,requestedBy&building_id=${userUnit?.buildingId}&filter[search]=${page}`;

      getstaffIDList(params, requestAmenitiesCallback);
    } else {
      const params = `?include=building,requestedBy&building_id=${selectedBuilding}&filter[search]=${page}`;

      getstaffIDList(params, requestAmenitiesCallback);
    }
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  const handleSelect = (e) => {
    if (e === 'Staff ID Request List') {
      console.log('change tab');
      setRefreshList(true);
    }
    setActiveKey(e);
  };

  useEffect(() => {
    if (submit !== null) {
      setMsg({ message: '', status: '' });
      submitStaffIDForm(submit, submitCallback);
    }
  }, [submit]);
  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      let params;

      if (user.attributes.role === 'owner') {
        params = `?filter[search]=&filter[unit]=${selectedUnit}&filter[role]=tenant`;
      } else {
        params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      }

      userAccounts(params);
    }
  }, [selectedUnit]);
  useEffect(() => {
    setActiveKey('Staff ID Application Form');
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
    setLoading(true);
    getBuildingLists('?include=units', buildingListCallback);
  }, []);
  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=building,requestedBy&building_id=${userUnit?.buildingId}`;

        getstaffIDList(params, requestAmenitiesCallback);
      } else {
        const params = `?include=building,requestedBy&building_id=${selectedBuilding}`;

        getstaffIDList(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=building,requestedBy&building_id=${userUnit?.buildingId}&filter[search]=${search}`;
        getstaffIDList(params, requestAmenitiesCallback);
      } else {
        const params = `?include=building,requestedBy&building_id=${selectedBuilding}&filter[search]=${search}`;
        getstaffIDList(params, requestAmenitiesCallback);
      }
    }
  }, [search]);
  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setSelectedBuilding(data.data[0].id);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        console.log(data.message);
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'deny' });
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Request - Staff ID</title>
      </Helmet>
      <Main title='Request - Staff ID'>
        <Container fluid>
          <Row>
            <Col>
              <TabsUI>
                <Tabs
                  defaultActiveKey='Staff ID Application Form'
                  id='Staff ID Application Form'
                  className='mb-3 finance-nav'
                  onSelect={(e) => handleSelect(e)}
                  activeKey={activeKey}
                >
                  <Tab
                    eventKey='Staff ID Application Form'
                    title='Staff ID Application Form'
                  >
                    <ApplicationForm
                      setSubmit={setSubmit}
                      buildings={buildings}
                      msg={msg}
                      search={search}
                      user={user}
                      userUnit={userUnit}
                    />
                  </Tab>
                  <Tab
                    eventKey='Staff ID Request List'
                    title='Staff ID Request List'
                  >
                    <RequestList
                      tableData={tableData}
                      user={user}
                      refreshTable={refreshTable}
                      buildings={buildings}
                      setSelectedBuilding={setSelectedBuilding}
                      selectedBuilding={selectedBuilding}
                      setSearch={setSearch}
                      userUnit={userUnit}
                      links={links}
                      meta={meta}
                      selectPage={selectPage}
                    />
                  </Tab>
                </Tabs>
              </TabsUI>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default StaffID;
