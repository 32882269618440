/* eslint-disable camelcase */
/* eslint-disable newline-per-chained-call */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

// api
import { submitRebook } from 'api/request';

const RebookModal = ({
  rebookModal,
  setRebookModal,
  id,
  date,
  refreshTable,
}) => {
  const [msg, setMsg] = useState({ message: '', status: '' });
  const dateToday = new Date().toISOString();
  const [dateData, setDateData] = useState({
    startDateTime: '',
    endDateTime: '',
  });

  useEffect(() => {
    if (date.startDate !== '' && date.endDate !== '') {
      const dataStartDateTime = new Date(date.startDate);
      const dataEndDateTime = new Date(date.endDate);

      const minutesStart = String(dataStartDateTime.getMinutes()).padStart(2, '0');
      const hoursStart = String(dataStartDateTime.getHours()).padStart(2, '0');
      const dayStart = String(dataStartDateTime.getDate()).padStart(2, '0');
      const monthStart = String(dataStartDateTime.getMonth() + 1).padStart(
        2,
        '0',
      );
      const yearStart = dataStartDateTime.getFullYear();

      const minutesEnd = String(dataEndDateTime.getMinutes()).padStart(2, '0');
      const hoursEnd = String(dataEndDateTime.getHours()).padStart(2, '0');
      const dayEnd = String(dataEndDateTime.getDate()).padStart(2, '0');
      const monthEnd = String(dataEndDateTime.getMonth() + 1).padStart(
        2,
        '0',
      );
      const yearEnd = dataEndDateTime.getFullYear();

      setDateData({
        startDateTime: `${yearStart}-${monthStart}-${dayStart}T${hoursStart}:${minutesStart}`,
        endDateTime: `${yearEnd}-${monthEnd}-${dayEnd}T${hoursEnd}:${minutesEnd}`,
      });
    }
  }, [date]);

  // Useform
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const onSubmit = (e) => {
    console.log(e);
    const params = {
      start_datetime: dateData.startDateTime,
      end_datetime: dateData.endDateTime,
    };

    submitRebook(id, params, submitCallback);
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: data.message, status: 'success' });
        refreshTable();
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <Modal
      show={rebookModal}
      onHide={() => {
        setRebookModal(false);
      }}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Rebook Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className='amenitiesForm'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group className='mb-3'>
            <Form.Label>
              Start Date and Time{' '}
              {errors.startDateTime ? (
                <span style={{ color: '#ff5c5c' }}>
                  {errors.startDateTime.message}
                </span>
              ) : (
                ''
              )}
            </Form.Label>
            <Form.Control
              type='datetime-local'
              min={dateToday.substring(0, 16)}
              value={dateData.startDateTime}
              {...register('startDateTime', {
                required: '(Required)',
                onChange: (event) => {
                  setDateData((prevState) => {
                    return {
                      ...prevState,
                      startDateTime: event.target.value,
                    };
                  });
                },
              })}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>
              End Date and Time{' '}
              {errors.endDateTime ? (
                <span style={{ color: '#ff5c5c' }}>
                  {errors.endDateTime.message}
                </span>
              ) : (
                ''
              )}
            </Form.Label>
            <Form.Control
              type='datetime-local'
              value={dateData.endDateTime}
              {...register('endDateTime', {
                required: '(Required)',
                onChange: (event) => {
                  setDateData((prevState) => {
                    return {
                      ...prevState,
                      endDateTime: event.target.value,
                    };
                  });
                },
              })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Row style={{ width: '100%' }}>
          <Col>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
                fontSize: 12,
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col style={{ display: 'flex', gap: '1em' }}>
            <Button
              variant='secondary'
              onClick={() => {
                setRebookModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleSubmit(onSubmit)}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default RebookModal;
