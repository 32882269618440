const At = () => {
  return (
    <svg viewBox='0 0 60.439 60.439'>
      <path
        id='at'
        d='M38.22,8a30.219,30.219,0,1,0,16.5,55.538,2.929,2.929,0,0,0,.677-4.31l-1.24-1.515a2.916,2.916,0,0,0-3.823-.625A22.421,22.421,0,1,1,38.22,15.8c12.2,0,22.421,7.021,22.421,19.5,0,4.726-2.57,9.717-7.088,10.2-2.114-.055-2.061-1.567-1.642-3.659l2.855-14.758a2.924,2.924,0,0,0-2.871-3.48H46.414a1.647,1.647,0,0,0-1.637,1.461v.011c-1.791-2.181-4.929-2.653-7.308-2.653-9.088,0-16.8,7.583-16.8,18.456,0,7.957,4.482,12.9,11.7,12.9A12.33,12.33,0,0,0,41.509,49.1c1.16,4.156,4.949,4.156,8.616,4.156,13.271,0,18.314-8.726,18.314-17.962C68.439,18.681,55.038,8,38.22,8ZM35.578,45.1c-2.711,0-4.4-1.9-4.4-4.968,0-5.483,3.751-8.862,7.144-8.862,2.716,0,4.338,1.857,4.338,4.968C42.665,41.724,38.537,45.1,35.578,45.1Z'
        transform='translate(-8 -8)'
      />
    </svg>
  );
};

export default At;
