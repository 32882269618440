// Packages
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import EditViolationForm from './EditViolationForm';

// API

// CSS
import './EditViolation.scss';

// Main Component
const EditViolation = (props) => {
  console.log(props);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Edit Violations</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Edit Violation'
              id='violations-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Edit Violation'
                title='Edit Violation'
              >
                <EditViolationForm />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default EditViolation;
