// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
// import { BsThreeDots } from 'react-icons/bs';
import { Link } from 'react-router-dom';
// Components

// CSS/SCSS
import './Table.scss';

function Table() {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tenant Name',
        accessor: 'tenantName',
      },
      {
        Header: 'Date Requested',
        accessor: 'dateRequested',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // dropdown on table action
    const renderAction = (id) => {
      return (
        <Link to={`/my-tenant/request/${id}`} style={{ color: '#2C2C2C', fontWeight: 600 }}>
          View
        </Link>
      );
    };

    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        tenantName: 'Juan Dela Cruz',
        dateRequested: '01/20/2021',
        type: 'Concerns',
        status: 'Resolved',
      },
      {
        id: 2,
        tenantName: 'Juan Dela Cruz',
        dateRequested: '01/20/2021',
        type: 'Pets',
        status: `Pending for Owner's Approval`,
      },
      {
        id: 3,
        tenantName: 'Juan Dela Cruz',
        dateRequested: '01/20/2021',
        type: 'Gate Pass',
        status: 'Completed',
      },
      {
        id: 4,
        tenantName: 'Juan Dela Cruz',
        dateRequested: '01/20/2021',
        type: 'Work Permit',
        status: `Pending for Owner's Approval`,
      },
      {
        id: 5,
        tenantName: 'Juan Dela Cruz',
        dateRequested: '01/20/2021',
        type: 'Concerns',
        status: 'Cancelled',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        tenantName: data.tenantName,
        dateRequested: data.dateRequested,
        type: data.type,
        status: data.status,
        action: renderAction(data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='my-tenant-billing-container-table'>
      <table
        style={{ width: '100%' }}
        {...getTableProps()}
        className='data-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, idx) => (
                    // Apply the header cell props
                    <th
                      key={idx}
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  key={index}
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, idx) => {
                      // Apply the cell props
                      return (
                        <td
                          key={idx}
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.Header === 'Status'
                              ? 'status-column'
                              : ''
                          } ${cell.value === 'Resolved' ? 'green' : ''} 
                            ${cell.value === `Completed` ? 'green' : ''}
                            ${cell.value === `Cancelled` ? 'red' : ''}
                            ${cell.value === `Pending for Owner's Approval` ? 'yellow' : ''}
                          `}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default Table;
