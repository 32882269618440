/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// import core & vendor packages below
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Download from '../../../../components/SVG/Download';

// import components below

// import assets below
import './View.scss';
import { useEffect } from 'react';
import { viewMoveinMoveout } from 'api/request';

// main component
const View = () => {
  const { id } = useParams();
  const nav = useNavigate();
  // response api here

  const [getData, setData] = useState({
    lease_expiry_date: '',
    property_engineer_name: '',
    reference_id: '',
    remarks: '',
    representative_contact_number: '',
    representative_name: '',
    request_move_id: '',
    schedule_date: '',
    status: '',
    type: '',
    utility_electricity_reading: '',
    utility_electricity_serial: '',
    utility_water_reading: '',
    utility_water_serial: '',
    unit_number: '',
    name: '',
  });

  useEffect(() => {
    viewMoveinMoveout(id, callback);
  }, []);

  // Handle Response from api's
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setData((prev) => {
          return {
            ...prev,
            schedule_date: data?.data?.attributes?.schedule_date,
            lease_expiry_date: data?.data?.attributes?.lease_expiry_date,
            unit_number:
              data?.data?.relationships?.unit?.attributes.unit_number,
            name: data?.data?.relationships?.user?.attributes?.name,
            representative_name: data?.data?.attributes?.representative_name,
            representative_contact_number:
              data?.data?.attributes?.representative_contact_number,
            utility_electricity_reading:
              data?.data?.attributes?.utility_electricity_reading,
            utility_electricity_serial:
              data?.data?.attributes?.utility_electricity_serial,
            utility_water_reading:
              data?.data?.attributes?.utility_water_reading,
            utility_water_serial: data?.data?.attributes?.utility_water_serial,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  return (
    <div>
      <Form className='moveInOut_viewRequest'>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Tenant Name</h6>
              <span>{getData.name}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Unit No.</h6>
              <span>{getData.unit_number}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Move In Date</h6>
              <span>{getData.schedule_date}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mb-3'>Lease Expiry Date</h6>
              <span>{getData.lease_expiry_date}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Authorized Representative Name</h6>
              <span>{getData.representative_name}</span>
            </div>
          </Col>
          <Col>
            <div>
              <h6 className='mb-3'>Representative Contact No.</h6>
              <span>{getData.representative_contact_number}</span>
            </div>
          </Col>
        </Row>
        <h6>UTILITY READING</h6>
        <Row>
          <Col>
            <div>
              <h6 className='h5'>Utility</h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>Serial No.</h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>Move In Reading</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Form.Label>Electricity</Form.Label>
            </div>
          </Col>
          <Col>
            <div>
              <h6>
                {getData.utility_electricity_serial
                  ? getData.utility_electricity_serial
                  : '---'}
              </h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>
                {getData.utility_electricity_reading
                  ? getData.utility_electricity_reading
                  : '---'}
              </h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Form.Label>Water</Form.Label>
            </div>
          </Col>
          <Col>
            <div>
              <h6>
                {getData.utility_water_serial
                  ? getData.utility_water_serial
                  : '---'}
              </h6>
            </div>
          </Col>
          <Col>
            <div>
              <h6>
                {getData.utility_water_reading
                  ? getData.utility_water_reading
                  : '---'}
              </h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h6 className='mt-4 mb-3'>Date Taken</h6>
              <span>Jan. 20, 2021</span>
            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-end'>
          <div>
            <Link
              to='/administration/requests/move-in-out'
              className='btn btn-white shadow-sm'
              style={{ color: '#00B6E9' }}
            >
              Back
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default View;
