// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import PaymentDuesFilter from '../Filter';
import PaymentDuesTable from '../Table';
import PaymentDuesPagination from '../Pagination';

// main component
const PaymentDuesTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='dashboardPaymentDues'
        className='mb-3'
      >
        <Tab
          eventKey='dashboardPaymentDues'
          title='Payments Due'
        >
          <PaymentDuesFilter />
          <PaymentDuesTable />
          <PaymentDuesPagination />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default PaymentDuesTabs;
