// import core & vendor packages
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

// import components below
import Main from '../../../components/Main';
import FinanceReportsFilter from './Filter';
import FinanceReportsTabs from './Tabs';

// import assets below
import './style.scss';

// main component
const FinanceReport = () => {
  const [report, setReport] = useState('Summary Reports');

  return (
    <Fragment>
      <Helmet>
        <title>Finance Reports</title>
      </Helmet>
      <Main title='Finance Reports'>
        <Container fluid>
          <Row>
            <Col>
              <FinanceReportsFilter
                report={report}
                onChangeReport={setReport}
              />
              <FinanceReportsTabs report={report} />
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default FinanceReport;
