// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import UploadDocumentForm from './UploadDocumentForm';

// API

// CSS
import './UploadDocument.scss';

const UploadDocumentServiceProvider = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Service Provider - Upload Document</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Tabs
              defaultActiveKey='Upload Document'
              id='house-rules-form'
              className='mb-3 house-rules'
            >
              <Tab
                eventKey='Upload Document'
                title='Upload Document'
              >
                <UploadDocumentForm />
              </Tab>
            </Tabs>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default UploadDocumentServiceProvider;
