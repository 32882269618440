// import core & vendor packages below
import { Fragment, useState } from 'react';

// import components below
import CollectionsVSExpendituresFilter from './Filter';
import CollectionsVSExpendituresTable from './Table';
import CollectionsVSExpendituresGraph from './Graph';

// main component
const CollectionsVSExpenditures = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <CollectionsVSExpendituresFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && <CollectionsVSExpendituresTable />}
      {toggle && <CollectionsVSExpendituresGraph selected={selectedFilter} />}
    </Fragment>
  );
};

export default CollectionsVSExpenditures;
