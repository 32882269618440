// import core and vendor packages below
import {
  Form,
  InputGroup,
  FormControl,
  Dropdown,
  Button,
} from 'react-bootstrap';

// import components below
import FilterUI from '../../../components/UI/FilterUI';

// import style below
import './style.scss';

// main component
const OccupancyReportsFilter = (props) => {
  const { toggle, onToggle, selected, onSelect } = props;

  const dropdownItems = ['Occupancy Rate', 'Occupancy Based on Units'];

  return (
    <div className='occupancyReportsFilter'>
      <FilterUI>
        <InputGroup>
          <FormControl
            type='search'
            placeholder='Search'
          />
          <InputGroup.Text>
            <svg
              width='22'
              height='22'
              viewBox='0 0 25.362 25.584'
            >
              <path
                id='search'
                d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                transform='translate(-336.364 -87.386)'
                fill='#c7c7c7'
              />
            </svg>
          </InputGroup.Text>
        </InputGroup>
        <Form.Select>
          <option>Tower 1</option>
          <option>Tower 2</option>
          <option>Tower 3</option>
        </Form.Select>
        {toggle && (
          <Dropdown
            className='d-inline'
            drop='end'
          >
            <Dropdown.Toggle id='dropdown-autoclose-true'>
              <svg viewBox='0 0 29.498 25.811'>
                <path
                  id='sliders-h'
                  d='M28.576,52.28H9.218v-.922a.925.925,0,0,0-.922-.922H6.453a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,53.2v1.844a.925.925,0,0,0,.922.922H5.531v.922a.925.925,0,0,0,.922.922H8.3a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V53.2A.925.925,0,0,0,28.576,52.28Zm0-9.218H23.967V42.14a.925.925,0,0,0-.922-.922H21.2a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,43.984v1.844a.925.925,0,0,0,.922.922H20.28v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922h4.609a.925.925,0,0,0,.922-.922V43.984A.925.925,0,0,0,28.576,43.062Zm0-9.218H16.593v-.922A.925.925,0,0,0,15.671,32H13.827a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,34.765v1.844a.925.925,0,0,0,.922.922H12.905v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V34.765A.925.925,0,0,0,28.576,33.844Z'
                  transform='translate(0 -32)'
                  fill='#fff'
                />
              </svg>
              <span> Filter</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                {dropdownItems &&
                  dropdownItems.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={() => {
                          onSelect(item);
                        }}
                        disabled={selected === item}
                      >
                        {item}
                      </Button>
                    );
                  })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </FilterUI>
      <div className='buttonWrapper'>
        <Button onClick={() => onToggle(!toggle)}>
          {toggle ? 'Table' : 'Graph'}
        </Button>
        <Button>Download</Button>
      </div>
    </div>
  );
};

export default OccupancyReportsFilter;
