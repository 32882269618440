// import core & vendor packages below
import { Chart } from 'react-google-charts';

// import assets below
import './style.scss';

// main component
const AssociationDuesVSOPEXGraph = (props) => {
  const { selected } = props;

  const optionsCollectionsTotal = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'none' },
    tooltip: { isHtml: true },
    bar: { groupWidth: '50%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 200000, 400000, 600000, 800000, 1000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsTotal = [
    [
      'Data',
      'Collections from Current Collections',
      { role: 'style' },
      { role: 'tooltip', p: { html: true } },
    ],
    ['Collection', 600000, '#2179BF', 600000],
    ['Expenditures', 920000, '#00B6E9', 920000],
  ];

  const optionsCollectionsMonthly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#2179BF', '#00B6E9'],
    tooltip: { isHtml: true },
    bar: { groupWidth: '90%' },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 200000, 400000, 600000, 800000, 1000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsMonthly = [
    [
      'Month',
      'Collections',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'Expenditures',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['JAN', 200000, 200000, 200000, 200000],
    ['FEB', 200000, 200000, 200000, 200000],
    ['MAR', 200000, 200000, 200000, 200000],
    ['APR', 200000, 200000, 200000, 200000],
    ['MAY', 200000, 200000, 200000, 200000],
    ['JUN', 200000, 200000, 200000, 200000],
    ['JUL', 200000, 200000, 200000, 200000],
    ['AUG', 200000, 200000, 200000, 200000],
    ['SEP', 200000, 200000, 200000, 200000],
    ['OCT', 200000, 200000, 200000, 200000],
    ['NOV', 200000, 200000, 200000, 200000],
    ['DEC', 200000, 200000, 200000, 200000],
  ];

  const optionsCollectionsQuarterly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#2179BF', '#00B6E9'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 200000, 400000, 600000, 800000, 1000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsQuarterly = [
    [
      'Quarter',
      'Collection',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'Expenditures',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['Q1', 420000, 420000, 500000, 500000],
    ['Q2', 340000, 340000, 700000, 700000],
    ['Q3', 480000, 480000, 900000, 900000],
    ['Q4', 190000, 190000, 200000, 200000],
  ];

  const optionsCollectionsYearly = {
    chartArea: { width: '90%', top: 100, right: 40, bottom: 100, left: 120 },
    legend: { position: 'top', maxLines: 1 },
    colors: ['#2179BF', '#00B6E9'],
    tooltip: { isHtml: true },
    vAxis: {
      textStyle: { color: '#00B6E9' },
      format: 'decimal',
      minValue: 0,
      ticks: [0, 200000, 400000, 600000, 800000, 1000000],
    },
    hAxis: {
      textStyle: { color: '#00B6E9' },
    },
  };

  const dataCollectionsYearly = [
    [
      'Year',
      'Collection',
      { type: 'number', role: 'tooltip', p: { html: true } },
      'Expenditures',
      { type: 'number', role: 'tooltip', p: { html: true } },
    ],
    ['2021', 420000, 420000, 500000, 500000],
    ['2022', 340000, 340000, 700000, 700000],
  ];

  return (
    <div className='associationDuesVSOPEXGraph'>
      {selected === 'Total' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsTotal}
          data={dataCollectionsTotal}
        />
      )}
      {selected === 'Monthly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsMonthly}
          data={dataCollectionsMonthly}
        />
      )}
      {selected === 'Quarterly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsQuarterly}
          data={dataCollectionsQuarterly}
        />
      )}
      {selected === 'Yearly' && (
        <Chart
          chartType='ColumnChart'
          width='100%'
          height='650px'
          options={optionsCollectionsYearly}
          data={dataCollectionsYearly}
        />
      )}
    </div>
  );
};

export default AssociationDuesVSOPEXGraph;
