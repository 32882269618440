// import core and vendor packages below

// import components below

// import style below
import './style.scss';

// main component
const TableUI = (props) => {
  const { children } = props;

  return <div className='tableUI'>{children}</div>;
};

export default TableUI;
