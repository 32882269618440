// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';
// Components

// CSS/SCSS
import './CollectionTables.scss';

// Main Component
const CollectionTable = () => {
  // use navigate
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },

      {
        Header: ' Payor',
        accessor: 'payor',
      },
      {
        Header: 'SOA #',
        accessor: 'soa',
      },
      {
        Header: 'Month',
        accessor: 'month',
      },
      {
        Header: '  OR#',
        accessor: 'or',
      },
      {
        Header: 'OR Date',
        accessor: 'orDate',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // navigate Request
    const actionHandler = (actionNo) => {
      return (
        <u
          onClick={() =>
            navigate(`/finance/collections/view-collection-details/${actionNo}`)
          }
        >
          View
        </u>
      );
    };
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        unitNo: 'A 101',
        type: 'C',
        payor: 'Juan Dela Cruz',
        soa: '1002401',
        month: 'Jan',
        or: '0110',
        orDate: '01/21/2021',
        amount: '231,010,000.00',
        action: 'View',
      },
      {
        id: 2,
        unitNo: 'A 101',
        type: 'C',
        payor: 'Juan Dela Cruz',
        soa: '1002401',
        month: 'Jan',
        or: '0110',
        orDate: '01/21/2021',
        amount: '231,010,000.00',
        action: 'View',
      },
      {
        id: 3,
        unitNo: 'A 101',
        type: 'C',
        payor: 'Juan Dela Cruz',
        soa: '1002401',
        month: 'Jan',
        or: '0110',
        orDate: '01/21/2021',
        amount: '231,010,000.00',
        action: 'View',
      },
      {
        id: 4,
        unitNo: 'A 101',
        type: 'C',
        payor: 'Juan Dela Cruz',
        soa: '1002401',
        month: 'Jan',
        or: '0110',
        orDate: '01/21/2021',
        amount: '231,010,000.00',
        action: 'View',
      },
      {
        id: 5,
        unitNo: 'A 101',
        type: 'C',
        payor: 'Juan Dela Cruz',
        soa: '1002401',
        month: 'Jan',
        or: '0110',
        orDate: '01/21/2021',
        amount: '231,010,000.00',
        action: 'View',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        unitNo: data.unitNo,
        type: data.type,
        payor: data.payor,
        soa: data.soa,
        month: data.month,
        or: data.or,
        orDate: data.orDate,
        amount: data.amount,
        action: actionHandler(data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='collection-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CollectionTable;
