// Packages
import { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main/';
import HouseRulesForm from './HouseRulesForm/HouseRulesForm';
import HouseRulesContent from './HouseRules/HouseRulesContent';

// API

// CSS
import './HouseRules.scss';

// Main Component
const HouseRules = () => {
  // handle refresh loading
  const [refreshList, setRefreshList] = useState(false);
  const handleSelect = (e) => {
    if (e === 'House Rules') {
      setRefreshList(true);
    }
  };
  // To render onscreen
  return (
    <Fragment>
      <Helmet>
        <title>House Rules</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='House Rules Form'
                id='house-rules-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
              >
                <Tab
                  eventKey='House Rules Form'
                  title='House Rules Form'
                >
                  <HouseRulesForm />
                </Tab>
                <Tab
                  eventKey='House Rules'
                  title='House Rules'
                >
                  <HouseRulesContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default HouseRules;
