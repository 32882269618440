// Packages
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// Components
import TabsUI from '../../../components/UI/TabsUI';
import AmenitiesForm from './Form';
import AmenitiesFilter from './Filter';
import AmenitiesTable from './Table';
import AmenitiesPagination from './Pagination';
import userContext from 'components/userContext/userContext';

// API
import {
  getBuildingLists,
  getPaymentInstruction,
  userAccounts,
  submitRequestAmenities,
  getRequestAmenities,
} from 'api/request';

import { getAmenitiesListWithPage, getAmenitiesCalendar } from 'api/Amenities';

const AmenitiesTabs = () => {
  const [amenities, setAmenities] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [paymentInstruction, setPaymentInstruction] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState({});
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedAmenities, setSelectedAmenities] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  // Context Values

  // eslint-disable-next-line no-unused-vars
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;

  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,amenity,requestedBy&unit_id=${userUnit?.id}`;

      getRequestAmenities(params, requestAmenitiesCallback);
    } else {
      setLoading(true);
      const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,amenity`;

      getRequestAmenities(params, requestAmenitiesCallback);
    }
  };

  useEffect(() => {
    if (amenities.length === 0) {
      setLoading(true);
      getAmenitiesListWithPage('?per_page=0', callback);
    }

    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }

    if (paymentInstruction === '') {
      setLoading(true);
      getPaymentInstruction(paymentCallback);
    }
  }, []);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      const params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedAmenities) {
      getAmenitiesCalendar(selectedAmenities, calenderCallback);
    }
  }, [selectedAmenities]);

  useEffect(() => {
    if (Object.keys(submit).length) {
      setMsg({ message: '', status: '' });
      submitRequestAmenities(submit, submitCallback);
    }
  }, [submit]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,amenity,requestedBy&unit_id=${userUnit?.id}`;

        getRequestAmenities(params, requestAmenitiesCallback);
      } else {
        const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,amenity`;

        getRequestAmenities(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,amenity,requestedBy&unit_id=${userUnit?.id}&filter[search]=${search}`;

      getRequestAmenities(params, requestAmenitiesCallback);
    } else {
      const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,amenity&filter[search]=${search}`;

      getRequestAmenities(params, requestAmenitiesCallback);
    }
  }, [search]);

  useEffect(() => {
    // console.log('CALENDAR', calendar);
  }, [calendar]);

  const selectPage = (page) => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?building_id=${userUnit?.buildingId}&include=user,building,unit,user,amenity&unit_id=${userUnit?.id}${page}`;

      getRequestAmenities(params, requestAmenitiesCallback);
    } else {
      const params = `?building_id=${selectedBuilding}&include=user,building,unit,user,amenity${page}`;

      getRequestAmenities(params, requestAmenitiesCallback);
    }
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);

        setSelectedBuilding(data?.data[0]?.id);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setAmenities(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const paymentCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setPaymentInstruction(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        refreshTable();
        setMsg({ message: data.message, status: 'success' });
      } else {
        console.log(data.message);
        setMsg({
          message:
            data?.message || 'Something went wrong please refresh the browser.',
          status: 'failed',
        });
      }
    }
  };

  const calenderCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setCalendar(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='amenitiesForm'
        className='mb-3'
      >
        <Tab
          eventKey='amenitiesForm'
          title='Amenities Form'
        >
          {loading && <p>Loading...</p>}
          {!loading && (
            <AmenitiesForm
              setSelectedUnit={setSelectedUnit}
              setSelectedAmenities={setSelectedAmenities}
              amenities={amenities}
              buildings={buildings}
              paymentInstruction={paymentInstruction}
              names={names}
              setNames={setNames}
              setSubmit={setSubmit}
              msg={msg}
              user={user}
              userUnit={userUnit}
              calendar={calendar}
            />
          )}
        </Tab>
        <Tab
          eventKey='amenitiesBookingList'
          title='Amenities Booking List'
        >
          <AmenitiesFilter
            setSearch={setSearch}
            buildings={buildings}
            user={user}
            userUnit={userUnit}
            setSelectedBuilding={setSelectedBuilding}
            selectedBuilding={selectedBuilding}
          />
          <AmenitiesTable
            tableData={tableData}
            user={user}
            refreshTable={refreshTable}
          />
          <AmenitiesPagination
            links={links}
            meta={meta}
            selectPage={selectPage}
          />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default AmenitiesTabs;
