// packages
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

// CSS
import './GovernventRequirementsForm.scss';

// Components

// API

// CSS

// Main Component
const GovernventRequirementsForm = () => {
  // States
  const [requirementForm, setRequirementForm] = useState([
    { id: 1, document: '', entity: '', periodcovered: '', renewaldate: '' },
  ]);

  // This is fire on every first render
  useEffect(() => {
    if (requirementForm) {
      console.log('Rendering...');
    }
  }, []);

  // This will fire on every requirementForm value change
  useEffect(() => {
    console.log(requirementForm);
  }, [requirementForm]);

  // To Change Value on any Textbox
  const changeValue = (e, requirements, type) => {
    const newValue = requirementForm.map((form) => {
      if (requirements.id === form.id) {
        if (type === 'document') {
          return { ...requirements, document: e };
        }

        if (type === 'entity') {
          return { ...requirements, entity: e };
        }

        if (type === 'periodcovered') {
          return { ...requirements, periodcovered: e };
        }

        if (type === 'renewaldate') {
          return { ...requirements, renewaldate: e };
        }
      }

      return form;
    });

    setRequirementForm(newValue);
  };

  // To Add Building
  const addRequirement = () => {
    const newID = requirementForm.length + 1;

    const newRequirement = {
      id: newID,
      document: '',
      entity: '',
      periodcovered: '',
      renewaldate: '',
    };

    setRequirementForm((prevState) => {
      return [...prevState, newRequirement];
    });
  };

  // To render on screen
  return (
    <Card className='admin-form requirement-form shadow-sm'>
      <Form>
        {requirementForm &&
          requirementForm.length > 0 &&
          requirementForm.map((requirement) => {
            return (
              <Row key={requirement.id}>
                <Col className='item-column'>
                  <Form.Group
                    className='mb-3 item-no'
                    controlId={requirement.id}
                    key={requirement.id}
                  >
                    <Form.Label>Item No.</Form.Label>

                    <Form.Control
                      aria-label='Item No.'
                      type='text'
                      value={requirement.id}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={requirement.id}
                    key={requirement.id}
                  >
                    <Form.Label>Name of Document</Form.Label>

                    <Form.Control
                      aria-label='Name of Document'
                      placeholder='Name of Document Here'
                      type='text'
                      value={requirement.document}
                      onChange={(e) =>
                        changeValue(e.target.value, requirement, 'document')
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={requirement.id}
                    key={requirement.id}
                  >
                    <Form.Label>Entity</Form.Label>

                    <Form.Control
                      aria-label='Entity'
                      placeholder='Enter Entity Here'
                      type='text'
                      value={requirement.entity}
                      onChange={(e) =>
                        changeValue(e.target.value, requirement, 'entity')
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={requirement.id}
                    key={requirement.id}
                  >
                    <Form.Label>Period Covered</Form.Label>

                    <Form.Control
                      aria-label='Period Covered'
                      type='date'
                      value={requirement.periodcovered}
                      onChange={(e) =>
                        changeValue(
                          e.target.value,
                          requirement,
                          'periodcovered',
                        )
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className='mb-3'
                    controlId={requirement.id}
                    key={requirement.id}
                  >
                    <Form.Label>Renewal Date</Form.Label>

                    <Form.Control
                      aria-label='Renewal Date'
                      type='date'
                      value={requirement.renewaldate}
                      onChange={(e) =>
                        changeValue(e.target.value, requirement, 'renewaldate')
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            );
          })}

        <Form.Group
          className='buttons add mt-5'
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            className='submit'
            variant='primary'
            onClick={addRequirement}
          >
            Add
          </Button>
        </Form.Group>

        <Form.Group className='buttons pt-5'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            className='cancel'
            variant='default'
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default GovernventRequirementsForm;
