// import core & vendor packages below
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';

// import assets below
import ChartPie from '../../SVG/ChartPie';
import ClipboardList from '../../SVG/ClipboardList';
import Wrench from '../../SVG/Wrench';

// main component
const Finance = (props) => {
  const { activeKey, pathname, bodOnly, adminOnly } = props;

  return (
    <Fragment>
      <Nav.Item>
        <span>Engineering</span>
      </Nav.Item>
      {adminOnly && (
        <Fragment>
          <Nav.Item
            className={pathname === '/engineering/engineering-cms' && 'active'}
          >
            <div className='svg__wrapper'>{ChartPie()}</div>
            <Link
              className='nav-link'
              to='/engineering/engineering-cms'
            >
              Engineering CMS
            </Link>
          </Nav.Item>
          <Nav.Item
            className={
              pathname === '/engineering/equipment-inventory' && 'active'
            }
          >
            <div className='svg__wrapper'>{ClipboardList()}</div>
            <Link
              className='nav-link'
              to='/engineering/equipment-inventory'
            >
              Equipment Inventory
            </Link>
          </Nav.Item>
        </Fragment>
      )}
      {bodOnly && (
        <Nav.Item
          className={pathname === '/engineering/equipment-status' && 'active'}
        >
          <div className='svg__wrapper'>{ClipboardList()}</div>
          <Link
            className='nav-link'
            to='/engineering/equipment-status'
          >
            Equipment Status
          </Link>
        </Nav.Item>
      )}
      {adminOnly && (
        <Accordion defaultActiveKey={activeKey}>
          <Accordion.Item eventKey='maintenance-planner'>
            <Accordion.Header>
              <div className='svg__wrapper'>{ClipboardList()}</div>
              <Nav.Link
                className='nav-link'
                href='#'
              >
                Maintenance Planner
              </Nav.Link>
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Item
                className={
                  pathname === '/engineering/maintenance-planner/schedule' &&
                  'active'
                }
              >
                <div className='svg__wrapper'>{Wrench()}</div>
                <Link
                  className='nav-link'
                  to='/engineering/maintenance-planner/schedule?activeKey=maintenance-planner'
                >
                  Schedule
                </Link>
              </Nav.Item>
              <Nav.Item
                className={
                  pathname === '/engineering/maintenance-planner/calendar' &&
                  'active'
                }
              >
                <div className='svg__wrapper'>{Wrench()}</div>
                <Link
                  className='nav-link'
                  to='/engineering/maintenance-planner/calendar?activeKey=maintenance-planner'
                >
                  Calendar
                </Link>
              </Nav.Item>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </Fragment>
  );
};

export default Finance;
