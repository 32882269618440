// Packages
import React, { useState } from 'react';

// Components
import TermsAndCondition from './TermsAndCondition';
import DGCondoPrivacyPolicy from './DGCondoPrivacyPolicy';

// API

// CSS
import './style.scss';

const Footer = () => {
  const [openTC, setOpenTC] = useState(false);
  const [openDGCondoPP, setOpenDGCondoPP] = useState(false);

  return (
    <>
      <div className='footer'>
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault();
            setOpenDGCondoPP(true);
          }}
        >
          DGCondo Privacy Policy
        </a>
        <a href='#condocorpprivacypolicy'>CondoCorp Privacy Policy</a>
        <a
          href='#'
          onClick={(e) => {
            e.preventDefault();
            setOpenTC(true);
          }}
        >
          Terms and Conditions
        </a>
      </div>

      <TermsAndCondition
        open={openTC}
        set={setOpenTC}
      />

      <DGCondoPrivacyPolicy
        open={openDGCondoPP}
        set={setOpenDGCondoPP}
      />
    </>
  );
};

export default Footer;
