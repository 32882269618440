/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
// Packages
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './ViolationReportTable.scss';

// Main Component
const ViolationReportTable = ({
  tableData,
  setViolationForm,
  setActiveKey,
  violationFormHandler,
}) => {
  // To return view/delete action
  const renderAction = (reference_id, id, violationcheck) => {
    console.log(violationcheck);
    return (
      <>
        {violationcheck && (
          <Link
            to={`/administration/violations/view-violation-report/${id}`}
            style={{ color: '#00C7FF' }}
          >
            {reference_id}
          </Link>
        )}
        {!violationcheck && (
          <u
            style={{ color: '#00C7FF' }}
            onClick={() => {
              violationFormHandler(id);
            }}
          >
            {reference_id}
          </u>
        )}
      </>
    );
  };

  // To return Offense
  const renderOffense = (number) => {
    switch (number) {
      case 1:
        return <span className='first'>1st</span>;
      case 2:
        return <span className='second'>2nd</span>;
      case 3:
        return <span className='third'>3rd</span>;
      default:
    }
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Violation No.',
        accessor: 'violationno',
      },
      {
        Header: 'Violation',
        accessor: 'violation',
      },
      {
        Header: 'Unit No.',
        accessor: 'unitno',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Date and Time',
        accessor: 'dateandtime',
      },
      {
        Header: 'No. of Offense',
        accessor: 'numoffense',
      },
    ],
    [],
  );
  // Let's Assume this data is from API

  // Data of the table
  const data = React.useMemo(() => {
    return tableData.map((data) => {
      return {
        violationno: renderAction(
          data?.attributes?.reference_id,
          data?.attributes?.id,
          data?.relationships?.violation?.id,
        ),
        violation: data?.relationships.violation?.attributes?.name,
        unitno: data?.relationships?.unit?.attributes?.unit_number,
        name: data?.relationships?.user?.attributes?.name,
        dateandtime: `${data?.attributes?.date} | ${data?.attributes?.time}`,
        numoffense: renderOffense(data?.attributes?.offense_no),
      };
    });
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props
    <table
      {...getTableProps()}
      className='admin-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps()}>
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === 'Status' ? 'status-column' : ''
                        } ${cell.value === 'Resolved' ? 'green' : 'yellow'}`}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default ViolationReportTable;
