/* eslint-disable no-unused-vars */
// Packages
import { Fragment, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import GeneralContractForm from './GeneralContractForm/GeneralContractForm';
import ContractListContent from './ContractList/ContractListContent';

// API

// CSS
import './GeneralContract.scss';

const GeneralContract = () => {
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(null);
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);

  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const handleSelect = (e) => {
    if (e === 'Contract List') {
      setRefreshList(true);
    }

    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('General Contracts Form');
  }, []);
  const refreshTable = () => {
    setLoading(true);
    const params = `/api/v1/announcements?filter[recipient]=all&per_page=5`;
    // getAnouncementList(params, requestAmenitiesCallback);
  };
  useEffect(() => {
    setLoading(true);
    refreshTable();
  }, []);

  useEffect(() => {
    if (submit) {
      setMsg({ message: '', status: '' });
      // submitAnnouncementForm(submit, submitCallback);
    }
  }, [submit]);
  useEffect(() => {
    const identifier = setTimeout(() => {
      const params = `/api/v1/announcements?filter[search]=${search}&&filter[recipient]=${filter} &per_page=5`;
      // getAnouncementList(params, requestAmenitiesCallback);
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [search]);
  useEffect(() => {
    const params = `/api/v1/announcements?filter[recipient]=${filter}&per_page=5`;

    // getAnouncementList(params, requestAmenitiesCallback);
  }, [filter]);
  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }
      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'error' });
      }
    }
  };
  const selectPage = (page) => {
    const params = `/api/v1/announcements?filter[recipient]=all&per_page=5${page}`;

    // getAnouncementList(params, requestAmenitiesCallback);
  };

  // To render on screen
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Fragment>
      <Helmet>
        <title>Service Provider</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='General Contracts Form'
                id='amenities-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='General Contracts Form'
                  title='General Contracts Form'
                >
                  <GeneralContractForm
                    setSubmit={setSubmit}
                    msg={msg}
                  />
                </Tab>
                <Tab
                  eventKey='Contract List'
                  title='Contract List'
                >
                  <ContractListContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default GeneralContract;
