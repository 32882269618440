// packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS
import './UploadDocumentForm.scss';

// Components

// API

// CSS

// Main Component
const UploadDocumentForm = () => {
  // States
  const [name, setName] = useState('');

  // Show filename after selecting
  const showFileName = (e) => {
    const [file] = e.target.files;
    const { name: fileName } = file;
    setName(fileName);
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col>
            <div className='text-center'>
              <Form.Group
                className='mb-4'
                controlId='formUpload'
              >
                <Form.Label className='mb-4'>Upload Document</Form.Label>

                <div className='upload-cont'>
                  <div className='upload-input'>
                    <label htmlFor='formUpload'>Select file</label>
                    <Form.Control
                      type='file'
                      className='custom-file-upload'
                      aria-label='Click to Select File to Upload'
                      onChange={showFileName}
                      accept='.xlsx, .xls, .csv'
                    ></Form.Control>
                  </div>

                  <span>{name && name}</span>
                </div>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Form.Group
          className='buttons'
          style={{ justifyContent: 'center' }}
        >
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Submit
          </Button>
          <Button
            as={Link}
            to={'/administration/contracts/service-providers'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default UploadDocumentForm;
