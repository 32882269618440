import React from 'react';
import { Row, Form, Button, Dropdown } from 'react-bootstrap';

// scss
import './ScheduleList.scss';
import ScheduleTable from './ScheduleTable';
function ScheduleList() {
  return (
    <div className='ScheduleList'>
      <div className='d-flex justify-content-between'>
        <div className='col-8 mt-1'>
          <Row>
            <div className='col-5'>
              <Form.Group
                controlId='formSearch'
                className='mb-5 formSearch'
              >
                <Form.Control
                  type='text'
                  placeholder='Search'
                  className='search'
                />

                <Button className='searchbtn'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25.362'
                    height='25.584'
                    viewBox='0 0 25.362 25.584'
                  >
                    <path
                      id='search'
                      d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                      transform='translate(-336.364 -87.386)'
                      fill='#c7c7c7'
                    />
                  </svg>
                </Button>
              </Form.Group>
            </div>

            <div className='col'>
              <div className='col-2 filter'>
                <Dropdown>
                  <Dropdown.Toggle>
                    <svg
                      className='mx-2'
                      xmlns='http://www.w3.org/2000/svg'
                      width='29.498'
                      height='25.811'
                      viewBox='0 0 29.498 25.811'
                    >
                      <path
                        id='sliders-h'
                        d='M28.576,52.28H9.218v-.922a.925.925,0,0,0-.922-.922H6.453a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,53.2v1.844a.925.925,0,0,0,.922.922H5.531v.922a.925.925,0,0,0,.922.922H8.3a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V53.2A.925.925,0,0,0,28.576,52.28Zm0-9.218H23.967V42.14a.925.925,0,0,0-.922-.922H21.2a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,43.984v1.844a.925.925,0,0,0,.922.922H20.28v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922h4.609a.925.925,0,0,0,.922-.922V43.984A.925.925,0,0,0,28.576,43.062Zm0-9.218H16.593v-.922A.925.925,0,0,0,15.671,32H13.827a.925.925,0,0,0-.922.922v.922H.922A.925.925,0,0,0,0,34.765v1.844a.925.925,0,0,0,.922.922H12.905v.922a.925.925,0,0,0,.922.922h1.844a.925.925,0,0,0,.922-.922v-.922H28.576a.925.925,0,0,0,.922-.922V34.765A.925.925,0,0,0,28.576,33.844Z'
                        transform='translate(0 -32)'
                        fill='#fff'
                      />
                    </svg>
                    Filter
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href='#/action-1'>Type</Dropdown.Item>
                    <Dropdown.Item
                      disabled
                      href='#/action-2'
                    >
                      Schedule
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled
                      href='#/action-2'
                    >
                      Assigned Service
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Row>
        </div>
        <div className='mt-3'>
          <a className='download'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='31.948'
              height='31.972'
              viewBox='0 0 31.948 31.972'
            >
              <path
                id='icons8_download'
                d='M21.948,5.973a1.775,1.775,0,0,0-1.749,1.8V24.786L17.9,22.491A1.775,1.775,0,1,0,15.394,25l5.325,5.325a1.775,1.775,0,0,0,2.51,0L28.554,25a1.775,1.775,0,1,0-2.51-2.51l-2.295,2.3V7.772a1.775,1.775,0,0,0-1.8-1.8ZM11.325,15.759A5.352,5.352,0,0,0,6,21.083V32.62a5.352,5.352,0,0,0,5.325,5.325h21.3a5.352,5.352,0,0,0,5.325-5.325V21.083a5.352,5.352,0,0,0-5.325-5.325H29.961a1.775,1.775,0,1,0,0,3.55h2.662A1.749,1.749,0,0,1,34.4,21.083V32.62A1.749,1.749,0,0,1,32.623,34.4h-21.3A1.749,1.749,0,0,1,9.55,32.62V21.083a1.749,1.749,0,0,1,1.775-1.775h2.662a1.775,1.775,0,1,0,0-3.55Z'
                transform='translate(-6 -5.972)'
                fill='#fff'
              />
            </svg>
            Download
          </a>
        </div>
      </div>
      <ScheduleTable />
      <nav className='d-flex justify-content-between pagination'>
        <ul className='pagination'>
          <ul className='page-link mx-1'>1</ul>
          <ul className='page-link mx-1'>2</ul>
        </ul>
        <p>Showing 7 out of 50 entries</p>
      </nav>
    </div>
  );
}

export default ScheduleList;
