/* eslint-disable no-unused-vars */
// Packages
import React, { useState } from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useTable, usePagination } from 'react-table';
import { Link } from 'react-router-dom';

// Components

// CSS/SCSS
import './AnnouncementListTable.scss';

// Main Component
const AnnouncementListTable = ({ tableData, deleteSingleList }) => {
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [storeId, setStoreId] = useState();
  const handleClose = () => setConfirmationDelete(false);
  // modal show confirmation delete
  const handleShow = (id) => {
    setStoreId(id);
    return setConfirmationDelete(true);
  };
  const renderAction = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant='success'
          className='action-button'
          id='dropdown-basic'
        >
          <svg
            fill='none'
            fillRule='evenodd'
            stroke='black'
            strokeWidth='0.501'
            strokeLinejoin='bevel'
            strokeMiterlimit='10'
            fontFamily='Times New Roman'
            fontSize='16'
            version='1.1'
            overflow='visible'
            width='170.667pt'
            height='50.597pt'
            viewBox='-328.917 -2421.35 170.667 50.597'
          >
            <defs></defs>
            <g
              id='Page background'
              transform='scale(1 -1)'
            />
            <g
              id='MouseOff'
              transform='scale(1 -1)'
            >
              <g
                id='Group'
                strokeLinejoin='miter'
                stroke='none'
                fill='#000000'
              >
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-303.869 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-242.309 2396.3) rotate(153.947)'
                />
                <ellipse
                  rx='25.044'
                  ry='25.044'
                  transform='translate(-183.298 2395.8) rotate(153.947)'
                />
              </g>
            </g>
            <g
              id='MouseOver'
              transform='scale(1 -1)'
            />
            <g
              id='navigation bar'
              transform='scale(1 -1)'
            />
            <g
              id='Layer_4'
              transform='scale(1 -1)'
            />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item
            as={Link}
            to={`/administration/view-announcements/${id}`}
          >
            View/Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            style={{ color: '#CB1212' }}
            onClick={() => handleShow(id)}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Building',
        accessor: 'building',
      },
      {
        Header: 'Entity',
        accessor: 'entity',
      },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Recipient',
        accessor: 'recipient',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    return tableData.map((data) => {
      return {
        building: data?.attributes?.building,
        entity: data?.attributes?.entity,
        subject: data?.attributes?.subject,
        recipient: data?.attributes?.recipient,
        date: data.attributes?.created.string,
        action: renderAction(data.id),
      };
    });
  }, [tableData]);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /**
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
     * 
     */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    <>
      <Modal
        show={confirmationDelete}
        onHide={handleClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              handleClose();
              deleteSingleList(storeId);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <table
        {...getTableProps()}
        className='admin-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, idx) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      key={idx}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  key={index}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, idx) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.Header === 'Status'
                              ? 'status-column'
                              : ''
                          } ${cell.value === 'Resolved' ? 'green' : 'yellow'}`}
                          key={idx}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </>
  );
};

export default AnnouncementListTable;
