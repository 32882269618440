// import core & vendor packages below
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

// import components below
import FormUI from 'components/UI/FormUI';

// import assets below
import './Form.scss';

// API
import { getWordPermitByReferenceID, denyWorkPermit } from 'api/WorkPermit';

// main component
const DenyForm = () => {
  // Useform
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const [msg, setMsg] = useState({
    message: '',
    status: '',
  });

  const [userData, setUserData] = useState({
    buildingName: '',
    unitNo: '',
    name: '',
    workType: '',
    dateFiled: '',
    periodFromTo: '',
    scopeOfWork: '',
    status: '',
    personnels: [],
  });

  const enlargePhoto = (url) => {
    window.open(url, '_blank');
  };

  const onSubmit = () => {
    setMsg({ message: '', status: '' });

    const params = {
      description: userData.remarks,
    };

    denyWorkPermit(id, params, denyCallback);
  };

  useEffect(() => {
    getWordPermitByReferenceID(id, '', callback);
  }, []);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);

        const items = data.data.relationships.personnel.map((item) => {
          return {
            id: item?.id,
            name: item?.attributes?.name,
            image: item?.attributes?.personnel_id,
          };
        });

        setUserData((prevState) => {
          return {
            ...prevState,
            buildingName:
              data.data?.relationships?.building[0]?.attributes?.name,
            unitNo: data.data?.relationships?.unit[0]?.attributes?.unit_number,
            name: data.data?.relationships?.user[0]?.attributes?.name,
            workType: data.data?.attributes?.type,
            dateFiled: data.data?.attributes?.date_filed,
            periodFromTo: `${data.data?.attributes?.period_covered_from} - ${data.data?.attributes?.period_covered_to}`,
            scopeOfWork: data.data?.attributes?.description,
            status: data?.data?.attributes?.status,
            personnels: items,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const denyCallback = async (res) => {
    const { data, status } = await res;

    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setMsg({ message: 'Denied Successfully', status: 'success' });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'failed' });
      }
    }
  };

  return (
    <div className='gatePass_denyForm'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormUI>
          <h6>SUMMMARY OF REQUEST</h6>
          <Row>
            <Col>
              <div>
                <h6 className='mb-3'>Building Name</h6>
                <span>{userData.buildingName}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className='mb-3'>Unit No.</h6>
                <span>{userData.unitNo}</span>
              </div>
            </Col>
            <Col>
              <div>
                <h6 className='mb-3'>Name</h6>
                <span>{userData.name}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <h6 className='mb-3'>Work Type</h6>
                <span>{userData.workType}</span>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <h6 className='mb-3'>Date Filed</h6>
                <span>{userData.dateFiled}</span>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <h6 className='mb-3'>Period Covered From / To</h6>
                <span>{userData.periodFromTo}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col lg='4'>
              <Form.Group>
                <h6 className='mb-3'>Scope of Work</h6>
                <span>{userData.scopeOfWork}</span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='personnelList'>
                <div className='personnelList__header'>
                  <h6>List of Personnel</h6>
                  <h6>View Worker ID</h6>
                </div>
                <div className='personnelList__body'>
                  {userData.personnels &&
                    userData.personnels.map((list) => {
                      return (
                        <div key={list.id}>
                          <Form.Group>
                            <span>{list.name}</span>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>
                              <span
                                onClick={() => {
                                  enlargePhoto(list.image);
                                }}
                              >
                                View
                              </span>
                              <Form.Control type='file' />
                            </Form.Label>
                            <span>{list.image}</span>
                          </Form.Group>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Remarks{' '}
                  {errors.remarks ? (
                    <span style={{ color: '#ff5c5c' }}>
                      {errors.remarks.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows='10'
                  {...register('remarks', {
                    required: '(Required)',
                    onChange: (event) => {
                      setUserData((prevState) => {
                        return {
                          ...prevState,
                          remarks: event.target.value,
                        };
                      });
                    },
                  })}
                  value={userData.remarks}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='send-button'>
            <Col className='message-text-result'>
              <span
                style={{
                  color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                  display: 'block',
                }}
              >{`${msg.message}`}</span>
            </Col>
            <Col className='send-button-cont'>
              <Button
                variant='submit'
                type='submit'
              >
                Submit
              </Button>
              <Button
                variant='cancel'
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </FormUI>
      </Form>
    </div>
  );
};

export default DenyForm;
