// Packages

// Components
import DashboardCard from './../DashboardCard/DashboardCard';

// API

// CSS
import './DashboardCardList.scss';

const DashboardCardList = () => {
  return (
    <div className='dashboard_card_list'>
      <DashboardCard
        title='Reports'
        tab='true'
        tabSelect='View'
        color='#EAB72B'
        link='/dashboard'
        icon={
          <svg viewBox='0 0 42.161 56.214'>
            <path
              id='file-alt'
              d='M24.594,14.932V0H2.635A2.629,2.629,0,0,0,0,2.635V53.579a2.629,2.629,0,0,0,2.635,2.635H39.526a2.629,2.629,0,0,0,2.635-2.635V17.567H27.229A2.643,2.643,0,0,1,24.594,14.932ZM31.62,40.843A1.321,1.321,0,0,1,30.3,42.161H11.858a1.321,1.321,0,0,1-1.318-1.318v-.878a1.321,1.321,0,0,1,1.318-1.318H30.3a1.321,1.321,0,0,1,1.318,1.318Zm0-7.027A1.321,1.321,0,0,1,30.3,35.134H11.858a1.321,1.321,0,0,1-1.318-1.318v-.878a1.321,1.321,0,0,1,1.318-1.318H30.3a1.321,1.321,0,0,1,1.318,1.318Zm0-7.905v.878A1.321,1.321,0,0,1,30.3,28.107H11.858A1.321,1.321,0,0,1,10.54,26.79v-.878a1.321,1.321,0,0,1,1.318-1.318H30.3A1.321,1.321,0,0,1,31.62,25.911Zm10.54-12.527v.67H28.107V0h.67a2.633,2.633,0,0,1,1.866.769l10.749,10.76A2.627,2.627,0,0,1,42.161,13.384Z'
            />
          </svg>
        }
      />
      <DashboardCard
        title='Requests'
        link='/dashboard/requests'
        icon={
          <svg viewBox='0 0 49.297 56.34'>
            <path
              id='building'
              d='M47.977,52.818h-2.2V2.641A2.641,2.641,0,0,0,43.135,0H6.162A2.641,2.641,0,0,0,3.521,2.641V52.818H1.32A1.321,1.321,0,0,0,0,54.139v2.2H49.3v-2.2A1.321,1.321,0,0,0,47.977,52.818ZM14.085,8.363a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32v4.4a1.321,1.321,0,0,1-1.32,1.32h-4.4a1.321,1.321,0,0,1-1.32-1.32Zm0,10.564a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32v4.4a1.321,1.321,0,0,1-1.32,1.32h-4.4a1.321,1.321,0,0,1-1.32-1.32Zm5.722,16.286h-4.4a1.321,1.321,0,0,1-1.32-1.32v-4.4a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32v4.4A1.321,1.321,0,0,1,19.807,35.212ZM28.17,52.818H21.127V43.575a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32Zm7.042-18.927a1.321,1.321,0,0,1-1.32,1.32h-4.4a1.321,1.321,0,0,1-1.32-1.32v-4.4a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32Zm0-10.564a1.321,1.321,0,0,1-1.32,1.32h-4.4a1.321,1.321,0,0,1-1.32-1.32v-4.4a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32Zm0-10.564a1.321,1.321,0,0,1-1.32,1.32h-4.4a1.321,1.321,0,0,1-1.32-1.32v-4.4a1.321,1.321,0,0,1,1.32-1.32h4.4a1.321,1.321,0,0,1,1.32,1.32Z'
            />
          </svg>
        }
        color='#C33B7A'
      />
      <DashboardCard
        title='Payments Due'
        color='#7F3BC3'
        link='/dashboard/payments-due'
        icon={
          <svg
            x='0px'
            y='0px'
            viewBox='0 0 330 330'
          >
            <path
              id='XMLID_334_'
              d='M287.5,120.01h-21.077c0.705-4.904,1.077-9.914,1.077-15.01c0-5.089-0.371-10.093-1.074-14.99H287.5
	c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15h-30.141C240.48,24.562,204.307,0,162.5,0c-0.208,0-0.416,0.004-0.623,0.013h-89.3
	c-0.026,0-0.051-0.004-0.077-0.004c-8.284,0-15,6.716-15,15v0.004V60.01h-15c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h15v30
	h-15c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h15V195v120c0,8.284,6.716,15,15,15s15-6.716,15-15V210h75
	c41.799,0,77.966-24.553,94.851-59.99H287.5c8.284,0,15-6.716,15-15C302.5,126.726,295.784,120.01,287.5,120.01z M87.5,30.013h75
	c0.19,0,0.38-0.003,0.568-0.011c24.256,0.181,45.803,11.94,59.394,30.008H87.5V30.013z M87.5,90.01h148.494
	c0.986,4.845,1.506,9.858,1.506,14.99c0,5.139-0.521,10.158-1.511,15.01H87.5V90.01z M162.5,180h-75v-29.99h134.951
	C208.754,168.208,186.98,180,162.5,180z'
            />
          </svg>
        }
      />
      <DashboardCard
        title='Announcements'
        color='#0067B3'
        link='/dashboard/announcements'
        icon={
          <svg viewBox='0 0 54.043 54.029'>
            <path
              id='Path_22'
              data-name='Path 22'
              d='M7241.163,19319.676c-.158-.244-1.772-4.393-3.737-9.637l-3.465-9.217-.832-.053c-.939-.061-1.3-.23-1.625-.768-.182-.279-.209-.539-.209-1.479v-1.127h-2.82c-2.639,0-2.848-.012-3.176-.215a1.931,1.931,0,0,1-.55-.551c-.2-.332-.209-.549-.209-5.994s.012-5.656.209-6a1.9,1.9,0,0,1,.55-.543c.328-.205.537-.217,3.176-.217h2.82v-1.137c0-1.279.136-1.648.759-2.027.339-.2.56-.211,8.951-.223,4.727-.01,8.905-.049,9.284-.1s.816-.084.986-.084l.282-.023V19301l-.391-.061c-.221-.035-1.6-.1-3.068-.125l-2.673-.08,2.849,5.7c1.563,3.137,2.927,5.975,3.023,6.313a3.343,3.343,0,0,1-.227,2.373c-.21.477-.674,1-2.689,2.979-2.746,2.7-2.967,2.859-4.235,2.916-.077,0-.152.006-.228.006A3.2,3.2,0,0,1,7241.163,19319.676Zm34.039-6.365a39.259,39.259,0,0,0-6.388-5.857,36.353,36.353,0,0,0-12.664-5.691,9.042,9.042,0,0,1-1.246-.346c-.04-.045-.062-4.908-.052-10.83l.029-10.746.266-.086c.147-.039.815-.2,1.478-.355a35.759,35.759,0,0,0,18.271-11.094,7.175,7.175,0,0,1,1.183-1.143,1.99,1.99,0,0,1,1.772.084c.792.539.73-1.566.73,23.391,0,20.563-.012,22.43-.17,22.766a1.6,1.6,0,0,1-1.512.877C7276.2,19314.279,7275.836,19314.07,7275.2,19313.311Z'
              transform='translate(-7224.54 -19266.994)'
            />
          </svg>
        }
      />
      <DashboardCard
        title='Building Directory'
        tab='true'
        tabTitle='View'
        color='#45C33B'
        link='/dashboard/building-directory'
        icon={
          <svg
            x='0px'
            y='0px'
            viewBox='0 0 512 512'
          >
            <g>
              <g>
                <path d='M409.6,307.2H256c-14.14,0-25.6,11.46-25.6,25.6v25.6c0,14.14,11.46,25.6,25.6,25.6h153.6c14.14,0,25.6-11.46,25.6-25.6 v-25.6C435.2,318.66,423.74,307.2,409.6,307.2z M409.6,358.4H256v-25.6h153.6V358.4z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M409.6,204.8H256c-14.14,0-25.6,11.46-25.6,25.6V256c0,14.14,11.46,25.6,25.6,25.6h153.6c14.14,0,25.6-11.46,25.6-25.6 v-25.6C435.2,216.26,423.74,204.8,409.6,204.8z M409.6,256H256v-25.6h153.6V256z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M140.8,51.2H89.6c-7.074,0-12.8,5.726-12.8,12.8v51.2c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8V64 C153.6,56.926,147.874,51.2,140.8,51.2z M128,102.4h-25.6V76.8H128V102.4z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M243.2,51.2H192c-7.074,0-12.8,5.726-12.8,12.8v51.2c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8V64 C256,56.926,250.274,51.2,243.2,51.2z M230.4,102.4h-25.6V76.8h25.6V102.4z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M140.8,153.6H89.6c-7.074,0-12.8,5.726-12.8,12.8v51.2c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8 v-51.2C153.6,159.326,147.874,153.6,140.8,153.6z M128,204.8h-25.6v-25.6H128V204.8z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M140.8,256H89.6c-7.074,0-12.8,5.726-12.8,12.8V320c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8 v-51.2C153.6,261.726,147.874,256,140.8,256z M128,307.2h-25.6v-25.6H128V307.2z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M140.8,358.4H89.6c-7.074,0-12.8,5.726-12.8,12.8v51.2c0,7.074,5.726,12.8,12.8,12.8h51.2c7.074,0,12.8-5.726,12.8-12.8 v-51.2C153.6,364.126,147.874,358.4,140.8,358.4z M128,409.6h-25.6V384H128V409.6z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M499.2,486.4h-12.8V179.2c0-14.14-11.46-25.6-25.6-25.6h-256c-14.14,0-25.6,11.46-25.6,25.6v307.2h-128V25.6h230.4V128 h25.6V25.6c0-14.14-11.46-25.6-25.6-25.6H51.2C37.06,0,25.6,11.46,25.6,25.6v460.8H12.8c-7.074,0-12.8,5.726-12.8,12.8 c0,7.074,5.726,12.8,12.8,12.8h486.4c7.074,0,12.8-5.726,12.8-12.8C512,492.126,506.274,486.4,499.2,486.4z M307.2,486.4H256 v-51.2h51.2V486.4z M409.6,486.4H384v-51.2h25.6V486.4z M460.8,486.4h-25.6v-51.2c0-14.14-11.46-25.6-25.6-25.6H384 c-14.14,0-25.6,11.46-25.6,25.6v51.2h-25.6v-51.2c0-14.14-11.46-25.6-25.6-25.6H256c-14.14,0-25.6,11.46-25.6,25.6v51.2h-25.6 V179.2h256V486.4z' />
              </g>
            </g>
          </svg>
        }
      />
      <DashboardCard
        title='Supplier&#39;s Directory'
        color='#E57C25'
        link='/dashboard/suppliers-directory'
        icon={
          <svg viewBox='0 0 60 49.092'>
            <path
              id='icons8_inventory_flow'
              d='M9.5,6A2.046,2.046,0,0,0,7.455,8.045V29.251l.035-.035a4.761,4.761,0,0,1,7.665,5.423h7.982a4.771,4.771,0,1,1,0,9.543H15.154a4.743,4.743,0,0,1-7.346,5.681,7.512,7.512,0,0,0,7.146,5.228H49.046a7.51,7.51,0,0,0,7.5-7.5V40.022l-.035.035a4.761,4.761,0,0,1-7.665-5.42H40.864a4.773,4.773,0,0,1,0-9.546h7.982a4.761,4.761,0,0,1,7.665-5.42l.035.035V8.045A2.046,2.046,0,0,0,54.5,6Zm17.728,9.546h9.546a2.045,2.045,0,1,1,0,4.091H27.228a2.045,2.045,0,1,1,0-4.091Zm25.888,5.433a2.046,2.046,0,0,0-1.425,3.513l3.327,3.327H40.864a2.046,2.046,0,1,0,0,4.091H55.017l-3.327,3.327a2.046,2.046,0,1,0,2.892,2.892L61.4,31.31a2.046,2.046,0,0,0,0-2.892L54.583,21.6A2.046,2.046,0,0,0,53.116,20.979ZM10.824,30.527a2.046,2.046,0,0,0-1.406.618L2.6,37.963a2.046,2.046,0,0,0,0,2.892l6.818,6.818a2.045,2.045,0,1,0,2.893-2.893L8.983,41.455H23.137a2.046,2.046,0,1,0,0-4.091H8.983l3.327-3.327a2.046,2.046,0,0,0-1.486-3.51Z'
              transform='translate(-2 -6)'
            />
          </svg>
        }
      />
    </div>
  );
};

export default DashboardCardList;
