// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import View from './View';

// main component
const ViewBookingTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='viewBooking'
        className='mb-3'
      >
        <Tab
          eventKey='viewBooking'
          title='View Booking'
        >
          <View />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default ViewBookingTabs;
