// Packages
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ViewAnnouncementContent from './ViewAnnouncementContent';

// API

// CSS
import './ViewAnnouncement.scss';

const ViewAnnouncement = () => {
  return (
    <Fragment>
      <Helmet>
        <title>View Announcement</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Announcement'
                id='house-rules-form'
                className='mb-3 house-rules'
              >
                <Tab
                  eventKey='View Announcement'
                  title='View/Edit Announcement'
                >
                  <ViewAnnouncementContent />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ViewAnnouncement;
