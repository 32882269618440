/* eslint-disable no-unused-vars */
// Packages
import React from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// Components
import AnnouncementListTable from './AnnouncementListTable';

// CSS/SCSS
import './AnnouncementList.scss';
import TablePagination from 'components/Pagination';

// Main Component
const AnnouncementList = ({
  tableData,
  links,
  meta,
  selectPage,
  deleteSingleList,
  msg,
  setSearch,
  setFilter,
}) => {
  // To render on screen
  return (
    <>
      <Form className='formBuiling'>
        <Row>
          <Col md={2}>
            <Form.Group
              controlId='formSearch'
              className='mb-5 formSearch'
            >
              <Form.Control
                type='text'
                placeholder='Search'
                className='search'
                onChange={(e) => setSearch(e.target.value)}
              />

              <Button className='searchbtn'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.362'
                  height='25.584'
                  viewBox='0 0 25.362 25.584'
                >
                  <path
                    id='search'
                    d='M336.364,98.038a10.652,10.652,0,1,1,21.3,0,10.515,10.515,0,0,1-1.56,5.515l4.865,4.864a2.687,2.687,0,0,1-1.9,4.553,2.692,2.692,0,0,1-1.912-.755l-4.9-4.916a10.581,10.581,0,0,1-5.241,1.391A10.689,10.689,0,0,1,336.364,98.038Zm17.559,0a6.907,6.907,0,1,0-6.907,6.907A6.92,6.92,0,0,0,353.923,98.038Z'
                    transform='translate(-336.364 -87.386)'
                    fill='#c7c7c7'
                  />
                </svg>
              </Button>
            </Form.Group>
          </Col>
          <Col md={2}>
            <div
              className='edit-container'
              style={{ textAlign: 'left' }}
            >
              <DropdownButton
                id='filter'
                title='Filter'
                onSelect={(e) => setFilter(e)}
              >
                <Dropdown.Item eventKey='all'>All</Dropdown.Item>
                <Dropdown.Item eventKey='owner'>Owner</Dropdown.Item>
                <Dropdown.Item eventKey='tenant'>Tenant</Dropdown.Item>
              </DropdownButton>
            </div>
          </Col>
        </Row>
      </Form>

      <Card className='card admin-form shadow-sm'>
        <AnnouncementListTable
          tableData={tableData}
          links={links}
          meta={meta}
          selectPage={selectPage}
          deleteSingleList={deleteSingleList}
        />
        <TablePagination
          links={links}
          meta={meta}
          selectPage={selectPage}
        />
        <span
          style={{
            color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
            display: 'block',
          }}
        >{`${msg.message}`}</span>
      </Card>
    </>
  );
};

export default AnnouncementList;
