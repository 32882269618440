/* eslint-disable react/jsx-no-comment-textnodes */
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Tabs, Col, Tab, Button } from 'react-bootstrap';

// component
import Main from '../../../components/Main';
import PreviousChargeTable from './PreviousChargeTable';
import CurrentChargeTable from './CurrentChargeTable';

// css
import './DownloadSOA.scss';
import OtherChargesTable from './OtherChargesTable';
import AdvanceorOverpaymentTable from './AdvanceorOverpaymentTable';

// main render
function DownloadSOA() {
  return (
    <Fragment>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Statement of Account'
                id='View Statement of Account'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View Statement of Account'
                  title='View Statement of Account  '
                >
                  <div className='soa'>
                    <div className='container-table'>
                      <Row>
                        <div className='col'>
                          <h5 className='font-weight-bold'>Name</h5>
                          <p>Juan Dela Cruz</p>
                          <h5 className='font-weight-bold'>Billing Date</h5>
                          <p>01/05/2022</p>
                        </div>
                        <div className='col'>
                          <h5 className='font-weight-bold'>Unit No.</h5>
                          <p>A 101</p>
                          <h5 className='font-weight-bold'>Due Date</h5>
                          <p>01/31/2022</p>
                        </div>
                        <div className='col'>
                          <h5>Payer Type</h5>
                          <p>AD</p>
                        </div>
                      </Row>
                      <h3 className='blueHeader'>Previous Charge</h3>
                      <div className=' shadow-lg payment-table'>
                        <PreviousChargeTable />
                      </div>
                      <Row>
                        <div className='col-6'>
                          <Row className='mt-5'>
                            <div className='col'>
                              <h5>Penalty</h5>
                              <p>0.00</p>
                            </div>
                            <div className='col'>
                              <h5>Total Amount</h5>
                              <p>10,000,000.00</p>
                            </div>
                          </Row>
                        </div>
                      </Row>

                      <h3 className='blueHeader'>Current Charges</h3>
                      <p className='fw-bold'>
                        Regular Assessment of Operating Expenses:
                      </p>
                      <div className='payment-table'>
                        <CurrentChargeTable />
                      </div>
                      <h3 className='blueHeader py-5'>Other Charges</h3>
                      <div className='payment-table'>
                        <OtherChargesTable />
                      </div>
                      <Row className='mt-5'>
                        <div className='col-6'>
                          <Row>
                            <div className='col'>
                              <h5>Penalty</h5>
                              <p>2%</p>
                            </div>
                            <div className='col'>
                              <h5>Total Current Charges</h5>
                              <p>10,000.00</p>
                            </div>
                          </Row>
                        </div>
                      </Row>
                      <h3 className='blueHeader py-5'>Advance/Overpayment</h3>
                      <div className='payment-table'>
                        <AdvanceorOverpaymentTable />
                      </div>
                      <Row className='mt-5'>
                        <div className='col'>
                          <h5>Total Advance</h5>
                          <br />
                          <h5>0.00</h5>
                          <br />
                          <h5>Notes</h5>
                          <br />
                          <h5>Lorem ipsum dolor</h5>
                        </div>
                        <div className='col text-end px-5'>
                          <h3 className='blueHeader'>Total Amount Due</h3>
                          <h3>10,000</h3>
                        </div>
                      </Row>
                      <Button className='btn mt-5'>Download</Button>
                    </div>
                  </div>
                </Tab>

                <nav className='d-flex justify-content-between pagination'>
                  <ul className='pagination'>
                    <ul className='page-link mx-1'>1</ul>
                  </ul>
                  <p>Showing 4 out of 4 entries</p>
                </nav>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default DownloadSOA;
