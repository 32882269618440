// Packages
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main/';
import AmenitiesForm from './AmenitiesForm/AmenitiesForm';
import AmenitiesContent from './AmenitiesList/AmenitiesContent';

// API

// CSS
import './Amenities.scss';

// Main Component
const Amenities = () => {
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const handleSelect = (e) => {
    if (e === 'Amenities List') {
      console.log(e);
      setRefreshList(true);
    }

    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('Amenities Form');
  }, []);

  // To render on screen
  return (
    <Fragment>
      <Helmet>
        <title>Amenities</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Amenities Form'
                id='amenities-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='Amenities Form'
                  title='Amenities Form'
                >
                  <AmenitiesForm />
                </Tab>
                <Tab
                  eventKey='Amenities List'
                  title='Amenities List'
                >
                  <AmenitiesContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default Amenities;
