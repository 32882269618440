// Packages
import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// CSS
import './EditDetailsForm.scss';

// Main Component
const EditDetailsForm = () => {
  const [isChanged, setIsChanged] = useState(false);

  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formEquipment'
            >
              <Form.Label>Equipment</Form.Label>
              <Form.Control
                aria-label='Equipment'
                type='text'
                className='border-disabled'
                placeholder='Equipment Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBrand'
            >
              <Form.Label>Brand</Form.Label>
              <Form.Control
                aria-label='Brand'
                type='text'
                className='border-disabled'
                placeholder='Brand Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formModelNo'
            >
              <Form.Label>Model No.</Form.Label>
              <Form.Control
                aria-label='Model No.'
                type='text'
                className='border-disabled'
                placeholder='Model No. Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formSerialNo'
            >
              <Form.Label>Serial No.</Form.Label>
              <Form.Control
                aria-label='Serial No.'
                type='text'
                className='border-disabled'
                placeholder='Serial No. Here'
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formLocation'
            >
              <Form.Label>Location</Form.Label>
              <Form.Control
                aria-label='Location'
                type='text'
                className='border-disabled'
                placeholder='Location Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formDateInstalled'
            >
              <Form.Label>Date Installed</Form.Label>
              <Form.Control
                aria-label='Date Installed'
                type='text'
                className='border-disabled'
                placeholder='Date Installed Here'
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formStatus'
            >
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label='Default select example'
                defaultValue='In Good Condition'
                onChange={() => {
                  setIsChanged(true);
                }}
              >
                <option value='In Good Condition'>In Good Condition</option>
                <option value='For Replacement'>For Replacement</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {isChanged && (
          <Row>
            <Col>
              <Form.Group
                className='mb-5'
                controlId='formStatus'
              >
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  aria-label='Add Remarks here'
                  as='textarea'
                  rows={5}
                  className='mb-2 remarks'
                  placeholder='Add Remarks Here'
                />

                <Button
                  className='add-remarks'
                  variant='primary'
                  type='submit'
                >
                  Add Remarks
                </Button>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Form.Group className='buttons'>
          <Button
            className='submit'
            variant='primary'
            type='submit'
          >
            Save
          </Button>
          <Button
            as={Link}
            to={'/engineering/equipment-inventory'}
            className='cancel'
            variant='default'
            style={{ padding: '0.8em 2em' }}
          >
            Cancel
          </Button>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default EditDetailsForm;
