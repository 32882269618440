// Packages
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from 'components/Main';
import ServiceProviderForm from './ServiceProviderForm/ServiceProviderForm';
import ContractListContent from './ContractList/ContractListContent';

// API

// CSS
import './ServiceProvider.scss';

const ServiceProvider = () => {
  const [refreshList, setRefreshList] = useState(false);
  const [activeKey, setActiveKey] = useState();

  const handleSelect = (e) => {
    if (e === 'Contract List') {
      setRefreshList(true);
    }

    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('Service Provider Form');
  }, []);

  console.log(refreshList);

  // To render on screen
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Fragment>
      <Helmet>
        <title>Service Provider</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Service Provider Form'
                id='amenities-form'
                className='mb-3 house-rules'
                onSelect={(e) => handleSelect(e)}
                activeKey={activeKey}
              >
                <Tab
                  eventKey='Service Provider Form'
                  title='Service Provider Form'
                >
                  <ServiceProviderForm />
                </Tab>
                <Tab
                  eventKey='Contract List'
                  title='Contract List'
                >
                  <ContractListContent
                    refreshList={refreshList}
                    setRefreshList={setRefreshList}
                    setActiveKey={setActiveKey}
                  />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
};

export default ServiceProvider;
