/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Packages
import { getBuildingListData } from 'api/request';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
// CSS
import './AnnouncementForm.scss';

// Main Component
const AnnouncementForm = ({
  buildings,
  setSelectedBuilding,
  selectedBuilding,
  setSubmit,
  msg,
}) => {
  // Useform
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const [userData, setUserData] = useState({
    building: 'all',
    recipient: '',
    entity: '',
    subject: '',
    content: '',
    image: '',
  });

  const recipientList = [
    {
      id: 1,
      value: 'All',
    },
    {
      id: 2,
      value: 'Owner',
    },
    {
      id: 3,
      value: 'Tenant',
    },
  ];

  const onSubmit = () => {
    const newFormData = new FormData();
    if (!userData.image) {
      setError('image', {
        type: 'custom',
        message: 'Please Select Image',
      });

      return;
    }
    newFormData.append('building', userData.building);
    newFormData.append('recipient', userData.recipient.toLowerCase());
    newFormData.append('entity', userData.entity);
    newFormData.append('subject', userData.subject);
    newFormData.append('content', userData.content);
    newFormData.append('image', userData.image);
    setSubmit(newFormData);
  };
  useEffect(() => {
    setSubmit(null);
    setSelectedBuilding(null);
  }, []);
  useEffect(() => {
    if (msg.message === 'Announcement created successfully.') {
      setValue('building', 'all');
      setValue('recipient', '');
      setValue('entity', '');
      setValue('subject', '');
      setValue('content', '');
      setValue('image', '');

      setUserData({
        building: 'all',
        recipient: '',
        entity: '',
        subject: '',
        content: '',
        images: '',
      });
    }
  }, [msg]);
  // To render on screen
  return (
    <Card className='admin-form announcement shadow-sm'>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        encType='multipart/form-data'
      >
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formBuilding'
            >
              <Form.Label>Building</Form.Label>
              <Form.Select
                {...register('building', {
                  required: '(Required)',
                  value: userData.building,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        building: event.target.value,
                      };
                    });
                  },
                })}
              >
                <option value='all'>All</option>
                {buildings &&
                  buildings.length > 0 &&
                  buildings.map((list, idx) => {
                    if (selectedBuilding === '' && idx === 0) {
                      setSelectedBuilding(list.id);
                    }
                    return (
                      <option
                        key={list.id}
                        value={list.id}
                      >
                        {list?.attributes?.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-5'
              controlId='formRecipient'
            >
              <Form.Label>Recipient</Form.Label>
              <Form.Select
                aria-label='Select Title'
                {...register('recipient', {
                  required: '(Required)',
                  value: userData.recipient,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        recipient: event.target.value,
                      };
                    });
                  },
                })}
              >
                <option
                  value=''
                  hidden
                >
                  Select Recipient Here
                </option>
                {recipientList.map((val) => {
                  return (
                    <option
                      key={val.id}
                      value={val.value}
                    >
                      {val.value}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formEntity'
            >
              <Form.Label>Entity</Form.Label>
              <Form.Control
                aria-label='Enter Entity Here'
                placeholder='Enter Entity Here'
                {...register('entity', {
                  required: '(Required)',
                  value: userData.entity,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        entity: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formSubject'
            >
              <Form.Label>Subject</Form.Label>
              <Form.Control
                aria-label='Enter Subject Here'
                placeholder='Enter Subject Here'
                {...register('subject', {
                  required: '(Required)',
                  value: userData.subject,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        subject: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formUpload'
            >
              <Form.Label>
                Upload Image{' '}
                {errors.image ? (
                  <span style={{ color: '#ff5c5c' }}>
                    {errors.image.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Label>

              <div className='upload-cont image-upload'>
                <div className='upload-input'>
                  <label htmlFor='formUpload'>Select file</label>

                  <Form.Control
                    type='file'
                    className='custom-file-upload'
                    aria-label='Click to Select File to Upload'
                    {...register('image', {
                      value: userData.image,
                      onChange: (e) => {
                        // eslint-disable-next-line prefer-const
                        let docuArr = [];

                        Array.from(e.target.files).forEach((file) => {
                          docuArr.push(file);
                        });

                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            image: e.target.files[0],
                          };
                        });
                      },
                    })}
                    accept='image/*'
                  ></Form.Control>
                </div>
                <span>
                  {userData.image && (
                    <>
                      {userData.image && <>1 File Selected</>}
                      {!userData.image && <>No Images Uploaded</>}
                    </>
                  )}
                </span>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group
              className='mb-5'
              controlId='formContent'
            >
              <Form.Label>Content</Form.Label>
              <Form.Control
                as='textarea'
                rows={10}
                placeholder='Enter Content Here'
                {...register('content', {
                  required: '(Required)',
                  value: userData.content,
                  onChange: (event) => {
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        content: event.target.value,
                      };
                    });
                  },
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <span
              style={{
                color: msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                display: 'block',
              }}
            >{`${msg.message}`}</span>
          </Col>
          <Col>
            <Form.Group className='buttons'>
              <Button
                className='submit'
                variant='primary'
                type='submit'
              >
                Submit
              </Button>
              <Button
                className='cancel'
                variant='default'
              >
                Cancel
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AnnouncementForm;
