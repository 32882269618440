import { Modal, Button } from 'react-bootstrap';

import React from 'react';

const DeleteModal = ({ confirmDelete, setDeleteModal, deleteModal }) => {
  return (
    <Modal
      show={deleteModal}
      onHide={() => {
        setDeleteModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Confirm delete?</Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            setDeleteModal(false);
          }}
        >
          Close
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            setDeleteModal(false);
            confirmDelete();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
