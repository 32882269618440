// Packages
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

// CSS
import './ViolationsForm.scss';

// Components

// API
import { submitViolationClassification } from 'api/request';

// CSS

// Main Component
const ViolationsForm = () => {
  // States
  const [msg, setMsg] = useState([]);
  const [violations, setViolations] = useState([
    { id: 1, value: '' },
    { id: 2, value: '' },
    { id: 3, value: '' },
    { id: 4, value: '' },
    { id: 5, value: '' },
  ]);

  // Will render one time
  useEffect(() => {
    if (violations) {
      console.log('Rendering...');
    }
  }, []);

  // To change value on any textbox
  const changeViolationValue = (e, id) => {
    const newValue = violations.map((violation) => {
      if (id === violation.id) {
        return { id: violation.id, value: e };
      }

      return { id: violation.id, value: violation.value };
    });

    setViolations(newValue);
  };

  // To add Violation textbox
  const addViolation = () => {
    const newID = violations.length + 1;

    const newViolation = { id: newID, value: '' };

    setViolations((prevState) => {
      return [...prevState, newViolation];
    });
  };

  // Submit Violation/s
  const submitViolations = () => {
    setMsg([]);

    violations.forEach((violation) => {
      if (violation.value !== '') {
        const params = {
          name: violation.value,
        };

        submitViolationClassification(params, callback);
      }
    });
  };

  const callback = async (response, params) => {
    const { status, data } = await response;

    if (status === 201 || status === 200) {
      setMsg((prevState) => {
        return [...prevState, { message: `${data.message} (${data.data.attributes.name})`, status: 'success' }];
      });
    } else if (status === 400 || status === 422) {
      if (data.errors) {
        const { errors } = data;
        if (errors['name']) {
            setMsg((prevState) => {
              return [...prevState, { message: `${errors['name']} (${params.name})`, status: 'failed' }];
            });
        }
    }
    } else {
      setMsg([{ message: 'Something went wrong', status: 'failed' }]);
      console.log(data);
    }
};

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Form.Group
          className='mb-3'
          controlId='formViolations'
        >
          <Form.Label>Violation Classification</Form.Label>
        </Form.Group>

        {violations &&
          violations.length > 0 &&
          violations.map((violation) => {
            return (
              <Form.Group
                className='mb-3'
                controlId={violation.id}
                key={violation.id}
              >
                <Form.Control
                  aria-label='Violation'
                  placeholder='Enter Violation Here'
                  type='text'
                  value={violation.value}
                  onChange={(e) =>
                    changeViolationValue(e.target.value, violation.id)
                  }
                />
              </Form.Group>
            );
          })}

        <Form.Group
          className='buttons add mt-5'
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            className='submit'
            variant='primary'
            onClick={addViolation}
          >
            Add
          </Button>
        </Form.Group>

        <Form.Group className='buttons pt-5'>
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col className='message-text'>
                {/* {msg && msg.length > 0 && msg.map((data) => {
                  <span style={{ color: hasErrors ? '#ff5c5c' : '#3bd14d' }}>{data}</span>;
                  })
                } */}
                {msg.map((data, idx) => {
                  return <span key={idx} style={{ color: data.status === 'success' ? '#3bd14d' : '#ff5c5c', display: 'block' }}>{`${data.message}`}</span>;
                })}
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  onClick={submitViolations}
                >
                  Submit
                </Button>
                <Button
                  className='cancel'
                  variant='default'
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default ViolationsForm;
