// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { Dropdown } from 'react-bootstrap';
// import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
// Components

// CSS/SCSS
import './Table.scss';

function Table() {
  // Use Navigate from router dom
  const navigate = useNavigate();
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Tenant Name',
        accessor: 'tenantName',
      },
      {
        Header: 'Month',
        accessor: 'month',
      },
      {
        Header: 'Billing',
        accessor: 'billing',
      },
      {
        Header: 'Payment Due',
        accessor: 'paymentDue',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // dropdown on table action
    const renderAction = (id) => {
      return (
        <Dropdown>
          <Dropdown.Toggle>
            <svg
              fill='none'
              fillRule='evenodd'
              stroke='black'
              strokeWidth='0.501'
              strokeLinejoin='bevel'
              strokeMiterlimit='10'
              fontFamily='Times New Roman'
              fontSize='16'
              version='1.1'
              overflow='visible'
              width='170.667pt'
              height='50.597pt'
              viewBox='-328.917 -2421.35 170.667 50.597'
            >
              <defs></defs>
              <g
                id='Page background'
                transform='scale(1 -1)'
              ></g>
              <g
                id='MouseOff'
                transform='scale(1 -1)'
              >
                <g
                  id='Group'
                  strokelinejoin='miter'
                  stroke='none'
                  fill='#000000'
                >
                  <ellipse
                    rx='25.044'
                    ry='25.044'
                    transform='translate(-303.869 2396.3) rotate(153.947)'
                  ></ellipse>
                  <ellipse
                    rx='25.044'
                    ry='25.044'
                    transform='translate(-242.309 2396.3) rotate(153.947)'
                  ></ellipse>
                  <ellipse
                    rx='25.044'
                    ry='25.044'
                    transform='translate(-183.298 2395.8) rotate(153.947)'
                  ></ellipse>
                </g>
              </g>
              <g
                id='MouseOver'
                transform='scale(1 -1)'
              ></g>
              <g
                id='navigation bar'
                transform='scale(1 -1)'
              ></g>
              <g
                id='Layer_4'
                transform='scale(1 -1)'
              ></g>
            </svg>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                navigate(`/billing/payment-history/${id}`);
              }}
            >
              View Payment History
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    };

    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        tenantName: 'Juan Dela Cruz',
        month: 'Jan21-00001',
        billing: '10,000.00',
        paymentDue: '10,000.00',
      },
      {
        id: 2,
        tenantName: 'Juan Dela Cruz',
        month: 'Jan21-00001',
        billing: '10,000.00',
        paymentDue: '10,000.00',
      },
      {
        id: 3,
        tenantName: 'Juan Dela Cruz',
        month: 'Jan21-00001',
        billing: '10,000.00',
        paymentDue: '10,000.00',
      },
      {
        id: 4,
        tenantName: 'Juan Dela Cruz',
        month: 'Jan21-00001',
        billing: '10,000.00',
        paymentDue: '10,000.00',
      },
      {
        id: 5,
        tenantName: 'Juan Dela Cruz',
        month: 'Jan21-00001',
        billing: '10,000.00',
        paymentDue: '10,000.00',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        tenantName: data.tenantName,
        month: data.month,
        billing: data.billing,
        paymentDue: data.paymentDue,
        action: renderAction(data.id),
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='my-tenant-billing-container-table'>
      <table
        style={{ width: '100%' }}
        {...getTableProps()}
        className='data-table'
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, index) => (
              // Apply the header row props
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, idx) => (
                    // Apply the header cell props
                    <th
                      key={idx}
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  key={index}
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, idx) => {
                      // Apply the cell props
                      return (
                        <td
                          key={idx}
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default Table;
