// import core & vendor packages below
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import RequestsFilter from '../Filter';
import RequestsTable from '../Table';
import RequestsPagination from '../Pagination';

// main component
const RequestsTabs = () => {
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='dashboardRequests'
        className='mb-3'
      >
        <Tab
          eventKey='dashboardRequests'
          title='Requests'
        >
          <RequestsFilter />
          <RequestsTable />
          <RequestsPagination />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default RequestsTabs;
