// import core & vendor packages below
import { Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../../components/UI/TabsUI';
import Collections from '../SummaryReports/Collections';
import ARAging from '../SummaryReports/ARAging';
import CollectionEfficiency from '../SummaryReports/CollectionEfficiency';
import Expenditures from '../SummaryReports/Expenditures';
import Budget from '../SummaryReports/Budget';
import BudgetVSActual from '../ComparativeReports/BudgetVSActual';
import AssociationDuesVSOPEX from '../ComparativeReports/AssociationDuesVSOPEX';
import CollectionsVSExpenditures from '../ComparativeReports/CollectionsVSExpenditures';

// main component
const FinanceReportsTabs = (props) => {
  const { report } = props;

  return (
    <Fragment>
      {report === 'Summary Reports' && (
        <TabsUI>
          <Tabs
            defaultActiveKey='collections'
            className='mb-3'
          >
            <Tab
              eventKey='collections'
              title='Collections'
            >
              <Collections />
            </Tab>
            <Tab
              eventKey='arAging'
              title='AR Aging'
            >
              <ARAging />
            </Tab>
            <Tab
              eventKey='collectionsEfficiency'
              title='Collections Efficiency'
            >
              <CollectionEfficiency />
            </Tab>
            <Tab
              eventKey='expenditures'
              title='Expenditures'
            >
              <Expenditures />
            </Tab>
            <Tab
              eventKey='budget'
              title='Budget'
            >
              <Budget />
            </Tab>
          </Tabs>
        </TabsUI>
      )}
      {report === 'Comparative Reports' && (
        <TabsUI>
          <Tabs
            defaultActiveKey='budgetVSActual'
            className='mb-3'
          >
            <Tab
              eventKey='budgetVSActual'
              title='Budget vs Actual'
            >
              <BudgetVSActual />
            </Tab>
            <Tab
              eventKey='associationDuesVSOPEX'
              title='Association Dues vs OPEX'
            >
              <AssociationDuesVSOPEX />
            </Tab>
            <Tab
              eventKey='collectionsVSExpenditures'
              title='Collections vs Expenditures'
            >
              <CollectionsVSExpenditures />
            </Tab>
          </Tabs>
        </TabsUI>
      )}
    </Fragment>
  );
};

export default FinanceReportsTabs;
