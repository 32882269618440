// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';

// Components

function AdvanceorOverpaymentTable() {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Particulars',
        accessor: 'particulars',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Balance',
        accessor: 'balance',
      },
      {
        Header: 'amt. Applied',
        accessor: 'amtApplied',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        particulars: 'Assoc. Dues',
      },
      {
        id: 2,
        particulars: 'Real Estate Tax',
      },
      {
        id: 3,
        particulars: 'Bldg. Insurance',
      },
      {
        id: 4,
        particulars: 'Water Charges',
      },
      {
        id: 5,
        particulars: 'Discount (2%)',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        particulars: data.particulars,
        remarks: data.remarks,
        balance: data.balance,
        amtApplied: data.amtApplied,
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <table
      style={{ width: '100%' }}
      {...getTableProps()}
      className='data-table'
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup, index) => (
            // Apply the header row props
            <tr
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column, idx) => (
                  // Apply the header cell props
                  <th
                    key={idx}
                    {...column.getHeaderProps()}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row, index) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr
                key={index}
                {...row.getRowProps()}
              >
                {
                  // Loop over the rows cells
                  row.cells.map((cell, idx) => {
                    // Apply the cell props
                    return (
                      <td
                        key={idx}
                        {...cell.getCellProps()}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

export default AdvanceorOverpaymentTable;
