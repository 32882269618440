// import core & vendor packages
import { Fragment, useState } from 'react';

// import assets below
import BudgetVSActualFilter from './Filter';
import BudgetVSActualTable from './Table';
import BudgetVSActualGraph from './Graph';

// main component
const BudgetVSActual = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Total');

  const onSelectFilter = (params) => {
    setSelectedFilter(params);
    return params;
  };

  return (
    <Fragment>
      <BudgetVSActualFilter
        toggle={toggle}
        onToggle={setToggle}
        selected={selectedFilter}
        onSelect={onSelectFilter}
      />
      {!toggle && <BudgetVSActualTable />}
      {toggle && <BudgetVSActualGraph selected={selectedFilter} />}
    </Fragment>
  );
};

export default BudgetVSActual;
