/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Button, Form } from 'react-bootstrap';

// Components
import Main from '../../../../components/Main';
import { carStickerApprove, carStickerViewApprove } from 'api/request';

function CarViewRequest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: '',
    unit_number: '',
    date: '---',
    model_year: '',
    car_model: '',
    color: '',
    plate_no: '',
    slot_number: '',
    or_cr: '',
    parking_lease_agreement: '',
  });
  const [triggerSticker, setTriggerSticker] = useState(false);
  const [btnApprove, setBtnApprove] = useState('Approve');
  const [btnDeny, setBtnDeny] = useState('Deny');
  const [msg, setMsg] = useState({ message: '', status: '' });

  const approveHandler = (e) => {
    if (!triggerSticker) {
      setTriggerSticker(true);
    }

    if (triggerSticker) {
      carStickerApprove(id, '', approvecallback);
    }
    if (!triggerSticker) {
      setBtnApprove('Activate Car Sticker');
      setBtnDeny('Cancel');
    }
  };
  const denyHandler = () => {
    if (triggerSticker) {
      navigate(-1);
    }
    if (!triggerSticker) {
      setBtnApprove('Activate Car Sticker');
      setBtnDeny('Cancel');
      navigate(`/administration/requests/car-sticker/car-deny-request/${id}`);
    }
  };

  useEffect(() => {
    console.log(id);
    const params = `/api/v1/request/car-stickers/${id}`;
    carStickerViewApprove(params, callback);
  }, []);
  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data?.data);
        setUserData((prev) => {
          return {
            ...prev,
            name: data?.data?.relationships?.user?.attributes?.name,
            unit_number:
              data?.data?.relationships?.unit?.attributes?.unit_number,
            date: data?.data?.attributes?.date,
            model_year: data?.data?.attributes?.model_year,
            car_model: data?.data?.attributes?.car_model,
            color: data?.data?.attributes?.color,
            plate_no: data?.data?.attributes?.plate_no,
            slot_number:
              data?.data?.relationships?.parking_slot?.attributes?.slot_number,
            or_cr: data?.data?.attributes?.or_cr.name,
            parking_lease_agreement:
              data?.data?.attributes?.parking_lease_agreement.name,
          };
        });
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };
  const approvecallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'success' });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

      if (status === 400 || status === 401 || status === 422) {
        console.log(data.message);
        setMsg({ message: data.message, status: 'error' });
      }
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='View Request'
                id='View Request'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='View Request'
                  title='View Request'
                >
                  <div className='staffUpload shadow-md'>
                    <Row>
                      <div className='col'>
                        <p className='h5 fw-bold'>Name</p>
                        <p>{userData.name}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Unit No.</p>
                        <p>{userData.unit_number}</p>
                      </div>
                      <div className='col'>
                        <p className='h5 fw-bold'>Date</p>
                        <p>{userData.date}</p>
                      </div>
                    </Row>

                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Model Year</p>
                        <p>{userData.model_year}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Car Model</p>
                        <p>{userData.car_model}</p>
                      </div>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Color </p>
                        <p>{userData.color}</p>
                      </div>
                    </Row>
                    <Row>
                      <div className='col-lg-4'>
                        <p className='h5 fw-bold'>Plate No.</p>
                        <p>{userData.plate_no}</p>
                      </div>
                      <div className='col-lg-4 '>
                        <p className='h5 fw-bold'>Parking Slot No.</p>
                        <p>{userData.slot_number}</p>
                      </div>
                    </Row>

                    <p className='headerInformation'>UPLOAD DOCUMENTS</p>

                    <Row>
                      <div className='col-lg-4'>
                        <div className='text-start'>
                          <Form.Group
                            className='mb-4'
                            controlId='formUpload'
                          >
                            <Form.Label className='my-4 fw-bold h5'>
                              OR/CR
                            </Form.Label>

                            <div className='upload-cont d-flex justify-content-start'>
                              <div className='upload-input'>
                                <label htmlFor='formUpload'></label>
                                <Form.Control
                                  type='file'
                                  className='custom-file-upload'
                                  aria-label='Click to Select File to Upload'
                                  accept='.xlsx, .xls, .csv'
                                ></Form.Control>
                              </div>

                              <span style={{ color: 'black' }}>
                                {userData.or_cr && userData.or_cr}
                              </span>
                              <span>{!userData.or_cr && '---'}</span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <Form.Group
                          className='mb-4'
                          controlId='formUpload'
                        >
                          <Form.Label className='my-4 fw-bold h5'>
                            Parking Lease Agreement
                          </Form.Label>

                          <div className='upload-cont d-flex justify-content-start'>
                            <div className='upload-input'>
                              <label htmlFor='formUpload'></label>
                              <Form.Control
                                type='file'
                                className='custom-file-upload'
                                aria-label='Click to Select File to Upload'
                                accept='.xlsx, .xls, .csv'
                              ></Form.Control>
                            </div>

                            <span style={{ color: 'black' }}>
                              {userData.parking_lease_agreement &&
                                userData.parking_lease_agreement}
                            </span>
                            <span>
                              {!userData.parking_lease_agreement && '---'}
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                    </Row>
                    {triggerSticker && (
                      <Row>
                        <div className='col-lg-4'>
                          <div className='text-start'>
                            <Form.Group
                              className='mb-4'
                              controlId='formUpload'
                            >
                              <Form.Label className='my-4 fw-bold h5'>
                                Proof of Payment
                              </Form.Label>

                              <div className='upload-cont d-flex justify-content-start'>
                                <div className='upload-input'>
                                  <label htmlFor='formUpload'></label>
                                  <Form.Control
                                    type='file'
                                    className='custom-file-upload'
                                    aria-label='Click to Select File to Upload'
                                    accept='.xlsx, .xls, .csv'
                                  ></Form.Control>
                                </div>

                                <span style={{ color: 'black' }}>
                                  {userData.or_cr && userData.or_cr}
                                </span>
                                <span>{!userData.or_cr && '---'}</span>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </Row>
                    )}
                    <div className='d-flex justify-content-between'>
                      {' '}
                      <span
                        style={{
                          color:
                            msg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                          display: 'block',
                        }}
                      >
                        {msg.message}
                      </span>
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='btn mx-1'
                          onClick={approveHandler}
                        >
                          {btnApprove}
                        </Button>
                        <Button
                          onClick={denyHandler}
                          className='btn mx-1 shadow-sm'
                          style={{
                            backgroundColor: !triggerSticker
                              ? '#CB1212'
                              : '#FFFFFF',
                            color: !triggerSticker ? '#ffffff' : '#00B6E9',
                          }}
                        >
                          {btnDeny}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default CarViewRequest;
