const PurchaseOrder = () => {
  return (
    <svg viewBox='0 0 21.184 23'>
      <path
        id='icons8_purchase_order'
        d='M9.539,5A2.738,2.738,0,0,0,6.816,7.724V21.947H5.908A.908.908,0,0,0,5,22.855v1.211A3.948,3.948,0,0,0,8.934,28H22.25a3.948,3.948,0,0,0,3.934-3.934V7.724A2.738,2.738,0,0,0,23.461,5Zm2.421,4.842a.908.908,0,1,1-.908.908A.908.908,0,0,1,11.961,9.842Zm3.026,0h6.053a.908.908,0,1,1,0,1.816H14.987a.908.908,0,0,1,0-1.816Zm-3.026,3.632a.908.908,0,1,1-.908.908A.908.908,0,0,1,11.961,13.474Zm3.026,0h6.053a.908.908,0,1,1,0,1.816H14.987a.908.908,0,0,1,0-1.816Zm-3.026,3.632a.908.908,0,1,1-.908.908A.908.908,0,0,1,11.961,17.105Zm3.026,0h6.053a.908.908,0,1,1,0,1.816H14.987a.908.908,0,1,1,0-1.816ZM6.816,23.763h11.5v.3a3.818,3.818,0,0,0,.71,2.118H8.934a2.105,2.105,0,0,1-2.118-2.118Z'
        transform='translate(-5 -5)'
      />
    </svg>
  );
};

export default PurchaseOrder;
