// import core & vendor packages
import { Col } from 'react-bootstrap';

// import components below
import TableLeft from './TableLeft';
import TableRight from './TableRight';

// import assets below
import './style.scss';

// main component
const BudgetVSActualTable = () => {
  return (
    <div className='dashboardBudgetVSActualTable'>
      <div className='dashboardBudgetVSActualTable__content'>
        <Col>
          <TableRight />
        </Col>
        <Col>
          <TableLeft />
        </Col>
      </div>
    </div>
  );
};

export default BudgetVSActualTable;
