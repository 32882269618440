// Packages
import React from 'react';
import { useTable, usePagination } from 'react-table';

// Components

// CSS/SCSS
import './ArAgingTable.scss';

// Main Component
const ArAgingTable = () => {
  // Columns of the table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Acount Type',
        accessor: 'acountType',
      },
      {
        Header: 'Unit No.',
        accessor: 'unitNo',
      },
      {
        Header: 'Current',
        accessor: 'current',
      },
      {
        Header: '1t3',
        accessor: 'one',
      },
      {
        Header: '31-60',
        accessor: 'two',
      },
      {
        Header: '61-90',
        accessor: 'three',
      },
      {
        Header: '>90',
        accessor: 'four',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
    ],
    [],
  );

  // Data of the table
  const data = React.useMemo(() => {
    // Let's assume this data is from API
    const dataFromAPI = [
      {
        id: 1,
        name: 'Jargel G Astudillo',
        acountType: 'O',
        unitNo: 'A 254',
        current: '5,120,000,000',
        one: '5,120,000,000',
        two: '5,120,000,000',
        three: '5,120,000,000',
        four: '5,120,000,000',
        total: '5,120,000,000',
      },
      {
        id: 2,
        name: 'Jargel G Astudillo',
        acountType: 'T',
        unitNo: 'A 254',
        current: '5,120,000,000',
        one: '5,120,000,000',
        two: '5,120,000,000',
        three: '5,120,000,000',
        four: '5,120,000,000',
        total: '5,120,000,000',
      },
      {
        id: 3,
        name: 'Jargel G Astudillo',
        acountType: 'T',
        unitNo: 'A 254',
        current: '5,120,000,000',
        one: '5,120,000,000',
        two: '5,120,000,000',
        three: '5,120,000,000',
        four: '5,120,000,000',
        total: '5,120,000,000',
      },
      {
        id: 4,
        name: 'Jargel G Astudillo',
        acountType: 'O',
        unitNo: 'A 254',
        current: '5,120,000,000',
        one: '5,120,000,000',
        two: '5,120,000,000',
        three: '5,120,000,000',
        four: '5,120,000,000',
        total: '5,120,000,000',
      },
      {
        id: 5,
        name: 'Jargel G Astudillo',
        acountType: 'O',
        unitNo: 'A 254',
        current: '5,120,000,000',
        one: '5,120,000,000',
        two: '5,120,000,000',
        three: '5,120,000,000',
        four: '5,120,000,000',
        total: '5,120,000,000',
      },
    ];

    return dataFromAPI.map((data) => {
      return {
        name: data.name,
        acountType: data.acountType,
        unitNo: data.unitNo,
        current: data.current,
        one: data.one,
        two: data.two,
        three: data.three,
        four: data.four,
        total: data.total,
      };
    });
  }, []);

  // Initialize useTable and get its properties and functions
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize } */
  } = useTable({ columns, data }, usePagination);

  // To render on screen
  return (
    // apply the table props

    <div className='arAging-container-table'>
      <div className='list_container'>
        <table
          style={{ width: '100%' }}
          {...getTableProps()}
          className='data-table'
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, index) => (
                // Apply the header row props
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, idx) => (
                      // Apply the header cell props
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, index) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    key={index}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, idx) => {
                        // Apply the cell props
                        return (
                          <td
                            key={idx}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ArAgingTable;
