const Users = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31.518'
      height='22.063'
      viewBox='0 0 31.518 22.063'
    >
      <path
        id='users'
        d='M4.728,41.455A3.152,3.152,0,1,0,1.576,38.3,3.155,3.155,0,0,0,4.728,41.455Zm22.062,0A3.152,3.152,0,1,0,23.638,38.3,3.155,3.155,0,0,0,26.79,41.455Zm1.576,1.576H25.214a3.143,3.143,0,0,0-2.221.916,7.2,7.2,0,0,1,3.7,5.388h3.25a1.574,1.574,0,0,0,1.576-1.576V46.183A3.155,3.155,0,0,0,28.366,43.031Zm-12.607,0a5.516,5.516,0,1,0-5.516-5.516A5.513,5.513,0,0,0,15.759,43.031Zm3.782,1.576h-.409a7.615,7.615,0,0,1-6.747,0h-.409A5.675,5.675,0,0,0,6.3,50.28V51.7a2.364,2.364,0,0,0,2.364,2.364H22.85A2.364,2.364,0,0,0,25.214,51.7V50.28A5.675,5.675,0,0,0,19.541,44.607Zm-11.016-.66A3.142,3.142,0,0,0,6.3,43.031H3.152A3.155,3.155,0,0,0,0,46.183v1.576a1.574,1.574,0,0,0,1.576,1.576H4.821a7.222,7.222,0,0,1,3.7-5.388Z'
        transform='translate(0 -32)'
        fill='#fff'
      />
    </svg>
  );
};

export default Users;
