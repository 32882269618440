/* eslint-disable react/destructuring-assignment */
// packages
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Container } from 'react-bootstrap';

// CSS
import './UnitNumber.scss';
import UnitNumberTable from './UnitNumberTable';
// Components

// API
import { getBuildingUnitDetails } from 'api/request';

// CSS

// Main Component
const UnitNumber = ({
  handleNext,
  tableList,
  selectedBuilding,
  setSelectedBuilding,
  handleCancel,
  search,
  searchNow,
  setSearchNow,
}) => {
  // States
  const [unitList, setUnitList] = useState([]);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [updateMsg, setUpdateMsg] = useState({ message: '', status: '' });

  useEffect(() => {
    if (selectedBuilding !== '') {
      getBuildingUnitDetails(selectedBuilding, '', callback);
      setLoading(true);
      setUnitList([]);
    }
  }, [selectedBuilding]);

  useEffect(() => {
    if (searchNow) {
      const param = `filter[search]=${search}&`;

      setLoading(true);
      setUnitList([]);
      getBuildingUnitDetails(selectedBuilding, param, callback);
    }
  }, [searchNow]);

  useEffect(() => {
    // console.log(unitList);
  }, [unitList]);

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setUnitList(data.data);
        setLoading(false);
        setSearchNow(false);
      }

      if (status === 401 || status === 422) {
        console.log(data);
        setMsg('Something went wrong.');
      }
    }
  };

  // To render on screen
  return (
    <Card className='admin-form shadow-sm'>
      <Form>
        <Row>
          <Col md={3}>
            <Form.Group
              className='mb-4'
              controlId='formAmenity'
            >
              <Form.Label>Building Name</Form.Label>
              <Form.Select
                aria-label='Select Amenity'
                value={selectedBuilding}
                onChange={(e) => {
                  setSelectedBuilding(e.target.value);
                  setUpdateMsg({ message: '', status: '' });
                }}
              >
                {tableList &&
                  tableList.map((val, idx) => {
                    if (idx === 0 && selectedBuilding === '') {
                      setSelectedBuilding(val.id);
                    }

                    return (
                      <option
                        value={val.id}
                        key={val.id}
                      >
                        {val.attributes.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {loading && <p>Loading...</p>}
        {!loading && msg && <p>{msg}</p>}
        {!loading && (
          <>
            {unitList && unitList.length === 0 && <p>Table is empty</p>}
            {unitList && unitList.length > 0 && (
              <UnitNumberTable
                unitList={unitList}
                setUpdateMsg={setUpdateMsg}
              />
            )}
          </>
        )}

        <Form.Group className='mt-5 buttons'>
          <Container
            fluid
            style={{ padding: 0 }}
          >
            <Row>
              <Col className='message-text'>
                <span
                  style={{
                    color:
                      updateMsg.status === 'success' ? '#3bd14d' : '#ff5c5c',
                    display: 'block',
                  }}
                >
                  {`${updateMsg.message}`}
                </span>
              </Col>
              <Col className='submit-buttons'>
                <Button
                  className='submit'
                  variant='primary'
                  onClick={() => handleNext('Unit Details')}
                >
                  Next
                </Button>
                <Button
                  className='cancel'
                  variant='default'
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Form>
    </Card>
  );
};

export default UnitNumber;
