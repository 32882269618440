import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

// Components
import Main from '../../../components/Main';
import ScheduleForm from './ScheduleForm';
import ScheduleList from './ScheduleList/ScheduleList';
function Schedule() {
  return (
    <Fragment>
      <Helmet>
        <title>Finace CMS</title>
      </Helmet>
      <Main>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey='Schedule'
                id='Schedule'
                className='mb-3 finance-nav'
              >
                <Tab
                  eventKey='Schedule'
                  title='Schedule'
                >
                  <ScheduleForm />
                </Tab>
                <Tab
                  eventKey='Schedule List'
                  title='Schedule List'
                >
                  <ScheduleList />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Main>
    </Fragment>
  );
}

export default Schedule;
