/* eslint-disable no-unused-vars */
// import core & vendor packages

import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// import components below
import TabsUI from '../../../components/UI/TabsUI';
import MoveInOutForm from './Form';
import MoveInOutFilter from './Filter';
import MoveInOutTable from './Table';
import MoveInOutPagination from './Pagination';
import userContext from 'components/userContext/userContext';
import TablePagination from 'components/Pagination';
// API
import {
  getAmenitiesList,
  getBuildingLists,
  userAccounts,
  submitRequestAmenities,
  getRequestAmenities,
  moveinMoveout,
  getmoveinmoveoutList,
} from 'api/request';
// import assets below
import './Tabs.scss';

// main component
const MoveInOutTabs = () => {
  const [filterForm, setFilterForm] = useState('0');
  const [filterTable, setFilterTable] = useState('0');
  const [buildings, setBuildings] = useState([]);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [names, setNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [submit, setSubmit] = useState({});
  const [msg, setMsg] = useState({ message: '', status: '' });
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const [activeKey, setActiveKey] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const handleSelect = (e) => {
    if (e === 'moveInOutList') {
      console.log('change tab');
      setRefreshList(true);
    }
    setActiveKey(e);
  };
  useEffect(() => {
    setActiveKey('moveInOutForm');
  }, []);
  // Context Values

  // eslint-disable-next-line no-unused-vars
  const { data, setData } = useContext(userContext);
  const { user, userUnit } = data;
  const role = user?.attributes?.role;
  const refreshTable = () => {
    if (role === 'owner' || role === 'tenant') {
      const params = `?include=user,building,unit,requestedBy&building_id=${userUnit?.buildingId}&type=${filterTable}&unit_id=${userUnit?.id}`;

      getmoveinmoveoutList(params, requestAmenitiesCallback);
    } else {
      setLoading(true);

      const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}`;

      getmoveinmoveoutList(params, requestAmenitiesCallback);
    }
  };

  useEffect(() => {
    if (buildings.length === 0) {
      setLoading(true);
      getBuildingLists('?include=units', buildingListCallback);
    }
    setLoading(true);
    getBuildingLists('?include=units', buildingListCallback);
  }, []);

  useEffect(() => {
    setNames([]);

    if (selectedUnit !== '') {
      let params;

      if (user.attributes.role === 'owner') {
        params = `?filter[search]=&filter[unit]=${selectedUnit}&filter[role]=tenant`;
      } else {
        params = `?filter[search]=&filter[unit]=${selectedUnit}`;
      }

      userAccounts(params, nameCallback);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (Object.keys(submit).length) {
      setMsg({ message: '', status: '' });
      moveinMoveout(submit, submitCallback);
    }
  }, [submit]);
  // selected building
  useEffect(() => {
    if (selectedBuilding !== '') {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}&unit_id=${userUnit?.id}`;

        getmoveinmoveoutList(params, requestAmenitiesCallback);
      } else {
        console.log(selectedBuilding);
        const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}`;

        getmoveinmoveoutList(params, requestAmenitiesCallback);
      }
    }
  }, [selectedBuilding, filterTable, activeKey]);

  useEffect(() => {
    if (search) {
      if (role === 'owner' || role === 'tenant') {
        const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}&filter[search]=${search}&unit_id=${userUnit?.id}`;

        getmoveinmoveoutList(params, requestAmenitiesCallback);
      } else {
        const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}&filter[search]=${search}`;

        getmoveinmoveoutList(params, requestAmenitiesCallback);
      }
    }
  }, [search]);

  const selectPage = (page) => {
    console.log(selectedBuilding);
    const params = `?include=user,building,unit,requestedBy&building_id=${selectedBuilding}&type=${filterTable}&filter[search]=${search}${page}`;

    getmoveinmoveoutList(params, requestAmenitiesCallback);
  };

  const requestAmenitiesCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        console.log(data.data);
        setTableData(data.data);
        setLinks(data.links);
        setMeta(data.meta);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const buildingListCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setBuildings(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const callback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const nameCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setNames(data.data);
        setLoading(false);
      }

      if (status === 401 || status === 422) {
        console.log(data.message);
      }
    }
  };

  const submitCallback = async (res) => {
    const { data, status } = await res;
    if (res) {
      if (status === 200 || status === 201 || status === 202) {
        setLoading(false);
        setMsg({ message: data.message, status: 'success' });
      }

      if (status === 401 || status === 422) {
        setMsg({ message: data.message, status: 'deny' });
      }
    }
  };
  return (
    <TabsUI>
      <Tabs
        defaultActiveKey='moveInOutForm'
        className='mb-3'
        onSelect={(e) => handleSelect(e)}
        activeKey={activeKey}
      >
        <Tab
          eventKey='moveInOutForm'
          title='Move In/Out Form'
        >
          {loading && <p>Loading...</p>}
          {!loading && (
            <MoveInOutForm
              setSelectedUnit={setSelectedUnit}
              buildings={buildings}
              names={names}
              setNames={setNames}
              setSubmit={setSubmit}
              msg={msg}
              user={user}
              userUnit={userUnit}
              filter={filterForm}
              onFilter={setFilterForm}
              setMsg={setMsg}
            />
          )}
        </Tab>
        <Tab
          eventKey='moveInOutList'
          title='Move In/Out List'
        >
          <MoveInOutFilter
            setFilterTable={setFilterTable}
            setSearch={setSearch}
            buildings={buildings}
            setSelectedBuilding={setSelectedBuilding}
            selectedBuilding={selectedBuilding}
            user={user}
            userUnit={userUnit}
          />
          <MoveInOutTable
            filterTable={filterTable}
            tableData={tableData}
            user={user}
            refreshTable={refreshTable}
          />
          <TablePagination
            links={links}
            meta={meta}
            selectPage={selectPage}
          />
        </Tab>
      </Tabs>
    </TabsUI>
  );
};

export default MoveInOutTabs;
